import { Deserializable } from './deserializable';
import { Country, CountryList } from './country';
import { ReachTarget } from './constants';
import { CampaignPlanner } from './campaignPlanner.model';
import { CreatorApproval, CreatorApprovalPlanner } from './CreatorApproval.model';
import { CampaignPresentation } from './campaignPresentation.model';

export enum CampaignStatus {
  Approved = 'Approved', Finished = 'Finished', Rejected = 'Rejected', RequestedApproval = 'Update Requested',
  Draft = 'Draft', Pending = 'Pending', Prospect = 'Prospect'
}

export class CampaignScenario {
  type: string;
  investment: number;
  pricingModel: string;
  impressions: number;
  influencers: string;
  contents: number;
  platforms: string[];
}

export class Campaign implements Deserializable {


  constructor() {
    this.budget = '';
    this.name = '';
    this.goal = 0;
    this.campaignIdea = '';
    this.campaignTarget = '';
    this.creatorRequirements = '';
    this.launchDate = new Date();
    this.endDate = null;
    this.categories = new Set<number>();
    this.cover = '';
    this.image1 = '';
    this.image2 = '';
    this.image3 = '';
    this.image4 = '';
    this.platforms = [];
    this.publicToken = '';
    this.pricingModel = 'CPP';
    this.productShipping = 'campaign_like';
    this.isPublic = '1';
    this.creatorsAgeTo = 40;
    this.creatorsAgeFrom = 20;
    this.creatorsGender = null;
    this.creatorRequirements = '';
    this.launchDateString = '';
    this.endDateString = '';
    this.appCategories = [];
    this.countryList = [];
    this.revenue = 0;
    this.margin = 50;
    this.previewUrl = '';
    this.trackingLink = '';
    this.paidEvents = '';
    this.companyId = 0;
    this.currency = 'USD';
    this.target = ReachTarget.reach;
    this.tracksPerformance = false;
    this.restrictsCountry = false;
    this.autoApprovesCreators = false;
    this.budgetGross = 0;
    this.quantity= 0;
    this.commission = 0;
    this.cost = 0;
    this.tax = 0;
    this.profit = 0;
    this.shippingCost = 0;
    this.agencyShippingCostFee = 0;
    this.planner = [];
    this.desglose = '';
    this.proposalDetails = '';
    this.isCostEnabled = false;
  }
  id: number;
  owner: number;
  budget: string;
  brand: string;
  name: string;
  goal: number;
  campaignIdea: string;
  campaignTarget: string;
  creatorRequirements: string;
  hashTags = new Array<{display: string; value: string}>();
  mentions = new Array<{display: string; value: string}>();
  launchDate: Date;
  endDate: Date | null;
  status: CampaignStatus;
  categories: Set<number>;
  creatorsAgeTo: number;
  creatorsAgeFrom: number;
  creatorsGender: string;
  icon: string;
  cover: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  platforms: Array<string>;
  publicToken: string;
  pricingModel: string;
  productShipping: string;
  isPublic: string;
  creatorType = new Set<string>();
  postRequirements: string;
  dos: string;
  donts: string;
  launchDateString: string;
  endDateString: string;
  countryList: Array<Country>;
  revenue: number;
  margin: number;
  previewUrl: string;
  trackingLink: string;
  appCategories: Array<number>;
  paidEvents: string;
  companyId: number;
  brandId: number;
  currency: string;
  target: ReachTarget;
  quantity: number;
  cost: number;
  tracksPerformance: boolean;
  restrictsCountry: boolean;
  autoApprovesCreators: boolean;
  budgetGross: number;
  commission: number;
  tax: number;
  profit: number;
  shippingCost: number;
  agencyShippingCostFee: number;
  planner: Array<CampaignPlanner>;
  scenarios: Array<CampaignScenario>;
  campaignPresentation: CampaignPresentation | null;
  desglose: string;
  proposalDetails: string;
  isCostEnabled: boolean;

  private static getStatus(statusString: string): CampaignStatus {
    switch (statusString.toLowerCase()) {
      case 'approved': {
        return CampaignStatus.Approved;
      }
      case 'rejected': {
        return CampaignStatus.Rejected;
      }
      case 'pending': {
        return CampaignStatus.Prospect;
      }
      case 'request_approval': {
        return CampaignStatus.RequestedApproval;
      }
      case 'expired':
      case 'finished': {
        return CampaignStatus.Finished;
      }
      case 'draft': {
        return CampaignStatus.Draft;
      }
      default: {
        return CampaignStatus.RequestedApproval;
      }
    }
  }

  private static getPlatforms(platforms: string): Array<string> {
    return platforms.split(',').filter( x => x.length > 1);
  }

  getPlatformsForContents(): string[] {
    return this.platforms.filter(platform => ['instagram', 'tiktok', 'youtube'].includes(platform));
  }

  deserialize(input: any): this {
    this.id = input.id;
    this.owner = input.user_id;
    this.budget = input.campaign_budget;
    this.brand = input.brand_name;
    this.name = input.campaign_name ? input.campaign_name : this.name;
    this.goal = input.goal ? Number.parseInt(input.goal, 10) : 0;
    this.campaignIdea = input.campaign_idea ? input.campaign_idea : this.campaignIdea;
    this.campaignTarget = input.campaign_target ? input.campaign_target : this.campaignTarget;
    this.creatorRequirements = input.creator_requirement ? input.creator_requirement : this.creatorRequirements;
    if (input.required_s) {
      input.required_s.split(',').filter( x => x.length > 0).forEach(x => this.hashTags.push({ display: x, value: x }));
    }
    if (input.required_mentions) {
      input.required_mentions.split(',').filter( x => x.length > 0).forEach(x => this.mentions.push({ display: x, value: x }));
    }
    const launch = input.campaign_start_date?.split('-') ?? [];
    this.launchDate = launch.length >= 3 ?
      new Date(input.campaign_start_date) : new Date();
    const end = input.campaign_end_date?.split('-') ?? [];
    this.endDate = end.length >= 3 ?
      new Date(input.campaign_end_date) : null;
    this.status = input.campaign_status ? Campaign.getStatus(input.campaign_status) : this.status;
    this.categories = new Set<number>();
    this.icon = input.campaign_icon;
    this.cover = input.campaign_cover;
    this.image1 = input.campaign_image1;
    this.image2 = input.campaign_image2;
    this.image3 = input.campaign_image3;
    this.image4 = input.campaign_image4;
    this.creatorsGender = (input.preffered_gender) ?
      input.preffered_gender[0].toUpperCase() + input.preffered_gender.substring(1).toLowerCase() : this.creatorsGender;
    this.creatorsAgeFrom = input.age_range ? Number.parseInt(input.age_range, 10) : this.creatorsAgeFrom;
    this.creatorsAgeTo = input.age_to ? Number.parseInt(input.age_to, 10) : this.creatorsAgeTo;
    if(Array.isArray(input.platforms)) {
      this.platforms = input.platforms.length > 0 ? input.platforms : undefined;
    }else {
      this.platforms = input.platforms ? Campaign.getPlatforms(input.platforms) : this.platforms;
    }
    this.publicToken = input.public_token;
    this.pricingModel = input.pricing_model;
    this.productShipping = input.campaign_like ? input.campaign_like : this.productShipping;
    this.isPublic = input.is_public ? input.is_public : this.isPublic;
    if (input.creator_type) {
      input.creator_type.split(',').filter( x => x.length > 0).forEach( x => {
        if (x !== 'null') {
    this.creatorType.add(x);
}
});
    }
    this.postRequirements = input.requirement_example ? input.requirement_example : '';
    this.dos = input.dos ? input.dos : '';
    this.donts = input.donts ? input.donts : '';
    this.countryList = input.country ?
      input.country.split(',').filter( x => x.length > 0 && x !== '0').map( x =>
        CountryList.countryList.find(y => y.code_country === Number.parseInt(x, 10))) :
      new Array<Country>();
    this.revenue = input.revenue ? input.revenue : 0;
    this.margin = input.margin ? input.margin : 0;
    this.previewUrl = input.preview_url ? input.preview_url : '';
    this.trackingLink = input.tracking_link ? input.tracking_link : '';
    this.paidEvents = input.paidEvents ? input.paidEvents : '';
    this.companyId = input.company_id ? Number.parseInt(input.company_id, 10) : 0;
    this.brandId = input.brand_id ? Number.parseInt(input.brand_id, 10) : 0;
    this.currency = input.currency ? input.currency : '';
    this.tracksPerformance = !!input.tracks_performance;
    this.restrictsCountry = !!input.restricts_country;
    this.autoApprovesCreators = !!input.auto_approves_creators;
    this.setLaunchDateString();
    this.setEndDateString();
    this.budgetGross = input.budget_gross ? Number.parseInt(input.budget_gross, 10) : 0;
    this.commission = input.commission ? Number.parseInt(input.commission, 10) : 0;
    this.tax = input.tax ? Number.parseInt(input.tax, 10) : 0;
    this.profit = input.profit ? Number.parseInt(input.profit, 10) : 0;
    this.shippingCost = input.shipping_cost ? Number.parseFloat(input.shipping_cost) : 0;
    this.agencyShippingCostFee = input.agency_shipping_cost_fee ? Number.parseFloat(input.agency_shipping_cost_fee) : 0;
    this.planner = input.campaign_planner ? input.campaign_planner.map(p => ({
      influencerType: p.influencerType,
      followers: Number.parseInt(p.followers, 10),
      quantity: Number.parseInt(p.quantity, 10),
      feed: Number.parseInt(p.feed, 10),
      stories: Number.parseInt(p.stories, 10),
      reels: Number.parseInt(p.reels, 10),
      videos: Number.parseInt(p.videos, 10),
      menciones: Number.parseInt(p.menciones, 10),
      videosTiktok: Number.parseInt(p.videosTiktok, 10),
      name: p.name ?? '',
      targetSelect: p.targetSelect ?? '',
      profitNet: Number.parseInt(p.profitNet, 10),
      shippingCost: Number.parseFloat(p.shipping_cost),
      agencyShippingCostFee: Number.parseFloat(p.agency_shipping_cost_fee),
      quantityCost: Number.parseInt(p.quantityCost, 10),
      cost: Number.parseInt(p.cost, 10),
      budgetTable: Number.parseInt(p.budgetTable, 10),
      impresionsGoalTable: Number.parseInt(p.impresionsGoalTable, 10),
      targetCostTable: Number.parseInt(p.targetCostTable, 10),
      active: Number.parseInt(p.active, 10),
      table: Number.parseInt(p.table, 10),
    })) : [];
    this.scenarios = input.campaign_scenarios ? input.campaign_scenarios.map(s => ({
      type: s.type,
      investment: s.investment ? Number.parseFloat(s.investment) : null,
      pricingModel: s.pricing_model,
      impressions: s.impressions ? Number.parseInt(s.impressions, 10) : null,
      influencers: s.influencers,
      contents: s.contents ? Number.parseInt(s.contents, 10) : null,
      platforms: s.platforms?.split(',') ?? []
    })) : [];
    this.campaignPresentation = input.campaign_presentation ? new CampaignPresentation().deserialize(input.campaign_presentation) : null;
    this.desglose = input.desglose ? input.desglose : '';
    this.proposalDetails = input.proposal_details ? input.proposal_details : '';
    this.isCostEnabled = input.is_cost_enabled ? !!input.is_cost_enabled : false;
    return this;
  }

  public clone(): Campaign {
    const newCampaign = new Campaign();
    newCampaign.id = this.id;
    newCampaign.owner = this.owner;
    newCampaign.budget = this.budget;
    newCampaign.brand = this.brand;
    newCampaign.name = this.name;
    newCampaign.goal = this.goal;
    newCampaign.campaignIdea = this.campaignIdea;
    newCampaign.campaignTarget = this.campaignTarget;
    newCampaign.creatorRequirements = this.creatorRequirements;
    newCampaign.hashTags = this.hashTags;
    newCampaign.mentions = this.mentions;
    newCampaign.launchDate = this.launchDate;
    newCampaign.endDate = this.endDate;
    newCampaign.status = this.status;
    newCampaign.categories = this.categories;
    newCampaign.isPublic = this.isPublic;
    newCampaign.icon = this.icon;
    newCampaign.cover = this.cover;
    newCampaign.image1 = this.image1;
    newCampaign.image2 = this.image2;
    newCampaign.image3 = this.image3;
    newCampaign.image4 = this.image4;
    newCampaign.platforms = this.platforms;
    newCampaign.publicToken = this.publicToken;
    newCampaign.pricingModel = this.pricingModel;
    newCampaign.postRequirements = this.postRequirements;
    newCampaign.dos = this.dos;
    newCampaign.donts = this.donts;
    newCampaign.countryList = this.countryList.map(x => x);
    newCampaign.revenue = this.revenue;
    newCampaign.margin = this.margin;
    newCampaign.previewUrl = this.previewUrl;
    newCampaign.trackingLink = this.trackingLink;
    newCampaign.appCategories = this.appCategories;
    newCampaign.paidEvents = this.paidEvents;
    newCampaign.setLaunchDateString();
    newCampaign.setEndDateString();
    newCampaign.companyId = this.companyId;
    newCampaign.brandId = this.brandId;
    newCampaign.currency = this.currency;
    newCampaign.tracksPerformance = this.tracksPerformance;
    newCampaign.restrictsCountry = this.restrictsCountry;
    newCampaign.autoApprovesCreators = this.autoApprovesCreators;
    newCampaign.budgetGross = this.budgetGross;
    newCampaign.commission = this.commission;
    newCampaign.tax = this.tax;
    newCampaign.profit = this.profit;
    newCampaign.shippingCost = this.shippingCost;
    newCampaign.agencyShippingCostFee = this.agencyShippingCostFee;
    newCampaign.planner = this.planner;
    newCampaign.scenarios = this.scenarios;
    newCampaign.campaignPresentation = this.campaignPresentation;
    newCampaign.desglose = this.desglose;
    newCampaign.proposalDetails = this.proposalDetails;
    newCampaign.isCostEnabled = this.isCostEnabled;
    return newCampaign;
  }

  public initFromCampaign(campaign: Campaign): void {
    this.id = campaign.id;
    this.budget = campaign.budget;
    this.brand = campaign.brand;
    this.name = campaign.name;
    this.goal = campaign.goal;
    this.campaignIdea = campaign.campaignIdea;
    this.campaignTarget = campaign.campaignTarget;
    this.creatorRequirements = campaign.creatorRequirements;
    this.hashTags = campaign.hashTags;
    this.mentions = campaign.mentions;
    this.launchDate = campaign.launchDate;
    this.endDate = campaign.endDate;
    this.categories = campaign.categories;
    this.icon = campaign.icon;
    this.cover = campaign.cover;
    this.image1 = campaign.image1;
    this.image2 = campaign.image2;
    this.image3 = campaign.image3;
    this.image4 = campaign.image4;
    this.platforms = campaign.platforms;
    this.publicToken = campaign.publicToken;
    this.pricingModel = campaign.pricingModel;
    this.productShipping = campaign.productShipping;
    this.isPublic = campaign.isPublic;
    this.creatorsAgeTo = campaign.creatorsAgeTo;
    this.creatorsAgeFrom = campaign.creatorsAgeFrom;
    this.creatorsGender = campaign.creatorsGender;
    this.creatorType = campaign.creatorType;
    this.status = campaign.status;
    this.postRequirements = campaign.postRequirements;
    this.dos = campaign.dos;
    this.donts = campaign.donts;
    this.countryList = campaign.countryList.map(x => x);
    this.revenue = campaign.revenue;
    this.margin = campaign.margin;
    this.previewUrl = campaign.previewUrl;
    this.trackingLink = campaign.trackingLink;
    this.appCategories = campaign.appCategories;
    this.paidEvents = campaign.paidEvents;
    this.companyId = campaign.companyId;
    this.currency = campaign.currency;
    this.tracksPerformance = campaign.tracksPerformance;
    this.restrictsCountry = campaign.restrictsCountry;
    this.autoApprovesCreators = campaign.autoApprovesCreators;
    this.budgetGross = campaign.budgetGross;
    this.commission = campaign.commission;
    this.tax = campaign.tax;
    this.profit = campaign.profit;
    this.shippingCost = campaign.shippingCost;
    this.agencyShippingCostFee = campaign.agencyShippingCostFee;
    this.planner = campaign.planner;
    this.brandId = campaign.brandId;
    this.scenarios = campaign.scenarios;
    this.campaignPresentation = campaign.campaignPresentation;
    this.desglose = campaign.desglose;
    this.proposalDetails = campaign.proposalDetails;
    this.isCostEnabled = campaign.isCostEnabled;
    this.setLaunchDateString();
    this.setEndDateString();
  }

  isApproved(): boolean {
    return this.status === CampaignStatus.Approved;
  }
  
  getStatusColor(): string {
    switch (this.status) {
      case CampaignStatus.Approved: {
        return '#5DBB47';
      }
      case CampaignStatus.Finished: {
        return '#BB4747';
      }
      case CampaignStatus.Rejected: {
        return '#BB4747';
      }
      case CampaignStatus.RequestedApproval: {
        return '#B9A35C';
      }
      case CampaignStatus.Pending: {
        return '#FFCD33';
      }
      default: {
        return 'grey';
      }
    }
  }

  private getCategories(categories: string): void {
    this.categories = new Set<number>();
    let cats = categories.split(',');
    cats = cats.filter( x => x.length !== 0);
    cats.forEach( x => this.categories.add(Number.parseInt(x, 10)));
  }

  private setLaunchDateString(): void {
    this.launchDateString = this.launchDate.getFullYear()
      + '-' + (this.launchDate.getMonth() < 10 ? '0' +
        (this.launchDate.getMonth() + 1) : (this.launchDate.getMonth() + 1) )
      + '-' + (this.launchDate.getDate() < 10 ? '0' +
        this.launchDate.getDate() : this.launchDate.getDate());
  }

  private setEndDateString(): void {
    if (!this.endDate) {
      this.endDateString = '';
      return;
    }
    this.endDateString = this.endDate.getFullYear()
      + '-' + (this.endDate.getMonth() < 10 ? '0' +
        (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1) )
      + '-' + (this.endDate.getDate() < 10 ? '0' +
        this.endDate.getDate() : this.endDate.getDate());
  }


  public getString(items: Array<{value}>, tag: string): string {
    return items.reduce( (x, y) => x + tag + y.value + ' ', '');
  }

  public getAbbreviatedName(): string {
    return this.name.substr(0, 25) + (this.name.length >= 25 ? '...' : '');
  }

  public isPerformance(): boolean {
    return this.pricingModel === 'PER';
  }

  getCreatorTypeImages(): string {
    let icons = '';
    if (this.creatorType.size === 0) {
      // Default image
      return `<div class="d-inline-block w-25"><img class="round-highlight" src="../../assets/img/ic-nano.png" alt="Nano">
        <div class="f-color-gold">Nano</div></div><div class="d-inline-block w-25">
        <img class="round-highlight" src="../../assets/img/ic-micro.png" alt="Micro"><div class="f-color-gold">Micro</div></div>
        <div class="d-inline-block w-25"><img class="round-highlight" src="../../assets/img/ic-macro.png" alt="Macro">
        <div class="f-color-gold">Macro</div></div><div class="d-inline-block w-25">
        <img class="round-highlight" src="../../assets/img/ic-celebrity.png" alt="Celebrity"><div class="f-color-gold">Celebrity</div></div>`;
    }
    this.creatorType.forEach( type => {
      icons += '<div class="d-inline-block w-25"><img class="round-highlight" src="../../assets/img/ic-' + type + '.png"  alt="Icon">' +
        '<div class="f-color-gold">' + type + '</div></div>' ;
    });
    return icons;
  }

  getGrossPrice(price: number): number  {
    return price +
    price * (this.commission / 100) +
    price * (this.tax / 100) +
    price * (this.profit / 100);
}
}

