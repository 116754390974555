import {AfterViewInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Country, CountryList } from 'src/app/models/country';
import { SuggestedPrice } from 'src/app/models/suggestedPrice.model';
import { SuggestedPricesService } from 'src/app/services/suggested-price.service';
import { SuggestedPricesEditComponent } from '../suggested-prices-edit/suggested-prices-edit.component';
import { SuggestedPricesDeleteComponent } from '../suggested-prices-delete/suggested-prices-delete.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-suggestedprices',
  templateUrl: './suggested-prices.component.html',
  styleUrls: ['./suggested-prices.component.scss'],
})
export class SuggestedPricesComponent implements AfterViewInit, OnInit {


  columnsFollowers: string[] = [
    'followers_min',
    'followers_max',
  ]
  columnsPrices: string[] = [
    'instagram_feed_price_min',
    'instagram_feed_price_max',
    'instagram_reel_price_min',
    'instagram_reel_price_max',
    'instagram_story_price_min',
    'instagram_story_price_max',
    'youtube_video_exclusive_price_min',
    'youtube_video_exclusive_price_max',
    'youtube_video_mention_price_min',
    'youtube_video_mention_price_max',
    'tiktok_video_price_min',
    'tiktok_video_price_max',
  ]
  displayedColumns: string[] = [
    'id',
    'country',
    'creator_level']
    .concat( this.columnsFollowers)
    .concat(this.columnsPrices)
    .concat(['actions']) 


  dataSource: MatTableDataSource<SuggestedPrice>;
  priceCreation: SuggestedPrice;
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private suggestedPriceservice: SuggestedPricesService,public dialog: MatDialog) {
    this.priceCreation = new SuggestedPrice();
    this.dataSource = new MatTableDataSource([]);
   }

   ngOnInit(): void {
    this.fetchAll();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public openEdit(id: number): void {
    const [found, index] = this.find(id)
    const openDialog = this.dialog.open(SuggestedPricesEditComponent, {
      maxWidth: 1000,
      data: found,
    });
    openDialog.afterClosed().subscribe(() => {
      this.fetchAll()
    })
  }

  async fetchAll() {
    try {
      this.loading = true;
      const all = await this.suggestedPriceservice.httpAll().toPromise();
      console.log(all);
      this.dataSource.data = all;
      this.dataSource._updateChangeSubscription();
    } finally {
      this.loading = false;
    }
  }

  find(id: number): [SuggestedPrice, number] {
    const all = this.dataSource.data
    const found = all.find((item) => item.id === id)
    const index = all.indexOf(found)
    return [found, index]
  }

  resetCreate() {
    this.priceCreation = new SuggestedPrice();
  }

  async openCreate() {
    const openDialog = this.dialog.open(SuggestedPricesEditComponent, {
      width: '1000px',
      data: (new SuggestedPrice()).null(),
    });
    openDialog.afterClosed().subscribe(() => {
      this.fetchAll()
    })
  }

  async openDelete(id: number) {
    const [found, index] = this.find(id)
    if (!found) {
      throw new Error(`SuggestionPrice id:${id} not exists`)
    }
    const deleteDialog = this.dialog.open(SuggestedPricesDeleteComponent, {
      data: found
    });
    deleteDialog.afterClosed().subscribe(() => {
      this.fetchAll()
    })
  }

  exportExcel() {
    const workBook = XLSX.utils.book_new(); 
    const data = this.dataSource.data
    const formatedData = data.map((price: SuggestedPrice) => {
      return {
        "ID": price.id,
        "Country": price.country,
        "Level": price.creator_level,
        "Feed min": price.instagram_feed_price_min,
        "Feed max": price.instagram_feed_price_max,
        "Reel min": price.instagram_reel_price_min,
        "Reel max": price.instagram_reel_price_max,
        "Story min": price.instagram_story_price_min,
        "Story max": price.instagram_story_price_max,
        "Exclusive min": price.youtube_video_exclusive_price_min,
        "Exclusive max": price.youtube_video_exclusive_price_max,
        "Mention min": price.youtube_video_mention_price_min,
        "Mention max": price.youtube_video_mention_price_max,
        "Tiktok min": price.tiktok_video_price_min,
        "Tiktok max": price.tiktok_video_price_max,
      }
    })
    const workSheet = XLSX.utils.json_to_sheet(formatedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, 'inflencer.xlsx'); 
  }

}
