import { Component, Input, OnInit } from '@angular/core';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { Submission } from '../../../../models/submission.model';
import { Campaign } from '../../../../models/campaign.model';
import { Alert } from '../../../../models/interfaces';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { SocialNetworks } from 'src/app/models/SocialNetworks';

@Component({
  selector: 'app-campaign-statistics',
  templateUrl: './campaign-statistics.component.html',
  styleUrls: ['./campaign-statistics.component.scss']
})
export class CampaignStatisticsComponent implements OnInit {

  @Input() creators: Array<CreatorApproval>;
  @Input() submissions: Array<Submission>;
  @Input() isPerformance: boolean = false;
  @Input() campaign: Campaign;
  @Input() share = false;
  postedSubmissions: Array<Submission> = [];
  followers: number;
  likes: number;
  impressions: number;
  engagements: number;
  reach: number;
  comments: number;
  clicks: number;
  installs: number;
  conversions: number;
  savedShared: number;
  ctr: number;
  url: string;
  statsToDisplay = new Map<string, boolean>();
  uniqueUsers = new Set<number>();
  columns = ['Post format', 'Posted date', 
    'plannerViews', 'views', 'performanceViews',
    'plannerReach', 'reach', 'performanceReach',
    'plannerEngagement', 'engagement', 'performanceEngagement',
    'Goal Impressions', 'Likes', 'SavedShared', 'Comments',
    'Clicks', 'CTR', 'Conversions', 'Conversion rate'
  ];
  performanceColumns = ['Post format', 'Clicks', 'Conversions', 'Conversion rate'];
  alert: Alert = null;

  constructor(private ts: TranslateService) { }

  ngOnInit(): void {
    if (this.isPerformance) {
      this.performanceColumns.forEach(x => this.statsToDisplay.set(x, true));
    } else {
      this.columns.forEach(x => this.statsToDisplay.set(x, true));
    }
    this.postedSubmissions = this.submissions.filter( x => !!x.timePosted);
    this.comments = this.postedSubmissions.reduce( (x, y) => x + y.comments, 0);
    this.likes = this.postedSubmissions.reduce( (x, y) => x + y.likes, 0);
    this.reach = this.postedSubmissions.reduce( (x, y) => x + y.randomReach, 0);
    this.engagements = this.postedSubmissions.length > 0 ?
      this.postedSubmissions.reduce((sum, s) => sum + s.getPostEngagement(), 0) / this.postedSubmissions.length
      : 0;
    this.impressions = this.postedSubmissions.reduce( (x, y) => x + y.randomImpressions, 0);
    this.followers = 0;
    this.postedSubmissions.forEach( x => {
      if ((x.randomReach > 1 || this.isPerformance) && !this.uniqueUsers.has(x.userId)) {
        const creator = this.creators.find( y => y.userId === x.userId);
        this.followers += creator ? creator.getFollowers(SocialNetworks[x.platform]) : 0;
        this.uniqueUsers.add(x.userId);
      }
    });
    this.clicks = this.postedSubmissions.reduce( (x, y) =>
      x + Number.parseInt(y.clicks + '', 10), 0);
    this.installs = this.postedSubmissions.reduce( (x, y) =>
      x +  Number.parseInt(y.installsAndActions + '', 10), 0);
    this.savedShared = this.postedSubmissions.reduce( (x, y) =>
      x +  Number.parseInt(y.savedShared + '', 10), 0);
    this.conversions = this.postedSubmissions.length > 0 ?
      this.postedSubmissions.reduce( (x, y) => x + Number.parseInt(y.conversions + '', 10), 0)
      / this.postedSubmissions.length : 0;
    this.conversions = Math.round(this.conversions * 100) / 100;
    this.ctr = this.impressions > 0 ? Math.round(this.clicks / this.impressions * 10000) / 100 : 0;
    this.ctr = Math.round(this.ctr * 100) / 100;
    if (!this.isPerformance) {
    if (this.clicks === 0 ) {
      this.statsToDisplay.delete('Clicks');
    }
    if (this.installs === 0 ) {
      this.statsToDisplay.delete('Conversions');
    }
    if (this.conversions === 0 ) {
      this.statsToDisplay.delete('Conversion Rate');
    }
    if (this.ctr === 0 ) {
      this.statsToDisplay.delete('CTR');
    }
    } else {
      this.statsToDisplay.delete('Goal Impressions');
    }
    this.url = window.location.href.split('/').slice(0, 3).join('/') + '/campaignStats/'; // magic
  }

  copyMessage(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alert = {
      type: 'success',
      message: this.ts.instant('CAMPAIGN.SHARE.LINK.COPIED'),
    };
  }

  public export(): void {
    const readyToExport = this.submissions.filter(x => x.timePosted != null &&
      (x.likes !== 0 || x.randomImpressions !== 0 || x.clicks !== 0 || x.conversions !== 0))
      .map( x => {
      const creator = this.creators.find( y => x.userId === y.userId);
      return {
        username: creator.creator.getSocialName(x.platform),
        followers: creator.getFollowers(SocialNetworks[x.platform]),
        platform: x.platform,
        format: x.format,
        postedDate: `${x.timePosted.getDate()}/${x.timePosted.getMonth() + 1}/${x.timePosted.getFullYear()}`,
        impressions: x.randomImpressions,
        reach: x.randomReach,
        likes: x.likes,
        comments: x.comments,
        engagement: x.getPostEngagement(),
        clicks: x.clicks,
        savedShared: x.savedShared,
        conversions: x.installsAndActions,
        conversionRate: x.conversions,
        profileImage: creator.getProfilePicture(x.platform),
        contentUrl: (x.video ? x.video : '') + (x.image ? x.image : ''),
        campaign: this.campaign.name
      };
    });

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(readyToExport);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, this.campaign.name + '.xlsx'); // initiate a file download in browser
  }
}
