import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiktok-audience',
  templateUrl: './tiktok-audience.component.html',
  styleUrls: ['./tiktok-audience.component.css']
})
export class TiktokAudienceComponent implements OnInit {

  constructor() {
 return;
}

  ngOnInit(): void {
    return;
  }

}
