import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValuesFilter } from 'src/app/models/filters';

@Component({
  selector: 'app-select-search-filter',
  templateUrl: './select-search-filter.component.html',
  styleUrls: ['./select-search-filter.component.css']
})
export class SelectSearchFilterComponent implements OnInit {

  @Input() filterName: string;
  @Input() filterTitle: string;
  @Input() filterDescription: string;
  @Input() options: string[];
  @Input() clearAction: Observable<void>;

  @Output() filterChanged = new EventEmitter<ValuesFilter | null>();

  selectForm = this.fb.group({
    selected: [null]
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.selectForm.valueChanges.subscribe(() => this.formChanged());
    this.clearAction.subscribe(() => {
      this.selectForm.get('selected').setValue(null);
      this.formChanged();
    });
  }

  formChanged() {
    this.filterChanged.next(this.selectForm.get('selected').value !== null ?
      new ValuesFilter(this.filterName, [this.selectForm.get('selected').value], undefined) : null);
  }
}
