import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'emptyToDash'})
export class emptyToDashPipe implements PipeTransform {
  transform(value: string|null|undefined, prefix: string = ''): string {
    if (typeof value === 'string') {
      value = value.trim();
    }
    return (value === null || value === undefined || value === '') ? '-' : prefix+value;
  }
}