import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Creator } from 'src/app/models/creator.model';
import { CreatorApproval, CreatorApprovalPlanner } from 'src/app/models/CreatorApproval.model';
import { Submission } from 'src/app/models/submission.model';
import { CreatorService } from 'src/app/services/creator.service';

class HistoryListItem {
  approval: CreatorApproval;
  visible: { [socialNetwork: string]: boolean };
  submissions: Submission[];

  impressions(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.randomImpressions, 0);
  }

  reach(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.randomReach, 0);
  }

  likes(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.likes, 0);
  }

  comments(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.comments, 0);
  }

  postEngagement(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.getPostEngagement(), 0);
  }

  clicks(platform?: string) {
    return this.submissions
      .filter(s => !platform || s.platform === platform)
      .reduce((sum, val) => sum + val.clicks, 0);
  }

  platformSubmissions(platform: string) {
    return this.submissions.filter(s => s.platform === platform);
  }

  constructor(approval: CreatorApproval, submissions: Submission[]) {
    this.approval = approval;
    this.submissions = submissions;
    this.visible = {};
    approval.planner.forEach(p => this.visible[p.platform] = false);
  }
}

@Component({
  selector: 'app-creator-history',
  templateUrl: './creator-history.component.html',
  styleUrls: ['./creator-history.component.css'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({transform: 'translateY(-100%)', opacity: 0}),
        animate(800)
      ]),
      transition('* => void', [
        animate(800, style({transform: 'translateY(-100%)', opacity: 0}))
      ])
    ])
  ]
})
export class CreatorHistoryComponent implements OnInit {

  tiktok = faTiktok;

  @Input()
  creator: Creator;

  loading = false;
  historyItems: HistoryListItem[];

  constructor(private creatorService: CreatorService) { }

  ngOnInit(): void {
    this.loading = true;
    this.creatorService.getCreatorHistory(this.creator.id).subscribe(({ approvals, submissions }) => {
      this.loading = false;
      this.historyItems = approvals
        .filter(a => a.status === 'Confirmed')
        .map(a => {
          if (this.creator.hasInstagram()) {
            a.planner.push(CreatorApprovalPlanner.newPlanner('instagram', a ));
          }
          if (this.creator.hasYoutube()) {
            a.planner.push(CreatorApprovalPlanner.newPlanner('youtube', a));
          }
          if (this.creator.hasTikTok()) {
            a.planner.push(CreatorApprovalPlanner.newPlanner('tiktok', a));
          }
          return new HistoryListItem(a, submissions.filter(s => s.status === 'Approved' && s.campaignId === a.campaignId));
        }
        ).sort((c1, c2) => c2.approval.campaignId - c1.approval.campaignId);
    });
  }

}
