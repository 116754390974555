import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotificationDTO } from '../models/notificationDTO.model';
import { NotificationSubject } from '../models/notificationSubject.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private sendNotificationsUrl = 'notification/sendToMany';
  private addDeviceIdUrl = 'notification/device/add';
  private getUserNotificationsUrl = 'notification/getUnreadNotifications';
  private markAsReadUrl = 'notification/markAsRead';

  notifications = new Subject<NotificationSubject>();

  private notReadedNotifications: BehaviorSubject<NotificationDTO[]> = new BehaviorSubject<NotificationDTO[]>(undefined);

  constructor(private rs: RestService) {}

  public sendNotifications(recipients: string, body: string, title: string): Observable<any> {
    return this.rs.post(this.sendNotificationsUrl, {recipients, body, title});
  }

  public addDevice(userId: number ,deviceId: string, token: string): Observable<any> {
    return this.rs.post(this.addDeviceIdUrl, {user_id: userId ,deviceId, token});
  }

  public getUserUnreadedNotifications(userId: number): Observable<any> {
    return this.rs.get(this.getUserNotificationsUrl, `user_id=${userId}`);
  }

  public markAsRead(notificationIds: any[]): Observable<void> {
    const newNotReaded = this.notReadedNotifications.getValue().filter(notification => !notificationIds.includes(notification.id));
    this.notReadedNotifications.next(newNotReaded);
    this.notifications.next({newNotificationArr: newNotReaded});
    return this.rs.post(this.markAsReadUrl , JSON.stringify({notification_id: notificationIds}));
  }

  public getNotReadedNotifications(): NotificationDTO[] {
    return this.notReadedNotifications.value ?? []
  }

  public notReadedNotificactions(): Observable<NotificationDTO[]>{
    return this.notReadedNotifications;
  }

  public setNotReadedNotifications(value: NotificationDTO[]): void{
    return this.notReadedNotifications.next(value);
  }

  public listenNotifications(): Observable<NotificationSubject> {
    return this.notifications;
  }
}
