import { Campaign } from './campaign.model';
import { CreatorApproval } from './CreatorApproval.model';
import { Deserializable } from './deserializable';

export class CommercialProposal implements Deserializable {

    caseStudies: string[];
    shareToken: string;
    campaignId: number;
    campaign: Campaign;
    creators: CreatorApproval[];

    deserialize(input: any): this {
        this.caseStudies = input.case_studies;
        this.shareToken = input.share_token;
        this.campaignId = input.campaign_id;
        this.campaign = new Campaign().deserialize(input.campaign);
        this.creators = input.creators.map(c => new CreatorApproval().deserialize(c));
        return this;
    }

}
