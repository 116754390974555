import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Country, CountryList } from 'src/app/models/country';
import { CreatorApproval, CreatorApprovalPlanner } from 'src/app/models/CreatorApproval.model';
import { CreatorsPlannerEditComponent } from '../edit/creators-planner-edit.component';
import * as XLSX from 'xlsx';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Submission } from 'src/app/models/submission.model';
import { RemoveFunctions } from 'src/app/support/typescript_helpers';
import { Alert } from 'src/app/models/interfaces';
import { Creator } from 'src/app/models/creator.model';
import { AudienceDetailsDialogComponent } from '../../../audience/audience-details-dialog/audience-details-dialog.component';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { CreatorPlannerContentsComponent } from '../contents/contents.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserService } from 'src/app/services/user.service';


class Total {
  approved = false;
  netNegotiatedPrice = 0;
  feeds = 0;
  reels = 0;
  stories = 0;
  mentions = 0;
  exclusives = 0;
  tiktokVideos = 0;
  views = 0;
  reach = 0;
  engagements = 0;
}

interface TableItem {
  followers: number;
  country: string;
  name: string;
  platform: string;
  creatorApproval: CreatorApproval;
  planner: CreatorApprovalPlanner;
  netNegotiatedPrice: number;
  agencyFee: number;
  profit: number;
  feeds: number;
  reels: number;
  stories: number;
  exclusives: number;
  mentions: number;
  tiktokVideos: number;
  reach: number;
  views: number;
  engagements: number;
}

@Component({
  selector: 'app-creators-planner-table',
  templateUrl: './creators-planner-table.component.html',
  styleUrls: ['./creators-planner-table.component.scss'],
})
export class CreatorsPlannerComponent implements AfterViewInit, OnInit {

  @Input() campaign: Campaign;
  @Output() saveCreator = new EventEmitter<void>();
  @Input() creatorList: CreatorApproval[];
  @Input() submissions: Submission[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  alert: Alert;
  loading = false;
  totalApprovedFilter = true;
  displayedColumns: string[] = [
    'country',
    'name',
    'platform',
    'followers',
    'approved',
    'netNegotiatedPrice',
    'agencyFee',
    'profit',
    'feeds',
    'reels',
    'stories',
    'exclusives',
    'mentions',
    'tiktokVideos',
    'reach',
    'views',
    'engagements',
    'actions'
  ];
  dataSource: MatTableDataSource<TableItem> = new MatTableDataSource([]);
  total = new Total();

  targetCost: number;
  currentCost = 0;
  currentImpressions = 0;

  isCostEnabled = false;
  isAdmin = false;

  constructor(private campaignService: CampaignService, public dialog: MatDialog, userService: UserService) {
    this.dataSource.sortingDataAccessor = this.tableSort;
    this.isAdmin = userService.isAdmin();
  }

  ngOnInit(): void {
    this.updateTable();
    this.updateTargetCost();
    this.isCostEnabled = this.campaign.isCostEnabled;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  changeIsCostEnabled(status: boolean) {
    this.isCostEnabled = status;
    this.campaignService.enableCosts(this.campaign, status).subscribe(() => {
      this.isCostEnabled = status;
      this.campaign.isCostEnabled = status;
      this.creatorList.forEach(creatorApproval => {
        if (creatorApproval.campaign) {
          creatorApproval.campaign.isCostEnabled = status;
        }
      });
    }, err => {
      this.isCostEnabled = !status;
      this.campaign.isCostEnabled = status;
      this.creatorList.forEach(creatorApproval => {
        if (creatorApproval.campaign) {
          creatorApproval.campaign.isCostEnabled = status;
        }
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  tableSort(data: any, sortName: string): string | number {
    if (typeof data[sortName] === 'string') {
      return data[sortName].toLocaleLowerCase();
    }
    if (typeof(data[sortName]) === 'undefined') {
      return data.planner[sortName];
    }
    return data[sortName];
  };

  updateTable() {
    this.dataSource.data = this.fetchData();
    this.updateTotal();
    this.updateTargetCost();
    this.updateCostImpressions();
  }

  updateTotal() {
    this.total = this.calcTotal(this.dataSource.data);
  }

  updateTargetCost() {
    this.targetCost = this.calcTargetCost();
  }

  fetchData() {
    const all = [];
    for (const creatorApproval of this.creatorList) {
      for (const platform of this.campaign.platforms) {
        if (!creatorApproval[platform]) {
          continue;
        }
        const planner = creatorApproval.getPlannerOrBuild(platform);
        const row: TableItem = {
          country : creatorApproval.creator.country,
          name: creatorApproval.creator[platform].username,
          platform,
          followers: creatorApproval.creator.getFollowers(platform),
          creatorApproval,
          planner,
          netNegotiatedPrice: planner.netNegotiatedPrice(),
          agencyFee: planner.agencyFee,
          profit: planner.profit,
          feeds: planner.content.feeds,
          reels: planner.content.reels,
          stories: planner.content.stories,
          exclusives: planner.content.exclusives,
          mentions: planner.content.mentions,
          tiktokVideos: planner.content.tiktokVideos,
          views: planner.totalViews(),
          reach: planner.totalReach(),
          engagements: planner.totalEngagements(),
        };
        all.push(row);
      }
    }
    return all;
  }

  calcTotal(table: TableItem[]): Total{
    const data = table.filter(x => this.totalApprovedFilter ? x.planner.approved : true );
    let total = new Total();
    data.forEach((item) => {
      const sum = (prop: keyof TableItem) => item[prop] + (total[prop] ?? 0);
      total = {
        approved: this.totalApprovedFilter,
        netNegotiatedPrice: sum('netNegotiatedPrice'),
        feeds: sum('feeds'),
        reels: sum('reels'),
        stories: sum('stories'),
        mentions: sum('mentions'),
        exclusives: sum('exclusives'),
        tiktokVideos: sum('tiktokVideos'),
        views: sum('views'),
        reach: sum('reach'),
        engagements: sum('engagements'),
      };
    });
    return total;
  }

  calcTargetCost(): number {
    const budgetNet =
      this.campaign.budgetGross -
      this.campaign.budgetGross * (this.campaign.commission / 100) -
      this.campaign.budgetGross * (this.campaign.tax / 100);
    return  budgetNet - budgetNet * (this.campaign.profit / 100);
  }

  updateCostImpressions() {
    this.currentCost = this.dataSource.data
      .filter((i) => i.planner.approved)
      .reduce( (sum, item) => sum + item.planner.netNegotiatedPrice(), 0);

    this.currentImpressions = this.dataSource.data
      .filter((i) => i.planner.approved)
      .reduce((sum, item) => sum + item.planner.totalViews(), 0);
  }

  updatePlanner(planner: CreatorApprovalPlanner) {
    const userApproval = this.creatorList.find(item => Number(item.id) === Number(planner.approval_id));
    userApproval.planner = (userApproval.planner || [])
      .filter(item => item.id !== planner.id)
      .concat(planner);
    this.updateTable();
  }

  openEdit(userApprovalId: number, platform: string): void {
    const userApproval = this.creatorList.find(item => Number(item.id) === Number(userApprovalId));
    const found = this.dataSource.data.find((item) =>
      item.planner.approval_id === userApprovalId && item.planner.platform === platform
    );
    const openDialog = this.dialog.open(CreatorsPlannerEditComponent, {
      maxWidth: 1000,
      data: { 
        planner: found.planner, 
        campaign: this.campaign, 
        creator: found.creatorApproval.creator,
        userApproval,
      },
    });
    openDialog.afterClosed().subscribe((planner) => {
      if (!planner) {
        return;
      }
      userApproval.addToPlanner(planner);
      this.updateTable();
    });
  }

  openContentPlanner(username: string, userApprovalId: number, platform: string): void {
    const creatorApproval = this.creatorList.find(item => Number(item.id) === Number(userApprovalId));
    const planner = creatorApproval.planner.find(
      item => item.platform == platform
    );
    const contents = planner?.plannerContents || [];
    const openDialog = this.dialog.open(CreatorPlannerContentsComponent, {
      // maxWidth: 1000,
      data: { 
        campaign: this.campaign, 
        creatorApproval,
        contents,
        platform,
        username,
      },
    });
    openDialog.afterClosed().subscribe((planner: CreatorApprovalPlanner) => {
      planner && this.updatePlanner(planner);
    });
  }

  toogleApproved(row: TableItem) {
    row.planner.approved = !row.planner.approved;
    this.updateTotal();
    const partialPlanner = {
      approval_id: row.planner.approval_id,
      platform: row.planner.platform,
      approved: row.planner.approved,
    };
    this.campaignService.toggleApproveCampaignCreatorPlanner(partialPlanner, this.campaign).subscribe(() => {
      //
    }, err => {
      row.planner.approved = !row.planner.approved;
      this.alert = { message: err.error.errors, type: 'danger' };
    });

  }

  toggleTotalApproved() {
    this.totalApprovedFilter = !this.totalApprovedFilter;
    this.updateTable();
  }

  getCountryCode(country?: string) {
    return (
      CountryList.countryList.find(
        (c) => 
          c.country.toLowerCase() === country?.toLowerCase()
          || c.code2.toLowerCase() === country.toLowerCase()
      )?.code2 ?? '-'
    );
  }

  openAudienceModal(row: TableItem) {
    if (row.creatorApproval.creator.audienceCount() === 0) {
      return;
    }
    this.dialog.open(AudienceDetailsDialogComponent, {
      width: '1305px',
      height: '782px',
      data: {
        creator: row.creatorApproval.creator,
        creatorApproval : row.creatorApproval,
        updateProfile: false,
        campaign: this.campaign,
      },
      panelClass: 'audience-dialog-container'
    });
  }


  exportExcel() {
    const decimals = (x: number, y: number ) =>
      (x || x === 0) ?  x.toLocaleString( undefined, { minimumFractionDigits: y, maximumFractionDigits: 2 }) : null;
    const workBook = XLSX.utils.book_new();
    const data = this.dataSource.data;
    const workSheet = XLSX.utils.json_to_sheet(data.map(row => ({
      id: decimals(row.planner.id, 0),
      userApprovalId: decimals(row.planner.approval_id, 0),
      country: row.country,
      name: row.name,
      platform: row.platform,
      followers: decimals(row.followers, 0),
      approved: row.planner.approved,
      netNegotiatedPrice: decimals(row.planner.netNegotiatedPrice(), 2),
      agencyFee: decimals(row.planner.agencyFee, 2),
      profit: decimals(row.planner.profit, 2),
      feeds: decimals(row.planner.content.feeds, 0),
      reels: decimals(row.planner.content.reels, 0),
      stories: decimals(row.planner.content.stories, 0),
      mentions: decimals(row.planner.content.mentions, 0),
      exclusive: decimals(row.planner.content.exclusives, 0),
      videos: decimals(row.planner.content.tiktokVideos, 0),
      views: decimals(row.views, 2),
      reach: decimals(row.reach, 2),
      engagements: decimals(row.engagements, 2),
    })));
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `creators-planner-${this.campaign.id}.xlsx`);
  }

}
