import { LocalStorageService } from 'ngx-webstorage';
import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationDTO } from '../models/notificationDTO.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { NotificationTypes } from '../ui/footer/notification/const';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  creatorChatNotificationData = new BehaviorSubject<{openChat: boolean; creatorId: number}>(null);
  creatorSubmissionData = new BehaviorSubject<{openSubmissionModal: boolean; creatorId: number; submissionId: number}>(null);

  messaging = getMessaging();

  constructor(
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    private router: Router,
    private localStorageService: LocalStorageService
    ) {}

  async requestPermission() {
    try {
      const currentToken = await getToken(this.messaging, { vapidKey: environment.firebase.vapidKey });
      if (!currentToken) {
        console.log('No registration token available. Request permission to generate one.');
      }
      const deviceInfo = this.deviceService.getDeviceInfo();
      const deviceId = `${deviceInfo.browser.replace(/-/g,'')}-${deviceInfo.os_version.replace(/-/g,'')}`;
      this.notificationService.addDevice(parseInt(this.localStorageService.retrieve('userId')), deviceId, currentToken).subscribe();
    } catch (err){
      console.log('An error occurred while retrieving token. ', err);
    }
  }

  listenMessages(): void {
    onMessage(this.messaging, payload => {
      const notification: NotificationDTO = {
        id: parseInt(payload.data.id),
        title: payload.notification.title,
        body: payload.notification.body,
        readed: false,
        data: payload.data,
        time: new Date()
      };
      this.notificationService.notifications.next({newNotification: notification});
    });
  }

  listenBackgroundMessages(): void {
    // To listen for background messages from firebase-messaging-sw postMessage method
    navigator.serviceWorker.addEventListener('message', async (event) => {
      if(!event.data?.isFirebaseMessaging){
        //Si llega con url y el flag readed en true quiere decir que se clickeo sobre la background notification.
        if (event.data?.payload?.redirectRelativeUrl && event.data.readed){
          await this.router.navigate([event.data.payload.redirectRelativeUrl]);
          if (NotificationTypes.NEGOTIATION_CHAT_ARR.includes(event.data.payload.name)) {
            this.creatorChatNotificationData.next({
              openChat: true,
              creatorId: event.data.payload.creatorId
            });
          } else if (event.data.payload.name === NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST) {
            this.creatorSubmissionData.next({
              openSubmissionModal: true,
              creatorId: event.data.payload.creatorId,
              submissionId: event.data.payload.submissionId,
            });
          }

        } else {
          const notification: NotificationDTO = {
            id: event.data.payload.id || event.data.id,
            title: event.data.title,
            body: event.data.body,
            readed: event.data.readed,
            data: event.data.payload,
            time: new Date()
          };
          this.notificationService.notifications.next({newNotification: notification});
        }
      }
    });
  }

  public checkOpenNegociationChat(): Observable<{openChat: boolean; creatorId: number}> {
    return this.creatorChatNotificationData;
  }

  public checkOpenCurrentSubmission(): Observable<{openSubmissionModal: boolean; creatorId: number; submissionId: number}> {
    return this.creatorSubmissionData;
  }
}
