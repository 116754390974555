import { Component, Input, OnInit } from '@angular/core';
import { Creator } from '../../../../../models/creator.model';
import { CreatorApproval, CreatorApprovalPlanner } from '../../../../../models/CreatorApproval.model';
import { Submission } from '../../../../../models/submission.model';
import { SuggestedPrice } from '../../../../../models/suggestedPrice.model';
import { YoutubeAudience } from '../../../../../models/youtube.model';

@Component({
  selector: 'app-social-youtube-audience',
  templateUrl: './audience-youtube.component.html',
  styleUrls: ['./audience-youtube.component.css']
})
export class AudienceYoutubeComponent implements OnInit {

  @Input() public creator: Creator;
  @Input() public creatorSubmissions: Submission[];
  @Input() public suggestedPrices: SuggestedPrice[];
  @Input() public updateProfile: boolean;
  @Input() public creatorApproval?: CreatorApproval;

  public creatorPrice: SuggestedPrice;
  public youtubeAud: YoutubeAudience;
  public labels: string[];
  public maleData: number[];
  public femaleData: number[];

  constructor() {
  }

  public ngOnInit(): void {
    this.creatorPrice = this.suggestedPrices.find(
      (price) => {
        const followers = this.creator.getFollowers('instagram');
        if (price.creator_level === 'Celebrity') {
          return (followers >= price.followers_min && followers <= price.followers_max) || followers > price.followers_max;
        } else if (price.creator_level === 'Micro') {
          return (followers >= price.followers_min && followers <= price.followers_max) || followers < price.followers_min;
        }
        return (followers >= price.followers_min && followers <= price.followers_max);
      }
    ) || new SuggestedPrice();
    if (this.creator.hasYoutubeAudience()) {
      this.youtubeAud = this.creator.youtube.audience;
    }
    this.labels = this.creator.youtube.audience.gendersChartSeries.map((gender) => gender.name);
    this.femaleData = this.creator.youtube.audience.gendersChartSeries.map((gender) => gender.series[1]).map((gender) => gender.value);
    this.maleData = this.creator.youtube.audience.gendersChartSeries.map((gender) => gender.series[0]).map((gender) => gender.value);
  }

  public setVideoUrl(id) {
    return 'https://www.youtube.com/embed/' + id;
  }
}
