import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { UserRole } from '../models/user.model';
import { EnvironmentService } from './environment.service';
import { Campaign } from '../models/campaign.model';
import { AppService } from './app.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  get authToken(): string {
    return this._authToken;
  }

  private baseUrl: string = this.environmentService.getInfluencersURI();
  loginUrl = this.baseUrl + 'login';
  logoutUrl = this.baseUrl + 'logout';
  registerUrl = this.baseUrl + 'register';
  onboardingUrl = this.baseUrl + 'onboard';
  validateOnboardingUrl = this.baseUrl + 'validateOnboarding';
  resetPasswordUrl = this.baseUrl + 'recover';
  validateAccountUrl = this.baseUrl + 'validateAccount';
  deleteUserUrl = this.baseUrl + 'deleteUser';

  private _authToken: string = null;
  logout$ = new Subject<any>();
  login$ = new Subject<any>();
  private _isAdmin = false;

  constructor(private http: HttpClient, private readonly environmentService: EnvironmentService, private ss: LocalStorageService, private router: Router, private translate: TranslateService, private appService: AppService) {
    this._authToken = this.ss.retrieve('token');
  }

  getLang(): string {
    return this.translate.currentLang;
  }

  public getLogoutEvent(): Subject<any> {
    return this.logout$;
  }

  get getLoginEvent(): Subject<any> {
    return this.login$;
  }

  login(email: string, password: string): Observable<any> {
    this._authToken = null;
    return this.post(this.loginUrl, { email, password }).
      pipe(tap(x => {
        this._authToken = x.access_token;
        this.ss.store('token', x.access_token);
        this.ss.store('email', email);
        this.ss.store('roles', x.roles);
        const aux = email.split('@');
        this._isAdmin = aux.length === 2 && (aux[1] === 'lemmonet.com' || aux[1] === 'duhcreators.com');
        this.appService.getConfigBackendFresh();
        this.login$.next();
      }));
  }

  async logout(): Promise<Observable<any>> {
    this._authToken = null;
    this.ss.clear('token');
    this.ss.clear('email');
    await this.router.navigate(['/login']);
    this.logout$.next();
    this._isAdmin = false;
    return this.post(this.logoutUrl, null);
  }

  register(email: string, password: string): Observable<any> {
    return this.post(this.registerUrl, { email, password });
  }

  onboarding(firstname: string, lastname: string, companyName: string, email: string, password: string, country: string, industry: string,
    companyRole: string, phoneNumber: string) {
    return this.post(this.onboardingUrl, {
      firstname,
      lastname,
      company_name: companyName,
      email,
      password,
      country,
      industry,
      company_role: companyRole,
      mobile_number: phoneNumber
    });
  }

  validateAccountWithInfo(token: string, firstname?: string, lastname?: string, password?: string, country?: string, industry?: string,
    companyRole?: string, phoneNumber?: string) {
    return this.post(this.validateAccountUrl, {
      token,
      firstname,
      lastname,
      password,
      country,
      industry,
      company_role: companyRole,
      mobile_number: phoneNumber
    });
  }

  validateOnboarding(companyId: number) {
    const token = this.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      })
    };
    return this.http.post(`${this.baseUrl}companies/${companyId}/users/validate`, { company_id: companyId }, httpOptions);
  }

  private post(url: string, entity: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json'
      })
    };
    return this.http.post(url, entity, httpOptions).pipe(share());
  }

  isLoggedIn(): boolean {
    return this._authToken != null;
  }

  resetPassword(email: string) {
    return this.post(this.resetPasswordUrl, { email });
  }

  isAdmin(): boolean {
    const email = this.ss.retrieve('email');
    if(email){
      const aux = email.split('@');
      return aux.length === 2 && (aux[1] === 'lemmonet.com' || aux[1] === 'duhcreators.com');
    }
    return false;
  }

  deleteUser(userId: number) {
    const token = this.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      })
    };
    return this.http.post(this.deleteUserUrl, { user_id: userId }, httpOptions);
  }

  getCurrentUserRoles(): UserRole[] {
    const userRoles: any[] | undefined = this.ss.retrieve('roles');
    return userRoles?.map(UserRole.deserialize) ?? [];
  }

  getCurrentUserId() {
    return this.ss.retrieve('userId');
  }

  hasPrivileges(privileges: string[], companyId: number, brandId?: number, campaignId?: number): boolean {
    if (this.isAdmin()) {
      return true;
    }
    const userPrivileges = this.getCurrentUserRoles()
      .filter(r => r.companyId === companyId &&
        (r.brandId === null || (r.brandId === brandId && r.campaignId === null) || (r.campaignId === campaignId)))
      .reduce((mostSpecific, ur) =>
        (ur.brandId === null && mostSpecific.find(r => r.brandId !== null)) ||
          (ur.campaignId === null && mostSpecific.find(r => r.campaignId !== null)) ? mostSpecific : mostSpecific.concat([ur]), [])
      .reduce((privilegeList, ur) => privilegeList.concat(ur.role?.privileges), []);

    return userPrivileges.filter(p => !!privileges.find(name => p.name === name)).length > 0 ?? false;
  }
}
