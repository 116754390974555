import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-create-campaign-image-picker',
  templateUrl: './create-campaign-image-picker.component.html',
  styleUrls: ['./create-campaign-image-picker.component.scss']
})
export class CreateCampaignImagePickerComponent implements OnInit {

  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) {
  }

  encapsulation: ViewEncapsulation.None;
  @Input() initialImage = '';
  @Input() isCover = false;
  @Input() isLogo = false;
  @Input() canUploadVideo = false;
  @Output() newImage = new EventEmitter<{ media: string; file: File; type: string }>();
  fileChangedEvent: Event = null;
  mediaType: 'image' | 'video';
  video: SafeUrl;
  mediaFile: File;
  croppedImage = '';
  displayImage: string;
  displayVideo: SafeUrl = '';
  modalRef: NgbModalRef;

  ngOnInit(): void {
    // this.prevCropped === '' ? this.croppedImage = this.prevCropped : this.croppedImage = '';
    if (this.initialImage && this.initialImage.includes('vimeo')) {
      this.displayVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.initialImage);
    } else {
      this.displayImage = this.initialImage;
    }
  }

  fileChangeEvent(event: Event): void {
    this.fileChangedEvent = event;
    const files = (event.target as HTMLInputElement).files;
    if (files.length > 0 ) {
      this.mediaFile = files[0];
      this.mediaType = files[0].type.includes('image') ? 'image' : 'video';
      if (this.mediaType === 'video') {
        this.video = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.mediaFile));
      }
    }
  }

  isVimeo(): boolean {
    return this.displayVideo && this.displayVideo.toString().includes('vimeo');
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  open(content: TemplateRef<CreateCampaignImagePickerComponent>): void {
    this.croppedImage = '';
    this.modalRef = this.modalService.open(content,{size: 'lg'});
  }

  close(): void {
    this.displayImage = this.croppedImage;
    this.displayVideo = this.video;
    this.newImage.emit({
      media: this.mediaType === 'image' ? this.displayImage : this.displayVideo.toString(),
      type: this.mediaType,
      file: this.mediaFile
    });
    this.modalRef.close();
  }

  clearMedia() {
    this.newImage.emit({
      type: this.mediaType,
      media: null,
      file: null
    });
    this.croppedImage = '';
    this.fileChangedEvent = null;
    this.video = null;
    this.mediaFile = null;
    this.mediaType = null;
  }
}
