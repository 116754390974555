import { EventEmitter, Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ConfigBackend } from '../models/configBackend.model';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { share, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _configBackendStore: ConfigBackendStorage;

  private _httpReplayCache: Observable<Object>;
  private _httpReplayCacheTime: number;

  get configBackend(): ConfigBackend {
    this.getConfigBackend();
    return this._configBackendStore ? this._configBackendStore.configBackend : null;
  }

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private localStorageService: LocalStorageService, private translateService: TranslateService) {
  }

  async getConfigBackend() : Promise<ConfigBackend> {
    if (this._configBackendStore && !this._configBackendStore.isExpired()) {
      return this._configBackendStore.configBackend;
    }
    const stored = this.localStorageService.retrieve('configBackend');
    this._configBackendStore = stored ? new ConfigBackendStorage(stored) : null;
    if (!this._configBackendStore || this._configBackendStore.isExpired()) {
      return await this.getConfigBackendFresh();
    }
    return this._configBackendStore.configBackend;
  }

  async getConfigBackendFresh(): Promise<ConfigBackend> {
    let httpQuery;
    if (this._httpReplayCache && this._httpReplayCacheTime > (Date.now() - 1000 * 20)) {
      httpQuery = this._httpReplayCache;
    } else {
      httpQuery = this.getHttp('/config');
      this._httpReplayCache = httpQuery;
      this._httpReplayCacheTime = Date.now();
    }
    const response = await httpQuery.toPromise();
    const configBackend = new ConfigBackend(response);
    const configBackendStore = new ConfigBackendStorage(configBackend);
    this.localStorageService.store('configBackend', configBackendStore.serialize());
    this._configBackendStore = configBackendStore;
    return this._configBackendStore.configBackend;
  }

  getLang() {
    return this.translateService.currentLang;
  }

  getHttp(url: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
      })};
    const token = this.localStorageService.retrieve('token');
    if (token) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'bearer ' + token);
    }
    const baseUrl = this.environmentService.getInfluencersURI().replace(/\/$/, '');
    return this.http.get(baseUrl + url, httpOptions).pipe(shareReplay());
  }  
}

export class ConfigBackendStorage {
  expiration: number;
  configBackend: ConfigBackend;

  constructor(data: ConfigBackend | string) {
    if (typeof (data) === 'string') {
      return this.deserialize(data);
    }
    this.expiration = Date.now();
    this.configBackend = data;
  }

  isExpired(): boolean {
    const expiration = environment.configBackendExpirationMs;
    return this.expiration < (Date.now() - expiration);
  }

  serialize(): string {
    return JSON.stringify({
      expiration: this.expiration,
      configBackend: this.configBackend.serialize()
    });
  }

  deserialize(input: string): this {
    const data = JSON.parse(input);
    this.expiration = data.expiration;
    this.configBackend = new ConfigBackend(data.configBackend);
    return this;
  }
}