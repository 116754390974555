import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public hasError = false;
  private errorMessage = '';

  constructor() {
 return;
}

  public setError(error = 'An error has occurred, please try again later.'): void {
    this.errorMessage = error;
    this.hasError = true;
  }

  public getAlert(): {type: string; message: string} {
    if (!this.hasError) {
      return null;
    }
    this.hasError = false;
    return {type: 'danger', message: this.errorMessage};
  }
}
