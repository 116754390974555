import { animate, state, style, transition, trigger } from "@angular/animations";

const contentOpen =  state('open', style({
    width: "49%",
    marginBottom: "10px"
}));

const contentOpenReduced = state('openReduced', style({
    width: "99%",
    marginBottom: "10px"
}));

export class ContentWrapperAnimation {

    public static contentWrapperAnimation = trigger('contentWrapperState', [
        contentOpen,
        contentOpenReduced,
        transition('open => openReduced', animate('500ms ease-in-out')),
        transition('openReduced => open', animate('500ms ease-in-out')),
      ])
  }
  
  
  