import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Creator } from '../../../models/creator.model';
import { CountriesMap } from '../../../models/countriesMap.model';
import { Youtube, YoutubeAudience } from 'src/app/models/youtube.model';
import { Subscription } from 'rxjs';
import { SocialNetworks } from '../../../models/SocialNetworks';
import { CreatorPrices } from 'src/app/services/creator.service';


class Audience {
  commentCount: number;
  subscriberCount: number;
  videoCount: number;
  viewCount: number;
  gender: Array<Array<string|number>>;
  country: Array<Array<string|number>>;
  media: Array<Array<string|number>>;
  prices: CreatorPrices;

  constructor(youtubeAudience: YoutubeAudience) {
    this.commentCount = youtubeAudience.commentCount;
    this.subscriberCount = youtubeAudience.subscriberCount;
    this.videoCount = youtubeAudience.videoCount;
    this.viewCount = youtubeAudience.viewCount;
    this.gender = Audience.setGender(youtubeAudience.raw.gender);
    this.country = this.setCountry(youtubeAudience.raw.country);
    this.media = youtubeAudience.recentMedia;
    return this;
  }

  private static setGender(values: Array<any>) {
    const empty = [['age13-17', 'female', 0], ['age13-17', 'male', 0], ['age18-24', 'female', 0], ['age18-24', 'male', 0],
      ['age25-34', 'female', 0], ['age25-34', 'male', 0], ['age35-44', 'female', 0], ['age35-44', 'male', 0],
      ['age45-54', 'female', 0], ['age45-54', 'male', 0], ['age55-64', 'female', 0], ['age55-64', 'male', 0],
      ['age65-', 'female', 0], ['age65-', 'male', 0]];
    const aggregated = new Array<any>();
    if (!values) {
      values = [];
    }
    empty.forEach( x => {
      const elem = values.find( y => x[0] === y[0] && x[1] === y[1]);
      if (elem) {
        aggregated.push(elem);
      } else {
        aggregated.push(x);
      }
    });
    return aggregated;
  }

  private setCountry(country: Array<Array<any>>): Array<any> {
    const countries = [];
    if (country) {
      country.forEach( x => {
        countries.push( [x[0], x[1] * 100 / this.subscriberCount]);
      });
    }
    while (countries.length < 5) {
      countries.push(['AR', 0]);
    }
    return countries;
  }

  public getCountryForUodate(): any {
    const countries = [];
    this.country.forEach( x => {
      countries.push( [x[0], x[1] as number * this.subscriberCount / 100]);
    });
    return countries;
  }
}

@Component({
  selector: 'app-youtube-audience',
  templateUrl: './youtube-audience.component.html',
  styleUrls: ['./youtube-audience.component.scss']
})
export class YoutubeAudienceComponent implements OnInit, OnDestroy {
  youtube: Youtube;
  @Input() creator: Creator;
  @Input() getAudienceEvent: EventEmitter<any>;
  @Output() audienceObject = new EventEmitter<any>();
  newAudience: Audience;
  countries = CountriesMap.countries;
  countiesTotal: number;
  genderTotals: Map<string, number>;
  private subscription: Subscription;
  creatorPrices: CreatorPrices;

  ngOnInit(): void {
    this.subscription = this.getAudienceEvent.subscribe(() => {
      this.emitAudience();
    });
    this.newAudience = new Audience(this.creator.youtube.audience);
    if (this.creator.youtube.audience === null) {
      return;
    }
    this.gendersTotal();
    this.countriesTotal();
    this.creatorPrices = { youtube: {
      videoExclusivePrice: this.youtube?.videoExclusivePrice,
      videoMentionPrice: this.youtube?.videoMentionPrice,
    }};
  }



  gendersTotal(): void {
    this.genderTotals = new Map<string, number>();
    const males = this.newAudience.gender.map(r => r[1] === 'male' ? r[2] : 0).reduce( (x: number, y: number) => x + y, 0);
    const females = this.newAudience.gender.map(r => r[1] === 'female' ? r[2] : 0).reduce( (x: number, y: number) => x + y, 0);
    this.genderTotals.set('male', males as number);
    this.genderTotals.set('female', females as number);
  }

  countriesTotal(): void {
    this.countiesTotal = this.newAudience.country.map(r => r[1]).reduce((x: number, y: number) => x + y, 0) as number;
  }

  emitAudience(): void {
    this.newAudience.country = this.newAudience.getCountryForUodate();
    this.audienceObject.emit({
      audience: JSON.stringify(this.newAudience),
      socialId: this.creator.youtube.channelId,
      socialNetwork: SocialNetworks.YouTube
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

