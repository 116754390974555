import { Deserializable } from './deserializable';

export class CampaignPresentation implements Deserializable 
{
  id: number;
  campaign_id: number;
  file_id: number;
  revision_id: string;
  file_pdf: string;
  file_pptx: string;
  created_at: string;
  updated_at: string;
  success_cases: string[] = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return {
      id: this.id,
      campaign_id: this.campaign_id,
      file_id: this.file_id,
      revision_id: this.revision_id,
      file_pdf: this.file_pdf,
      file_pptx: this.file_pptx,
      created_at: this.created_at,
      updated_at: this.updated_at,
      success_cases: this.success_cases.join(', '),
    }
  }
}

