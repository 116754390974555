export class Company {
  ownerId: number;
  logo: string;
  brandName: string;
  legalName: string;
  id: number;
  isAdminApproved: boolean;
  registerDate: Date;
  connectId: number;

  constructor(logo: string, brandName: string, legalName: string, id: number, owner: number, registerDate: Date = null,
              isAdminApproved = false, connectId: number) {
    this.logo = logo;
    this.brandName = brandName;
    this.legalName = legalName;
    this.id = id;
    this.ownerId = owner;
    this.isAdminApproved = isAdminApproved;
    this.registerDate = registerDate;
    this.connectId = connectId;
  }

  static deserialize(input: {
    logo: string;
    brand_name: string;
    legal_name: string;
    id: string;
    owner: string;
    admin_approved: string;
    register_date: string;
    connect_id: string; // Added connect_id property
  }): Company {
    if (!input) {
      return null;
    }
    return new Company(input.logo, input.brand_name, input.legal_name,
      Number.parseInt(input.id, 10), Number.parseInt(input.owner, 10), input.register_date ?
        new Date(input.register_date) : null, !!input.admin_approved, Number.parseInt(input.connect_id, 10)); // Parse connect_id
  }

  clone(): Company {
    return new Company(this.logo, this.brandName, this.legalName, this.id, this.ownerId, this.registerDate, this.isAdminApproved, this.connectId);
  }
}