import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Campaign } from '../../../../../models/campaign.model';
import { CreatorApproval } from '../../../../../models/CreatorApproval.model';
import { Submission } from '../../../../../models/submission.model';
import { SubmissionDialogComponent } from '../../submission-dialog/submission-dialog.component';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { ContentPlannerDialogComponent } from '../../content-planner-dialog/content-planner-dialog.component';

type Item = Submission | CreatorPlannerContent;

@Component({
  selector: 'app-campaign-week-calendar',
  templateUrl: './campaign-week-calendar.component.html',
  styleUrls: ['./campaign-week-calendar.component.scss']
})
export class CampaignWeekCalendarComponent implements OnInit {
  
  public weekday: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]);
  public actualDate = new Date();
  public firstDayOfWeek = new Date();

  @Input() public calendarList = new BehaviorSubject<Item[]>([]);
  @Input() public creators: Array<CreatorApproval> = [];
  @Input() campaign: Campaign;
  @Input() colors: any = {};

  constructor(public dialog: MatDialog) { }

  public ngOnInit(): void {
    this.firstDayOfWeek = this.getFirstDayOfWeek(this.firstDayOfWeek);
  }


  getCreatorApproval(item: CreatorPlannerContent | Submission) : CreatorApproval {
    return this.creators.find(creatorApproval => item instanceof Submission ?
        creatorApproval.userId === item.userId :
        creatorApproval.id === item.userApprovalId);
  }

  public stringDayByIndex(index: number): string {
    if(!index){
      return this.firstDayOfWeek.toLocaleDateString('default',{day: "2-digit"});
    }
    const date = new Date(this.firstDayOfWeek);
    date.setDate(date.getDate() + index);
    return date.toLocaleDateString('default',{day: "2-digit"});
  }

  public subOnDay(subDay: string, index: number) {
    const date = new Date(this.firstDayOfWeek);
    date.setDate(date.getDate() + index);
    const getYear = date.toLocaleString("default", {year: "numeric"});
    const getMonth = date.toLocaleString("default", {month: "2-digit"});
    const getDay = date.toLocaleString("default", {day: "2-digit"});
    return getYear + "-" + getMonth + "-" + getDay === subDay;
  }

  private getFirstDayOfWeek(d: Date): Date {
    d = new Date(d);
    const day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6: 0);
    return new Date(d.setDate(diff));
  }

  lastDayOfWeek() : Date {
    const last = new Date(this.firstDayOfWeek);
    last.setDate(this.firstDayOfWeek.getDate() + 7);
    return  last;
  }

  public nextWeek() {
    this.actualDate.setDate(this.actualDate.getDate() + 7);
    this.firstDayOfWeek = this.getFirstDayOfWeek(this.actualDate);
  }
  
  public backWeek() {
    this.actualDate.setDate(this.actualDate.getDate() + -7);
    this.firstDayOfWeek = this.getFirstDayOfWeek(this.actualDate);
  }

  public actualWeek() {
    this.actualDate = new Date();
    this.firstDayOfWeek = this.getFirstDayOfWeek(this.actualDate);
  }

  getCreatorSocialName(sub: Submission): string {
    return this.getCreatorApproval(sub).creator.getSocialName();
  }

  public getCreatorSocialImg(sub: Submission): string {
    return this.creators.find((value: CreatorApproval) => value.creator.id === sub.userId).creator.getProfilePicture();
  }

  public openSubmission(sub: Submission): void {
    this.dialog.open(SubmissionDialogComponent, {
      width: '656px',
      data: {
        allSubmissions: this.calendarList.value,
        campaign: this.campaign,
        submission: sub,
        creators: this.creators.map(value => value.creator),
        creatorApproval: this.creators
      }
    });
  }

  public openPlanner(content: CreatorPlannerContent): void {
    const creatorApproval = this.getCreatorApproval(content);
    this.dialog.open(ContentPlannerDialogComponent, {
      width: '480px',
      data: {
        campaign: this.campaign,
        content,
        creatorApproval,
        planner: creatorApproval.planner.find(planner => planner.id === content.plannerId)
      }
    });
  }

}
