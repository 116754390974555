import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../../../models/campaign.model';
import { CampaignService } from '../../../../services/campaign.service';
import { combineLatest, Subscription } from 'rxjs';
import { Alert } from '../../../../models/interfaces';
import { BrandService } from 'src/app/services/brand.service';
import { Privilege, Role } from 'src/app/models/role.model';
import { UserService } from 'src/app/services/user.service';
import { PrivilegeBlockModalComponent } from 'src/app/modules/shared/privilege-block-modal/privilege-block-modal.component';
import { UserRole } from 'src/app/models/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignComponent } from '../campaign.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit, OnDestroy {

  readonly ROLE_NAMES = Role.NAMES;

  campaign: Campaign;
  backup: Campaign;
  selectedRole = 'viewer';
  modalRef: NgbModalRef;
  roles: Array<Role>;
  collaborators: Array<UserRole>;
  newEmail: string;
  sub = new Subscription();
  alert: Alert = null;
  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CampaignComponent, private dialogRef: MatDialogRef<ShareComponent>, private modalService: NgbModal, private cs: CampaignService, private brandService: BrandService,
              private userService: UserService) {
                this.campaign = this.data.campaign;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.VIEW_USERS], this.campaign.companyId, this.campaign.brandId, this.campaign.id)
    ) {
      combineLatest([this.cs.getCampaignUserRoles(this.campaign.companyId, this.campaign.brandId, this.campaign.id),
        this.brandService.getRoles(this.campaign.companyId)]).subscribe(([userRoles, roles]) => {
          this.roles = roles;
          this.collaborators = [];
          for (let i = 0; i < userRoles.length; i++) {
            const campaignRole = userRoles[i];
            const index = this.collaborators.findIndex(c => c.user?.email === campaignRole.user?.email);
            if (index !== -1) {
              const collaborator = this.collaborators[index];
              if ((!collaborator.campaignId && campaignRole.campaignId) || (!collaborator.brandId && campaignRole.brandId)) {
                this.collaborators.splice(index, 1);
                this.collaborators.push(campaignRole);
              } else if((collaborator.campaignId && campaignRole.campaignId) || (collaborator.brandId && campaignRole.brandId)) {
                this.collaborators.push(campaignRole);
              }
            } else {
              this.collaborators.push(campaignRole);
            }
          }
          this.loading = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.close();
  }

  open(content: TemplateRef<ShareComponent>): void {
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_USERS], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
      this.modalRef = this.modalService.open(content, { windowClass: 'app-share-campaign-modal'});
    } else {
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }

  close(): void {
    this.loading = false;
    this.sub.unsubscribe();
    this.alert = null;
    this.newEmail = '';
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  invite(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.sub = this.cs.inviteToCampaign(this.newEmail, this.campaign.companyId, this.campaign.brandId, this.campaign.id, this.selectedRole)
      .subscribe(
      (userRole) => {
        this.alert = {
          type: 'success',
          message: 'User invited to collaborate successfully.',
        };
        userRole.role = this.roles.find(r => r.name === this.selectedRole);
        this.collaborators.push(userRole);
        // this.campaign.privileges.push(x);
      },
      () => {
        this.alert = {
          type: 'danger',
          message: 'An error occurred while trying to share campaign, please try later.',
        };
        this.loading = false;
      }, () => {
        this.loading = false;
      }
    );
    return;
  }

  removeFromCampaign(userRole: UserRole): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.sub = this.cs.removeFromCampaign(this.campaign.companyId, this.campaign.brandId, this.campaign.id, userRole.id).subscribe(
      () => {
        this.loading = false;
        this.alert = {
          type: 'success',
          message: 'User removed from campaign successfully.',
        };
        this.collaborators = this.collaborators.filter(c => c.id !== userRole.id);
        // this.campaign.privileges = this.campaign.privileges.filter( (y) => y.id !== id);
      },
      () => {
        this.alert = {
          type: 'danger',
          message: 'An error occurred while trying remove user from campaign, please try later.',
        };
        this.loading = false;
      }, () => {
        this.loading = false;
      }
    );
    return;
  }
}
