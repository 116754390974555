import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-to-list',
  templateUrl: './data-to-list.component.html',
  styleUrls: ['./data-to-list.component.css']
})
export class DataToListComponent implements OnInit {

  @Input() data: any;
  
  constructor() { }

  ngOnInit(): void { }

  isScalar(val: any): boolean {
    return val !== null && (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean');
  }

  isArray(val: any): boolean {
    return Array.isArray(val);
  }

  isObjectKeyValue(val: any): boolean {
    return val !== null && typeof val === 'object' && !this.isArray(val);
  }

}
