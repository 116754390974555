import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-privilege-block-modal',
  templateUrl: './privilege-block-modal.component.html',
  styleUrls: ['./privilege-block-modal.component.css']
})
export class PrivilegeBlockModalComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

  }

  close(): void {
    this.modalService.dismissAll();
  }

}
