import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, HostListener, TemplateRef } from '@angular/core';
import { NgbCarouselConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { InstagramAudience } from '../../../../models/instagram.model';
import { YoutubeAudience } from '../../../../models/youtube.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Creator } from '../../../../models/creator.model';
import { TikTokAudience } from '../../../../models/tiktok.model';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CreatorService } from 'src/app/services/creator.service';
import { Submission } from 'src/app/models/submission.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AudienceDetailsDialogComponent } from '../audience/audience-details-dialog/audience-details-dialog.component';
import { Campaign } from 'src/app/models/campaign.model';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-creator-details-modal',
  templateUrl: './creator-details-modal.component.html',
  styleUrls: ['./creator-details-modal.component.scss']
})
export class CreatorDetailsModalComponent implements OnInit, OnDestroy {

  constructor(public dialogRef: MatDialogRef<CreatorDetailsModalComponent>, private readonly creatorService: CreatorService,
              config: NgbCarouselConfig, private cs: CreatorService,
              private modalService: NgbModal, private translate: TranslateService, private http: HttpClient, private readonly dialog: MatDialog,              ) {
    // Image Gallery Config
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    this.onResize();
  }

  screenHeight: number;
  screenWidth: number;
  // Chart Options
  view: number[] = [window.innerWidth * .2, window.innerHeight * .2];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = this.translate.instant('CAMPAIGN.CREATORS.AGE');
  showYAxisLabel = true;
  title = this.translate.instant('CAMPAIGN.CREATORS.AGE') + '&' + this.translate.instant('CAMPAIGN.CREATORS.GENDER');
  colorScheme = {
    domain: ['#FFF432', '#333333', '#C7B42C', '#AAAAAA']
  };
  @Input() creator: Creator;
  @Input() campaign: Campaign;
  @Input() creatorSubmissions: Submission[];
  instagramAud: InstagramAudience;
  youtubeAud: YoutubeAudience;
  tikTokAud: TikTokAudience;
  modalRef: NgbModalRef;
  creatorSub: Subscription;
  flag = '../../../assets/img/flags/';
  activeAudience: string;
  audienceCount = 0;
  public dialogOpen : boolean = false;
  showCountryPerc = true;
  yLabel = this.translate.instant('CAMPAIGN.CREATORS.FOLLOWERS');
  tiktok = faTiktok;
  avgImpressions = { feeds: 0, stories: 0, reels: 0 };

  ngOnInit(): void {
    if (this.creator.hasYoutube()) {
      this.youtubeAud = this.creator.youtube.audience;
      if (this.creator.youtube.audience.gendersChartSeries) {
        this.creator.youtube.audience.gendersChartSeries = this.creator.youtube.audience.gendersChartSeries.map(s => ({
            name: s.name, series: s.series.map(g => ({ name: this.translate.instant(g.name.toUpperCase()), value: g.value }))
          }));
      }
    }
    if (this.creator.hasInstagramAudience()) {
      this.instagramAud = this.creator.instagram.audience;
      if (this.creator.instagram.audience.gendersChartSeries) {
        this.creator.instagram.audience.gendersChartSeries = this.creator.instagram.audience.gendersChartSeries.map(s => ({
            name: s.name, series: s.series.map(g => ({ name: this.translate.instant(g.name.toUpperCase()), value: g.value }))
          }));
      }
      this.avgImpressions = {
        stories: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Story'),
          this.instagramAud.impressionsPerStory),
        feeds: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Feed'),
          this.instagramAud.impressionsPerFeed),
        reels: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Reel'), 0),
      };
    }
    if (this.creator.hasTikTokAudience()) {
      this.tikTokAud = this.creator.tikTok.audience;
    }
    this.audienceCount = this.creator.audienceCount();
    const audiences = this.creator.socialNetworks.filter(x => x.hasAudience);
    if (audiences.length > 0) {
      this.activeAudience = audiences.pop().socialNetwork.toLowerCase();
    }
    if (this.creator.hasInstagramAudience()) {
      if (this.creator.instagram.audience.audienceCountry) {
        let totalSum = 0;
        this.creator.instagram.audience.audienceCountry.forEach(c => {
 totalSum = totalSum + c[1];
});
        this.showCountryPerc = totalSum <= 101;
      } else {
        this.showCountryPerc = false;
      }
      this.avgImpressions = {
        stories: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Story'), this.instagramAud.impressionsPerStory),
        feeds: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Feed'), this.instagramAud.impressionsPerFeed),
        reels: this.getAverageImpressions(this.creatorSubmissions.filter(s => s.format === 'Reel'), 0),
      };
    }
  }

  addPercentages(sum: number, p: [string, number]) {
    return sum + (p.length > 1 ? p[1] : 0);
  }
  getAverageImpressions(submissions: Submission[], defaultValue: number) {
    return submissions.length > 0 ? Math.round(submissions.reduce((sum, s) => sum + (s.randomImpressions ?? 0), 0) / submissions.length) :
    Math.round(defaultValue);
  }
  public open(): void {
    if (this.creator.audienceCount() === 0) {
      return;
    }
    if (this.creator.hasInstagramAudience()) {
      this.updateInstagramFeedImages();
    }
    this.dialog.open(AudienceDetailsDialogComponent, {
      width: '1305px',
      height: '782px',
      panelClass: 'audience-dialog-container',
      data: {
        creatorSubmissions : this.creatorSubmissions,
        updateProfile: true,
        creator : this.creator
      }});
      this.dialogOpen = true;
  }

  private displayError(error: TemplateRef<CreatorDetailsModalComponent>): void {
    this.modalRef = this.modalService.open(error, { windowClass: 'app-creators-details-modal-error' });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }


  ngOnDestroy(): void {
    if (this.creatorSub) {
      this.creatorSub.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.view = [window.innerWidth * .55, window.innerHeight * .222];
  }

  updateInstagramFeedImages() {
    if (!this.creator.hasInstagram()) {
      return;
    }
    const imgRequests = this.instagramAud.imagesToDisplay.map(url => this.http.get(url, {observe: 'response', responseType: 'text'}));
    if (imgRequests.length === 0) {
      return;
    }
    combineLatest(imgRequests).subscribe(() => null, () => {
      this.cs.updateCreatorImages([this.creator.id]).subscribe(creators => {
        this.creator = creators[0];
        this.instagramAud = this.creator.instagram.audience;
      });
    });
  }
}
