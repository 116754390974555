import { Helper } from '../support/helper.support';
import { Campaign } from './campaign.model';
import { Deserializable } from './deserializable';

export class Submission implements Deserializable {
  id: number;
  campaignId: number;
  campaign?: Campaign;
  userId: number;
  status: string;
  time: Date;
  averageLikes: number;
  captions: string;
  note: string;
  requestedtime: number;
  image: string;
  timePosted: Date;
  timeEnded: number;
  likes: number;
  comments: number;
  instagramPostId: number;
  captionErrors: string;
  hasCaptionErrors: boolean;
  randomImpressions: number;
  randomReach: number;
  video: string;
  dateToPost: string;
  timeToPostStart: string;
  timeToPostEnd: string;
  clicks: number;
  installsAndActions: number;
  savedShared: number;
  platform: string;
  format: string;
  conversions: number;
  mediaFile: File;
  thumbnail: string;
  ctr: number;
  plannerContentId: number;

  public constructor() {
    this.captions = '';
    this.note = '';
    this.platform = '';
    this.format = '';
    this.status = 'Requested';
    this.video = '';
  }

  public getStartsAtDate() : Date {
    return new Date( this.dateToPost + 'T' + this.timeToPostStart + ':00' );
  }

  public getEndsAtDate() : Date {
    return new Date( this.dateToPost + 'T' + this.timeToPostEnd + ':00' );
  }

  public deserialize(input: any): this {
    this.id = input.id;
    this.campaignId = input.campaign_id;
    this.campaign = input.campaign ? new Campaign().deserialize(input.campaign) : undefined;
    this.userId = parseInt(input.user_id, 10);
    this.status = input.status;
    this.time = input.requested_time ? new Date(parseInt(input.requested_time, 10) * 1000 ) : null;
    this.averageLikes = input.average_likes ? parseInt(input.average_likes, 10) : 0;
    this.captions = input.creator_captions;
    this.note = input.creator_note;
    this.requestedtime = input.requestedtime;
    this.image = input.post_image_url;
    this.timePosted = input.post_time ? new Date(parseInt(input.post_time, 10) * 1000) : null;
    this.timeEnded = input.post_end_time;
    this.likes = input.likes_calculated ? parseInt(input.likes_calculated, 10) : 0;
    this.comments = input.comments_calculated ? parseInt(input.comments_calculated, 10) : 0;
    this.instagramPostId = input.instagram_post_id ? parseInt(input.instagram_post_id, 10) : 0;
    this.captionErrors = input.captions_error;
    this.hasCaptionErrors = input.is_captions_error === '1';
    this.randomImpressions = input.random_impressions ? parseInt(input.random_impressions, 10) : 0;
    this.randomReach = input.random_reach ? parseInt(input.random_reach, 10) : 0;
    this.video = input.video_url;
    this.dateToPost = input.date_to_post;
    this.timeToPostStart = input.time_to_post_start;
    this.timeToPostEnd = input.time_to_post_end;
    this.clicks = input.clicks ? parseInt(input.clicks, 10) : 0;
    this.installsAndActions = input.installs_and_actions ? parseInt(input.installs_and_actions, 10) : 0;
    this.savedShared = parseInt(input.saved_shared ?? 0, 10);
    this.platform = input.platform ? input.platform : null;
    this.format = input.format ? input.format : this.image ? 'Story Photo' : 'Story Video';
    this.conversions = this.clicks !== 0 ? Math.round(this.installsAndActions / this.clicks * 10000) / 100 : 0;
    this.ctr = this.randomImpressions !== 0 ? (Math.round(100 * 100 * this.clicks / this.randomImpressions) / 100) : 0;
    this.thumbnail = input.thumbnail_url;
    this.plannerContentId = Helper.numberOrNull(input.planner_content_id);
    return this;
  }

  // getPlatformIcon(): string {
  //   return this.platform ? '<i class="mr-3 fa fa-' + this.platform + '"></i>' : '<i class="mr-3 fa fa-instagram"></i>';
  //   // return this.platform ? '<i class="mr-3 fa fa-youtube-play"></i>' : '<i class="mr-3 fa fa-instagram"></i>';
  // }


  getPlatformBackgroundColor(): string {
    switch (this.format) {
      case 'Feed Video': {
        return '#F0D5D5';
      }
      case 'Story Video': {
        return '#FFF6DC';
      }
      case 'Story Photo': {
        return '#FFDBFE';
      }
      case 'Feed Photo': {
        return '#DCD5FF';
      }
      case 'Video': {
        return '#F0D5D5';
      }
      default: {
        return 'grey';
      }
    }
  }

  getPlatformFontColor(): string {
    switch (this.format) {
      case 'Feed Video': {
        return '#C33131';
      }
      case 'Video': {
        return '#C33131';
      }
      case 'Story Video': {
        return '#FFC100';
      }
      case 'Story Photo': {
        return '#FF00E6';
      }
      case 'Feed Photo': {
        return '#430BFF';
      }
      default: {
        return 'white';
      }
    }
  }

  getPlatformIcon(): string {
    if (this.platform === 'youtube') {
      return '<i class="mr-1 fa fa-youtube-play"></i>';
    } else if (this.platform === 'instagram') {
      return '<i class="mr-1 fa fa-instagram"></i>';
    }
  }

  clone(): Submission {
    const clone = new Submission();
    clone.id = this.id;
    clone.campaignId = this.campaignId;
    clone.userId = this.userId;
    clone.status = this.status;
    clone.time = new Date(this.time);
    clone.averageLikes = this.averageLikes;
    clone.captions = this.captions;
    clone.note = this.note;
    clone.requestedtime = this.requestedtime;
    clone.image = this.image;
    clone.timePosted = this.timePosted ? new Date(this.timePosted) : null;
    clone.timeEnded = this.timeEnded;
    clone.likes = this.likes;
    clone.comments = this.comments;
    clone.instagramPostId = this.instagramPostId;
    clone.captionErrors = this.captionErrors;
    clone.hasCaptionErrors = this.hasCaptionErrors;
    clone.randomImpressions = this.randomImpressions;
    clone.randomReach = this.randomReach;
    clone.video = this.video;
    clone.dateToPost = this.dateToPost;
    clone.timeToPostStart = this.timeToPostStart;
    clone.timeToPostEnd = this.timeToPostEnd;
    clone.clicks = this.clicks;
    clone.installsAndActions = this.installsAndActions;
    clone.savedShared = this.savedShared;
    clone.platform = this.platform;
    clone.format = this.format;
    clone.conversions = this.conversions;
    clone.ctr = this.ctr;
    clone.campaign = this.campaign;
    clone.plannerContentId = this.plannerContentId;
    return clone;
  }

  public getPostEngagement(): number {
    return this.randomImpressions > 0 ?
      Math.round(((this.comments + this.likes + this.savedShared) / this.randomImpressions) * 100)
      : 0;
  }

  getPostEngagementAbs(): number {
    return this.comments + this.likes + this.savedShared
  }

  getStatus(): string {
    if (this.status === 'Requested') {
      return 'Pending';
    }
    return this.status;
  }
}

export class PerformanceSubmission extends Submission implements Deserializable {

  deserialize(input: any): this {
    super.deserialize(input);
    this.userId = parseInt(input.LemmonetInfluencerID, 10);
    this.clicks = Number.parseInt(input.Clicks ? input.Clicks : '0', 10);
    this.conversions = Math.round(Number.parseFloat(input.Cr ? input.Cr : '0') * 10000) / 100;
    this.installsAndActions = Number.parseInt(input.Installs ? input.Installs : '0', 10);
    this.status = '';
    this.timePosted = new Date();
    this.platform = 
      input.InstagramUserID ? 'instagram' : 
      input.TiktokUserID ? 'tiktok' :
      input.YouTubeUserID ? 'youtube' : 
      null;
    this.format = 
      input.InstagramUserID ? 'Story Video' : 
      input.TiktokUserID ? 'Video' :
      input.YouTubeUserID ? 'Video' : 
      null;
    this.randomImpressions = 0;
    this.randomReach = 0;
    return this;
  }
}
