import { User } from '../../models/user.model';
import { FormGroup } from '@angular/forms';
import { RequestHelper } from '../../models/requestHelper';
import { Company } from '../../models/company.model';

export class UpdateUserModel extends User {
  updatesProfileImage: boolean;
  updatesCompanyLogo: boolean;
  password?: string;
  form: FormGroup;
  newProfileImage: string;
  newLogo: string;

  constructor(user: User) {
    super(user.firstName, user.lastName, user.email,
      user.profileImage, user.phoneNumber, user.company, user.id, user.active, user.verificationToken);
    if (!user.company) {
      this.company = new Company('', '', '', 0, 0, new Date(), false, 0);
    }
    this.updatesProfileImage = false;
    this.updatesCompanyLogo = false;
    this.newProfileImage = '';
    this.newLogo = '';
  }

  getFormData(): FormData {
    const formData: FormData = new FormData();
    const values = this.form.value;
    this.updatesProfileImage = this.newProfileImage !== '';
    this.updatesCompanyLogo = this.newLogo !== '';
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phoneNumber', values.phoneNumber);
    formData.append('email', values.email);
    formData.append('updatesProfileImage', this.updatesProfileImage.toString());
    if (this.updatesProfileImage) {
      if (this.newProfileImage) {
        formData.append('profilePicture', RequestHelper.newFileFromString(this.newProfileImage, 'profileImage.jpg'), 'profilePicture');
      } else {
        formData.append('profilePicture', '');
      }
    }
    formData.append('updatesCompanyLogo', this.updatesCompanyLogo.toString());
    if (this.updatesCompanyLogo) {
      formData.append('newLogo', RequestHelper.newFileFromString(this.newLogo, 'logo.jpg'),
        'newLogo');
    }
    if (values.password !== '') {
      formData.append('password', values.password);
    }
    formData.append('companyId', (this.hasCompany() ? this.company.id : 0) + '');
    formData.append('brandName', values.brandName);
    formData.append('legalName', values.legalName);
    return formData;
  }
}
