import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Company } from '../../../models/company.model';
import { BrandService } from '../../../services/brand.service';
import { SuggestedPrice } from 'src/app/models/suggestedPrice.model';
import { SuggestedPricesService } from 'src/app/services/suggested-price.service';
import { Country, CountryList } from 'src/app/models/country';
import { DOCUMENT } from '@angular/common';
import { $ } from 'protractor';
import { CreatorLevels, creatorLevelsAll } from 'src/app/enum/creator-levels.enum';


@Component({
  selector: 'x-edit-suggested-price',
  templateUrl: './suggested-prices-edit.component.html',
  styleUrls: ['./suggested-prices-edit.component.css'],
})
export class SuggestedPricesEditComponent {

  public priceForm: FormGroup;
  public loading= false

  public creatorLevels: string[] = creatorLevelsAll();
  public countries: Country[] = CountryList.countryList;

  constructor(
    @Inject(MAT_DIALOG_DATA) public price: SuggestedPrice,
    public dialogRef: MatDialogRef<SuggestedPrice>,
    private suggestedPricesService: SuggestedPricesService,
    private formBuilder: FormBuilder) {
    this.priceForm = this.createFormFromObject(price)
  }

  private createFormFromObject(price: SuggestedPrice) : FormGroup
  {
    const formItems = {};
    for (const [key, value] of Object.entries(this.price)) {
      formItems[key] = new FormControl(value);
    }
    return this.formBuilder.group(formItems);
  }

  public close(): void {
    this.dialogRef.close();
  }

  async save() 
  {
    this.loading = true;
    const price: SuggestedPrice = this.priceForm.value
    try {
      if (price.id ) {
        await this.suggestedPricesService.httpUpdate(price).toPromise()
      } else {
        await this.suggestedPricesService.httpCreate(price).toPromise()
      }
      this.loading = false;
      this.close()
    } catch (err) {
      this.loading = false;
      this.priceForm.setErrors(err.error.errors)
      console.error('--errors', err)
    }
  }


  blur() {
    // console.log('---here', this.matCountry )
    // console.log($('#matCountry'))
  }
}
