import { animate, state, style, transition, trigger } from "@angular/animations";


const contentStyle = style({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "15px",
  width: "100%",
  minWidth: "182px",
  height: "80px",
  background: "#FFFFFF",
  border: "1px solid #C5C5C5",
  borderRadius: "10px",
  opacity: 1
});

const contentOpen =  state('open', contentStyle);

const contentClosed = state('closed', contentStyle);

const contentOtherStates = state('*', style({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "15px",
  minWidth: "182px",
  width: "100%",
  height: "80px",
  background: "#FFFFFF",
  border: "1px solid #C5C5C5",
  borderRadius: "10px",
  opacity: 0
}));

export class ContentAnimations {

    public static contentAnimation = trigger('contentState', [
        contentOpen,
        contentClosed,
        contentOtherStates,
        transition('closed => *', animate('100ms ease-in-out')),
        transition('* => closed', animate('100ms ease-in-out')),
        transition('open => *', animate('100ms ease-in-out')),
        transition('* => open', animate('100ms ease-in-out')),
      ])
  }
  
  
  