import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SuggestedPrice } from '../models/suggestedPrice.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestedPricesService {

  constructor(private restService: RestService, private translateService: TranslateService) { }

  public httpAll(): Observable<SuggestedPrice[]> 
  {
    return this.restService.get('creators/price-reference');
  }

  public httpCreate(data: SuggestedPrice) : Observable<SuggestedPrice>
  {
    return this.restService.post('creators/price-reference', data);
  }

  public httpUpdate(data: SuggestedPrice) : Observable<SuggestedPrice>
  {
    if(!data.id) {
      throw new Error('suggestedPrice id is required');
    }
    return this.restService.post(`creators/price-reference/${data.id}/update`, data);
  }

  public httpDelete(id: number) : Observable<SuggestedPrice>
  {
    if(!id) {
      throw new Error('suggestedPrice id is required');
    }
    return this.restService.post(`creators/price-reference/${id}/delete`, {});
  }
}

