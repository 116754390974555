export class Currency {
  readonly code: string;
  readonly name: string;
  readonly symbol: string;

  constructor(code: string, name: string, symbol: string) {
    this.code = code;
    this.name = name;
    this.symbol = symbol;
  }

  toString(): string {
    return this.name + ' (' + this.code + ') - ' + this.symbol;
  }

}

export class Currencies {
  public static get(): Array<Currency> {
    const list = new Array<Currency> ();
    list.push(new Currency('ARS', 'Argentine Peso', '$'));
    list.push(new Currency('BRL', 'Brazilian Real', 'R$'));
    list.push(new Currency('CLP', 'Chilean Peso', '$'));
    list.push(new Currency('MXN', 'Mexican Peso', '$'));
    list.push(new Currency('EUR', 'Euro', '€'));
    list.push(new Currency('GBP', 'Pound Sterling', '£'));
    list.push(new Currency('USD', 'United States Dollar', 'US$'));
    return list;
  }
}

export enum ReachTarget {
  reach = 'Reach',
  impressions = 'Impression/views'
}
