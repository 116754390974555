import { Deserializable } from './deserializable';
import { Company } from './company.model';
import { Role } from './role.model';
import { Brand } from './brand.model';
import { Category } from './categories.model';

export class ConfigBackend implements Deserializable {
  categories: Category[] = [];
  campaign_presentation_success_cases: string[] = [];
  
  constructor(data: any) {
    if ( typeof(data) == 'string' ) {
      return this.deserialize(data);
    }
    Object.assign(this, data);
    this.categories = data.categories.map((category: any) => new Category(category));
  }

  deserialize(input: string): this {
    const data = JSON.parse(input);
    Object.assign(this, data);
    this.categories = data.categories.map((category: any) => new Category(category));
    this.campaign_presentation_success_cases = data.campaign_presentation_success_cases;
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }
}
