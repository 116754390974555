import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../../models/campaign.model';
import { PreviewImagesDialogComponent } from '../campaign/preview-images-dialog/preview-images-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  modalRef: NgbModalRef;
  @Input() image: string;
  @Input() campaign: Campaign;
  safeVideo: SafeUrl;
  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer,private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.isVideo()) {
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.image);
    }
  }

  public openPreviewImage(image: string){
    const dialogRef = this.dialog.open(PreviewImagesDialogComponent, {
      width: '1106px',
      height: '550px',
      data: {
        imageUrl: image
      }
    })
  }

  public getImagesUrl():string[]{
    let imagesArrayUrl = [];
    imagesArrayUrl.push(this.campaign.image1 ?? null);
    imagesArrayUrl.push(this.campaign.image2 ?? null);
    imagesArrayUrl.push(this.campaign.image3 ?? null);
    imagesArrayUrl.push(this.campaign.image4 ?? null);
    return imagesArrayUrl;
  }

  isVideo(): boolean {
    return this.image.includes('vimeo');
  }
  open(content: TemplateRef<ImageModalComponent>): void {
    this.modalRef = this.modalService.open(content, { windowClass: 'image-modal'});
  }

  close(): void {
    this.modalRef.close();
  }
}
