import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlideBarService {

  private showSideBar$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit() {
  }

  getShowNav(){
    return this.showSideBar$.asObservable();
  }

  setShowNav(showHide: boolean) {
    this.showSideBar$.next(showHide);
  }

  toggleNavState() {
    this.showSideBar$.next(!this.showSideBar$.value);
  }

  isNavOpen() {
    return this.showSideBar$.value;
  }
}
