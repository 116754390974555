import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Campaign } from '../../../../../../models/campaign.model';
import { Creator } from '../../../../../../models/creator.model';
import { CreatorApproval } from '../../../../../../models/CreatorApproval.model';
import { Privilege } from '../../../../../../models/role.model';
import { Submission } from '../../../../../../models/submission.model';
import { CampaignService } from '../../../../../../services/campaign.service';
import { SubmissionService } from '../../../../../../services/submission.service';
import { UserService } from '../../../../../../services/user.service';
import { SubmissionDialogComponent } from '../../submission-dialog.component';
import { DialogConfirmComponent } from 'src/app/modules/shared/dialog-confirm/dialog-confirm.component';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';

@Component({
  selector: 'app-submission-infobox',
  templateUrl: './submission-infobox.component.html',
  styleUrls: ['./submission-infobox.component.css']
})
export class SubmissionInfoboxComponent implements OnInit {

  @Input() public submission: Submission;
  @Input() public campaign: Campaign;
  @Input() public creatorApproval: CreatorApproval;
  @Output() errors = new EventEmitter<string>();
  @Output() upload = new EventEmitter<boolean>();
  @ViewChild('uploadCaptionsModal') callEditCaptionsDialog: TemplateRef<any>;
  private editCaptionsDialogRef: MatDialogRef<TemplateRef<any>>;
  canEdit = false;
  plannerContents: CreatorPlannerContent[] = [];
  selectedPlannerContent: CreatorPlannerContent;

  data: Submission;

  constructor(private readonly sanitizer: DomSanitizer,
    public userService: UserService,
    private submissionService: SubmissionService,
    private readonly dialog: MatDialog,
    ) {
      //
     }

  public ngOnInit(): void {
    this.data = Object.assign(new Submission(), this.submission);
    this.canEdit = this.userService.hasPrivileges([Privilege.PREDEFINED.EDIT_CONTENT],
      this.campaign.companyId, this.campaign.brandId, this.campaign.id
    );
    this.plannerContents = this.creatorApproval.getPlannerContents();
    this.selectedPlannerContent = this.plannerContents.find(content => content.id === this.submission.plannerContentId);
  }

  onChangePlannerContent(value : CreatorPlannerContent) {
    this.data.plannerContentId = value ? value.id : null;
  }

  public OpenUploadCaptionsModal(){
    this.editCaptionsDialogRef = this.dialog.open(this.callEditCaptionsDialog, {
      width: '500px',
      // height : auto
      data: {
        data: this.data,
        campaign: this.campaign,
      }
    });
  }

  public closeEditCaptionsDialog(){
    this.editCaptionsDialogRef.close();
  }

  onUpload() {
    this.upload.emit(true);
  }

  edit(): void {
    this.submissionService.editSubmission(this.data).subscribe( value => {
      Object.assign(this.submission, this.data);
      this.editCaptionsDialogRef.close();
    }, error => {
      this.errors.emit(error);
    });
  }

  delete(): void {

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirm Delete',
        description: 'Are you sure you want to delete this submission?',
      },
    });
    dialogRef.afterClosed().subscribe(ok => {
      if(!ok) {
        return;
      }
      this.submissionService.deleteSubmission(this.submission).subscribe( (x) => {
        delete this.submission;
        this.editCaptionsDialogRef.close();
      }, x => {
        this.errors.emit(x.error);
      });
    });

  }
}
