import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandService } from '../../../services/brand.service';
import { UpdateUserModel } from '../updateUserModel';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { Company } from '../../../models/company.model';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profileForm: FormGroup;
  submitted = false;
  user: UpdateUserModel;
  user$: Observable<User>;
  posting = false;
  updateSubscription: Subscription;
  joinableCompanies: Array<Company>;
  createNew = false;
  companyToJoin = 0;

  constructor(private fb: FormBuilder,
              private bs: BrandService,
              private router: Router,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.user$ = this.bs.getUser();
    this.user$.subscribe(x => {
      this.joinableCompanies = this.bs.joinableCompanies;
      this.user = new UpdateUserModel(x);
      this.profileForm = this.fb.group({
        email: [this.user.email, Validators.compose([
          Validators.required, Validators.email
        ])],
        password: ['', Validators.minLength(8)],
        firstName: [this.user.firstName, Validators.required],
        lastName: [this.user.lastName, Validators.required],
        phoneNumber: [this.user.phoneNumber, Validators.compose([
          Validators.required, Validators.minLength(8)
        ])]
      });
      this.user.form = this.profileForm;
      if (this.user.hasCompany()) {
        this.createClicked();
      }
    });
  }

  private isProfileComplete() {
    return !this.profileForm.invalid;
  }

  private isCompanyComplete() {
    return (this.createNew && this.user.company && (this.user.company.logo !== '' || this.user.newLogo !== ''))
      || this.companyToJoin !== 0;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.profileForm.controls;
  }

  submit(): void {
    this.submitted = true;
    if (!this.isProfileComplete() || !this.isCompanyComplete()) {
      return;
    }
    if (this.companyToJoin !== 0) {
      this.updateProfileAndJoinCompany();
    } else {
      this.createOrEdit();
    }
  }

  updateProfileAndJoinCompany(): void {
    this.user.company.id = this.companyToJoin;
    this.updateSubscription = this.bs.joinCompany(this.user).subscribe(async () => {
      this.posting = false;
      this.ngOnInit();
      await this.router.navigate(['dashboard']);
    }, error => {
      this.posting = false;
      this.alertService.emitError(error.error.message);
      if (error.status === 500) {
        this.alertService.emitError('An error occurred, please try again later.');
      }
    });
  }

  createOrEdit(): void {
    this.updateSubscription = this.bs.updateProfile(this.user).subscribe(async () => {
      this.posting = false;
      this.ngOnInit();
      this.alertService.emitSuccess('Profile updated successfully.');
      await this.router.navigate(['dashboard']);
    });
  }

  onNewImage(croppedImage: string): void {
    this.user.newProfileImage = croppedImage;
  }

  ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  createClicked(): void {
    this.profileForm.addControl('brandName', new FormControl({
      value: this.user.company.brandName,
      disabled: ((this.user.company.id > 0 && !this.user.isCompanyOwner()))
    }, [Validators.required]));
    this.profileForm.addControl('legalName', new FormControl({
      value: this.user.company.legalName,
      disabled: (this.user.company.id > 0)
    }, [Validators.required]));
    this.createNew = true;
    this.companyToJoin = 0;
  }

  joinClicked(companyId: number): void {
    this.profileForm.removeControl('brandName');
    this.profileForm.removeControl('legalName');
    this.companyToJoin = companyId;
    this.createNew = false;
  }
}
