export class TimeOptions {
    readonly value: string;
    readonly viewValue: string;
  
    constructor(value: string, viewValue: string) {
      this.value = value;
      this.viewValue = viewValue;
    }
  }
  
  export class TimeOptionsList {
    public static readonly timeOptionsList: Array<TimeOptions> = [
      new TimeOptions ( 'timeBands', 'Time bands' ),
      new TimeOptions ( 'exactTime', 'Exact time' ),
    ];
  }

  export class Time{
    readonly value: string;

    constructor(value: string ) {
        this.value = value;
      }

  }







