import { Component, Inject, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Comment, CreatorApproval } from '../../../../models/CreatorApproval.model';
import { CommentService } from '../../../../services/comment.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Privilege } from 'src/app/models/role.model';
import { PrivilegeBlockModalComponent } from 'src/app/modules/shared/privilege-block-modal/privilege-block-modal.component';
import { CampaignCreatorCardComponent } from '../campaign-creator-card/campaign-creator-card.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnDestroy {

  public modalRef: NgbModalRef;
  public ca: CreatorApproval;
  public newComment : string = undefined;
  public subscription: Subscription;
  public commentList: Array<Comment>;
  public posting = false;
  public editing: Comment = null;
  public deleting: Comment = null;


  constructor(@Inject(MAT_DIALOG_DATA) public data: CommentsComponent, private dialogRef: MatDialogRef<CommentsComponent>, private modalService: NgbModal, private cs: CommentService, private userService: UserService) {
    this.ca = this.data.ca;
  }

  public ngOnInit(): void {
    this.commentList = null;
    this.editing = null;
    this.deleting = null;
    this.subscription = this.cs.getComments(this.ca.campaign.companyId, this.ca.campaign.brandId, this.ca.campaignId, this.ca.creator.id)
      .subscribe(
      x => {
        this.commentList = x;
        this.commentList.forEach(y => this.setCommentColor(y));
        this.posting = false;
      });
  }

  public createComment(): void {

    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_PROFILES], this.ca.campaign.companyId, this.ca.campaign.brandId, this.ca.campaign.id)) {
      this.posting = true;
      this.subscription = this.cs.newComment(this.ca.campaign.companyId, this.ca.campaign.brandId, this.ca.campaignId, this.newComment,
        this.ca.creator.id, this.ca.id).subscribe(
        x => {
          this.commentList.push(x);
          this.posting = false;
          this.newComment = '';
        }
      );
    } else {
      this.dialogRef.close();
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent, {windowClass: 'privilege-block-modal'});
    }
  }

  public close(){
      this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.dialogRef.close();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public setCommentColor(comment: Comment): void {
    if (comment.comment.includes('Invited') || comment.comment.includes('Confirmed')) {
      comment.fontColor = 'white';
      comment.commentColor = '#4caf50';
    } else if (comment.comment.includes('Rejected') || comment.comment.includes('Canceled')) {
      comment.fontColor = '#721c24';
      comment.commentColor = '#f8d7da';
    }
  }
  public startEdit(comment: Comment) {
    this.editing = comment;
  }

  public confirm(comment: Comment): void {
    if (this.posting) {
      return;
    }
    if (this.editing) {
      this.posting = true;
      this.cs.editComment(comment.comment, this.ca.campaign.companyId, this.ca.campaign.brandId, this.ca.campaignId, this.ca.creator.id,
        this.ca.id, comment.id).subscribe(() => {
        this.posting = false;
        this.editing = null;
      }, () => this.posting = false);
    } else if (this.deleting) {
      this.posting = true;
      this.cs.deleteComment(this.ca.campaign.companyId, this.ca.campaign.brandId, this.ca.campaignId,
        this.ca.creator.id, this.ca.id, comment.id).subscribe(() => {
        this.posting = false;
        this.commentList = this.commentList.filter(c => c.id !== comment.id);
        this.deleting = null;
      });
    }
  }

  public deleteComment(comment: Comment): void {
    this.deleting = this.deleting ? null : comment;
  }
}
