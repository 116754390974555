import { Component, Input, OnChanges} from '@angular/core';
import { Brand } from 'src/app/models/brand.model';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { BrandService } from 'src/app/services/brand.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.css']
})
export class CompanyUsersComponent implements OnChanges {

  @Input()
  company: Company;

  @Input()
  brands: Brand[] = [];

  @Input()
  users: User[] = [];
  usersList: User[] = [];

  selectedUser: User | null = null;

  searchString = '';
  newUserEmail = '';
  invalidEmail = false;
  emailConflict = false;
  loading = false;

  constructor(private userService: UserService, private brandService: BrandService) { }

  ngOnChanges(): void {
    this.usersList = this.users;
  }

  search(value: string) {
    this.searchString = value;
    const lowerSearch = this.searchString.toLowerCase();
    this.usersList = this.users.filter(u => u.firstName.toLowerCase().startsWith(lowerSearch) ||
      u.lastName.toLowerCase().startsWith(lowerSearch) || u.email.toLowerCase().includes(lowerSearch));
  }

  addUser() {
    if (!this.newUserEmail || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.newUserEmail)) {
      this.invalidEmail = true;
      return;
    }
    this.invalidEmail = this.emailConflict = false;
    this.loading = true;
    this.brandService.createCompanyUser(this.newUserEmail, this.company).subscribe((result) => {
      this.loading = false;
      if (!result) {
        this.emailConflict = true;
      } else {
        this.usersList.push(result.user);
      }
    });
  }

  approveUser(user: User) {
    this.loading = true;
    this.userService.validateAccountWithInfo(user.verificationToken).subscribe(() => {
      user.active = true;
      user.verificationToken = null;
      this.loading = false;
    });
  }

  deleteUser(user: User) {
    this.loading = true;
    this.userService.deleteUser(user.id).subscribe(() => {
      this.users = this.users.filter(u => u.id !== user.id);
      this.usersList = this.usersList.filter(u => u.id !== user.id);
      this.loading = false;
    });
  }

  selectUser(user: User) {
    this.selectedUser = user;
  }

  adminChecked(user: User, checked: boolean) {
    this.loading = true;
    if (checked) {
      this.brandService.addUserRoleByName('admin', this.company.id, user.id, null, null).subscribe((adminRole) => {
        user.roles = user.roles ? user.roles.concat(adminRole) : [adminRole];
        this.loading = false;
      });
    } else {
      const adminRole = user.roles.find(r => r.role?.name === 'admin' && r.companyId === this.company.id && !r.brandId && !r.campaignId);
      if (adminRole) {
        this.brandService.deleteUserRole(this.company.id, user.id, adminRole.id).subscribe(() => {
          user.roles = user.roles?.filter(r => r.id !== adminRole.id);
          this.loading = false;
        });
      }
    }
  }
}
