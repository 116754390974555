import { Component, ElementRef, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { options } from './gender-followers-chart.config';

@Component({
  selector: 'app-gender-followers-chart',
  templateUrl: './gender-followers-chart.component.html',
  styleUrls: ['./gender-followers-chart.component.css']
})

export class GenderFollowersChartComponent implements OnInit {
  public chart: Chart<'bar', string[], string>;
  @Input() public labels: string[];
  @Input() public femaleData: string[];
  @Input() public maleData: string[];

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.createChart();
  }

  createChart(): void {
    const htmlRef = this.elementRef.nativeElement.querySelector(`#followersChart`);
    this.chart = new Chart(htmlRef, {
      type: 'bar', //this denotes tha type of chart
      data: {// values on X-Axis
        labels: this.labels || [],
	       datasets: [
          {
            data: this.maleData,
            borderRadius: 50,
            backgroundColor: '#7400E7'
          },
          {
            data: this.femaleData,
            borderRadius: 50,
            backgroundColor: '#D8C2ED'
          }
        ]
      },
      options
    });
  }

}
