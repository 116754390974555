import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../MustMatchValidator';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CountryList } from 'src/app/models/country';


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  @Input() verificationToken: string | null = null;
  public registerForm: FormGroup;
  submitted = false;
  error = false;
  registerFailed = false;
  @Output() registered = new EventEmitter<boolean>();
  errorMessage: string;
  submitting = false;
  tosUrl: string;
  privacyPolicyUrl: string;
  countries = CountryList.countryList;

  constructor(private fb: FormBuilder, private us: UserService) {
    switch (navigator.language) {
      case 'es':
        this.tosUrl = 'https://lemmonet.com/influencers/termsandconditions/influencerstc_ES.html';
        this.privacyPolicyUrl = 'https://lemmonet.com/influencers/privacypolicy/LemmonetPrivacyPolicy_ES.html';
        break;
      case 'pt':
        this.tosUrl = 'https://lemmonet.com/influencers/termsandconditions/influencerstc_PT.html';
        this.privacyPolicyUrl = 'https://lemmonet.com/influencers/privacypolicy/LemmonetPrivacyPolicy_PT.html';
        break;
      default:
        this.tosUrl = 'https://lemmonet.com/influencers/termsandconditions/influencerstc_EN.html';
        this.privacyPolicyUrl = 'https://lemmonet.com/influencers/privacypolicy/LemmonetPrivacyPolicy_EN.html';
        break;
    }
  }

  get f(): { [key: string]: AbstractControl } {
 return this.registerForm.controls;
}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      companyName: ['', !this.verificationToken ? [Validators.required] : []],
      country: ['', Validators.required],
      email: ['', !this.verificationToken ? Validators.compose([ Validators.required, Validators.email ]) : []],
      industry: ['', Validators.required],
      companyRole: ['', Validators.required],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      tosAgree: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  register(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.submitting = true;
    this.error = false;
    const values = this.registerForm.value;
    if (!this.verificationToken) {
      this.us.onboarding(values.firstName, values.lastName, values.companyName, values.email,
        values.password, values.country, values.industry, values.companyRole, values.phoneNumber).subscribe( () => {

        this.registered.emit(true);
        this.submitting = false;
      }, error => this.showError(error));
    } else {
      this.us.validateAccountWithInfo(this.verificationToken, values.firstName, values.lastName, values.password, values.country,
        values.industry, values.companyRole, values.phoneNumber).subscribe(() => {
        this.registered.emit(true);
        this.submitting = false;
      }, error => this.showError(error));
    }
  }

  private showError(error: HttpErrorResponse) {
    this.registerFailed = true;
    this.errorMessage = '';
    if ('errors' in error.error) {
      if ( 'email' in error.error.errors) {
        this.errorMessage += ' ' + error.error.errors.email[0];
      }
      if ( 'password' in error.error.errors) {
        this.errorMessage += ' ' + error.error.errors.password[0];
      }
    }
    if (this.errorMessage === '') {
      this.errorMessage = 'There was a problem with the onboarding, please try later!';
    }
    this.submitting = false;
  }
}
