import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'x-social-networks-icons',
  templateUrl: './social-networks-icons.component.html',
  styleUrls: ['./social-networks-icons.component.scss']
})
export class SocialNetworksIconsComponent implements OnInit {

  @Input() public socialNetworkName: string;
  public socialNetworkNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor() {}

  public ngOnInit(): void {
    this.socialNetworkNameSubject.next(this.socialNetworkName);
  }

}
