import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Company } from '../../../models/company.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BrandService } from '../../../services/brand.service';
import { User } from '../../../models/user.model';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-move-user-to-company',
  templateUrl: './move-user-to-company.component.html',
  styleUrls: ['./move-user-to-company.component.scss']
})
export class MoveUserToCompanyComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) content;
  submitted = false;
  targetCompany: number;
  modalRef: NgbModalRef;
  @Output() movedUserId = new EventEmitter<{ user: number; targetCompany: number }>();
  @Input() bigBadCompanyList: Array<Company>;
  user: User;
  posting = false;

  constructor(
    private modalService: NgbModal,
    private brandService: BrandService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    return;
  }

  open(user: User): void {
    this.posting = false;
    this.user = user;
    this.targetCompany = 0;
    this.modalRef = this.modalService.open(this.content, { windowClass: 'app-share-campaign-modal'});
  }

  move(): void {
    this.posting = true;
    this.brandService.moveUserToCompany(this.user.id, this.targetCompany).subscribe(() => {
      this.alertService.emitSuccess( 'Profile updated successfully.' );
      this.movedUserId.emit({ user: this.user.id, targetCompany: this.targetCompany });
      this.modalRef.close();
    }, () => {
      this.modalRef.close();
    });
  }

}
