import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmEmailModalComponent } from '../confirm-email-modal/confirm-email-modal.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  verificationToken: string | null = null;

  constructor(public router: Router, private modalService: NgbModal, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.verificationToken = this.activatedRoute.snapshot.queryParamMap.get('v');
    return;
  }

  openModal(): void {
    const modalRef = this.modalService.open(ConfirmEmailModalComponent);
    modalRef.componentInstance.validated = !!this.verificationToken;
    modalRef.result.then(async () => {
        await this.router.navigate(['login']);
    }).catch(async () => {
        await this.router.navigate(['login']);
    });
  }
}
