import { animate, animateChild, query, stagger, state, style, transition, trigger } from "@angular/animations";

const tabStateClosed =  state('closed', style({
  padding: "10px",
  width: "2.5vw", 
  height: "510px",
  gap: "15px",
  background: "#FFFFFF",
}));

const tabStateOpen =  state('open', style({
  gap: "15px",
  width: "43.7vw",
  height: "510px",
  background: "#FBFBFB"
}));

const tabStateReducedOpen =  state('openReduced', style({
  gap: "15px",
  width: "23.1vw",
  height: "510px",
  background: "#FBFBFB"
}));


export class TabAnimations {
  public static tabAnimation = trigger('tabState', [
      tabStateClosed,
      tabStateOpen,
      tabStateReducedOpen,
      transition('closed => open',  [
        query('@contentState', animateChild(), {optional: true}),
        query('@contentWrapperState', animateChild(), {optional: true}),
        query('@headerState', animateChild()),
        animate('500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
      transition('open => closed',[
        query('@contentState', animateChild(), {optional: true}),
        query('@contentWrapperState', animateChild(), {optional: true}),
        query('@headerState', animateChild()),
        animate('500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
      transition('closed => openReduced',[
        query('@contentState', animateChild(), {optional: true}),
        query('@contentWrapperState', animateChild(), {optional: true}),
        query('@headerState', animateChild()),
        animate('500ms 500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
      transition('openReduced => closed',[
        query('@contentState', animateChild(), {optional: true}),
        query('@contentWrapperState', animateChild(), {optional: true}),
        query('@headerState', animateChild()),
        animate('500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
      transition('openReduced => open',[
        query('@contentState', animateChild(), {optional: true}),
        query('@contentWrapperState', animateChild(), {optional: true}),
        query('@headerState', animateChild()),
        animate('500ms 500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
      transition('open => openReduced', [
        query('@contentWrapperState', animateChild(), {optional: true}),
        animate('500ms cubic-bezier(0.55, 0.31, 0.15, 0.93)')
      ])
    ])
}
  
  
  