import { MessagingService } from './../../../../services/messaging.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationDTO } from '../../../../models/notificationDTO.model';
import { NotificationTypes } from '../const';
import { SlideBarService } from '../../../../services/side-bar.service';
import { CreatorService } from '../../../../services/creator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Optional } from '../../../../models/optional.model';
import { Creator } from '../../../../models/creator.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  @Input() notification: NotificationDTO;
  public creator: BehaviorSubject<Creator> = new BehaviorSubject<Creator>(undefined);

  constructor(
    private router: Router,
    private slideBarService: SlideBarService,
    private messagingService: MessagingService,
    private readonly creatorService: CreatorService
  ) {}


  public ngOnInit(): void {
    const notificationData = Optional.of(this.notification.data);
    notificationData.ifPresent(() => {
      this.creatorService.getCreator(notificationData.value.creatorId, true)
      .subscribe({
        next: (data: Creator) => {
          this.creator.next(data);
        }, error: (error: HttpErrorResponse) => {
          console.log(error);
        }
      });
    });
  }

  public async onClickNotification(){
    let notificationType: string;
    switch (this.notification.data.name) {
      case NotificationTypes.CHAT_FROM_CREATOR:
      case NotificationTypes.CREATOR_OFFERT_ACCEPTED:
      case NotificationTypes.CREATOR_OFFERT_COUNTER_OFFERT:
      case NotificationTypes.CREATOR_OFFERT_REJECTED:
        notificationType = this.notification.data.name;
        break;
      case NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST:
        notificationType = NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST;
        break;
      default:
        break;
    }
    if (NotificationTypes.NEGOTIATION_CHAT_ARR.includes(notificationType)) {
      this.messagingService.creatorChatNotificationData.next({
        openChat: true,
        creatorId: this.notification.data.creatorId
      });
    } else if (notificationType === NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST) {
      this.messagingService.creatorSubmissionData.next({
        openSubmissionModal: true,
        creatorId: this.notification.data.creatorId,
        submissionId: this.notification.data.submissionId
      });
    }
    if(this.router.url !==  this.notification.data.redirectRelativeUrl){
      await this.router.navigate([this.notification.data.redirectRelativeUrl]);
    }
    this.slideBarService.setShowNav(false);
  }
}
