import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RangeFilter } from 'src/app/models/filters';

@Component({
  selector: 'app-range-search-filter',
  templateUrl: './range-search-filter.component.html',
  styleUrls: ['./range-search-filter.component.css']
})
export class RangeSearchFilterComponent implements OnInit{

  @Input() filterName: string;
  @Input() filterTitle: string;
  @Input() filterDescription: string;
  @Input() rangeOptions: number[];
  @Input() clearAction: Observable<void>;
  @Input() isPercentage = false;

  rangeForm = this.fb.group({
    min: [null, Validators.min(0)],
    max: [null, Validators.min(0)],
    selected: [null]
  });

  get min() {
    return this.rangeForm.get('min');
  }
  get max() {
    return this.rangeForm.get('max');
  }
  get selected() {
    return this.rangeForm.get('selected');
  }

  @Output() filterChanged = new EventEmitter<RangeFilter | null>();

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.rangeForm.valueChanges.subscribe(() => this.formChanged());
    this.clearAction.subscribe(() => {
      this.rangeForm.get('min').setValue(null);
      this.rangeForm.get('max').setValue(null);
      this.rangeForm.get('selected').setValue(null);
      this.formChanged();
    });
  }

  formChanged() {
    if(this.selected.value || this.selected.value === 0) {
      const min = this.rangeOptions[this.selected.value];
      const max = this.selected.value < this.rangeOptions.length - 1 ? this.rangeOptions[this.selected.value + 1] : undefined;
      this.filterChanged.next(new RangeFilter(this.filterName, min, max, undefined));
    } else if (this.min.value || this.max.value){
      this.filterChanged.next(new RangeFilter(
        this.filterName, this.min ? parseInt(this.min.value, 10) : undefined, this.max ? parseInt(this.max.value, 10) : undefined, undefined
      ));
    } else {
      this.filterChanged.next(new RangeFilter(this.filterName, 0, undefined, undefined));
    }
  }

}
