import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { MessagingService } from './services/messaging.service';
import { Config } from 'protractor';
import { ConfigBackend } from './models/configBackend.model';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    translate: TranslateService, 
    private messagingService: MessagingService,
    private appService: AppService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(navigator.language.split('-')[0]);

    registerLocaleData(localeEs);
    registerLocaleData(localePt);
  }

  async ngOnInit() {
    document.getElementById('app_root_preloader').remove();
    // this.messagingService.requestPermission();
    this.messagingService.listenMessages();
    this.messagingService.listenBackgroundMessages();
    this.appService.getConfigBackend();
  }
}
