export enum CreatorLevels {
  Nano = "Nano",
  Micro = "Micro",
  Mid = "Mid",
  Macro = "Macro",
  Celebrity = "Celebrity",
};

export function creatorLevelsAll(): CreatorLevels[] {
  return [
    CreatorLevels.Nano,
    CreatorLevels.Micro,
    CreatorLevels.Mid,
    CreatorLevels.Macro,
    CreatorLevels.Celebrity,
  ]
}