import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';
import { SlideBarService } from '../../../../services/side-bar.service';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements AfterViewInit {

  public notReadedNotificationsQty: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
      private readonly slideBarService: SlideBarService,
      private readonly cdref: ChangeDetectorRef,
      private readonly notificationService: NotificationService
    ) { }

  public ngAfterViewInit(): void {
    this.notReadedBadgeSubcription();
  }

  private notReadedBadgeSubcription(): void {
    this.notificationService.notReadedNotificactions().subscribe(notRedednotificationsReceive => {
      this.notReadedNotificationsQty.next(notRedednotificationsReceive ? notRedednotificationsReceive.length : 0);
      this.cdref.detectChanges();
    });
  }

  public toggleNotificationsSideBar(): void {
    this.slideBarService.setShowNav(true);
  }

}
