import { Subscription } from "rxjs";

    /**
     * @description
     *  Wrapper de subscripciones. Permite liberar todas las subscripciones
     *  con un único método.
     */

export class Unsubscriber {

    /**
     * @description
     *  La lista de subscripciones registradas.
     */
    private subscriptions: Subscription[] = [];

    constructor() {}

    /**
     * @description
     *  Agrega una nueva subscripción.
     *
     * @param subscription
     *  La subscripción.
     *
     * @returns
     *  Este mismo objeto, lo que permite realizar un encadenamiento de
     *  métodos.
     */
    public addSubscription(subscription: Subscription): Unsubscriber {
        this.subscriptions.push(subscription);
        return this;
    }

    /**
     * @description
     *  Libera todas las subscripciones. Las mismas se liberan según el orden
     *  inverso de registración, es decir, las últimas en registrarse serán
     *  las primeras en liberarse.
     */
    public releaseSubscriptions(): void {
        this.subscriptions.reverse()
			.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
}
