import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable } from 'rxjs';
import { Comment } from '../models/CreatorApproval.model';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private newCommentUrl = 'newComment';
  private editCommentUrl = 'editComment';
  private getCommentsUrl = 'getComments';
  private deleteCommentUrl = 'deleteComment';

  constructor(private rs: RestService) {}

  newComment(companyId: number, brandId: number, campaignId: number, comment: string, creatorId: number, approvalId: number): Observable<any> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/comments`,
      { approval_id: approvalId, comment}
    );
  }

  editComment(comment: string, companyId: number, brandId: number, campaignId: number, creatorId: number,
    approvalId: number, id: number): Observable<Comment> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/comments/${id}/edit`,
      { approval_id: approvalId, comment, commentId: id}
    );
  }

  getComments(companyId: number, brandId: number, campaignId: number, id: number): Observable<Array<Comment>> {
    return this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${id}/comments`);
  }

  deleteComment(companyId: number, brandId: number, campaignId: number, creatorId: number, approvalId: number, id: number): Observable<void> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/comments/${id}/delete`,
      { approval_id: approvalId, commentId: id }
    );
  }
}
