import { state, style, trigger } from "@angular/animations";

const arrowStaticClosed = state('staticopen', style({
    opacity: 0
}));
  
const arrowRightClosed = state('rightclosed', style({
    transform: "scale(1.5) rotate(90deg)"
}));
  
const arrowRight = state('rightopen', style({
    transform: "scale(1.5) rotate(90deg)",
    marginRight: "15px",
    marginLeft: "10px"
}));
  
const arrowLeftClosed = state('leftclosed', style({
    transform: "scale(1.5) rotate(270deg)"
}));

const arrowLeft = state('leftopen', style({
    transform: "scale(1.5) rotate(270deg)",
    marginRight: "15px",
    marginLeft: "10px"
}));

const arrow = state('*', style({
    opacity: 0
}));

export class ArrowAnimation {
    public static arrowAnimation = trigger('arrowState', [
        arrowRightClosed,
        arrowRight,
        arrowStaticClosed,
        arrowLeftClosed,
        arrowLeft,
        arrow
    ]);
}
  
