import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CountryList } from 'src/app/models/country';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-creator-stats-card',
  templateUrl: './creator-stats-card.component.html',
  styleUrls: ['./creator-stats-card.component.scss']
})
export class CreatorStatsCardComponent implements OnInit{

  countryKeys = entry => entry[0];

  @Input()
  creator: CreatorApproval;
  @Input()
  locationHorizontal = false;
  @Input()
  chartWidth = 1;
  @Input()
  chartHeight = 1;

  countries;
  screenHeight: number;
  screenWidth: number;
  // Chart Options
  view: number[] = [window.innerWidth * .2, window.innerHeight * .2];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = 'Age';
  showYAxisLabel = true;
  yAxisLabel = 'Followers';
  title = 'Gender';
  colorScheme = {
    domain: ['#FFF432', '#333333', '#C7B42C', '#AAAAAA']
  };
  flag = '../../../assets/img/flags/';
  tiktokIcon = faTiktok;

  constructor(private ref: ChangeDetectorRef, public AppService: AppService) { }

  ngOnInit() {
    this.onResize();
    this.onResize(); // Necessary for gradient drawing
    this.onResize();
    this.onResize(); // Necessary for gradient drawing
  }

  navigateInstagram(name: string): void {
    window.open('https://www.instagram.com/' + name, '_blank');
  }

  navigateYoutube(name: string): void {
    window.open('https://www.youtube.com/' + name, '_blank');
  }

  navigateTikTok(name: string): void {
    window.open('https://www.tiktok.com/@' + name, '_blank');
  }

  addPercentages(sum: number, p: [string, number]) {
    return sum + (p.length > 1 ? p[1] : 0);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.view = [window.innerWidth * .55 * this.chartWidth, window.innerHeight * .222 * this.chartHeight];
    this.ref.detectChanges();
  }


  getCountryCode(country: string) {
    return CountryList.countryList.find(c => 
      c.country.toLowerCase() === country.toLowerCase()
      || c.code2.toLowerCase() === country.toLowerCase())?.code2  ?? '-';
  }
}
