import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { CreatorImageModalComponent } from './creator-image-modal/creator-image-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PrivilegeBlockModalComponent } from './privilege-block-modal/privilege-block-modal.component';
import { CreatorStatsCardComponent } from './creator-stats-card/creator-stats-card.component';
import { ThousandsPipe } from 'src/app/pipes/thousands.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TwoDecimalPipe } from 'src/app/pipes/twoDecimal.pipe';
import { MapToArrayPipe } from 'src/app/pipes/mapToArray.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RangeSearchFilterComponent } from './range-search-filter/range-search-filter.component';
import { CheckboxSearchFilterComponent } from './checkbox-search-filter/checkbox-search-filter.component';
import { OptionsSearchFilterComponent } from './options-search-filter/options-search-filter.component';
import { SelectSearchFilterComponent } from './select-search-filter/select-search-filter.component';
import { MultiselectSearchFilterComponent } from './multiselect-search-filter/multiselect-search-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [SpinnerComponent, CreatorImageModalComponent, PrivilegeBlockModalComponent, CreatorStatsCardComponent, ThousandsPipe, TwoDecimalPipe,
    MapToArrayPipe, RangeSearchFilterComponent, CheckboxSearchFilterComponent, OptionsSearchFilterComponent, SelectSearchFilterComponent,
    MultiselectSearchFilterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ImageCropperModule,
    NgxChartsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [SpinnerComponent, CreatorImageModalComponent, PrivilegeBlockModalComponent, CreatorStatsCardComponent, ThousandsPipe, MapToArrayPipe,
    TwoDecimalPipe, RangeSearchFilterComponent, CheckboxSearchFilterComponent, OptionsSearchFilterComponent, SelectSearchFilterComponent,
    MultiselectSearchFilterComponent],
  providers: [ThousandsPipe, TwoDecimalPipe, MapToArrayPipe]
})
export class SharedModule { }
