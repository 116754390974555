import { ChartOptions } from 'chart.js';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      }
    },
    y: {
      grid: {
        display: true,
        drawOnChartArea: true,
        drawBorder: true,
        borderDash: [8, 4],
        drawTicks: true,
      }
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      color: '#333',
      font: {
        family: 'Montserrat',
        size: 12,
        style: 'normal',
        weight: '600',
        lineHeight: 6,
      },
      align: 'start',
      text: 'Followers (%)',
    }
  },
};
