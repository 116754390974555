import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Brand } from 'src/app/models/brand.model';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  @Input()
  company: Company;

  brands: Brand[] = [];
  users: User[] = [];

  tab: 'brands' | 'users' = 'users';
  loading = false;
  constructor(private brandService: BrandService) { }

  ngOnInit() {
    this.loading = true;
    combineLatest([this.brandService.getCompanyBrands(this.company.id), this.brandService.getCompanyUsers(this.company.id)])
      .subscribe(([brands, users]) => {
      this.users = users;
      this.brands = brands;
      this.loading = false;
    }, () => this.loading = false);
  }
}
