export class Filter {
    name: string;
    platforms?: string[];
    min?: number;
    max?: number;

    constructor(name: string, platforms?: string[]) {
        this.name = name;
        this.platforms = platforms;
    }
}

export class ValuesFilter extends Filter {
    values: string[];

    constructor(name: string, values: string[], platforms?: string[]) {
        super(name, platforms);
        this.values = values;
    }
}

export class RangeFilter extends Filter {
    min?: number;
    max?: number;

    constructor(name: string, min?: number, max?: number, platforms?: string[]) {
        super(name, platforms);
        this.min = min;
        this.max = max;
    }
}
