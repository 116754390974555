import { Component, Input, OnInit } from '@angular/core';
import { Creator } from 'src/app/models/creator.model';
import { Submission } from 'src/app/models/submission.model';
import { CreatorService } from 'src/app/services/creator.service';
import { SubmissionService } from 'src/app/services/submission.service';

@Component({
  selector: 'app-precampaign-stats',
  templateUrl: './precampaign-stats.component.html',
  styleUrls: ['./precampaign-stats.component.css']
})
export class PrecampaignStatsComponent implements OnInit {

  @Input() creator: Creator;

  loading = false;
  precampaignSubmissions: Submission[];
  avgFeedImpressions = 0;
  avgStoryImpressions = 0;
  avgReelsImpressions = 0;

  constructor(private submissionService: SubmissionService, private creatorService: CreatorService) { }

  ngOnInit(): void {
    this.loading = true;
    this.submissionService.getPrecampaignSubmissions(this.creator.id).subscribe(submissions => {
      this.precampaignSubmissions = submissions;
      this.updateAverages();
      this.loading = false;
    });
  }

  updateAverages() {
    this.avgFeedImpressions = this.getAverageImpressions(this.precampaignSubmissions, 'Feed');
    this.avgReelsImpressions = this.getAverageImpressions(this.precampaignSubmissions, 'Reel');
    this.avgStoryImpressions = this.getAverageImpressions(this.precampaignSubmissions, 'Story');
  }
  getAverageImpressions(submissions: Submission[], format: string) {
    const filtered = submissions.filter(s => s.format === format);
    return filtered.length > 0 ? Math.round(filtered.reduce((avg, s) => avg + s.randomImpressions, 0) / filtered.length) : 0;
  }

  uploadPrecampaignStatsImage(croppedImage: string, format: string) {
    this.loading = true;
    this.creatorService.uploadPrecampaignStatsImage(this.creator.id, format, croppedImage).subscribe(submissions => {
      this.precampaignSubmissions.push(...submissions);
      this.updateAverages();
      this.loading = false;
    });
  }

  clearPrecampaignSubmissions(format: string) {
    this.loading = true;
    this.submissionService.clearPrecampaignSubmissions(this.creator.id, format, 'instagram').subscribe(() => {
      this.precampaignSubmissions = this.precampaignSubmissions.filter(s => s.format !== format);
      this.updateAverages();
      this.loading = false;
    }, () => this.loading = false);
  }
}
