import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login-form/login-form.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder, private us: UserService) { }
  public recoverPasswordFormGroup: FormGroup;
  submitted = false;
  errorResetting = false;
  posting = false;
  @Output() passwordReset = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.recoverPasswordFormGroup = this.fb.group({
      email: ['', Validators.compose([
        Validators.required, Validators.email
      ])]
    });
  }

  get f(): { [key: string]: AbstractControl } {
 return this.recoverPasswordFormGroup.controls;
}

  resetPassword(): void {
    this.posting = true;
    this.submitted = true;
    this.errorResetting = false;
    if (this.recoverPasswordFormGroup.invalid) {
      return;
    }
    const values = this.recoverPasswordFormGroup.value;
    this.us.resetPassword(values.email).subscribe( () => {
      this.posting = false;
      this.passwordReset.emit(true);
    }, () => {
      this.errorResetting = true;
      this.posting = false;
    });
    return;
  }
}
