export class Privilege {

    public static readonly PREDEFINED = {
        EDIT_COMPANY: 'editCompany',
        EDIT_BRANDS: 'editBrands',
        VIEW_BRANDS: 'viewBrands',
        EDIT_CAMPAIGNS: 'editCampaigns',
        VIEW_CAMPAIGNS: 'viewCampaigns',
        EDIT_USERS: 'editUsers',
        VIEW_USERS: 'viewUsers',
        EDIT_PROFILES: 'editProfiles',
        VIEW_PROFILES: 'viewProfiles',
        EDIT_CONTENT: 'editContent',
        VIEW_CONTENT: 'viewContent',
        VIEW_REPORTS: 'viewReports',
        EDIT_PAYMENTS: 'editPayments',
        VIEW_PAYMENTS: 'viewPayments'
    };

    id: number;
    name: string;
    description: string;

    constructor(id: number, name: string, description: string) {
        this.id = id;
        this.name = name;
        this.description = description;
    }

    static deserialize(data: any) {
        return new Privilege(Number.parseInt(data.id, 10), data.name, data.description);
    }
}

export class Role {

    public static readonly NAMES = {
        admin: 'ROLES.ADMINISTRATOR',
        campaignManager: 'ROLES.MANAGER.CAMPAIGN',
        profileManager: 'ROLES.MANAGER.PROFILE',
        contentManager: 'ROLES.MANAGER.CONTENT',
        viewer: 'ROLES.VIEWER',
    };

    id: number;
    name: string;
    companyId: number;
    privileges: Privilege[];

    constructor(id: number, name: string, companyId: number, privileges: Privilege[]) {
        this.id = id;
        this.name = name;
        this.companyId = companyId;
        this.privileges = privileges;
    }

    static deserialize(data: any) {
        return new Role(Number.parseInt(data.id, 10), data.name, data.company_id ?
          Number.parseInt(data.company_id, 10) : null, data.privileges?.map(Privilege.deserialize) ?? []);
    }
}

