import { MessagingService } from './../../../../services/messaging.service';
import { Component, Input, Output, TemplateRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { NegotiationOffer } from 'src/app/models/negotiationOffer.model';
import { NegotiationService } from 'src/app/services/negotiation.service';
import { Observable, Subscription } from 'rxjs';

import { Creator } from '../../../../models/creator.model';
import { CampaignCreatorCardComponent } from '../campaign-creator-card/campaign-creator-card.component';
@Component({
  selector: 'app-creator-negotiation-modal',
  templateUrl: './creator-negotiation-modal.component.html',
  styleUrls: ['./creator-negotiation-modal.component.scss']
})
export class CreatorNegotiationModalComponent implements OnInit {

  modalRef: NgbModalRef;
  dismissModalRef: NgbModalRef;

  @Input()
  creatorApproval: CreatorApproval;
  creator: Creator;

  offers: NegotiationOffer[] = [];
  selectedOffer: NegotiationOffer = null;
  page: 'history' | 'offerMake' | 'offerSent' | 'offerInfo' = 'history';
  loading = false;
  selectedReason = '';
  rejectionComments = '';
  socialNetworks = [];

  @Output() rejectedEmit = new EventEmitter();
  @Output() removeCreator = new EventEmitter<CreatorApproval>();


  @ViewChild('modalContent', { read: TemplateRef, static: true }) modalContent: TemplateRef<CreatorNegotiationModalComponent>;

  constructor(
    private modalService: NgbModal,
    private ts: TranslateService,
    private negotiationService: NegotiationService,
    private messagingService: MessagingService,
  ) { }

  ngOnInit() {
    this.messagingService.checkOpenNegociationChat()
      .subscribe(data => {
        if (this.creatorApproval.creator.id == data?.creatorId && !this.modalService.hasOpenModals() && data.openChat){
          this.open(this.modalContent);
          data.openChat = false;
        }
      });
   }

  back() {
    this.selectedOffer = null;
    this.page = 'history';
  }
  selectOffer(offer: NegotiationOffer) {
    this.page = 'offerInfo';
    this.selectedOffer = offer;
  }

  offerSent(offer: NegotiationOffer) {
    this.page = 'offerSent';
    if(this.selectedOffer) {
      this.selectedOffer.state = 'Declined';
      this.selectedOffer = null;
    }
    this.offers = [offer].concat(this.offers);
  }

  getOfferTime(offer: NegotiationOffer) {
    let time = Math.round((new Date().getTime() - new Date(offer.time).getTime()) / (1000 * 60));
    let label = '';
    if(time < 60) {
      label = this.ts.instant('TIME.MINUTE');
    } else if (time < 60 * 24) {
      time = Math.floor(time / 60);
      label = this.ts.instant('TIME.HOUR');
    } else {
      time = Math.floor(time / (60 * 24));
      label = this.ts.instant('TIME.DAY');
    }
    return this.ts.instant('CAMPAIGN.CREATORS.NEGOTIATION.TIME.AGO')
      .replace('$0', time.toString())
      .replace('$1', `${label}${time > 1 ? 's' : ''}`);
  }

  open(content: TemplateRef<CreatorNegotiationModalComponent>): void {
    this.loading = true;
    this.negotiationService.getNegotiationOffers(this.creatorApproval.campaign.companyId,
      this.creatorApproval.campaign.brandId,
      this.creatorApproval.campaignId,
      this.creatorApproval.creator.id
    ).subscribe(offers => {
      this.offers = offers;
      this.loading = false;
    });
    this.socialNetworks = this.getSocialNetworks();
    this.modalRef = this.modalService.open(content, { windowClass: 'app-creator-negotiation-modal' });
  }

  close(): void {
    this.modalRef?.close();
  }

  public dismissClicked(content: TemplateRef<CampaignCreatorCardComponent>): void {
    this.dismissModalRef = this.modalService.open(content, {windowClass: 'app-creators-details-modal-error'});
  }

  public reject(): void {
    this.rejectedEmit.emit({creator: this.creatorApproval, reason: ('Reason: ' + this.selectedReason +
    (this.rejectionComments.length === 0 ? '' : ' - Commented: ' + this.rejectionComments))});
    this.dismissModalRef.dismiss();
    this.modalRef.dismiss();
  }


  public getProfilePicture(socialNetwork = ''): string {
    if (this.creatorApproval.creator.socialNetworks.length === 0) {
      return '';
    }
    if (this.creatorApproval.creator.socialNetworks.length !== 0) {
      return this.creatorApproval.creator.socialNetworks.find( x => x.socialNetwork.toLowerCase() === socialNetwork.toLowerCase())?.profilePicture ?? '';
    } else {
      const instagram = this.creatorApproval.creator.socialNetworks.find(sn => sn.socialNetwork.toLowerCase() === 'instagram');
      return instagram?.profilePicture ?? this.creatorApproval.creator.socialNetworks.map(x => x).pop().profilePicture;
    }
  }

  public getSocialNetworks(){
    const socialNetworks = [];
    this.creatorApproval.creator.socialNetworks.forEach(function(element){
      const obj = {
        socialNetwork : element.socialNetwork,
        username :  element.username
      };
      socialNetworks.push(obj);
    });
    return socialNetworks;
  }
}
