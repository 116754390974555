import { ChartOptions } from 'chart.js';

export const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '75%',
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#333',
        font: {
          family: 'Montserrat',
          size: 12,
          style: 'normal',
          weight: '600'
        },
        padding: 24,
        usePointStyle: true,
      },
      onClick: () => {}
    }
  }
};
