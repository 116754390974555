import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company.model';
import { BrandService } from '../../../services/brand.service';
import { AlertService } from '../../../services/alert.service';


@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) content;
  submitted = false;
  company: Company;
  modalRef: NgbModalRef;
  @Output() createdCompanyForOwner = new EventEmitter<number>();
  userId: number;

  constructor(
    private modalService: NgbModal,
    private brandService: BrandService,
    private alertService: AlertService ) { }

  ngOnInit(): void {
 return;
}

  open(userId: number): void {
    this.userId = userId;
    this.submitted = false;
    this.company = new Company('', '', '', 0, 0, new Date(), false, null);
    this.modalRef = this.modalService.open(this.content, { windowClass: 'app-share-campaign-modal'});
  }

  close(): void {
    this.modalRef.close();
  }

  create(): void {
    if (this.company.logo === '' || this.company.legalName === '' || this.company.brandName === '') {
      return;
    }
    this.submitted = true;
    this.brandService.createCompany(this.company, this.userId).subscribe(() => {
      this.modalRef.close();
      this.createdCompanyForOwner.emit(this.userId);
      this.alertService.emitSuccess('User moved to new company successfully.');
    }, () => {
      this.modalRef.close();
    });
  }

}
