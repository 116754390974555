import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Campaign } from '../models/campaign.model';
import { catchError, map, tap } from 'rxjs/operators';
import { UpdateCampaignModel } from '../modules/campaign/UpdateCampaignModel';
import { CreatorApproval, CreatorApprovalPlanner } from '../models/CreatorApproval.model';
import { UserRole } from '../models/user.model';
import { Company } from '../models/company.model';
import { CommercialProposal } from '../models/commercialProposal';
import { CreatorPlannerContent } from '../models/CreatorPlannerContent.model';
import { CampaignPresentation } from '../models/campaignPresentation.model';

type PathCampaign = {companyId: number; brandId: number; id: number}

const baseUriCampaign = (data: PathCampaign) : string => 
  `companies/${data.companyId}/brands/${data.brandId}/campaigns/${data.id}`;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private getCampaignStatsUrl = 'getCampaignStats';
  private getCampaignCreatorsUrl = 'getCampaignPublicCreators';
  private campaignList: Array<Campaign>;
  private campaignList$: Observable<Array<Campaign>>;
  private cacheTime: number;
  private cardCampaignName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private cacheLife = 300000;
  private appDetailsUrl = 'appDetails';

  constructor(private rs: RestService) {
    this.rs.getLogoutEvent().subscribe( () => this.clearCaches());
  }

  getCampaignsList(): Observable<Array<Campaign>> {
      if (this.campaignList && (this.cacheLife + this.cacheTime - Date.now() ) > 0) {
      // if `data` is available just return it as `Observable`
      return of(this.campaignList);
    } else if (this.campaignList$) {
      // if observable is set then the request is in progress
      return this.campaignList$;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      this.campaignList$ = this.rs.get('campaigns')
        .pipe( catchError( (err, obs) => {
          this.campaignList$ = null;
          this.campaignList = null;
          throw err;
        }),
          tap ( (response) => {
            this.campaignList$ = null;
            this.campaignList = new Array<Campaign>();
            response.campaigns.forEach(( rawCampaign => {
              this.campaignList.push(new Campaign().deserialize(rawCampaign));
            }));
            this.cacheTime = Date.now();
          },
            () => {
              this.campaignList$ = null;
              this.campaignList = null;
            }),
          map( () => this.campaignList)
        );
      return this.campaignList$;
    }
  }

  getCampaign(id: number): Observable<Campaign> {
    return this.rs.get(`campaigns/${id}`).pipe(map( (x) => new Campaign().deserialize(x)));
  }

  createCampaign(campaign: UpdateCampaignModel): Observable<{ campaign: Campaign; upload: string[] }> {
    this.campaignList = null;
    const route = campaign.brandId ?
      `companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns` : `companies/${campaign.companyId}/campaigns`;
    return this.rs.postWithFile(route, campaign.getFormData());
  }

  updateCampaign(campaign: UpdateCampaignModel): Observable<{ campaign: Campaign; upload: string[] }> {
    this.campaignList = null;
    return this.rs.postWithFile(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}`,
      campaign.getFormData());
  }

  saveDraftCampaign(campaign: UpdateCampaignModel): Observable<{ campaign: Campaign; upload: string[] }> {
    this.campaignList = null;
    const route = campaign.brandId ?
      `companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/draft` : `companies/${campaign.companyId}/campaigns/draft`;
    return this.rs.postWithFile(route, campaign.getFormData());
  }

  delete(campaign: Campaign) {
    this.campaignList = null;
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/delete`, null);
  }

  clearCaches(): void {
    this.cacheLife = 0;
    this.campaignList$ = null;
    this.campaignList = null;
  }

  getCampaignStats(token: string): Observable<any> {
    return this.rs.getPublic(this.getCampaignStatsUrl + '/' + token);
  }

  getCampaignPublicCreators(token: string): Observable<any> {
    return this.rs.getPublic(this.getCampaignCreatorsUrl + '/' + token);
  }

  inviteToCampaign(email: string, companyId: number, brandId: number, campaignId: number, roleName: string): Observable<UserRole> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/invite`, { email, role: roleName }).pipe(
      map(UserRole.deserialize)
    );
  }

  removeFromCampaign(companyId: number, brandId: number, campaignId: number, userRoleId: number): Observable<any> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/remove`,
      { user_role_id: userRoleId ?? null });
  }

  clone(campaign: Campaign, options: {
    hasCampaignPlanners: boolean
    hasCampaignScenarios: boolean
    hasCreators: boolean
    hasCreatorsPlanners: boolean
  }): Observable<any> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/clone`,
      {id: campaign.id, newName: campaign.name, ...options}
    );
  }

  cloneWithCreators(campaign: Campaign): Observable<any> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/cloneWithCreators`,
      {id: campaign.id, newName: campaign.name}
    );
  }

  // getCategoryList(): Observable<any> {
  //   return this.rs.get('campaigns/categories');
  // }

  // getAppDetails(id, os): Observable<any> {
  //   return this.rs.get(this.appDetailsUrl, 'id=' + id + '&os=' + os);
  // }

  getCompanyList(): Observable<Company[]> {
    return this.rs.get(`companies/all`).pipe(
      map(companies => companies.map(Company.deserialize))
    );
  }

  uploadExampleVideo(url: string, video: File): Observable<number> {
    return this.rs.postFile(url, video);
  }

  // getCampaignCreatorPlanner(
  //   plannerId: number,
  //   pathCampaign: PathCampaign): Observable<{
  //     planner: CreatorApprovalPlanner,
  //     contents: CreatorPlannerContent[],
  //   }> {
  //   const uri = `companies/${pathCampaign.companyId}/brands/${pathCampaign.brandId}/campaigns/${pathCampaign.id}`
  //   + `/planner/${plannerId}`;

  //   return this.rs.get(uri).pipe(map(result => ({
  //     planner: (new CreatorApprovalPlanner()).deserialize(result.planner), 
  //     contents: result.contents.map(content => (new CreatorPlannerContent()).deserialize(content)),
  //   })));
  // }

  saveCampaignCreatorPlanner(
    planner: CreatorApprovalPlanner,
    pathCampaign: PathCampaign): Observable<any> {

    const uri = `companies/${pathCampaign.companyId}/brands/${pathCampaign.brandId}/campaigns/${pathCampaign.id}`
    + `/planner/userApproval/${planner.approval_id}/platform/${planner.platform}`;

    return this.rs.post(uri, planner.serialize());
  }

  toggleApproveCampaignCreatorPlanner(
    planner: {approval_id: number; platform: string; approved: boolean},
    pathCampaign: PathCampaign): Observable<any> {

    const uri = `companies/${pathCampaign.companyId}/brands/${pathCampaign.brandId}/campaigns/${pathCampaign.id}`
    + `/planner/userApproval/${planner.approval_id}/platform/${planner.platform}`;

    return this.rs.post(uri, planner);
  }


  uploadCampaignPlannerCsv(companyId: number, brandId: number, campaignId: number, file: File): Observable<CreatorApproval[]> {
    const data = new FormData();
    data.append('campaignId', campaignId.toString());
    data.append('planner', file);
    return this.rs.postWithFile(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/planner/upload`, data).pipe(
      map((approvals: any) => approvals.map(a => new CreatorApproval().deserialize(a)))
    );
  }

  getCampaignUserRoles(companyId: number, brandId: number, campaignId: number): Observable<UserRole[]> {
    return this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/roles/users`).pipe(
      map((response: any[]) => response.map(userRole => UserRole.deserialize(userRole)))
    );
  }

  shareCampaignProposal(companyId: number, brandId: number, campaignId: number, caseStudies: string[]): Observable<string> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/commercialProposals`,
      { campaign_id: campaignId, case_studies: caseStudies }).pipe(
      map((response: any) => response.share_token)
    );
  }

  getCampaignProposal(token: string): Observable<CommercialProposal> {
    return this.rs.get(`commercialProposals/${token}`).pipe(
      map((response: any) => new CommercialProposal().deserialize(response))
    );
  }

  public setCardCampaignName(cardCampaignName: string): void{
    this.cardCampaignName.next(cardCampaignName);
  }

  public getCardCampaignName(): Observable<string>{
    return this.cardCampaignName;
  }

  public setCampaignSelectedCreator(companyId: number,
    brandId: number, campaignId: number, userApprovalId: number, calcSelected: boolean): Observable<string> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/calculator/selected/${userApprovalId}`,
    { calc_selected: calcSelected });
  }

  public getCampaignSelectedCreator(companyId: number,
    brandId: number, campaignId: number, calcSelected: boolean): Observable<string> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/calculator/selected`,
    { calc_selected: calcSelected });
  }

  private getBasePlannerContentUri(pathCampaign: PathCampaign) {
    return baseUriCampaign(pathCampaign) + `/planner/content`;
  } 

  createPlannerContent(pathCampaign: PathCampaign, data: CreatorPlannerContent): Observable<CreatorPlannerContent> {
    const uri = this.getBasePlannerContentUri(pathCampaign);
    return this.rs.post(uri, data.serialize())
      .pipe(map( (item) => (new CreatorPlannerContent()).deserialize(item)));
  }

  updatePlannerContent(pathCampaign: PathCampaign, data: CreatorPlannerContent): Observable<CreatorPlannerContent> {
    const uri = this.getBasePlannerContentUri(pathCampaign) + `/${data.id}`;
    return this.rs.post(uri, data.serialize())
      .pipe(map( (item) => (new CreatorPlannerContent()).deserialize(item)));
  }

  deletePlannerContent(pathCampaign: PathCampaign, id: number): Observable<CreatorApprovalPlanner> {
    const uri = this.getBasePlannerContentUri(pathCampaign) + `/${id}/delete`;
    return this.rs.post(uri, {})
      .pipe(map(planner => (new CreatorApprovalPlanner()).deserialize(planner)));
  }
  
  public updateCreatorApproval(campaign: Campaign, creatorApproval: CreatorApproval): Observable<CreatorApproval>  {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/creators/${creatorApproval.userId}`,
    { about_me: creatorApproval.aboutMe, creator_level: creatorApproval.creatorLevel });
  }

  public createPresentation(campaign: Campaign, data: {success_cases: string[]}) : Observable<CampaignPresentation> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation`, data).pipe(map((response) => (new CampaignPresentation()).deserialize(response)));
  }

  public updatePresentation(campaign: Campaign, presentation: CampaignPresentation, data: {success_cases: string[]}) : Observable<CampaignPresentation> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation/${presentation.id}`, data ).pipe(map((response) => (new CampaignPresentation()).deserialize(response)));
  }

  public getPresentationLatestRevision(campaign: Campaign) : Observable<string> {
    if (!campaign.campaignPresentation) {
      return of(null);
    }
    return this.rs.get(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation/${campaign.campaignPresentation.id}/latestRevision` );
  }

  public exportPresentation(campaign: Campaign) : Observable<CampaignPresentation> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation/${campaign.campaignPresentation.id}/export`, {}).pipe(map((response) => (new CampaignPresentation()).deserialize(response)));
  }

  public createPresentation2(campaign: Campaign, data: {success_cases: string[]}) : Observable<CampaignPresentation> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation2`, data).pipe(map((response) => (new CampaignPresentation()).deserialize(response)));
  }

  public updatePresentation2(campaign: Campaign, presentation: CampaignPresentation, data: {success_cases: string[]}) : Observable<CampaignPresentation> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/presentation2/${presentation.id}`, data ).pipe(map((response) => (new CampaignPresentation()).deserialize(response)));
  }

  public enableCosts(campaign: Campaign, status: boolean): Observable<Campaign> {
    return this.rs.post(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/enableCosts`, {status});
  }
}


