import { Component, Inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreatorApprovalPlanner, PlannerForm, PlannerContentNamesByPlatform, CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Creator } from 'src/app/models/creator.model';
import { Helper } from 'src/app/support/helper.support';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { CreatorLevels, creatorLevelsAll } from 'src/app/enum/creator-levels.enum';


@Component({
  selector: 'app-edit-creators-planner',
  templateUrl: './creators-planner-edit.component.html',
  styleUrls: ['./creators-planner-edit.component.css'],
})
export class CreatorsPlannerEditComponent {

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  plannerForm: PlannerForm;
  userApprovalForm: FormGroup;
  campaign: Campaign;
  creator: Creator;
  userApproval: CreatorApproval;
  username: string;
  loading = false;
  platformContentNames = PlannerContentNamesByPlatform;
  dontFormatNumberFields = ['id','platform','approval_id','approved'];
  creatorLevels: CreatorLevels[] = creatorLevelsAll();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {planner: CreatorApprovalPlanner; campaign: Campaign; creator: Creator, userApproval: CreatorApproval},
    public dialogRef: MatDialogRef<CreatorsPlannerEditComponent>,
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    private _ngZone: NgZone) {
    this.campaign = this.data.campaign;
    this.creator = this.data.creator;
    this.userApproval = this.data.userApproval;
    this.userApprovalForm = this.createUserApprovalForm();
    this.plannerForm = new PlannerForm(this.data.planner.clone());
    this.plannerForm.setForm(this.createFormFromPlanner(this.plannerForm));
    this.username = this.creator[this.plannerForm.planner.platform].username;
    this.platformContentNames = PlannerContentNamesByPlatform[this.plannerForm.planner.platform];
    this.updateTotals();
  }

  private createUserApprovalForm(): FormGroup {
    return this.formBuilder.group({
      aboutMe: [this.userApproval.aboutMe || ''],
      creatorLevel: [this.userApproval.creatorLevel || ''],
    });
  }

  private createFormFromPlanner(plannerForm: PlannerForm): FormGroup {
    const formItems = {
      aboutMe: this.userApproval.aboutMe || '',
      creatorLevel: this.userApproval.creatorLevel || '',
      costPerClick: 0,
      totalPrice: 0,
      totalQuantity: 0,
      totalViews: 0,
      totalReach: 0,
      totalEngagements: 0,
    };
    for (const [key, value] of Object.entries(plannerForm.plannerToFormData())) {
      if (typeof(value) === 'number' && !this.dontFormatNumberFields.includes(key)) {
        formItems[key] = new FormControl(value !== null ? value.toLocaleString('es-AR') : null);
      } else {
        formItems[key] = new FormControl(value);
      }
    }
    return this.formBuilder.group(formItems);
  }

  format2Decimals(x: number): string{
    return (x ?? 0).toLocaleString('es-AR', {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }

  formatNumber(value: string): {valueNumber: number; formated: string } {
    if (!value && value !== '0') {
      return { valueNumber: null, formated: '', };
    }
    if ([',', '.']. includes(value[value.length - 1])) {
      return { valueNumber: null, formated: value, };
    }
    const valueNumber = parseFloat(value.toString().replace(/\./g ,'').replace(/\,/, '.'));
    return { valueNumber, formated: valueNumber.toLocaleString('es-AR'), };
  }

  onFormatNumberInput(prop: string) {
    const formProp = this.plannerForm.form.controls[prop];
    const parsed = this.formatNumber(formProp.value);
    this.plannerForm.setPlannerValue(prop, parsed.valueNumber);
  }

  onFormatNumberBlur(prop: string) {
    const formProp = this.plannerForm.form.controls[prop];
    const parsed = this.formatNumber(formProp.value);
    formProp.setValue(parsed.formated);
  }

  updateTotals() {
    const valuesForm = this.plannerForm.form.value;
    valuesForm.costPerClick = this.format2Decimals(this.plannerForm.planner.costPerClick());
    valuesForm.totalPrice = this.format2Decimals(this.plannerForm.planner.netNegotiatedPrice());
    valuesForm.totalQuantity = this.format2Decimals(this.plannerForm.planner.totalContent());
    valuesForm.totalViews = this.format2Decimals(this.plannerForm.planner.totalViews());
    valuesForm.totalReach = this.format2Decimals(this.plannerForm.planner.totalReach());
    valuesForm.totalEngagements = this.format2Decimals(this.plannerForm.planner.totalEngagements());
    this.plannerForm.form.setValue(valuesForm);
  }

  getEngagementPercentContentDisplay(contentName: string): string {
    const percentValue = this.plannerForm.planner.avgEngagementsPercent()[contentName];
    return (percentValue ? this.format2Decimals(percentValue) : '') + '%';
  }

  getTotalEngagementPercentDisplay(): string {
    const percentValue = CreatorApprovalPlanner.engagementAbsToPercent(
      this.plannerForm.planner.totalEngagements(),
      this.plannerForm.planner.totalViews());
    return (percentValue ? this.format2Decimals(percentValue) : '') + '%';
  }


  close() {
    this.dialogRef.close();
  }

  async save() {
    this.loading = true;
    
    if (this.userApprovalForm.dirty) {
      const aboutMeNew = this.userApprovalForm.get('aboutMe').value;
      const creatorLevelNew = this.userApprovalForm.get('creatorLevel').value;
      const userApproval = this.userApproval.clone();
      userApproval.aboutMe = aboutMeNew;
      userApproval.creatorLevel = creatorLevelNew;
      try {
        await this.campaignService.updateCreatorApproval(this.campaign, userApproval).toPromise();
        this.userApproval.aboutMe = aboutMeNew;
        this.userApproval.creatorLevel = creatorLevelNew;
      } catch (err) {
        this.loading = false;
        this.userApprovalForm.setErrors(err.error.errors);
        return;
      }
    }

    const plannerToUpdate = new CreatorApprovalPlanner(this.plannerForm.planner);
    const result = await this.campaignService.saveCampaignCreatorPlanner(plannerToUpdate, this.campaign)
    .subscribe(result => {
      this.plannerForm.planner.deserialize(result.planner);
      this.loading = false;
      this.dialogRef.close(this.plannerForm.planner);
    }, err => {
      this.loading = false;
      this.plannerForm.form.setErrors(err.error.errors);
    });
  }


  blur() {
    // console.log('---here', this.matCountry )
    // console.log($('#matCountry'))
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
