import { Helper } from '../support/helper.support';
import { Audience } from './Interfaces/Audience';
import { SocialUser } from './Interfaces/SocialUser';

export class YoutubeAudience extends Audience {
  commentCount: number;
  subscriberCount: number;
  followers: number;
  videoCount: number;
  viewCount: number;
  gendersChartSeries = [];
  recentMedia: Array<Array<any>>;
  averageLikes: number;
  averageMinutesWatched: number;
  audienceCountry: any[];
  averageViews: number;
  impressions = 0;
  engagementRate: number;
  viewRate: number;
  mediaIds: Array<string>;
  averageViewDuration: number;
  averageViewPercentage: number;
  averageSubscribersFlow: number;
  averageComments: number;
  hasAudience = true;
  raw: any;

  constructor(rawAudience: any) {
    super();
    if (!rawAudience) {
      this.hasAudience = false;
      return;
    }
    const audience = JSON.parse(rawAudience);
    this.raw = audience;
    this.recentMedia = audience.media ? audience.media.slice(0, 6) : audience.recentMedia;
    if (this.recentMedia.length === 0) {
      this.hasAudience = false;
      return;
    }
    this.commentCount = +audience.commentCount;
    this.followers = this.subscriberCount = +audience.subscriberCount;
    this.videoCount = +audience.videoCount;
    this.viewCount = +audience.viewCount;
    // this.setGenderChart(audience.gender);
    this.audienceCountry = audience.country ?
      audience.country.sort((x, y) => x[1] > y[1] ? -1 : 1).slice(0, 5).map(x =>
        [x[0], (100 * x[1] / this.viewCount)]) : [];
    this.averageLikes = this.recentMedia.map(x => Number.parseInt(x[3], 10)).reduce((x, y) => x + y, 0);
    this.averageViews = (Math.round(100 * this.recentMedia.map(
      x => Number.parseInt(x[2], 10)).reduce((x, y) => x + y, 0) / this.recentMedia.length) / 100);
    this.averageMinutesWatched = this.recentMedia
      .map(x => x[1])
      .reduce((x, y) => x + y, 0) / this.recentMedia.length / this.averageViews;
    this.averageMinutesWatched = Math.round(10 * this.averageMinutesWatched) / 10;
    this.viewRate = Math.round(this.averageViews / this.subscriberCount * 100 * 100) / 100;
    this.mediaIds = this.recentMedia.map(x => x[0]);
    this.averageViewDuration = this.recentMedia.map(x => Number.parseFloat(x[5])).reduce((x, y) => x + y, 0) / this.recentMedia.length;
    this.averageViewDuration = Math.round(this.averageViewDuration * 10) / 10;
    this.averageViewPercentage = this.recentMedia.map(x => Number.parseFloat(x[6])).reduce((x, y) => x + y, 0) / this.recentMedia.length;
    this.averageViewPercentage = Math.round(this.averageViewPercentage * 10) / 10;
    this.averageSubscribersFlow = this.recentMedia.map(x => x[4] - x[7]).reduce((x, y) => x + y, 0) / this.recentMedia.length;
    this.averageSubscribersFlow = Math.round(this.averageSubscribersFlow);
    this.averageComments = this.recentMedia.map(x => Number.parseInt(x[8], 10)).reduce((x, y) => x + y, 0) / this.recentMedia.length;
    this.averageComments = Math.round(this.averageComments);
    this.engagementRate = super.ratio(this.averageLikes + this.averageComments, this.followers);
  }

  public getSocialNetwork(): string {
    return 'youtube';
  }

  setGenderChart(gender: Array<Array<any>>): void {
    if (!gender) {
      this.gendersChartSeries = [
        {
          name: '13-17',
          series: [
            { name: 'Male', value: 4.16 },
            { name: 'Female', value: 4.66 }
          ]
        },
        {
          name: '18-24',
          series: [
            { name: 'Male', value: 15.56 },
            { name: 'Female', value: 22.8 }
          ]
        },
        {
          name: '25-34',
          series: [
            { name: 'Male', value: 11.2 },
            { name: 'Female', value: 19.04 }
          ]
        },
        {
          name: '35-44',
          series: [
            { name: 'Male', value: 4.24 },
            { name: 'Female', value: 9.19 }
          ]
        },
        {
          name: '45-54',
          series: [
            { name: 'Male', value: 3.13 },
            { name: 'Female', value: 2.06 }
          ]
        },
        {
          name: '55-64',
          series: [
            { name: 'Male', value: 2.455 },
            { name: 'Female', value: 0.505 }
          ]
        },
        {
          name: '65+',
          series: [
            { name: 'Male', value: 0.7 },
            { name: 'Female', value: 0.3 }
          ]
        }
      ];
    } else {
      this.gendersChartSeries = new Array<any>();
      const auxArray = [];
      auxArray['13-17'] = [];
      auxArray['18-24'] = [];
      auxArray['25-34'] = [];
      auxArray['35-44'] = [];
      auxArray['45-54'] = [];
      auxArray['55-64'] = [];
      auxArray['65-'] = [];
      gender.forEach(x => {
        auxArray[x[0].split('age')[1]].push(
          {
            name: x[1],
            value: x[2]
          });
      });
      Object.keys(auxArray).forEach(x =>
        this.gendersChartSeries.push(
          {
            name: x,
            series: auxArray[x]
          })
      );
    }
  }
}

export class Youtube implements SocialUser {
  id: number;
  userId: number;
  socialNetwork = 'youtube';
  username: string;
  description: string;
  audience: YoutubeAudience;
  channelTitle: string;
  profilePicture: string;
  hasAudience = false;
  channelId: string;
  engagementRate: number;
  videoMentionPrice: number;
  videoExclusivePrice: number;
  customUrl: string;
  avgMentions: number;
  avgVideoYoutube: number;
  avgReachMentions: number;
  avgReachVideoYoutube: number;
  avgEngagementMentions: number;
  avgEngagementVideoYoutube: number;

  constructor(rawInfo: any) {
    if (!rawInfo) {
      return;
    }
    this.id = rawInfo.id && parseInt(rawInfo.id);
    this.userId = rawInfo.user_id && parseInt(rawInfo.user_id);
    this.username = rawInfo.username;
    this.description = rawInfo.description;
    this.channelTitle = rawInfo.title;
    this.profilePicture = rawInfo.profile_picture;
    this.audience = new YoutubeAudience(rawInfo.audience);
    this.hasAudience = this.audience.hasAudience;
    this.channelId = rawInfo.channel_id;
    this.engagementRate = Math.round(100 * rawInfo.engagement_rate) / 100;
    this.videoMentionPrice = rawInfo.video_mention_price;
    this.videoExclusivePrice = rawInfo.video_exclusive_price;
    this.customUrl = rawInfo.custom_url;
    this.avgMentions = Helper.numberOrNull(rawInfo.avg_mentions);
    this.avgVideoYoutube = Helper.numberOrNull(rawInfo.avg_video_youtube);
    this.avgReachMentions = Helper.numberOrNull(rawInfo.avg_reach_mentions);
    this.avgReachVideoYoutube = Helper.numberOrNull(rawInfo.avg_reach_video_youtube);
    this.avgEngagementMentions = Helper.numberOrNull(rawInfo.avg_engagement_mentions);
    this.avgEngagementVideoYoutube = Helper.numberOrNull(rawInfo.avg_engagement_video_youtube);
  }

  goToProfile(): void {
    window.open('https://www.youtube.com/channel/' + this.channelId, '_blank');
  }
}

// Media object reference

//   id: string;
//   estimatedMinutesWatched: number;
//   views: number;
//   likes: number;
//   subscribersGained: number;
//   averageViewDuration: number;
//   averageViewPercentage: number;
//   averageSubscribersLost: number;
 //  comments: number
