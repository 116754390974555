import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public loginForm: FormGroup;
  posting = false;
  submitted = false;
  invalidCredentials = false;
  accountNotActive = false;

  constructor(private fb: FormBuilder, private us: UserService, public router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required, Validators.email
      ])],
      password: ['', Validators.required]
    });
  }

  get f(): { [key: string]: AbstractControl }  {
 return this.loginForm.controls;
}


  submitLogin(): void {
    this.posting = true;
    this.submitted = true;
    this.invalidCredentials = false;
    this.accountNotActive = false;
    if (this.loginForm.invalid) {
      this.posting = false;
      return;
    }
    const values = this.loginForm.value;
    this.us.login(values.email, values.password).subscribe( async () => {
      this.posting = false;
      await this.router.navigateByUrl('dashboard');
    }, error => {
      this.posting = false;
      if (error.status && (error.status === 401 || error.status === 403)) {
        if (!!error.error.code && error.error.code === 1) {
          this.accountNotActive = true;
          return;
        }
        this.invalidCredentials = true;
        return;
      }
      this.modalService.open(ErrorModalComponent);
    });
    return;
  }
}
