import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Submission } from 'src/app/models/submission.model';


class Total {
  followers: number;
  quantity: number;
  feed: number;
  stories: number;
  reels: number;
  videos: number;
  menciones: number;
  videosTiktok: number;
  estimatedImpressions: number;
}

interface TableItem {
  influencerType: string;
  followers: number;
  quantity: number;
  feed: number;
  stories: number;
  reels: number;
  videos: number;
  menciones: number;
  videosTiktok: number;
  estimatedImpressions: number;
}

@Component({
  selector: 'app-campaign-planner-table',
  templateUrl: './campaign-planner-table.component.html',
  styleUrls: ['./campaign-planner-table.component.scss'],
})
export class CampaingPlannerTableComponent implements AfterViewInit, OnInit {

  @Input() campaign: Campaign;
  @Input() submissions: Submission[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'influencerType',
    'followers',
    'quantity',
    'feed',
    'reels',
    'stories',
    'videos',
    'menciones',
    'videosTiktok',
    'estimatedImpressions',
  ];
  dataSource: MatTableDataSource<TableItem> = new MatTableDataSource([]);
  total = new Total();

  constructor(private campaignService: CampaignService, public dialog: MatDialog) {
    //
  }

  ngOnInit(): void {
    this.updateTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateTable() {
    this.dataSource.data = this.fetchData();
    this.updateTotal();
  }

  updateTotal() {
    this.total = this.calcTotal(this.dataSource.data);
  }

  fetchData() {
    const result = this.campaign.planner.filter( (table) => table.active === 1 )
      .map((item) => ({
        influencerType:
          item.influencerType.charAt(0).toUpperCase() +
          item.influencerType.slice(1),
        followers: item.followers,
        quantity: item.quantity,
        feed: item.feed,
        stories: item.stories,
        reels: item.reels,
        videos: item.videos,
        menciones: item.menciones,
        videosTiktok: item.videosTiktok,
        estimatedImpressions:this.calcEstimatedImpressions(item),
      }));
    return result;
  }

  calcEstimatedImpressions(item): number {
    return item.quantity *
        item.followers *
        (item.feed * 0.2 +
          item.stories * 0.15 +
          item.reels * 0.25 +
          item.videos * 0.2 +
          item.menciones * 0.2 +
          item.videosTiktok * 0.2);
    }

  calcTotal(table: TableItem[]): Total{
    let total = {} as Total;
    this.dataSource.data
      .forEach((item) => {
        const sum = (propName: keyof TableItem) => item[propName] + (total[propName] || 0);
        total = {
            quantity: sum('quantity'),
            followers: sum('followers'),
            feed: sum('feed'),
            stories: sum('stories'),
            reels: sum('reels'),
            videos: sum('videos'),
            menciones: sum('menciones'),
            videosTiktok: sum('videosTiktok'),
            estimatedImpressions: sum('estimatedImpressions'),
          };
      });
    return total;
  }

  exportExcel() {
    const decimals = (x: number, y: number ) =>
      x.toLocaleString( undefined, { minimumFractionDigits: y, maximumFractionDigits: 2 });
    const workBook = XLSX.utils.book_new();
    const data = this.dataSource.data;
    const workSheet = XLSX.utils.json_to_sheet(data.map(row => ({
      influencerType: row.influencerType,
      followers: decimals(row.followers, 0),
      quantity: decimals(row.quantity, 0),
      feed: decimals(row.feed, 0),
      stories: decimals(row.stories, 0),
      reels: decimals(row.reels, 0),
      videos: decimals(row.videos, 0),
      menciones: decimals(row.menciones, 0),
      videosTiktok: decimals(row.videosTiktok, 0),
      estimatedImpressions: decimals(row.estimatedImpressions, 2),
    })));
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `campaign-planner-${this.campaign.id}.xlsx`);
  }

}
