import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Creator } from '../../../models/creator.model';
import { Subscription } from 'rxjs';
import { SocialNetworks } from '../../../models/SocialNetworks';
import { CreatorPrices } from 'src/app/services/creator.service';
import { TikTok } from '../../../models/tiktok.model';


@Component({
  selector: 'app-tiktok-audience',
  templateUrl: './tiktok-audience.component.html',
  styleUrls: ['./tiktok-audience.component.css']
})
export class TiktokAudienceEditorComponent implements OnInit, OnDestroy {
  tiktok: TikTok;
  @Input() creator: Creator;
  @Input() getAudienceEvent: EventEmitter<any>;
  @Output() audienceObject = new EventEmitter<any>();
  creatorPrices: CreatorPrices;
  private subscription: Subscription;
  constructor() {
 return;
}

  ngOnInit(): void {
    this.subscription = this.getAudienceEvent.subscribe(() => {
     this.emitAudience();
    });
    this.creatorPrices = { tiktok: {
      videoPrice: this.tiktok?.videoPrice,
    }};
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private emitAudience(): void {
    this.audienceObject.emit({
      audience: JSON.stringify({ is_verified: this.creator.tikTok.isVerified}),
      socialId: this.creator.tikTok.username,
      socialNetwork: SocialNetworks.TikTok
    });
  }
}
