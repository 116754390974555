import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Instagram, AudienceInteractions, AudienceReach, InsightImages } from '../../../models/instagram.model';
import { CountriesMap } from '../../../models/countriesMap.model';
import { MapToArrayPipe } from '../../../pipes/mapToArray.pipe';
import { Creator } from '../../../models/creator.model';
import { Subscription } from 'rxjs';
import { SocialNetworks } from '../../../models/SocialNetworks';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreatorPrices } from 'src/app/services/creator.service';

class Audience {
  username: string;
  follows: number;
  followers: number;
  startedFollowing: number;
  stoppedFollowing: number;
  mediaCount: number;
  audienceCity = {};
  audienceCountry = {};
  audienceGenderAge = {};
  recentFeeds: any;
  IFRatio: number;
  ISRatio: number;
  dailyProfileViews: number;
  interactions: AudienceInteractions;
  reach: AudienceReach;
  prices: CreatorPrices;
}

@Component({
  selector: 'app-instagram-audience',
  templateUrl: './instagram-audience.component.html',
  styleUrls: ['./instagram-audience.component.scss']
})
export class InstagramAudienceComponent implements OnInit, OnDestroy {
  @Input() creator: Creator;
  @Input() getAudienceEvent: EventEmitter<any>;
  @Output() audienceObject = new EventEmitter<any>();
  instagram: Instagram;
  unprocessed: any;
  countries = CountriesMap.countries;
  genderAge: Array<any>;
  maleTotal: number;
  femaleTotal: number;
  countriesArray: Array<any>;
  citiesArray: Array<any>;
  countiesTotal: number;
  citiesTotalCount: number;
  IFRatio: number;
  ISRatio: number;
  insightImages: InsightImages = null;
  reachForm: FormGroup;
  interactionsForm: FormGroup;
  creatorPrices: CreatorPrices;
  private subscription: Subscription;

  constructor(private mapToArrayPipe: MapToArrayPipe, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscription = this.getAudienceEvent.subscribe(() => {
      this.emitAudience();
    });
    this.instagram = this.creator.instagram;
    if (this.instagram.audience === null) {
      return;
    }
    this.unprocessed = this.instagram.audience.unprocessedAudience;
    this.genderAge = this.getGenderAge();
    this.countriesArray = this.getCountries();
    while (this.countriesArray.length < 5) {
      this.countriesArray.push({key: 'AR', value: 0 });
    }
    this.citiesArray = this.getCities();
    while (this.citiesArray.length < 5) {
      this.citiesArray.push({key: 'Unkown' + this.citiesArray.length, value: 0 });
    }
    this.gendersTotal();
    this.countriesTotal();
    this.citiesTotal();
    this.initReachForm();
    this.initInteractionsForm();
    this.IFRatio = this.instagram.audience.followers > 0 ?
      this.instagram.audience.impressionsPerFeed / this.instagram.audience.followers : 0;
    this.ISRatio = this.instagram.audience.followers > 0 ?
      this.instagram.audience.impressionsPerStory / this.instagram.audience.followers : 0;
    this.creatorPrices = { instagram: {
      feedPrice: this.instagram.feedPrice,
      reelPrice: this.instagram.reelPrice,
      storyPrice: this.instagram.storyPrice
    }};
  }

  initReachForm(): void {
    this.reachForm = this.fb.group({
      reach: [this.creator.instagram.audience.reach.reach],
      reachIncrease: [this.creator.instagram.audience.reach.reachIncrease],
      nonFollowers: [this.creator.instagram.audience.reach.nonFollowersAccounts],
      nonFollowersIncrease: [this.creator.instagram.audience.reach.nonFollowersIncrease],
      followers: [this.creator.instagram.audience.reach.followersAccounts],
      posts: [this.creator.instagram.audience.reach.posts],
      stories: [this.creator.instagram.audience.reach.stories],
      reels: [this.creator.instagram.audience.reach.reels],
      impressions: [this.creator.instagram.audience.reach.impressions],
      impressionsIncrease: [this.creator.instagram.audience.reach.impressionsIncreasePerc],
      profileVisits: [this.creator.instagram.audience.reach.profileVisits],
      profileVisitsIncrease: [this.creator.instagram.audience.reach.profileVisitsIncreasePerc],
      webVisits: [this.creator.instagram.audience.reach.websiteVisits],
      webVisitsIncrease: [this.creator.instagram.audience.reach.websiteVisitsIncreasePerc],
      emailVisits: [this.creator.instagram.audience.reach.emails],
      emailVisitsIncrease: [this.creator.instagram.audience.reach.emailsIncreasePerc],
      smsVisits: [this.creator.instagram.audience.reach.smsSent],
      smsVisitsIncrease: [this.creator.instagram.audience.reach.smsSentIncreasePerc],
    });
  }
  initInteractionsForm(): void {
    this.interactionsForm = this.fb.group({
      postsLikes: [this.creator.instagram.audience.interactions.posts.likes],
      postsComments: [this.creator.instagram.audience.interactions.posts.comments],
      postsSaves: [this.creator.instagram.audience.interactions.posts.saved],
      postsIncrease: [this.creator.instagram.audience.interactions.posts.increasePerc],
      storiesLikes: [this.creator.instagram.audience.interactions.stories.likes],
      storiesComments: [this.creator.instagram.audience.interactions.stories.comments],
      storiesSaves: [this.creator.instagram.audience.interactions.stories.saved],
      storiesIncrease: [this.creator.instagram.audience.interactions.stories.increasePerc],
      reelsLikes: [this.creator.instagram.audience.interactions.reels.likes],
      reelsComments: [this.creator.instagram.audience.interactions.reels.comments],
      reelsSaves: [this.creator.instagram.audience.interactions.reels.saved],
      reelsIncrease: [this.creator.instagram.audience.interactions.reels.increasePerc],
    });
  }

  gendersTotal(): void {
    if (this.creator.instagram.audience.audienceGender) {
      this.maleTotal = this.creator.instagram.audience.audienceGender.m;
      this.femaleTotal = this.creator.instagram.audience.audienceGender.f;
    } else {
      this.maleTotal = this.genderAge.map(r => r.key[0] === 'M' ? r.value : 0).reduce( (x, y) => x + y, 0);
      this.femaleTotal = this.genderAge.map((r => r.key[0] === 'F' ? r.value : 0)).reduce( (x, y) => x + y, 0);
      if (this.maleTotal + this.femaleTotal >= 101) {
        this.maleTotal = 0;
        this.femaleTotal = 0;
      }
    }
  }

  countriesTotal(): void {
    this.countiesTotal = this.countriesArray.map(r => r.value).reduce( (x, y) => x + y, 0);
  }

  citiesTotal(): void {
    this.citiesTotalCount = this.citiesArray.map(r => r.value).reduce( (x, y) => x + y, 0);
  }

  emitAudience(): void {
    const audience = new Audience();
    audience.username = this.instagram.username;
    audience.followers = this.instagram.audience.followers;
    audience.startedFollowing = this.instagram.audience.startedFollowing;
    audience.stoppedFollowing = this.instagram.audience.stoppedFollowing;
    audience.follows = this.instagram.audience.follows;
    audience.recentFeeds = this.instagram.audience.recentFeeds;
    audience.mediaCount = this.instagram.audience.mediaCount;
    audience.IFRatio = this.IFRatio;
    audience.ISRatio = this.ISRatio;
    audience.dailyProfileViews = this.instagram.audience.dailyProfileViews;
    audience.reach = {
      reach: this.reachForm.controls['reach'].value,
      reachIncrease: this.reachForm.controls['reachIncrease'].value,
      followersAccounts: this.reachForm.controls['followers'].value,
      nonFollowersAccounts: this.reachForm.controls['nonFollowers'].value,
      nonFollowersIncrease: this.reachForm.controls['nonFollowersIncrease'].value,
      impressions: this.reachForm.controls['impressions'].value,
      posts: this.reachForm.controls['posts'].value,
      reels: this.reachForm.controls['reels'].value,
      stories: this.reachForm.controls['stories'].value,
      impressionsIncreasePerc: this.reachForm.controls['impressionsIncrease'].value,
      profileVisits: this.reachForm.controls['profileVisits'].value,
      profileVisitsIncreasePerc: this.reachForm.controls['profileVisitsIncrease'].value,
      websiteVisits: this.reachForm.controls['webVisits'].value,
      websiteVisitsIncreasePerc: this.reachForm.controls['webVisitsIncrease'].value,
      emails: this.reachForm.controls['emailVisits'].value,
      emailsIncreasePerc: this.reachForm.controls['emailVisitsIncrease'].value,
      smsSent: this.reachForm.controls['smsVisits'].value,
      smsSentIncreasePerc: this.reachForm.controls['smsVisitsIncrease'].value,
    };
    audience.interactions = {
      posts: {
        likes: this.interactionsForm.controls['postsLikes'].value,
        comments: this.interactionsForm.controls['postsComments'].value,
        saved: this.interactionsForm.controls['postsSaves'].value,
        increasePerc: this.interactionsForm.controls['postsIncrease'].value
      },
      stories: {
        likes: this.interactionsForm.controls['storiesLikes'].value,
        comments: this.interactionsForm.controls['storiesComments'].value,
        saved: this.interactionsForm.controls['storiesSaves'].value,
        increasePerc: this.interactionsForm.controls['storiesIncrease'].value,
      },
      reels: {
        likes: this.interactionsForm.controls['reelsLikes'].value,
        comments: this.interactionsForm.controls['reelsComments'].value,
        saved: this.interactionsForm.controls['reelsSaves'].value,
        increasePerc: this.interactionsForm.controls['reelsIncrease'].value,
      }
    };
    this.genderAge.forEach( x => audience.audienceGenderAge[x.key] = x.value * audience.followers / 100 );
    this.citiesArray.forEach( x => audience.audienceCity[x.key] = x.value );
    this.countriesArray.forEach( x => audience.audienceCountry[x.key] = x.value );
    this.audienceObject.emit({
      audience: JSON.stringify(audience),
      socialId: this.creator.instagram.id,
      socialNetwork: SocialNetworks.Instagram,
      insightImages: this.insightImages,
      creatorPrices: this.creatorPrices
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getGenderAge(): Array<{key: string; value: number}> {
    if (this.unprocessed?.audienceGenderAge) {
      const totalSum = this.mapToArrayPipe
        .transform(this.unprocessed.audienceGenderAge)
        .filter(v => v.key[0] === 'M')
        .reduce((sum, v) => sum + v.value, 0);
      if (totalSum <= 101) {
        return this.mapToArrayPipe.transform(this.unprocessed.audienceGenderAge)
          .sort( (z, y) => z.key > y.key).map( y => ({ key: y.key, value: y.value}));
      } else {
        return this.mapToArrayPipe.transform(this.unprocessed.audienceGenderAge)
          .sort( (z, y) => z.key > y.key)
          .map( y => ({ key: y.key, value: Math.round(1000 * 100 * y.value / this.instagram.audience.followers) / 1000}));
      }
    }
    return [
      {key: 'M.13-17', value: 4.16},
      {key: 'M.18-24', value: 15.56},
      {key: 'M.25-34', value: 11.2},
      {key: 'M.35-44', value: 4.24},
      {key: 'M.45-54', value: 3.13},
      {key: 'M.55-64', value: 2.455},
      {key: 'M.65+', value: 0.7},
      {key: 'F.13-17', value: 4.66},
      {key: 'F.18-24', value: 22.8},
      {key: 'F.25-34', value: 19.04},
      {key: 'F.35-44', value: 9.19},
      {key: 'F.45-54', value: 2.06},
      {key: 'F.55-64', value: 0.505},
      {key: 'F.65+', value: 0.3}];
  }

  private getCountries(): Array<{key: string; value: number}> {
    return this.instagram.audience.audienceCountry ? this.instagram.audience.audienceCountry.map(x => ({key: x[0], value: x[1]})) : [];
  }

  private getCities() {
    return this.instagram.audience.audienceCity ? this.instagram.audience.audienceCity.map(x => ({key: x[0], value: x[1]})) : [];
  }
  onNewImage(croppedImage: string, imageNumber: number): void {
    if (!this.insightImages) {
      this.insightImages = new InsightImages();
    }
    switch (imageNumber) {
      case 1:
        this.insightImages.cities = croppedImage;
        break;
      case 2:
        this.insightImages.countries = croppedImage;
        break;
      case 3:
        this.insightImages.maleAges = croppedImage;
        break;
      case 4:
        this.insightImages.femaleAges = croppedImage;
        break;
      case 5:
        this.insightImages.genders = croppedImage;
        break;
      case 6:
        this.insightImages.accountsReach = croppedImage;
        break;
      case 7:
        this.insightImages.impressions = croppedImage;
        break;
      case 8:
        this.insightImages.contentType = croppedImage;
        break;
      case 9:
        this.insightImages.interactions = croppedImage;
        break;
    }
  }
}
