import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValuesFilter } from 'src/app/models/filters';

@Component({
  selector: 'app-multiselect-search-filter',
  templateUrl: './multiselect-search-filter.component.html',
  styleUrls: ['./multiselect-search-filter.component.scss']
})
export class MultiselectSearchFilterComponent implements OnInit {

  @Input() filterName: string;
  @Input() filterTitle: string;
  @Input() filterDescription: string;
  @Input() options: { key: string; values: string[]; label: string }[];
  @Input() clearAction: Observable<void>;
  @Input() emptyOption = false;

  @Output() filterChanged = new EventEmitter<ValuesFilter | null>();

  selected: { values: string[]; label: string }[] = [];
  emptySelected = false;

  constructor() { }

  ngOnInit(): void {
    this.clearAction.subscribe(() => {
      this.selected = [];
      this.formChanged();
    });
  }

  formChanged() {
    const values = this.selected.reduce((arr,s) => arr.concat(s.values), []);
    if(this.emptySelected) {
      values.push(null);
    }
    this.filterChanged.next(values.length > 0 ?
      new ValuesFilter(this.filterName, values, undefined) : null);
  }

}
