import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { CreatorService } from '../../../services/creator.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { Creator } from '../../../models/creator.model';
import { AlertService } from '../../../services/alert.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SocialNetworks } from '../../../models/SocialNetworks';
import { socialNetworksArray } from '../../../models/SocialNetworksArray';
import { filter, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Filter, RangeFilter, ValuesFilter } from 'src/app/models/filters';
import { CountryList } from 'src/app/models/country';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';

@Component({
  selector: 'app-creators-manager',
  templateUrl: './creators-manager.component.html',
  styleUrls: ['./creators-manager.component.scss'],
})
export class CreatorsManagerComponent implements OnInit {
  socialNetworksArray = socialNetworksArray;

  /*socialNetworksArray = socialNetworksArray;
  socialNetwork = this.socialNetworksArray[0];
  inEditionView = false;
  inPrecampaignView = false;
  inHistoryView = false;
  searchString = '';
  private creators$: Subscription;
  creators: Array<{ creator: Creator, newInstagram: string, newYoutube: string, newTiktok: string }>;
  refreshing = false;
  loading = false;
  username: string;
  activeCreator: Creator = null;
  saveClicked = new EventEmitter<void>();
  modalRef: NgbModalRef;
  private toDeleteCreator: Creator;
  SocialNetworks = SocialNetworks;
  confirmGeneratePassword = -1;*/

  campaign: Campaign = null;
  campaignCreatorIds: number[] = [];
  selectedCreators: Creator[] = [];

  searchString: string = null;
  searchOrder = 'max_followers';
  platformFilter: ValuesFilter = null;
  platformOptions = socialNetworksArray.map((s) => ({
    label: s.name,
    key: s.value,
  }));
  followersFilterRanges = [5000, 10000, 50000, 350000];
  followersFilter: RangeFilter = new RangeFilter('followers', 0);
  engagementRateFilterRanges = [0, 1, 5, 10];
  engagementRateFilter: RangeFilter = new RangeFilter('engagement_rate', 0);
  clearSocialNetworkPerformanceFilter = new Subject<void>();
  countryList = CountryList.countryList.map((c) => ({
    key: c.code2,
    values: [c.country, c.code2],
    label: c.country,
  }));
  countriesFilter: ValuesFilter = null;
  clearCountriesFilter = new Subject<void>();
  genderOptions = [
    { label: 'MALE', key: 'male' },
    { label: 'FEMALE', key: 'female' },
    { label: 'UNSET', key: null },
  ];
  genderFilter: ValuesFilter = null;
  clearGenderFilter = new Subject<void>();
  verificationOptions = [
    { label: 'CREATORS.VERIFIED', key: true },
    { label: 'CREATORS.NOT.VERIFIED', key: false },
  ];
  verificationFilter: ValuesFilter = null;
  clearVerificationFilter = new Subject<void>();
  get filters() {
    const filterPlatforms = this.platformFilter?.values
      ? this.platformFilter.values
      : socialNetworksArray.map((p) => p.value);
    this.followersFilter.platforms = this.engagementRateFilter.platforms =
      filterPlatforms;
    let filters: Filter[] = [this.followersFilter, this.engagementRateFilter];
    filters = this.genderFilter ? filters.concat([this.genderFilter]) : filters;
    filters = this.countriesFilter
      ? filters.concat([this.countriesFilter])
      : filters;
    if (this.verificationFilter) {
      this.verificationFilter.platforms = [SocialNetworks.TikTok];
      filters.push(this.verificationFilter);
    }
    return filters;
  }

  searchResults: Creator[];
  currentPage = 1;
  resultsEnd = false;
  loading = false;
  loadingPage = false;
  modalRef: NgbModalRef;
  view: 'search' | 'edit' | 'precampaign' | 'history' = 'search';
  activeCreator: Creator = null;
  activeSocialNetwork: { name: string; value: string } = null;
  saveClicked = new EventEmitter<void>();

  constructor(
    private userService: UserService,
    private creatorService: CreatorService,
    private modalService: NgbModal,
    private alert: AlertService,
    private translateService: TranslateService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  search(nextPage = false) {
    if (this.loadingPage) {
      return;
    }
    this.loadingPage = true;
    if (nextPage) {
      this.currentPage++;
    } else {
      this.currentPage = 1;
      this.searchResults = [];
    }
    this.creatorService
      .searchCreators(
        this.filters.some((f) => f.max !== undefined)
          ? this.filters
          : [],
        this.searchString ?? null,
        this.searchOrder,
        this.searchOrder !== 'max_followers' &&
          this.searchOrder !== 'engagement_rate',
        this.currentPage
      )
      .subscribe((r) => {
        this.searchResults = this.searchResults.concat(r.creators);
        this.resultsEnd = r.count <= this.searchResults.length;
        this.loadingPage = false;
      });
  }

  ngOnInit(): void {
    if (
      this.route.snapshot.paramMap.has('id') &&
      /^\d+$/.test(this.route.snapshot.paramMap.get('id'))
    ) {
      this.creatorService
        .getCreator(+this.route.snapshot.paramMap.get('id'))
        .subscribe((creator) => {
          this.editCreator(
            creator,
            creator.hasInstagram()
              ? 'instagram'
              : creator.hasTikTok()
              ? 'tiktok'
              : 'youtube'
          );
        });
    } else {
      const campaign$ = this.route.queryParamMap.pipe(
        filter(
          (queryParams) =>
            queryParams.has('campaign') &&
            /^\d+$/.test(queryParams.get('campaign'))
        ),
        switchMap((queryParams) =>
          this.campaignService.getCampaign(+queryParams.get('campaign'))
        )
      );
      campaign$.subscribe((campaign) => (this.campaign = campaign));
      campaign$
        .pipe(
          switchMap((campaign) =>
            this.creatorService.getCampaignCreators(
              campaign.companyId,
              campaign.brandId,
              campaign.id
            )
          )
        )
        .subscribe(
          (creators) =>
            (this.campaignCreatorIds = creators.map((c) => c.userId))
        );
    }
    this.search();
  }

  editCreator(creator: Creator, socialNetwork: string): void {
    this.activeSocialNetwork = this.socialNetworksArray.find(
      (element) => element.value === socialNetwork
    );
    this.activeCreator = creator;
    this.view = 'edit';
  }

  editPrecampaign(creator: Creator): void {
    this.activeCreator = creator;
    this.view = 'precampaign';
  }

  saveChanges(audienceDetails: any): void {
    this.loading = true;
    const save = [
      this.creatorService.saveAudience(audienceDetails.id, audienceDetails),
    ];
    if (audienceDetails.creatorPrices) {
      save.push(
        this.creatorService.updateCreatorPrices(
          audienceDetails.id,
          audienceDetails.creatorPrices
        )
      );
    }
    if (audienceDetails.insightImages) {
      save.push(
        this.creatorService.uploadInsightImages(
          audienceDetails.id,
          audienceDetails.insightImages
        )
      );
    }
    combineLatest(save).subscribe(
      () => {
        window.scroll(0, 0);
        this.activeCreator = null;
        this.view = 'search';
        this.alert.emitSuccess('Profile update successfully');
        this.loading = false;
        this.search();
      },
      (err) => {
        window.scroll(0, 0);
        this.loading = false;
        switch (err.error.message) {
          case 'accountsReach':
            this.alert.emitError(
              this.translateService.instant(
                'CREATORS.INSIGHTS.ACCOUNTS.REACH.ERROR'
              )
            );
            break;
          case 'contentType':
            this.alert.emitError(
              this.translateService.instant(
                'CREATORS.INSIGHTS.CONTENT.TYPE.ERROR'
              )
            );
            break;
          case 'impressions':
            this.alert.emitError(
              this.translateService.instant(
                'CREATORS.INSIGHTS.IMPRESSIONS.ACTIVITY.ERROR'
              )
            );
            break;
          case 'growth':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.FOLLOWERS.ERROR')
            );
            break;
          case 'countries':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.COUNTRIES.ERROR')
            );
            break;
          case 'cities':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.CITIES.ERROR')
            );
            break;
          case 'genders':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.GENDERS.ERROR')
            );
            break;
          case 'ages':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.AGES.ERROR')
            );
            break;
          case 'maleAges':
            this.alert.emitError(
              this.translateService.instant('CREATORS.INSIGHTS.AGES.MALE.ERROR')
            );
            break;
          case 'femaleAges':
            this.alert.emitError(
              this.translateService.instant(
                'CREATORS.INSIGHTS.AGES.FEMALE.ERROR'
              )
            );
            break;
          case 'interactions':
            this.alert.emitError(
              this.translateService.instant(
                'CREATORS.INSIGHTS.INTERACTIONS.ERROR'
              )
            );
            break;
        }
      }
    );
  }

  openDelete(
    deleteConf: TemplateRef<CreatorsManagerComponent>,
    creator: Creator
  ): void {
    this.activeCreator = creator;
    this.modalRef = this.modalService.open(deleteConf);
  }

  deleteCreator(): void {
    this.loading = true;
    this.modalRef.close();
    this.searchResults = this.searchResults.filter(
      (r) => r.id !== this.activeCreator.id
    );
    this.creatorService.delete(this.activeCreator.id).subscribe(
      () => {
        this.loading = false;
        this.alert.emitSuccess('Creator was deleted successfully.');
      },
      () => {
        this.loading = false;
      }
    );
  }

  back(): void {
    this.view = 'search';
    this.activeCreator = null;
  }

  backToCampaign(): void {
    if (this.campaign) {
      this.router.navigateByUrl(`campaign/${this.campaign.id}/creators`);
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  viewCreatorHistory(creator: Creator): void {
    this.activeCreator = creator;
    this.view = 'history';
  }

  verifyCreator(creator: Creator): void {
    this.loading = true;
    this.creatorService.verifyCreator(creator).subscribe(
      () => {
        this.alert.emitSuccess(
          creator.tikTok.username + ' has been verified correctly.'
        );
        creator.tikTok.isVerified = true;
        creator.tikTok.isRejected = false;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  unverifyCreator(creator: Creator): void {
    this.loading = true;
    this.creatorService.unverifyCreator(creator).subscribe(
      () => {
        this.alert.emitSuccess(
          creator.tikTok.username + ' has been unverified correctly.'
        );
        creator.tikTok.isVerified = false;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  rejectCreator(creator: Creator): void {
    this.loading = true;
    this.creatorService.rejectCreator(creator).subscribe(
      () => {
        this.alert.emitSuccess(
          creator.tikTok.username + ' has been rejected correctly.'
        );
        creator.tikTok.isVerified = false;
        creator.tikTok.isRejected = true;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  newCreatorPlatform(
    username: string,
    creator: Creator,
    socialNetwork: string
  ): void {
    this.activeCreator = null;
    this.loading = true;
    this.creatorService
      .create(username, socialNetwork, null, null, null, creator.id)
      .subscribe(
        () => {
          this.alert.emitSuccess('User created successfully');
          this.activeCreator = null;
          this.loading = false;
          this.search();
        },
        () => {
          this.loading = false;
        }
      );
  }

  creatorSelected(creator: Creator, selected: boolean) {
    if (selected) {
      this.selectedCreators.push(creator);
    } else {
      this.selectedCreators = this.selectedCreators.filter(
        (c) => c.id !== creator.id
      );
    }
  }

  inviteSelectedCreators() {
    const creatorIds = this.selectedCreators.map((c) => c.id);
    if (creatorIds.length > 0) {
      this.loading = true;
      this.creatorService
        .inviteCreators(
          creatorIds,
          this.campaign.companyId,
          this.campaign.brandId,
          this.campaign.id
        )
        .subscribe(
          () => {
            this.loading = false;
            this.router.navigateByUrl(`campaign/${this.campaign.id}/creators`);
          },
          () => (this.loading = false)
        );
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 10
    ) {
      if (!this.resultsEnd) {
        this.search(true);
      }
    }
  }

  /*search(): void {
    if (this.searchString.length === 0 && !this.searchFilter) {
      return;
    }
    this.creators = undefined;
    this.refreshing = true;
    this.creators$ = this.creatorService.searchCreators(this.searchString, this.searchFilter)
      .subscribe(creators => {
        this.creators = creators.map(c => ({ creator: c, newInstagram: '', newYoutube: '', newTiktok: ''}));
      }).add(() => {
        this.refreshing = false;
      });
  }

  ngOnDestroy(): void {
    if (this.creators$) {
      this.creators$.unsubscribe();
    }
  }

  create(username?: string, creator?: Creator, socialNetwork?: string): void {
    this.activeCreator = null;
    this.refreshing = true;
    this.creatorService.create(username ?? this.username, socialNetwork ?? this.socialNetwork.value,
      null, null, null, creator?.id).subscribe(() => {
      this.alert.emitSuccess('User created successfully');
      this.activeCreator = null;
      this.inEditionView = false;
      this.search();
    }, () => {
      this.refreshing = false;
    });
  }

  editCreator(creator: Creator, socialNetwork: string): void {
    this.socialNetwork = this.socialNetworksArray.find(element => element.value === socialNetwork);
    this.activeCreator = creator;
    this.inEditionView = true;
  }

  editPrecampaign(creator: Creator): void {
    this.activeCreator = creator;
    this.inPrecampaignView = true;
  }

  viewCreatorHistory(creator: Creator): void {
    this.activeCreator = creator;
    this.inHistoryView = true;
  }

  back(): void {
    this.inEditionView = false;
    this.inPrecampaignView = false;
    this.inHistoryView = false;
    this.activeCreator = null;
  }

  saveChanges(audienceDetails: any): void {
    this.refreshing = true;
    let save = this.creatorService.saveAudience(audienceDetails.id, audienceDetails);
    if (audienceDetails.insightImages) {
      save = save.pipe(switchMap(() => this.creatorService.uploadInsightImages(audienceDetails.id, audienceDetails.insightImages)));
    }
    save.subscribe( () => {
      window.scroll(0, 0);
      this.activeCreator = null;
      this.inEditionView = false;
      this.alert.emitSuccess('Profile update successfully');
      this.search();
    }, (err) => {
      window.scroll(0, 0);
      this.refreshing = false;
      switch (err.error.message) {
        case 'accountsReach':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.ACCOUNTS.REACH.ERROR'));
          break;
        case 'contentType':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.CONTENT.TYPE.ERROR'));
          break;
        case 'impressions':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.IMPRESSIONS.ACTIVITY.ERROR'));
          break;
        case 'growth':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.FOLLOWERS.ERROR'));
          break;
        case 'countries':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.COUNTRIES.ERROR'));
          break;
        case 'cities':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.CITIES.ERROR'));
          break;
        case 'genders':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.GENDERS.ERROR'));
          break;
        case 'ages':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.AGES.ERROR'));
          break;
        case 'maleAges':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.AGES.MALE.ERROR'));
          break;
        case 'femaleAges':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.AGES.FEMALE.ERROR'));
          break;
        case 'interactions':
          this.alert.emitError(this.translateService.instant('CREATORS.INSIGHTS.INTERACTIONS.ERROR'));
          break;
      }
    });
  }

  deleteCreator(): void {
    this.refreshing = true;
    this.modalRef.close();
    this.creatorService.delete(this.toDeleteCreator.id).subscribe(() => {
      this.alert.emitSuccess('Creator was deleted successfully.');
      this.search();
    }, () => {
      this.refreshing = false;
    });
  }

  openDelete(deleteConf: TemplateRef<CreatorsManagerComponent>, creator: Creator): void {
    this.toDeleteCreator = creator;
    this.modalRef = this.modalService.open(deleteConf);
  }

  verifyCreator(creator: Creator): void {
    this.creatorService.verifyCreator(creator).subscribe( () => {
        this.alert.emitSuccess(creator.tikTok.username + ' has been verified correctly.');
        creator.tikTok.isVerified = true;
        creator.tikTok.isRejected = false;
    });
  }

  rejectCreator(creator: Creator): void {
    this.creatorService.rejectCreator(creator).subscribe( () => {
        this.alert.emitSuccess(creator.tikTok.username + ' has been rejected correctly.');
        creator.tikTok.isVerified = false;
        creator.tikTok.isRejected = true;
    });
  }

  generateCreatorPassword(creator: Creator): void {
    if (this.confirmGeneratePassword !== creator.id) {
      this.confirmGeneratePassword = creator.id;
      return;
    }
    this.loading = true;
    this.creatorService.generateCreatorPassword(creator.id).subscribe(({ password }) => {
      this.loading = false;
      this.confirmGeneratePassword = -1;
      this.alert.emitSuccess(
        this.translateService.instant('CREATORS.GENERATE.PASSWORD.SUCCESS').replace('USER_VAL', creator.fullName) + ' ' + password);
      window.scroll(0, 0);
    });
  }

  setSearchFilter(filter: string): void {
    this.searchFilter =  this.searchFilter !== filter ? filter : null;
  }*/
}
