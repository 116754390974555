import { Component, Input, OnInit } from '@angular/core';
import { CalculatorPlanner } from 'src/app/models/calculatorPlanner';
import { Campaign } from 'src/app/models/campaign.model';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { SocialNetworks } from 'src/app/models/SocialNetworks';
import { PerformanceSubmission, Submission } from 'src/app/models/submission.model';

@Component({
  selector: 'app-campaign-statistics-cards',
  templateUrl: './campaign-statistics-cards.component.html',
  styleUrls: ['./campaign-statistics-cards.component.scss']
})
export class CampaignStatisticsCardsComponent implements OnInit {

  @Input() campaign: Campaign;
  @Input() submissions: Array<Submission | PerformanceSubmission>;
  @Input() isPerformance: boolean = false;
  @Input() creators: Array<CreatorApproval>;

  columns = ['Post format', 'Posted date', 'Impressions', 'Goal Impressions', 'Reach', 'Likes', 'SavedShared', 'Comments', 'Post engagement',
    'Clicks', 'CTR', 'Conversions', 'Conversion rate'];
  performanceColumns = ['Clicks'];
  statsToDisplay = new Map<string, boolean>();
  followers: number;
  likes: number;
  impressions: number;
  engagements: number;
  engagementsAbs: number;
  reach: number;
  comments: number;
  clicks: number;
  savedShared: number;
  installs: number;
  conversions: number;
  ctr: number;

  calculatorPlanner: CalculatorPlanner = null;
  performance_views: number;
  performance_reach: number;
  performance_engagement: number;
  performance_min = -25;

  constructor() { }

  ngOnInit(): void {
    if (this.isPerformance) {
      this.performanceColumns.forEach(x => this.statsToDisplay.set(x, true));
    } else {
      this.columns.forEach(x => this.statsToDisplay.set(x, true));
    }
    const postedSubmissions = this.submissions?.filter( x => !!x.timePosted) ?? [];
    this.comments = postedSubmissions.reduce( (x, y) => x + y.comments, 0);
    this.likes = postedSubmissions.reduce( (x, y) => x + y.likes, 0);
    this.reach = postedSubmissions.reduce( (x, y) => x + y.randomReach, 0);
    this.engagements = postedSubmissions.length > 0 ?
      postedSubmissions.reduce((sum, s) => sum + s.getPostEngagement(), 0) / postedSubmissions.length
      : 0;
    this.engagementsAbs = postedSubmissions.length > 0 ?
      postedSubmissions.reduce((sum, s) => sum + s.getPostEngagementAbs(), 0)
      : 0;
      this.impressions = postedSubmissions.reduce( (x, y) => x + y.randomImpressions, 0);
    this.followers = 0;
    const uniqueUsers = new Set<number>();
    postedSubmissions.forEach( x => {
      if ((x.randomReach > 1 || this.isPerformance) && !uniqueUsers.has(x.userId)) {
        const creator = this.creators.find( y => y.userId === x.userId);
        this.followers += creator ? creator.getFollowers(SocialNetworks[x.platform]) : 0;
        uniqueUsers.add(x.userId);
      }
    });
    this.clicks = postedSubmissions.reduce( (x, y) =>
      x + Number.parseInt(y.clicks + '', 10), 0);
    this.savedShared = postedSubmissions.reduce( (x, y) =>
      x + Number.parseInt(y.savedShared + '', 10), 0);
    this.installs = postedSubmissions.reduce( (x, y) =>
      x +  Number.parseInt(y.installsAndActions + '', 10), 0);
    this.conversions = postedSubmissions.length > 0 ?
      postedSubmissions.reduce( (x, y) => x + Number.parseInt(y.conversions + '', 10), 0)
      / postedSubmissions.length : 0;
    this.conversions = Math.round(this.conversions * 100) / 100;
    this.ctr = this.impressions > 0 ? Math.round(this.clicks / this.impressions * 10000) / 100 : 0;
    this.ctr = Math.round(this.ctr * 100) / 100;
    if (!this.isPerformance) {
      if (this.clicks === 0 ) {
        this.statsToDisplay.delete('Clicks');
      }
      if (this.installs === 0 ) {
        this.statsToDisplay.delete('Conversions');
      }
      if (this.conversions === 0 ) {
        this.statsToDisplay.delete('Conversion rate');
      }
      if (this.ctr === 0 ) {
        this.statsToDisplay.delete('CTR');
      }
    } else {
      this.statsToDisplay.delete('Goal Impressions');
    }
    const plannersCampaign = this.creators
      .filter(creatorApproval => creatorApproval.status.toLowerCase() === 'confirmed')
      .map(creatorApproval => creatorApproval ? creatorApproval.getPlannersForCampaign() : [])
      .reduce((prev, planners) => prev.concat(planners), []);
    this.calculatorPlanner  = new CalculatorPlanner('calculatorPlanner', plannersCampaign);
    this.performance_reach = (this.calculatorPlanner.total.reach && this.reach) ?
      (this.reach * 100 / this.calculatorPlanner.total.reach) - 100
      : null ;
    this.performance_views = (this.impressions && this.calculatorPlanner.total.views) ?
      (this.impressions * 100 / this.calculatorPlanner.total.views) - 100
      : null;
    this.performance_engagement = (this.engagementsAbs && this.calculatorPlanner.total.engagementsAbs) ?
      (this.engagementsAbs * 100 / this.calculatorPlanner.total.engagementsAbs) - 100
      : null;
  }

  abs(value: number): number {
    return Math.abs(value);
  }

  sign(value: number): string {
    return value > 0 ? '+' : '-';
  }

}
