import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mapToArray' })
export class MapToArrayPipe implements PipeTransform {
  transform(map): any {
    // const arr = [];
    // for (const key in map) {
    //   arr.push({ key: key, value: map[key] });
    // }
    // return arr;
    return Object.keys(map).map((key) => ({ key, value: map[key] }));
  }
}
