export class NotificationTypes {
  public static NEGOTIATION_CHAT_ARR = ['chat_from_creator', 'creator_offert_accepted', 'creator_offert_rejected', 'creator_offert_counter_offer'];
  public static CREATOR_CAMPAIGN_REJECTION = 'creator_campaign_rejection';
  public static CREATOR_CAMPAIGN_CONFIRMATION = 'creator_campaign_confirmation';
  public static CREATOR_CAMPAIGN_INVITATION = 'creator_campaign_invitation';
  public static CREATOR_OFFERT_ACCEPTED = 'creator_offert_accepted';
  public static CREATOR_OFFERT_REJECTED = 'creator_offert_rejected';
  public static CREATOR_OFFERT_COUNTER_OFFERT = 'creator_offert_counter_offer';
  public static CREATOR_SUBMISSION_VALIDATION_REQUEST = 'creator_submission_validation_request';
  public static CHAT_FROM_CREATOR = 'chat_from_creator';
}
