import { FormGroup } from '@angular/forms';
import { RequestHelper } from '../../models/requestHelper';
import { Campaign, CampaignStatus } from '../../models/campaign.model';
import { User } from '../../models/user.model';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';

export class UpdateCampaignModel extends Campaign {
  updatesImage1 = false;
  updatesImage2 = false;
  updatesImage3 = false;
  updatesImage4 = false;
  updatesCover = false;
  updatesIcon = false;
  userModel: User;
  form: FormGroup;
  platformsInput = [
    { name: 'facebook', icon: 'facebook', selected: false },
    { name: 'instagram', icon: 'instagram', selected: false },
    { name: 'twitter', icon: 'twitter', selected: false },
    { name: 'youtube', icon: 'youtube-play', selected: false },
    { name: 'tiktok', icon: faTiktok, selected: false }
  ];

  constructor(user: User, campaign?: Campaign) {
    super();
    if (!campaign) {
      this.userModel = user;
      // this.brand = user.company.brandName;
      this.icon = user.company.logo || 'err';
      return this;
    } else {
      this.initFromCampaign(campaign);
      this.userModel = user;
      this.platformsInput.forEach(x => {
        if (this.platforms.find(y => y === x.name)) {
          x.selected = true;
        }
      });
      return this;
    }
  }

  /**
   * Creates a FormData ready send via http post with all campaign values.
   *
   * @returns FormData
   * */
  getFormData(): FormData {
    const formData: FormData = new FormData();
    const values = this.form.value;
    const plannerValues = this.form.controls.influencerPlanner['controls'][0];

    const plannerObj = [];
    let tablePlannerInputs = {};

    plannerValues.forEach((table, indexTable) => {
      plannerObj[indexTable] = [];
      tablePlannerInputs = table.controls['tablePlannerInputs'].value;
      plannerObj[indexTable].push(tablePlannerInputs);
      table.controls.forEach((element) => {
        plannerObj[indexTable].push(element.value);
      });
    });

    if (this.id) {
      formData.append('id', this.id.toString());
    }
    formData.append('brand_name', values.brand);
    formData.append('campaign_name', values.name);
    formData.append('campaign_idea', values.campaignIdea);
    formData.append('campaign_target', values.campaignTarget);
    formData.append('desglose', values.desglose);
    formData.append('proposal_details', values.proposalDetails);
    formData.append('creator_requirement', values.creatorRequirements);
    formData.append('hashtags',
      this.hashTags.length !== 0 ? this.hashTags.reduce((x, y) => x + ',' + y.value, '').substring(1) : ',');
    formData.append('mentions', this.mentions.length !== 0 ?
      this.mentions.reduce((x, y) => x + ',' + y.value, '').substring(1) : ',');
    formData.append('launch_date',
      values.launchDate.year + '-' + values.launchDate.month + '-' + values.launchDate.day);
    formData.append('end_date',
      values.endDate ? values.endDate.year + '-' + values.endDate.month + '-' + values.endDate.day : '');
    if (this.categories && this.categories.size > 0) {
      formData.append('category_id',
        Array.from(this.categories).reduce((prev, next) => next ? prev + next + ',' : prev, ''));
    }
    formData.append('platforms',
      this.platformsInput.reduce((prev, next) => next.selected ? prev + next.name + ',' : prev, ''));
    let countries = this.countryList.reduce((x, y) => x + (',' + y.code_country), '');
    if (countries.length > 0) {
      countries = countries.slice(1);
    }
    formData.append('country', countries);
    formData.append('age_range', this.creatorsAgeFrom.toString());
    formData.append('age_to', this.creatorsAgeTo.toString());
    formData.append('preffered_gender', this.creatorsGender);
    formData.append('pricing_model', this.pricingModel);
    formData.append('product_shipping', this.productShipping);
    formData.append('is_public', this.isPublic);
    formData.append('updates_icon', this.updatesIcon.toString());
    formData.append('updatesCover', this.updatesCover.toString());
    formData.append('updatesImage1', this.updatesImage1.toString());
    formData.append('updatesImage2', this.updatesImage2.toString());
    formData.append('updatesImage3', this.updatesImage3.toString());
    formData.append('updatesImage4', this.updatesImage4.toString());
    if (values.postRequirements) {
      formData.append('requirement_example', values.postRequirements);
    }
    formData.append('dos', values.dos);
    formData.append('donts', values.donts);
    formData.append('brand_logo', this.userModel.company.logo);
    formData.append('company_id', this.companyId + '');
    formData.append('currency', values.currency);
    formData.append('target', values.target);
    formData.append('restricts_country', this.restrictsCountry + '');
    formData.append('auto_approves_creators', this.autoApprovesCreators + '');
    formData.append('tracks_performance', this.tracksPerformance + '');
    if (this.status) {
      if (this.status === CampaignStatus.RequestedApproval || this.status === CampaignStatus.Prospect) {
        this.status = CampaignStatus.Pending;
      }
      formData.append('status', this.status.toString());
    }
    formData.append('creator_type',
      this.creatorType.size !== 0 ? Array.from(this.creatorType)
        .reduce((prev, next) => prev !== '' ? prev + ',' + next : next.toString(), '') : null);
    if (this.updatesIcon) {
      formData.append('campaign_icon',
        RequestHelper.newFileFromString(this.icon, 'updatesIcon.png'), 'updatesIcon');
    }
    if (this.updatesCover) {
      formData.append('campaign_cover',
        RequestHelper.newFileFromString(this.cover, 'updatesCover.png'), 'updatesCover');
    }
    if (this.image1 && this.image1 !== 'PENDING' && !this.isURL(this.image1)) {
      formData.append('campaign_image1',
        RequestHelper.newFileFromString(this.image1, 'updatesImage1.png'), 'updatesImage1');
    }
    if (this.image2 && this.image2 !== 'PENDING' && !this.isURL(this.image2)) {
      formData.append('campaign_image2',
        RequestHelper.newFileFromString(this.image2, 'updatesImage2.png'), 'updatesImage2');
    }
    if (this.image3 && this.image3 !== 'PENDING' && !this.isURL(this.image3)) {
      formData.append('campaign_image3',
        RequestHelper.newFileFromString(this.image3, 'updatesImage3.png'), 'updatesImage3');
    }
    if (this.image4 && this.image4 !== 'PENDING' && !this.isURL(this.image4)) {
      formData.append('campaign_image4',
        RequestHelper.newFileFromString(this.image4, 'updatesImage4.png'), 'updatesImage4');
    }
    if (this.pricingModel === 'PER') {
      formData.append('tracking_link', this.trackingLink);
      formData.append('preview_url', this.previewUrl);
      formData.append('revenue', this.revenue + '');
      formData.append('margin', this.margin + '');
      formData.append('paid_events', this.paidEvents);
      if (this.appCategories.length === 0) {
        this.appCategories.push(-1);
      }
      formData.append('app_categories',
        this.appCategories.reduce((prev, next) => next ? prev + next + ',' : prev, ''));
    }
    formData.append('goal', Math.round(values.goal).toString());
    formData.append('budgetGross', values.budgetGross.toString());
    formData.append('commission', values.commission.toString());
    formData.append('tax', values.tax.toString());
    formData.append('profit', values.profit.toString());
    formData.append('shipping_cost', values.shippingCost.toString());
    formData.append('agency_shipping_cost_fee', values.agencyShippingCostFee.toString());
    formData.append('planner', JSON.stringify(
      plannerObj
    ));
    formData.append('scenarios', JSON.stringify(
      values.scenarios.map(s => ({
        type: s.type,
        investment: s.investment,
        pricing_model: s.pricingModel,
        impressions: s.impressions,
        influencers: s.influencers,
        contents: s.contents,
        platforms: s.platforms?.join(',') ?? ''
      }))
    ));
    return formData;
  }

  isURL(string) {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null);
  }
}
