import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-creator-image-modal',
  templateUrl: './creator-image-modal.component.html',
  styleUrls: ['./creator-image-modal.component.scss']
})
export class CreatorImageModalComponent implements OnInit {

  constructor(private modalService: NgbModal) {
  }
  @Input() btnText = 'CREATORS.INSIGHTS.LOAD.STATS';
  @Input() initialImage = '';
  @Input() showImage = true;
  @Output() newImage = new EventEmitter<string>();
  imageChangedEvent: Event = null;
  croppedImage = '';
  displayImage: string = null;
  modalRef: NgbModalRef;

  ngOnInit(): void {
    // this.prevCropped === '' ? this.croppedImage = this.prevCropped : this.croppedImage = '';
    this.displayImage = this.initialImage;
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  open(content: TemplateRef<CreatorImageModalComponent>): void {
    this.croppedImage = '';
    this.modalRef = this.modalService.open(content);
  }

  close(): void {
    this.displayImage = this.croppedImage;
    this.newImage.emit(this.croppedImage);
    this.modalRef.close();
  }

  unselectImage(): void {
    this.imageChangedEvent = null;
    this.croppedImage = '';
    this.displayImage = null;
    this.newImage.emit(null);
    this.modalRef.dismiss();
  }
}
