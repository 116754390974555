import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '../../../../../enum/platforms-string.enum';
import { Creator } from '../../../../../models/creator.model';
import { CreatorApproval, CreatorApprovalPlanner, PlannerContentNamesByPlatform } from '../../../../../models/CreatorApproval.model';
import { SocialUser } from '../../../../../models/Interfaces/SocialUser';
import { CalculatorPlanner } from 'src/app/models/calculatorPlanner';
import { Campaign } from 'src/app/models/campaign.model';

export interface DataSource {
  contentName: string;
  content: number;
  views: number;
  engagementPercent: number;
  netNegotiatedPrice: number;
  reach: number;
  estimatedClicks: number;
  costPerView: number;
  costPerEngagement: number;
  costPerReach: number;
}

@Component({
  selector: 'app-audience-negociated-tab',
  templateUrl: './audience-negociated-tab.component.html',
  styleUrls: ['./audience-negociated-tab.component.css']
})
export class AudienceNegociatedTabComponent implements OnInit {

  @Input() creatorApproval: CreatorApproval;
  @Input() platform: string;
  dataSource: DataSource[] = [];
  calculator: CalculatorPlanner;
  displayedColumns: string[] = [
    'contentName',
    'content',
    'reach',
    'views',
    'engagementPercent',
    'estimatedClicks',
    'costPerReach',
    'costPerView',
    'costPerEngagement',
    'netNegotiatedPrice',
  ];

  ngOnInit() {
    if (!this.creatorApproval.campaign) {
      return;
    }
    if( !this.creatorApproval.campaign.isPerformance()) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'estimatedClicks');
    }
    if (this.platform) {
      const planner = this.creatorApproval.getPlannerOrBuild(this.platform);
      this.dataSource = this.plannerToDataSource(planner);
      this.calculator = new CalculatorPlanner('total', [planner]);
    } else {
      const planners = this.creatorApproval.getPlannersForCampaign();
      this.dataSource = [];
      this.calculator = new CalculatorPlanner('total', planners);
    }
    if (!this.calculator.total.negotiatedPrice || !this.creatorApproval.campaign.isCostEnabled) {
      this.displayedColumns = this.displayedColumns.filter(column => !['costPerView', 'costPerEngagement', 'costPerReach'].includes(column));
    }
  }

  private plannerToDataSource(planner: CreatorApprovalPlanner): DataSource[] {
    return PlannerContentNamesByPlatform[this.platform].map((name): DataSource => ({
      contentName: name,
      content: planner.content[name],
      reach: planner.reach()[name],
      views: planner.views()[name],
      engagementPercent: planner.avgEngagementsPercent()[name],
      estimatedClicks: planner.estimatedClicks,
      netNegotiatedPrice: null,
      costPerView: null,
      costPerEngagement: null,
      costPerReach: null,
    }));
  }

}
