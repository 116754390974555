import { Pipe, PipeTransform } from '@angular/core';

enum CapsStyle {
    TITLE = 'title',
    SENTENCE = 'sentence',
    LOWER_CASE = 'lower_case'
}

@Pipe({name: 'propNameToText'})
export class propNameToText implements PipeTransform {
  transform(value: string|null|undefined, capssTyle: CapsStyle = CapsStyle.TITLE): string {
    value = value.replace(new RegExp('[._-]', 'g'), ' ')
        .replace(new RegExp('([a-z])([A-Z])', 'g'), '$1 $2')
        .toLowerCase();

    const ucfirst = (data) => data.replace(new RegExp('^.', ''), (match) => match.toUpperCase());
    const toTitle = (data) => ucfirst(data).replace(new RegExp(' [A-z]', 'g'), (match) => match.toUpperCase());

    return capssTyle === CapsStyle.TITLE ? toTitle(value)
        : capssTyle === CapsStyle.SENTENCE ? ucfirst(value)
        : value;
  }
}

