import { Component, Inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreatorApprovalPlanner, PlannerForm, PlannerContentNamesByPlatform, PlannerContent, CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Creator } from 'src/app/models/creator.model';
import { Helper } from 'src/app/support/helper.support';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

type FormContentItem = {
  id: number,
  postAt: string,
  platform: string,
  format: string,
  description: string,
  script: string,
  approved: boolean,
  submissionId: number,
}

@Component({
  selector: 'app-creator-planner-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.css'],
})
export class CreatorPlannerContentsComponent {


  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  campaign: Campaign;
  creatorApproval: CreatorApproval;
  contentsPlanner: CreatorPlannerContent[] = [];
  platform: string;
  username: string;
  updatePlannerParent: (planner: CreatorApprovalPlanner) => void;
  updatePlannerContentsParent: (userApprovalId: Number, contents: CreatorPlannerContent[]) => void;
  form: FormGroup;
  plannerNew: CreatorApprovalPlanner;
  loading = false;
  loadingByIndex = new Map<number, boolean>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      campaign: Campaign,
      creatorApproval: CreatorApproval,
      contents: CreatorPlannerContent[],
      platform: string,
      username: string,
    },
    public dialogRef: MatDialogRef<CreatorPlannerContentsComponent>,
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    private _ngZone: NgZone) {
    this.campaign = this.data.campaign;
    this.creatorApproval = this.data.creatorApproval;
    this.contentsPlanner = this.data.contents;
    this.platform = this.data.platform;
    this.username = this.data.username;
    this.createForm();
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  private createForm() {
    this.form = this.formBuilder.group({
      contentRows: this.formBuilder.array([])
    });
    this.contentsPlanner.forEach((content: CreatorPlannerContent) => {
      this.addContent(Object.assign({}, content));
    });
    this.form.markAsUntouched();
    this.form.markAsPristine();
    if (this.contentsPlanner.length == 0) {
      this.addContent(null);
    }
  }

  get contentRows() {
    return this.form.controls["contentRows"] as FormArray;
  }

  addContent(content: FormContentItem = null) {
    const contentForm = this.formBuilder.group({
      id: [content && content.id || null],
      userApprovalId: [this.creatorApproval.id],
      postAt: [content && content.postAt ? new Date(content.postAt) : null],
      platform: [this.platform],
      format: [content && content.format || null, Validators.required],
      description: [content && content.description || null],
      script: [content && content.script || null],
      approved: [content && content.approved ? '1' : '0', Validators.required],
      submissionId: [content && (content.submissionId !== null) ? content.submissionId : null],
      deleted: [false],
      hide: [false],
    });
    this.contentRows.push(contentForm);
  }


  deleteRow(index: number) {
    const row = this.contentRows.at(index);
    const old = row.get('deleted').value;
    row.get('deleted').setValue(!old);
    row.get('deleted').markAsDirty();
  }

  close() {
    this.dialogRef.close(this.plannerNew);
  }

  async save() {
    this.loading = true;
    for(let [index, control] of this.contentRows.controls.entries()) {
      if (!control.dirty || control.get('hide').value) {
        continue;
      }
      const values = control.value
      if (values.submissionId === undefined) {
        values.submissionId = null;
      }
      const data = new CreatorPlannerContent(values);
      try {
        // delete
        if(values.deleted) {
          if(values.id === null) {
            control.get('hide').setValue(true);
            control.markAsPristine();
            continue;
          }
          this.plannerNew = await this.campaignService.deletePlannerContent(this.campaign, data.id).toPromise();
          const oldIndex = this.contentsPlanner.findIndex((content) => content.id === data.id)
          this.contentsPlanner.splice(oldIndex, 1);
          control.get('hide').setValue(true);
          control.markAsPristine();
        } else if (data.id !== null) {
          // update
          const updated = await this.campaignService.updatePlannerContent(this.campaign, data).toPromise();
          const old = this.contentsPlanner.find((content) => content.id === data.id)
          Object.assign(old, updated); 
          this.plannerNew = updated.planner;
        } else {
          // create
          const newest = await this.campaignService.createPlannerContent(this.campaign, data).toPromise();
          this.contentsPlanner.push(newest);
          this.plannerNew = newest.planner;
        }
      } catch (err) {
        console.error(err);
      }
    }
    this.loading = false;
    this.close();
  }

  getFormatInSubmissionStyle(row): string {
    const format = row.get('format').value;
    return format ? (new CreatorPlannerContent({format})).getFormatInSubmissionStyle() : null;
  }

  blur() {
    // console.log('---here', this.matCountry )
    // console.log($('#matCountry'))
  }
}
