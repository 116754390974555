import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign, CampaignStatus } from '../../../../models/campaign.model';
import { CampaignService } from '../../../../services/campaign.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignComponent } from '../campaign.component';

@Component({
  selector: 'app-clone-campaign',
  templateUrl: './clone-campaign.component.html',
  styleUrls: ['./clone-campaign.component.scss']
})
export class CloneCampaignComponent implements OnInit, OnDestroy {
  modalRef: NgbModalRef;
  originalCampaign: Campaign;
  newName = '';
  hasCampaignPlanners = false;
  hasCampaignScenarios = false;
  hasCreators = false;
  hasCreatorsPlanners = false;
  nameError = false;
  draftStatus = CampaignStatus.Draft;
  sub = new Subscription();
  cloning = false;
  cloned = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CampaignComponent, private dialogRef: MatDialogRef<CloneCampaignComponent>, private modalService: NgbModal, private campaignService: CampaignService, private userService: UserService) {
    this.originalCampaign = this.data.campaign;
  }



  ngOnInit(): void {
    this.newName = this.originalCampaign.name + ' - Duplicate';
  }

  close(){
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  clone(): void {
    if (!this.newName || this.newName.length === 0) {
      this.nameError = true;
      return;
    }
    this.cloning = true;
    this.nameError = false;
    const clone = this.originalCampaign.clone();
    clone.name = this.newName;
    this.cloning = true;
    const options = {
      hasCampaignPlanners: this.hasCampaignPlanners,
      hasCampaignScenarios: this.hasCampaignScenarios,
      hasCreators: this.hasCreators,
      hasCreatorsPlanners: this.hasCreatorsPlanners,
    }
    this.sub = this.campaignService.clone(clone, options).subscribe(() => {
      this.cloning = false;
      this.cloned = true;
      this.campaignService.clearCaches();
    });
  }

}
