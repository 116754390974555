import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileImageModalComponent } from '../brand/profile-image-modal/profile-image-modal.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CompanyApprovalComponent } from './company-approval/company-approval.component';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { BrandModule } from '../brand/brand.module';
import { MoveUserToCompanyComponent } from './move-user-to-company/move-user-to-company.component';
import { TranslateModule } from '@ngx-translate/core';
import { CreateCompanyUserComponent } from './create-company-user/create-company-user.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyUsersComponent } from './company-users/company-users.component';
import { CompanyBrandsComponent } from './company-brands/company-brands.component';
import { CreateBrandComponent } from './create-brand/create-brand.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { EditCompanyDialogComponent } from './edit-company-dialog/edit-company-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [CompanyApprovalComponent, CreateCompanyComponent, MoveUserToCompanyComponent, CreateCompanyUserComponent,
    CompanyDetailsComponent, CompanyUsersComponent, CompanyBrandsComponent, CreateBrandComponent, UserRolesComponent, EditCompanyDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatSortModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgbAlertModule,
    BrandModule,
    TranslateModule,
    MatCheckboxModule,
    SharedModule,
    RouterModule
  ],
  entryComponents: [
    ProfileImageModalComponent
  ]
})
export class CompanyModule { }
