import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Campaign } from '../../../models/campaign.model';
import { CampaignService } from '../../../services/campaign.service';
import { CampaignStatus } from '../../../models/campaign.model';
import { Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import { UserService } from 'src/app/services/user.service';
import { Privilege } from 'src/app/models/role.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivilegeBlockModalComponent } from '../../shared/privilege-block-modal/privilege-block-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  private campaignList: Array<Campaign>;
  displayList: Array<Campaign>;
  campaignList$: Observable<Array<Campaign>> = null;
  isTest: boolean = false;
  statusType = CampaignStatus;
  activeFilters: Set<CampaignStatus> = new Set<CampaignStatus>();
  refreshAndProntou: Subject<Array<Campaign>> = new Subject<Array<Campaign>>();
  viewMode = false;
  sub: Subscription;
  alert = null;

  constructor(private cs: CampaignService, public router: Router, private errorService: ErrorService,
    private userService: UserService, private modalService: NgbModal) {
      this.isAdmin = this.userService.isAdmin();
    return;
  }

  ngOnInit(): void {
      this.getData();
      this.alert = this.errorService.getAlert();
  }

  getData(): void {
    this.campaignList$ = this.cs.getCampaignsList();
    this.sub = this.campaignList$.subscribe( x => {
      this.campaignList = x.sort( (y, z) => y.id > z.id ? -1 : 1);
      this.filterByStatus(null);
      this.refreshAndProntou.next(this.displayList);
    });
  }

  filterByStatus(status: CampaignStatus): void {
    if (status === null) {
      this.activeFilters.clear();
    } else if (this.activeFilters.has(status)) {
      this.activeFilters.delete(status);
    } else {
      this.activeFilters.add(status);
    }
    if (this.activeFilters.size === 0) {
      this.displayList = this.campaignList;
    } else {
      this.displayList = new Array<Campaign>();
      this.campaignList.forEach( campaign => {
        let matches = false;
        this.activeFilters.forEach(x => matches = matches || x === campaign.status);
        if (matches) {
          this.displayList.push(campaign);
        }
      });
    }

    if (environment.production) {
      this.displayList = this.displayList.filter(campaign => {
        if (this.isTest) {
          return campaign.companyId == 153;
        } else {
          return campaign.companyId != 153;
        }
      });
    }

    this.refreshAndProntou.next(this.displayList);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  createCampaign(): void {
    const editCampaignRoles = this.userService.getCurrentUserRoles()
      .filter(r => r.role?.privileges.find(p => p.name === Privilege.PREDEFINED.EDIT_CAMPAIGNS) && r.campaignId === null);

    if (this.userService.isAdmin() || editCampaignRoles.length > 0) {
      this.router.navigate(['campaign/create']);
    } else {
      this.modalService.open(PrivilegeBlockModalComponent);
    }
  }
}
