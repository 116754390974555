import { animate, state, style, transition, trigger } from "@angular/animations";
import { HeaderAnimationPhases } from "./header-animation-phases.enum";

const headerClosed = state('closed', style({
    display: "flex",
    flexDirection: "column",
    opacity: 1,
    alignItems: "center" ,
    borderBottom: "0px solid #C5C5C5"
}));
  
const headerPreClosed = state('preClosed', style({
    display: "flex",
    flexDirection: "column",
    opacity: 0,
    alignItems: "center",
    borderBottom: "0px solid #C5C5C5"
}));

const headerPreClosedChange = state('preClosedChange', style({
    display: "flex",
    opacity: 0,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    borderRadius: "5px 5px 0px 0px",
    background: "#FFFFFF",
    borderBottom: "1px solid #C5C5C5"
}));

const headerPreOpen = state('preOpen', style({
    display: "flex",
    opacity: 0,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    borderRadius: "5px 5px 0px 0px",
    background: "#FFFFFF",
    borderBottom: "1px solid #C5C5C5"
}));

const headerPreOpenChange = state('preOpenChange', style({
    display: "flex",
    flexDirection: "column",
    opacity: 0,
    alignItems: "center",
}));

const headerOpen = state('open', style({
    display: "flex",
    opacity: 1,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    borderRadius: "5px 5px 0px 0px",
    background: "#FFFFFF",
    height: "50px",
    borderBottom: "1px solid #C5C5C5"
}));

  export class HeaderAnimations {

    public static headerAnimation =  trigger('headerState', [
        headerClosed,
        headerPreOpenChange,
        headerPreOpen,
        headerOpen,
        headerPreClosedChange,
        headerPreClosed,
        transition('closed => preOpenChange', animate('200ms ease-in-out')),
        transition('preOpenChange => preOpen', animate('200ms ease-in-out')),
        transition('preOpen => open', animate('200ms ease-in-out')),
        transition('open => preClosedChange', animate('200ms ease-in-out')),
        transition('preClosed => closed', animate('200ms ease-in-out')),
      ])

    public static headerChangePhases(headerPhase: HeaderAnimationPhases): HeaderAnimationPhases {
        switch(headerPhase){
            case HeaderAnimationPhases.preOpenChange :
                return HeaderAnimationPhases.PreOpen;
            case HeaderAnimationPhases.PreOpen :
                return HeaderAnimationPhases.Open;
            case HeaderAnimationPhases.PreClosedChange :
                return HeaderAnimationPhases.PreClosed;
            case HeaderAnimationPhases.PreClosed :
                return HeaderAnimationPhases.Closed;
        }
        return headerPhase;
    }
  }
  
  
  