import { Campaign } from './campaign.model';

export class Brand {

    id: number;
    name: string;
    legalName: string;
    logo: string;
    description: string;
    companyId: number;
    campaigns?: Campaign[];

    constructor(id: number, name: string, legalName: string, logo: string, description: string, companyId: number, campaigns?: Campaign[]) {
        this.id = id;
        this.name = name;
        this.legalName = legalName;
        this.logo = logo;
        this.description = description;
        this.companyId = companyId;
        this.campaigns = campaigns;
    }

    static deserialize(data: any) {
        return new Brand(Number.parseInt(data.id, 10), data.name.trim(), data.legal_name, data.logo_url,
            data.description, Number.parseInt(data.company_id, 10), data.campaigns?.map(c => new Campaign().deserialize(c)));
    }
}

