import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { CreatorApproval, CreatorApprovalPlanner } from '../../../../models/CreatorApproval.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../../../models/campaign.model';
import { Creator } from '../../../../models/creator.model';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';
import { CreatorService } from 'src/app/services/creator.service';
import { UserService } from 'src/app/services/user.service';
import { Privilege } from 'src/app/models/role.model';
import { CommentsComponent } from '../comments/comments.component';
import { AudienceDetailsDialogComponent } from '../audience/audience-details-dialog/audience-details-dialog.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { InstagramAudience } from '../../../../models/instagram.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Submission } from '../../../../models/submission.model';
import { CreatorFilter } from '../../../../enum/creator-filter.enum';
import { CreatorStatusModalComponent } from '../creator-status-modal/creator-status-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from '../../../../support/helper.support';
import { CalculatorPlanner } from 'src/app/models/calculatorPlanner';
import { CreatorPlannerModalComponent } from './creator-planner-modal/creator-planner-modal.component';
import { CampaignService } from 'src/app/services/campaign.service';
import { ConnectInventory } from 'src/app/models/connectInventory.model';
import { th } from 'date-fns/locale';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-campaign-creator-card',
  templateUrl: './campaign-creator-card.component.html',
  styleUrls: ['./campaign-creator-card.component.scss']
})
export class CampaignCreatorCardComponent implements OnInit, OnDestroy {
  @ViewChildren('chkboxes') chkboxes: QueryList<any>;
  @Input() public approval: CreatorApproval;
  @Input() public campaign: Campaign;
  @Input() public pricingModel: string;
  @Input() public submissions: Submission[];
  @Input() public checkedSuggested: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() accepted = new EventEmitter<CreatorApproval>();
  @Output() rejected = new EventEmitter();
  @Output() categorizeApprovals = new EventEmitter<boolean>();
  @Output() removeCreator = new EventEmitter<CreatorApproval>();
  @Output() addSuggestedSubmissions = new EventEmitter<{creator: CreatorApproval; deleted: boolean}>();
  @Input() public isAdmin = false;
  public instagramAud: InstagramAudience;
  public creator: Creator;
  public displayedAudience: string;
  public modalRef: NgbModalRef;
  public selectedReason = '';
  public rejectionComments = '';
  public tiktok = faTiktok;
  public canEdit = false;
  public instagramCardSpaceCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public tikTokCardSpaceCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public youtubeCardSpaceCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public extraCardSpaceCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public creatorFilters = CreatorFilter;
  // public negociatedPrice: number;
  public calculatorPlanner: CalculatorPlanner;
  public isSuggested: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showPoints: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public staticPoints: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshingShortUrl: boolean = false;


  constructor(private modalService: NgbModal, private router: Router, private campaignService: CampaignService, private creatorService: CreatorService,
    private readonly cdr: ChangeDetectorRef, private readonly userService: UserService, private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly alertService: AlertService) {

    }


  public ngOnInit(): void {
    this.calculatorPlanner = this.approval.getCalculatorPlanner();
    this.isSuggested.next(this.approval.status === 'Suggested');
    this.canEdit = this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_PROFILES], this.campaign.companyId, this.campaign.brandId, this.campaign.id);
    if (this.approval && this.approval.creator) {
      this.creator = this.approval.creator;
      if (this.creator.hasInstagramAudience()) {
        this.displayedAudience = 'instagram';
      } else if (this.creator.hasYoutubeAudience()) {
        this.displayedAudience = 'youtube';
      } else if (this.creator.hasTikTokAudience()) {
        this.displayedAudience = 'tiktok';
      } else {
        this.displayedAudience = 'none';
      }
    }
    this.instagramCardSpaceCondition.next(this.creator.hasInstagram());
    this.tikTokCardSpaceCondition .next(this.creator.checkTiktokCardSpace(this.creator));
    this.youtubeCardSpaceCondition.next(this.creator.checkYoutubeCardSpace(this.creator));
    this.extraCardSpaceCondition.next(this.creator.checkExtraCardSpace(this.creator));
  }

  public checkBoxChecked($event) {
    if($event){
      this.addSuggestedSubmissions.emit({creator: this.approval, deleted: false});
    } else {
      this.addSuggestedSubmissions.emit({creator: this.approval, deleted: true});
    }
  }



  public togglePoints() {
    this.showPoints.next(!this.showPoints.value);
  }

  public activateStaticPoints() {
    this.staticPoints.next(true);
  }

  public desactivateStaticPoints() {
    this.staticPoints.next(false);
  }

  private updateInstagramFeedImages(): void {
    if (!this.creator.hasInstagram()) {
      return;
    }
    this.instagramAud = this.creator.instagram.audience;
    const imgRequests = this.instagramAud.imagesToDisplay.map(url => this.http.get(url, {observe: 'response', responseType: 'text'}));
    if (imgRequests.length === 0) {
      return;
    }
    combineLatest(imgRequests).subscribe({
        next:()=>{
        this.creatorService.updateCreatorImages([this.creator.id]).subscribe({
          next:(creators: Creator[])=>{
            this.creator = creators[0];
            this.instagramAud = this.creator.instagram.audience;
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
          }
        });
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    });
  }

  public openAudienceModal(): void {
    if (this.creator.audienceCount() === 0) {
      return;
    }
    if (this.creator.hasInstagramAudience()) {
      this.updateInstagramFeedImages();
    }
    this.dialog.open(AudienceDetailsDialogComponent, {
      width: '1305px',
      height: '782px',
      data: {
        creator : this.creator,
        creatorApproval: this.approval,
        updateProfile: false,
        campaign: this.campaign,
      },
      panelClass: 'audience-dialog-container'
    });
  }

  public hasPrivileges(): boolean {
    return this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_PROFILES], this.campaign.companyId, this.campaign.brandId, this.campaign.id);
  }

  public openStatusModal(campaign: Campaign, approval: CreatorApproval): void {
    if (this.hasPrivileges()){
    const dialog = this.dialog.open(CreatorStatusModalComponent, {
      width: '600px',
      height: '300px',
      data: {
        creator : approval,
        campaign
      },
      panelClass: 'dialog-change-status'},
      );
    dialog.afterClosed().subscribe({
      next: (data: {categorizeApprovals: boolean; removeCreator: CreatorApproval}) => {
        if(data.hasOwnProperty('categorizeApprovals') && data.categorizeApprovals) {
          this.categorizeApprovalsFunc();
        }
        if(data.hasOwnProperty('removeCreator') && data.removeCreator){
          this.removeCreatorFunc();
        }
        this.campaignService.getCampaign(this.campaign.id).subscribe((campaign: Campaign) => {
          this.campaign = campaign;
        });
      }, error: (error: Error) => {
        console.log(error);
      }
    });
    }
  }

  public openTrackingLinkInfo(content: TemplateRef<any>) {
    this.modalService.open(content, {})
  }

  public openComments(approval: CreatorApproval){
    this.dialog.open(CommentsComponent, {
      width: '1000px',
      height: '460px',
      data: {
        ca : approval,
      }});
  }

  openCreatorPlannerModal() {
    const dialog = this.dialog.open(CreatorPlannerModalComponent, {
      width: '1200px',
      // height: '300px',
      data: {
        creatorApproval : this.approval,
        creator: this.creator,
        campaign: this.campaign,
        calculatorPlanner: this.calculatorPlanner,
      },
      panelClass: 'dialog-change-status'},
    );
  }

  rejectedClicked(content: TemplateRef<CampaignCreatorCardComponent>): void {
    this.modalRef = this.modalService.open(content, {windowClass: 'app-creators-details-modal-error'});
  }

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  reject(): void {
    this.rejected.emit({creator: this.approval, reason: ('Reason: ' + this.selectedReason +
        (this.rejectionComments.length === 0 ? '' : ' - Commented: ' + this.rejectionComments))});
    this.modalRef.close();
  }

  public rejectModal(event: any): void {
    this.rejected.emit({creator: event.creator, reason: event.reason});
    // this.modalRef.close();
  }

  navigateInstagram(name: string): void {
    window.open('https://www.instagram.com/' + name, '_blank');
  }

  navigateYoutube(id: string): void {
    window.open('https://www.youtube.com/channel/' + id, '_blank');
  }

  navigateTikTok(username: string): void {
    window.open('https://www.tiktok.com/@' + username, '_blank');
  }

  categorizeApprovalsFunc(): void {
    this.categorizeApprovals.emit(true);
  }

  removeCreatorFunc(): void {
    this.removeCreator.emit(this.approval);
  }

  goToProfile(): void {
    this.router.navigateByUrl(`/creator/${this.creator.id}`);
  }
  updateCreatorImages(userId: number, img: any) {
    this.creatorService.updateCreatorImages([userId]).subscribe((creators: Creator[]) => {
      if (this.creator.hasInstagram()) {
        if(this.creator.instagram.profilePicture !== creators[0].instagram.profilePicture) {
          this.creator.instagram.profilePicture = creators[0].instagram.profilePicture;
        } else {
          img.src = '../../../../assets/img/person.png';
        }
      }
      if (this.creator.hasTikTok()) {
        if(this.creator.tikTok.profilePicture !== creators[0].tikTok.profilePicture) {
          this.creator.tikTok.profilePicture = creators[0].tikTok.profilePicture;
        } else {
          img.src = '../../../../assets/img/person.png';
        }
      }
      if (this.creator.hasYoutube()) {
        if(this.creator.youtube.profilePicture !== creators[0].youtube.profilePicture) {
          this.creator.youtube.profilePicture = creators[0].youtube.profilePicture;
        } else {
          img.src = '../../../../assets/img/person.png';
        }
      }
      this.cdr.detectChanges();
    });
  }

  refreshShortUrl(inventory: ConnectInventory) {
    this.refreshingShortUrl = true;
    this.creatorService.generateShortUrl({
      url: inventory.trackingLink,
      service: null,
    }).subscribe((response: any) => {
      inventory.tinyUrl = response.shortUrl;
      this.refreshingShortUrl = false;
      this.alertService.emitSuccess('Refresh shortUrl done.');
    }, (error: HttpErrorResponse) => {
      this.refreshingShortUrl = false;
    });
  }

  copyClipboardShortUrl(inventory: ConnectInventory) 
  {
    Helper.copyToClipboard(inventory.tinyUrl);
    this.alertService.emitSuccess('Copied shortUrl.');
  }
}
