import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CreatorApprovalPlanner, CreatorApproval, PlannerContentNamesByPlatform } from 'src/app/models/CreatorApproval.model';
import { DOCUMENT } from '@angular/common';
import { $ } from 'protractor';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { Creator } from 'src/app/models/creator.model';
import { CalculatorPlanner } from 'src/app/models/calculatorPlanner';

interface Data {
  creatorApproval: CreatorApproval;
  campaign: Campaign;
  creator: Creator;
  calculatorPlanner: CalculatorPlanner;
};

@Component({
  selector: 'app-creator-planner-modal',
  templateUrl: './creator-planner-modal.component.html',
  styleUrls: ['./creator-planner-modal.component.css'],
})
export class CreatorPlannerModalComponent {

  public creatorApproval: CreatorApproval;
  public campaign: Campaign;
  public creator: Creator;
  public calculatorPlanner: CalculatorPlanner;
  public contentNamesByPlatform = PlannerContentNamesByPlatform;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<CreatorPlannerModalComponent>) {
    this.creatorApproval = this.data.creatorApproval;
    this.creator = this.data.creator;
    this.campaign = this.data.campaign;
    this.calculatorPlanner = this.data.calculatorPlanner;
  }

  close() {
    this.dialogRef.close();
  }

  blur() {

  }
}
