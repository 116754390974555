import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../../services/campaign.service';
import { Subscription } from 'rxjs';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import {
  PerformanceSubmission,
  Submission,
} from '../../../../models/submission.model';
import { Campaign } from '../../../../models/campaign.model';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  invalidToken = false;
  sub: Subscription;
  creators: Array<CreatorApproval>;
  submissions: Array<Submission>;
  submissionsPerformanceStats: Array<PerformanceSubmission>;
  campaign: Campaign;
  readyToDisplay = false;

  constructor(
    private route: ActivatedRoute,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.params.token;
    if (token === null || token === '') {
      this.invalidToken = true;
      return;
    }
    this.sub = this.campaignService
    .getCampaignStats(token)
    .subscribe((response) => {
      console.log(response);
        this.campaign = new Campaign().deserialize(response.campaign);
        this.creators = response.creators.map((creator) =>
          new CreatorApproval().deserialize({
            ...creator,
            campaign: this.campaign
          })
        );
        this.submissions = response.submissions.submissions.map((sub) =>
          new Submission().deserialize({...sub, campaign: this.campaign})
        );
        if (this.campaign.isPerformance()) {
          this.submissionsPerformanceStats =
            response.submissions.performance_stats.map((sub) =>
              new PerformanceSubmission().deserialize({...sub, campaign: this.campaign})
            );
        }
        this.creators.forEach((x) => {
          this.submissions.forEach((y) => {
            if (y.userId === x.userId) {
              x.submissions.push(y);
            }
          });
          this.submissionsPerformanceStats.forEach( y => {
            if ( y.userId === x.userId ) {
              x.submissionsPerformanceStats.push(y);
            }
          });
        });
        this.readyToDisplay = true;
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
