import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from 'src/app/models/campaign.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { Subscription } from 'rxjs';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { CampaignService } from 'src/app/services/campaign.service';
import { ActivatedRoute } from '@angular/router';
import { CampaignScenario } from 'src/app/models/campaign.model';

@Component({
  selector: 'app-campaign-commercial-proposal',
  templateUrl: './campaign-commercial-proposal.component.html',
  styleUrls: ['./campaign-commercial-proposal.component.scss'],
  providers: [{
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
  }]
})
export class CampaignCommercialProposalComponent implements OnInit {

  OBJECTIVE_CHAR_LIMIT = 570;
  tiktokIcon = faTiktok;
  CASE_STUDIES = ['AliExpress', 'Colgate', 'Easy', 'Embraer', 'IGG', 'Iunigo', 'Latam', 'Latam Charleston', 'Latam Panamerican',
    'Latam Solar Eclipse', 'Pedidos Ya', 'Renault', 'Shell', 'TNT', 'Todo Pago', 'Trapiche', 'Vamos BA'];

  @Input() campaign: Campaign;
  @Input() creators: CreatorApproval[];
  @Input() creatorsSub: Subscription;

  campaignCreatorTypes = '';
  campaignLocations = '';
  campaignPlatforms = '';
  modalRef: NgbModalRef;

  @ViewChild('commercialPresentation')
  commercialPresentation: ElementRef;

  selectedCases: string[] = [];
  scenariosColumns = [];
  splitObjectiveSlide = false;
  exporting = false;
  loadingCreators = true;

  sharing = false;

  constructor(private route: ActivatedRoute, private campaignService: CampaignService) { }

  ngOnInit() {
    if(this.campaign) {
      this.creatorsSub.add(() => this.loadingCreators = false);
      this.loadCampaignData();
    } else {
      this.sharing = true;
      this.loadingCreators = false;
      const token = this.route.snapshot.params.token;
      this.campaignService.getCampaignProposal(token).subscribe(proposal => {
        this.campaign = proposal.campaign;
        this.creators = proposal.creators;
        this.loadingCreators = false;
        this.selectedCases = proposal.caseStudies;
        this.loadCampaignData();
      });
    }
  }

  loadCampaignData() {
    this.scenariosColumns = this.campaign.scenarios.length > 0 ? Object.getOwnPropertyNames(this.campaign.scenarios[0])
      .filter(property => !!this.campaign.scenarios.map(s => s[property]).find(v => v !== null && v !== undefined)) : [];
    this.splitObjectiveSlide = this.campaign.campaignIdea.length > this.OBJECTIVE_CHAR_LIMIT ||
      this.campaign.creatorRequirements.length > this.OBJECTIVE_CHAR_LIMIT;
    this.campaignCreatorTypes = Array.from(this.campaign.creatorType).map(t => t.slice(0, 1).toUpperCase() + t.slice(1)).join(', ');
    this.campaignLocations = this.campaign.countryList.map(c => c.country).join(', ');
    this.campaignPlatforms = this.campaign.platforms.map(p => p.slice(0, 1).toUpperCase() + p.slice(1)).join(', ');
  }

  casesChanged(checked: boolean, caseName: string) {
    if (checked) {
      this.selectedCases.push(caseName);
    } else {
      this.selectedCases = this.selectedCases.filter(c => c !== caseName);
    }
  }

  shareProposal() {
    this.exporting = true;
    const url = window.location.href.split('/').slice(0, 3).join('/') + '/commercialProposals/';
    this.campaignService.shareCampaignProposal(this.campaign.companyId, this.campaign.brandId, this.campaign.id, this.selectedCases)
    .subscribe((token) => {
      window.open(`${url}${token}`, '__blank');
      this.exporting = false;
    });
  }
  downloadAsPDF() {
    this.exporting = true;
    html2canvas(document.querySelector('#commercialPresentation'), { useCORS: true, allowTaint: true }).then(canvas => {
      try {
        const slides = 13 + (this.selectedCases.length > 1 ? 1 + this.selectedCases.length : 0) + this.creators.length;
        const pageHeight = canvas.height / slides;
        const pdf = new jsPDF('p', 'pt', [canvas.width, pageHeight]);
        const imgData  = canvas.toDataURL('image/png', 1.0);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight; // top padding for other pages
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        const reportpdf = btoa(pdf.output());
        if (reportpdf) {
          const blobURL = URL.createObjectURL(this.pdfBlobConversion(reportpdf, 'application/pdf'));
          window.open(blobURL);
        }
        this.exporting = false;
      } catch (err) {
        console.log(err);
        this.exporting = false;
      }
    });
  }

  pdfBlobConversion(b64Data: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
