import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { SubmissionService } from '../../../../services/submission.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Creator } from '../../../../models/creator.model';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { MessagingService } from '../../../../services/messaging.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationTypes } from '../../../../ui/footer/notification/const';

@Component({
  selector: 'app-submission-modal',
  templateUrl: './submission-modal.component.html',
  styleUrls: ['./submission-modal.component.scss']
})
export class SubmissionModalComponent implements OnInit, OnDestroy {
  constructor(private modalService: NgbModal,
              private submissionService: SubmissionService,
              private userService: UserService,
              private sanitizer: DomSanitizer,
              private alertService: AlertService,
              private datePipe: DatePipe,
              private messagingService: MessagingService,
              private notificationService: NotificationService,
              ) { }

  subscription: Subscription = new Subscription();
  modalRef: NgbModalRef;
  tempSub = new Submission();
  @Input() visibleSubs: Array<boolean> = [];
  @Input() submission: Submission;
  @Input() creator: Creator;
  @Input() noEdit = false;
  @Input() noIcon = false;
  @Input() allSubmissions: Array<Submission>;
  @Output() statusChanged = new EventEmitter<Submission>();
  @Output() editMode = new EventEmitter<Submission>();
  @ViewChild('modalContent', { static: true }) content;
  posting = false;
  reasonEmpty = false;
  dateEmpty = false;
  safeVideo: SafeResourceUrl;
  calendarModalRef: NgbModalRef;
  confirmModalRef: NgbModalRef;
  editModalRef: NgbModalRef;
  tentativeDate: Date;
  // For claendar usage
  @Input() creators: Array<CreatorApproval>;
  show = false; // Needed for calendar submission opening, need a var change for change det/slow render bug
  rejectionText = '';
  loading = false;
  isAdmin = false;

  ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
    this.tempSub.id = this.submission.id;
    this.tempSub.campaign = this.submission.campaign;
    this.tempSub.dateToPost = this.submission.dateToPost;
    this.tempSub.timeToPostStart = this.submission.timeToPostStart;
    this.tempSub.status = this.submission.status;
    this.tempSub.captionErrors = this.submission.captionErrors ? this.submission.captionErrors : '';
    this.setVideoUrl();

    this.messagingService.checkOpenCurrentSubmission()
      .subscribe(data => {
        this.visibleSubs[data?.creatorId] = true;
        if (this.creator.id == data?.creatorId && this.submission.id == data?.submissionId && !this.modalService.hasOpenModals() && data.openSubmissionModal){
          this.open();
          data.openSubmissionModal = false;
        }
      });
  }

  updateNotificationMessagesReaded() {
    const markAsReaded = this.notificationService
      .getNotReadedNotifications()
        .filter(not => not.data.creatorId == this.creator.id && NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST ===not.data.name && not.data.submissionId == this.submission.id)
          .map(not => not.id);
    markAsReaded.length && this.notificationService.markAsRead(markAsReaded).subscribe();
  }



  setVideoUrl() {
    if (this.isYoutube()) {
      const videoId = this.submission.video.split('/').pop();
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoId);
    } else {
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.submission.video);
    }
  }
  open(): void {
    console.log('submission modal not opened yet :: ',{ submissionId: this.submission.id, isModalOpened :this.modalService.hasOpenModals()});
    this.modalRef = this.modalService.open(this.content, { windowClass: 'app-submission-details-modal'});
    this.setVideoUrl();
    this.show = true;
    console.log('submission modal opened:: ',{ submissionId: this.submission.id, isModalOpened :this.modalService.hasOpenModals()});
    this.updateNotificationMessagesReaded();
  }

  close(): void {
    this.modalRef.close();
  }

  isYoutube(): boolean {
    return !!this.submission.video && this.submission.video.includes('youtube');
  }

  isVimeo(): boolean {
    return !!this.submission.video && this.submission.video.includes('vimeo');
  }

  async downloadContent(): Promise<void> {
    this.loading = true;
    if (this.isVimeo()) {
      this.submissionService.downloadSubmissionVideo(this.submission.campaign.companyId, this.submission.campaign.brandId,
        this.submission.campaign.id, this.submission.id).subscribe(res => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res.video);
        let extension = '';
        switch (res.type) {
          case 'video/mp4': extension = '.mp4'; break;
          case 'video/mpeg': extension = '.m1v'; break;
          case 'video/ogg': extension = '.ogg'; break;
          case 'video/quicktime': extension = '.qt'; break;
          case 'video/webm': extension = '.webm'; break;
          case 'video/x-m4v': extension = '.m4v'; break;
          case 'video/3gpp': extension = '.3gpp'; break;
          case 'video/ms-asf': extension = '.asf'; break;
          case 'video/x-ms-wmv': extension = '.wmv'; break;
          case 'video/x-msvideo': extension = '.avi'; break;
        }
        a.download = `${this.creator.fullName}-${this.submission.id}-video${extension}`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loading = false;
      });
    } else if (this.submission.image) {
      const image = await fetch(this.submission.image);
      const imageBlob = await image.blob();
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      const self = this;
      reader.onloadend = async () => {
        const data = reader.result.toString();
        const base64 = data.split(',')[1];
        let type = data.split(',')[0] + ',';
        let extension = '';
        switch (base64.charAt(0)) {
          case '/':
            extension = '.jpg';
            type = 'data:image/jpeg;base64,';
            break;
          case 'i':
            extension = '.png';
            type = 'data:image/png;base64,';
            break;
          case 'R':
            extension = '.gif';
            type = 'data:image/gif;base64,';
            break;
          case 'U':
            extension = '.webp';
            type = 'data:image/webp;base64,';
            break;
        }
        const base64Img = await fetch(type + base64);
        const base64Blob = await base64Img.blob();
        const imageURL = URL.createObjectURL(base64Blob);
        const a = document.createElement('a');
        a.href = imageURL;
        a.download = `${self.creator.fullName}-${self.submission.id}-image${extension}`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        self.loading = false;
      };
    }
  }
  changeStatus(status: string): void {
    this.reasonEmpty = false;
    this.dateEmpty = false;
    if ((status === 'Update Required' || status === 'Rejected' ) && this.tempSub.captionErrors.length === 0) {
      this.reasonEmpty = true;
      return;
    }
    if (status === 'Approved' &&
      (this.tempSub.dateToPost.length === 0 || this.tempSub.timeToPostStart.length === 0)) {
      this.dateEmpty = true;
      return;
    }
    if ( status === 'Rejected' && this.rejectionText !== '' ) {
      this.tempSub.captionErrors = this.tempSub.captionErrors + ' - ' + this.rejectionText;
    }
    this.tempSub.status = status;
    this.posting = true;
    if (this.editModalRef) {
      this.editModalRef.close();
    }
    this.subscription = this.submissionService.changeSubmissionStatus(status, this.tempSub ).subscribe(
      () => {
        this.submission.dateToPost = this.tempSub.dateToPost;
        this.submission.timeToPostStart = this.tempSub.timeToPostStart;
        this.submission.status = this.tempSub.status;
        this.submission.captionErrors = this.tempSub.captionErrors;
        this.posting = false;
        this.statusChanged.emit(this.submission);
        this.modalRef.close();
      }, () => {
        this.posting = false;
        this.tempSub.captionErrors = this.submission.captionErrors;
        this.tempSub.dateToPost = this.submission.dateToPost;
        this.tempSub.timeToPostStart = this.submission.timeToPostStart;
        this.tempSub.status = this.submission.status;
      }
    );
  }

  navigateInstagram(): void {
      window.open('https://www.instagram.com/' + this.creator.instagram.username, '_blank');
  }

  public openCalendar(content: TemplateRef<SubmissionModalComponent>): void {
    this.calendarModalRef = this.modalService.open(content, { windowClass: 'app-calendar-modal'});
  }

  public confirmDate(tentativeDate: Date): void {
    this.calendarModalRef.close();
    this.tentativeDate = tentativeDate;
    this.tempSub.timeToPostStart = this.datePipe.transform(tentativeDate, 'HH:mm');
    this.tempSub.dateToPost = this.datePipe.transform(tentativeDate, 'yyyy-MM-dd');
  }

  openConfirmCheck(content: TemplateRef<SubmissionModalComponent>): void {
    if (!this.tempSub.dateToPost || !this.tempSub.timeToPostStart ||
      this.tempSub.dateToPost.length === 0 || this.tempSub.timeToPostStart.length === 0) {
      this.dateEmpty = true;
      return;
    }
    this.dateEmpty = false;
    this.confirmModalRef = this.modalService.open(content, { windowClass: 'app-accept-submission-modal'});
  }

  public openModal(template: TemplateRef<SubmissionModalComponent>): void {
    if (!this.noEdit) {
      this.editModalRef = this.modalService.open(template);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.modalRef) {
 this.modalRef.close();
}
    if (this.calendarModalRef) {
 this.calendarModalRef.close();
}
    if (this.confirmModalRef) {
 this.confirmModalRef.close();
}
    if (this.editModalRef) {
 this.editModalRef.close();
}
  }

  onNewImages({submission, images}: {submission: Submission; images: File[]}) {
    this.loading = true;
    this.submissionService.readStatsImages(submission.format, images).subscribe(data => {
      submission.likes = data.likes ?? submission.likes;
      submission.comments = data.comments ?? submission.comments;
      submission.randomReach = data.reach;
      submission.randomImpressions = data.impressions;
      submission.clicks = data.clicks;
      this.loading = false;
    });
  }

  setTimeFromInput($event: string, id: number): void {
    const date = new Date($event);
    date.setHours(24, 0, 0);
    this.submission.timePosted = date;
  }

  saveStats(): void {
    this.loading = true;
    this.submissionService.updateStats(this.submission).subscribe(() => {
      this.loading = false;
      this.alertService.emitSuccess('Submission update successful.');
      this.close();
    }, () => this.loading = false);
  }
}
