import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../services/user.service';
import { SocialNetworks } from '../../../../models/SocialNetworks';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-images-dialog',
  templateUrl: './preview-images-dialog.component.html',
  styleUrls: ['./preview-images-dialog.component.css']
})
export class PreviewImagesDialogComponent implements OnInit {
  public imageUrl: string;
  public socialNetwork: string;
  public safeVideo: SafeUrl;

  constructor(public dialogRef: MatDialogRef<PreviewImagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreviewImagesDialogComponent, private sanitizer: DomSanitizer) {
      this.imageUrl = this.data.imageUrl;
      this.socialNetwork = SocialNetworks.Instagram;
     }

   
  ngOnInit(): void {
    if (this.isVideo()) {
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageUrl);
    }
  }

  isVideo(): boolean {
    return this.imageUrl.includes('vimeo');
  }

  public close(): void{
    this.dialogRef.close();
  }

}
