import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
@Input() spinning;

  constructor() {
 return;
}

  ngOnInit(): void {
    return;
  }

  public spin(): void {
    this.spinning = true;
  }

  public stop(): void {
    this.spinning = false;
  }

}
