import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from '../../../../services/brand.service';
import { UpdateCampaignModel } from '../../UpdateCampaignModel';
import { CampaignService } from '../../../../services/campaign.service';
import { Options } from 'ng5-slider';
import { CampaignScenario, CampaignStatus } from '../../../../models/campaign.model';
import { Currencies, Currency, ReachTarget } from '../../../../models/constants';
import { Company } from '../../../../models/company.model';
import { UserService } from '../../../../services/user.service';
import { AlertService } from '../../../../services/alert.service';
import { Country, CountryList } from '../../../../models/country';
import { takeWhile } from 'rxjs/operators';
import { Brand } from 'src/app/models/brand.model';
import { Privilege } from 'src/app/models/role.model';
import { SocialNetworks } from 'src/app/models/SocialNetworks';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { SuggestedPricesService } from 'src/app/services/suggested-price.service';
import { SuggestedPrice } from '../../../../models/suggestedPrice.model';
import { Helper } from 'src/app/support/helper.support';


@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, OnDestroy {

  tiktokIcon = faTiktok;
  SCENARIOS_PLATFORMS = [SocialNetworks.Instagram, SocialNetworks.TikTok, SocialNetworks.YouTube];
  companyAdminApproved: boolean;
  sub: Subscription = new Subscription();
  brandSub: Subscription = new Subscription();
  campaignSub: Subscription = new Subscription();
  submitted = false;
  campaign: UpdateCampaignModel;
  savedCountryList: Array<Country>;
  status: string;
  isEdit = false;
  isDraft = false;
  ageRangeOptions: Options = {
    floor: 10,
    ceil: 60,
    step: 1,
    showSelectionBar: true,
    selectionBarGradient: {
      to: '#7400E7',
      from: '#7400E7'
    },
    translate: (value: number): string => value === 60 ? value.toString() + '+' : value.toString(),
    getPointerColor: (value: number): string => {
      if (value === this.campaign.creatorsAgeTo) {
        return '#7400E7';
      } else {
        return '#7400E7';
      }
    }
  };
  posting = false;
  uploadingExamples = false;
  videoExamples:  { [number: string]: File } = {};
  connectCountryList: Array<Country> = CountryList.countryList;
  currencyList: Array<Currency> = Currencies.get();
  currencySymbol: string;
  target: Array<ReachTarget> = [];
  categories: Array<any> = [];
  appArtwork: string;
  appTitle: string;
  categoryList: Array<any> = [];
  categoryListSub: Subscription;
  companyListSub: Subscription;
  companyList: Array<Company>;
  companyBrandList: Array<Brand>;
  isAdmin = false;
  isCompanyAdmin = false;
  reachTarget: ReachTarget;
  targetSelectValue: string;

  ownerIsLemOrUnassigned = true;
  companyLoadedFlag = false;
  public shippingCost: number;
  public agencyShippingCostFee: number;
  public profitNet: number;
  private targetCost: number;
  private budgetTable: number;
  private targetCostTable: number;
  private impresionsGoalTable: number;
  public tableName: string;
  private plannerTotalQuantity: any[] = [];
  private plannerTotalFollowers: any[] = [];
  private plannerTotalFeed: any[] = [];
  private plannerTotalStories: any[] = [];
  private plannerTotalReels: any[] =  [];
  private plannerTotalEstimatedImpressions: any[] = [];
  private plannerTotalEstimatedReach: any[] = [];
  private plannerTotalEstimatedCost: any[] = [];
  private plannerTotalVideosYoutube: any[] = [];
  private plannerTotalMenciones: any[] = [];
  private plannerTotalVideosTiktok: any[] = [];
  newBrand = true;
  private influencerPlannerArray: any[] = [];
  private planner: string[] = [];
  private activeTypes: string[] = [];
  public influencerTypeExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public countrySelectedError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public countrySelectedErrorString: string;
  public suggestedPricesData: SuggestedPrice[];
  public followersByType: any;


  instagramTable: boolean;
  youtubeTable: boolean;
  tiktokTable: boolean;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
              private bs: BrandService, private cs: CampaignService,
              private router: Router, private us: UserService,
              private suggestedPriceservice: SuggestedPricesService,
              private as: AlertService) {

  }

  ngOnInit(): void {
    this.fetchDataSuggestedPrices();
    this.followersByType = {
    };
    const editCampaignRoles = this.us.getCurrentUserRoles()
      .filter(r => r.role?.privileges.find(p => p.name === Privilege.PREDEFINED.EDIT_CAMPAIGNS));
    this.bs.getCompany()
    .pipe(takeWhile(() => !this.companyLoadedFlag)).subscribe({
      next: (company: any) => {
      this.companyLoadedFlag = true;
      this.isAdmin = this.us.isAdmin();
      this.target.push(ReachTarget.reach);
      this.target.push(ReachTarget.impressions);
      this.isCompanyAdmin = editCampaignRoles.filter(r => r.companyId === company.id && !r.brandId).length > 0;
      this.companyAdminApproved = company.isAdminApproved;
      if (this.isAdmin) {
        this.companyListSub = this.cs.getCompanyList().subscribe(companies => {
          this.companyList = companies;
        });
      } else {
        this.companyList = [company];
      }
      // this.categoryListSub = this.cs.getCategoryList().subscribe(x => {
      //   this.categoryList = x.result;
      // });
      if (this.isAdmin || this.isCompanyAdmin) {
        this.bs.getCompanyBrands(company.id).subscribe(brands => this.companyBrandList = brands);
      } else {
        this.companyBrandList = editCampaignRoles
          .filter(r => !r.campaignId && r.brandId && r.companyId === company.id)
          .map(r => r.brand);
      }

      const campaignId = this.route.snapshot.paramMap.get('id');
      this.isEdit = !!campaignId;
      if (campaignId && !Number.isNaN(Number.parseInt(campaignId, 10))) {
        let tableInstagramInit = false;
        let tableYoutubeInit = false;
        let tableTiktokInit = false;


        this.newBrand = false;
        this.campaignSub = this.cs.getCampaign(Number.parseInt(campaignId, 10)).subscribe((x) => {
          this.bs.getUser().subscribe((brand) => {
            this.campaign = new UpdateCampaignModel(brand, x);
            this.isDraft = this.campaign.status === CampaignStatus.Draft;
            this.campaign.form = this.initializeForm();
            this.influencerPlannerArray = this.initializePlanner();
            this.campaign.platformsInput.forEach(function(element){
              if(element.selected == true){
                switch(element.name){
                  case 'instagram':
                    tableInstagramInit = true;
                    break;
                  case 'youtube':
                    tableYoutubeInit = true;
                    break;
                  case 'tiktok':
                    tableTiktokInit = true;
                    break;
                }
              }
            });
            this.instagramTable = tableInstagramInit;
            this.youtubeTable = tableYoutubeInit;
            this.tiktokTable = tableTiktokInit;

            this.updatePlannerValues();
            this.setOwnerIsLemOrUnassigned();
            this.bs.getCompanyBrands(this.campaign.companyId).subscribe(brands => {
              this.companyBrandList = this.campaign.companyId === company.id ? this.companyBrandList : brands;
              this.campaign.form = this.initializeForm();
              this.updatePlannerValues();
              this.setOwnerIsLemOrUnassigned();
            });
          });
        });
      } else {
        this.brandSub = this.bs.getUser().subscribe((x) => {
          this.campaign = new UpdateCampaignModel(x);
          this.campaign.companyId = this.bs.company.id;
          this.bs.getCompanyBrands(this.campaign.companyId).subscribe(brands => {
            this.companyBrandList = this.campaign.companyId === company.id ? this.companyBrandList : brands;
            this.campaign.form = this.initializeForm();
            this.setOwnerIsLemOrUnassigned();
            this.addPlanner();
          });
        });
      }
  }, error: (error: HttpErrorResponse) => {
    console.log(error);
  }});
  }

  setOwnerIsLemOrUnassigned() {
    this.ownerIsLemOrUnassigned = this.companyList && this.campaign
        && ((this.campaign.companyId === this.companyList.find(x => x.legalName === 'Lemmonet')?.id
          && this.campaign.form.value.brand.toLowerCase() !== 'lemmonet')
          || this.campaign.companyId === this.companyList.find(x => x.legalName === 'Unassigned')?.id);
  }
  get f(): { [key: string]: AbstractControl } {
    return this.campaign.form.controls;
  }

  submit(): void {
    this.submitted = true;
    if (!this.canPost()) {
      return;
    }

    if(this.isEdit){
      this.campaign.form.controls.influencerPlanner['controls'] = [];
    }

    this.campaign.form.controls.influencerPlanner['controls'].push(this.influencerPlannerArray);
    this.posting = true;
    this.campaign.brandId = !this.newBrand ? this.companyBrandList.find(b => b.name === this.campaign.form.get('brand').value)?.id : null;
    if (this.isDraft) {
      // Si es draft y le doy create, es como hacer un edit del draft con full data
      this.campaignSub = this.cs.updateCampaign(this.campaign).subscribe(
        async ({ upload }) => {
          if (Object.values(this.videoExamples).filter(v => !!v).length > 0) {
            this.uploadingExamples = true;
            this.uploadVideoExamples(upload).subscribe(async () => {
              this.uploadingExamples = false;
              this.posting = false;
              this.as.emitSuccess('Campaign updated successfully');
              await this.router.navigate([`campaign/${this.campaign.id}`]);
            }, () => {
              this.uploadingExamples = false;
              this.posting = false;
              this.as.emitError('Error uploading video examples.');
            });
          } else {
            this.posting = false;
            this.as.emitSuccess('Campaign updated successfully');
            await this.router.navigate([`campaign/${this.campaign.id}`]);
          }
        },
        (error) => {
          this.as.emitError(error.error.message || error.error.error || error.error || 'Error');
          this.posting = false;
        });
    } else {
      this.campaignSub = this.cs.createCampaign(this.campaign).subscribe(
        async ({ upload }) => {
          if (Object.values(this.videoExamples).filter(v => !!v).length > 0) {
            this.uploadingExamples = true;
            this.uploadVideoExamples(upload).subscribe(async () => {
              this.uploadingExamples = false;
              this.posting = false;
              this.as.emitSuccess('Campaign created successfully');
              await this.router.navigate(['dashboard']);
            }, () => {
              this.uploadingExamples = false;
              this.posting = false;
              this.as.emitError('Error uploading video examples.');
            });
          } else {
            this.posting = false;
            this.as.emitSuccess('Campaign created successfully');
            await this.router.navigate(['dashboard']);
          }
        },
        (error) => {
          this.as.emitError(error.error.message || error.error.error || error.error || 'Error');
          this.posting = false;
        });
    }
    return;
  }

  async saveDraft(): Promise<void> {
    if(this.isEdit){
      this.campaign.form.controls.influencerPlanner['controls'] = [];
    }

    await this.campaign.form.controls.influencerPlanner['controls'].push(this.influencerPlannerArray);
    this.posting = true;
    this.campaign.brandId = !this.newBrand ? this.companyBrandList.find(b => b.name === this.campaign.form.get('brand').value)?.id : null;
    this.campaignSub = this.cs.saveDraftCampaign(this.campaign).subscribe(
      async ({ upload }) => {
        if (Object.values(this.videoExamples).filter(v => !!v).length > 0) {
          this.uploadingExamples = true;
          this.uploadVideoExamples(upload).subscribe(async () => {
            this.uploadingExamples = false;
            this.posting = false;
            this.as.emitSuccess('Draft created successfully');
            await this.router.navigate(['dashboard']);
          }, () => {
            this.uploadingExamples = false;
            this.posting = false;
            this.as.emitError('Error uploading video examples.');
          });
        } else {
          this.posting = false;
          this.as.emitSuccess('Draft created successfully');
          await this.router.navigate(['dashboard']);
        }
      },
      (error) => {
        this.as.emitError(error.error.message || error.error.error || error.error || 'Error');
        this.posting = false;
      });
    return;
  }

  updateCampaign(): void {
    this.submitted = true;
    if (!this.canPost()) {
      return;
    }
    this.posting = true;
    this.campaign.form.controls.influencerPlanner['controls'] = [];
    this.campaign.form.controls.influencerPlanner['controls'].push(this.influencerPlannerArray);

    this.campaignSub = this.cs.updateCampaign(this.campaign).subscribe(
      async ({ upload }) => {
        if (Object.values(this.videoExamples).filter(v => !!v).length > 0) {
          this.uploadingExamples = true;
          this.uploadVideoExamples(upload).subscribe(async () => {
            this.uploadingExamples = false;
            this.posting = false;
            this.as.emitSuccess('Campaign updated successfully');
            await this.router.navigate([`campaign/${this.campaign.id}`]);
          }, () => {
            this.uploadingExamples = false;
            this.posting = false;
            this.as.emitError('Error uploading video examples.');
          });
        } else {
          this.posting = false;
          this.as.emitSuccess('Campaign updated successfully');
          await this.router.navigate([`campaign/${this.campaign.id}`]);
        }
      },
      (error) => {
        this.as.emitError(error.error.message || error.error.error || error.error || 'Error');
        this.posting = false;
      });
    return;
  }

  uploadVideoExamples(upload: string[]): Observable<any> {
    if (Object.keys(this.videoExamples).length > 0) {
      const uploads = Object.keys(this.videoExamples)
        .filter(k => this.videoExamples[k])
        .map(k => this.cs.uploadExampleVideo(upload[parseInt(k, 10) - 1], this.videoExamples[k]));
      if (uploads.length > 0) {
        return combineLatest(uploads);
      }
    }
    return new Observable<void>();
  }
  onNewImage(event: { media: string; type: 'image' | 'video'; file: File }, imageNumber: number): void {
    switch (imageNumber) {
      case 0:
        this.campaign.icon = event.media;
        this.campaign.updatesIcon = true;
        this.campaign.form.value.icon = event.media;
        break;
      case 1:
        if (event.type === 'image') {
          this.campaign.image1 = event.media;
          this.videoExamples[imageNumber] = null;
        } else {
          this.campaign.image1 = null;
          this.videoExamples[imageNumber] = event.file;
        }
        this.campaign.updatesImage1 = true;
        break;
      case 2:
        if (event.type === 'image') {
          this.campaign.image2 = event.media;
          this.videoExamples[imageNumber] = null;
        } else {
          this.campaign.image2 = null;
          this.videoExamples[imageNumber] = event.file;
        }
        this.campaign.updatesImage2 = true;
        break;
      case 3:
        if (event.type === 'image') {
          this.campaign.image3 = event.media;
          this.videoExamples[imageNumber] = null;
        } else {
          this.campaign.image3 = null;
          this.videoExamples[imageNumber] = event.file;
        }
        this.campaign.updatesImage3 = true;
        break;
      case 4:
        if (event.type === 'image') {
          this.campaign.image4 = event.media;
          this.videoExamples[imageNumber] = null;
        } else {
          this.campaign.image4 = null;
          this.videoExamples[imageNumber] = event.file;
        }
        this.campaign.updatesImage4 = true;
        break;
      case 5:
        this.campaign.cover = event.media;
        this.campaign.updatesCover = true;
        this.campaign.form.controls.cover.setValue(event.media);
        break;
    }
    this.f.cover.updateValueAndValidity();
  }

  private canPost(): boolean {
    if (this.campaign.pricingModel !== 'PER') {
      return !(this.campaign.form.invalid) && this.campaign.countryList.length !== 0;
    }
    return !(this.campaign.form.invalid) && this.campaign.revenue !== 0 && this.campaign.countryList.length !== 0
      && this.campaign.previewUrl !== '' && this.campaign.owner !== 0 && this.campaign.margin >= 0 && this.campaign.margin <= 100
      && this.campaign.currency !== '';
  }

  ngOnDestroy(): void {
    if (this.campaignSub) {
      this.campaignSub.unsubscribe();
    }
    if (this.brandSub) {
      this.brandSub.unsubscribe();
    }
    if (this.categoryListSub) {
      this.categoryListSub.unsubscribe();
    }
    if (this.companyListSub) {
      this.companyListSub.unsubscribe();
    }
  }

  minSelectedCheckboxes(min = 1): ValidatorFn {
    return () => {
      const totalSelected = this.campaign.platformsInput
        .map(value => value.selected ? 1 : 0)
        .reduce((prev, next) => prev + next, 0);
      return totalSelected >= min ? null : { required: true };
    };
  }

  platformSelected(i: number): void {
    this.campaign.platformsInput[i].selected = !this.campaign.platformsInput[i].selected;
    switch(this.campaign.platformsInput[i].name){
      case 'instagram':
        this.instagramTable = this.campaign.platformsInput[i].selected == true ? true : false;
        break;
      case 'youtube':
        this.youtubeTable = this.campaign.platformsInput[i].selected == true ? true : false;
        break;
      case 'tiktok':
        this.tiktokTable = this.campaign.platformsInput[i].selected == true ? true : false;
        break;
    }
    this.f.platforms.updateValueAndValidity();
  }

  categorySelected(i: number): void {
    if (this.campaign.categories.has(i)) {
      this.campaign.categories.delete(i);
    } else if (this.campaign.categories.size < 3) {
      this.campaign.categories.add(i);
    }
    this.f.categories.updateValueAndValidity();
  }

  brandSelected(brandName: string | null) {
    this.newBrand = brandName.length === 0;
  }

  toggleCreatorType(type: string, initialize? : number): void {
    const set = this.campaign.creatorType;
    const influencerPlannerForm = (this.campaign.form.get('influencerPlanner') as FormArray);
    if (set.has(type)) {
      if(!initialize){
        set.delete(type);
        for(let i = 0; i <= this.influencerPlannerArray.length-1; i++){
          const index = this.influencerPlannerArray[i].controls.findIndex(c => c.get('influencerType').value.toLowerCase() === type);
          if (index !== -1) {
            this.influencerPlannerArray[i].removeAt(index);
          }
        }
      } else {
        this.influencerTypeExists.next(true);
      }
      this.activeTypes = this.activeTypes.filter((value)=>type !== value);
      if(set.size === 0){
        this.influencerTypeExists.next(false);
      }


    } else {
      this.influencerTypeExists.next(true);
      set.add(type);
      this.currencySymbol = '-';
      this.activeTypes.push(type);
      for(let i = 0; i < this.influencerPlannerArray.length; i++){
        const table = i+1;
        this.influencerPlannerArray[i].push(this.fb.group({
          influencerType: [type.charAt(0).toUpperCase() + type.slice(1)],
          followers: [this.getFollowersByType(type), Validators.min(0)],
          quantity: [0, Validators.min(0)],
          feed: [0, Validators.min(0)],
          stories: [0, Validators.min(0)],
          reels: [0, Validators.min(0)],
          videos: [0, Validators.min(0)],
          menciones: [0, Validators.min(0)],
          videosTiktok: [0, Validators.min(0)],
          estimatedImpressions: [{ value: 0, disabled: true }],
          estimatedReach: [{ value: 0, disabled: true }],
          estimatedCost: [{ value: 0, disabled: true }]
        }));

        this.influencerPlannerArray[i].controls.tablePlannerInputs = (this.fb.group({
          budgetTable: [0, Validators.min(0)],
          comission: [0, Validators.min(0)],
          tax: [0, Validators.min(0)],
          profit: [0, Validators.min(0)],
          shippingCost: [0, Validators.min(0)],
          agencyShippingCostFee: [0, Validators.min(0)],
          profitNet: [0, Validators.min(0)],
          targetSelect: [''],
          quantityCost: [0, Validators.min(0)],
          cost: [0, Validators.min(0)],
          impresionsGoalTable: [0, Validators.min(0)],
          targetCostTable: [0, Validators.min(0)],
          isActive: [(this.influencerPlannerArray.length == 1 ? 1 : 0), Validators.min(0)],
          table: [table, Validators.min(0)],
          tableName: ['',Validators.pattern('^[a-zA-Z]+$')]
        }));
        this.influencerPlannerArray[i].controls.tablePlannerInputs.controls.targetSelect.value = 'Reach';
        this.checkTarget(i);
      }
      this.activateTable(this.influencerPlannerArray.length-1);
    }
    this.getEstimatedCost();
    for(let i=0; i<this.influencerPlannerArray.length; i++){
      this.influencerPlannerArray[i].controls.forEach((element, index) => {
        this.updatePlannerTable(i, index);
      });
    }
  }

  initializeForm(): FormGroup {
    if(!this.isEdit){
      this.campaign.pricingModel = 'BRANDING';
      this.campaign.productShipping = '0';
    }

    this.campaign.target = this.target[0];
    const controls = this.campaign.platformsInput.map(() => new FormControl(false));
    const genderControls: Array<FormControl> = new Array<FormControl>();
    for (let i = 0; i < 6; i++) {
      genderControls.push(new FormControl(false));
    }
    const instagramPlatformSelectedNumber = 1;
    this.campaign.platformsInput[instagramPlatformSelectedNumber].selected = true;
    this.instagramTable = this.campaign.platformsInput[instagramPlatformSelectedNumber].selected;
    return this.fb.group({
      creatorRequirements: [this.campaign.creatorRequirements,
        Validators.compose([Validators.required, Validators.maxLength(5000)])],
      launchDate: [{
        year: this.campaign.launchDate.getFullYear(), month: (this.campaign.launchDate.getMonth() + 1),
        day: this.campaign.launchDate.getDate()
      }, Validators.required],
      endDate: [this.campaign.endDate ? {
        year: this.campaign.endDate.getFullYear(), month: (this.campaign.endDate.getMonth() + 1),
        day: this.campaign.endDate.getDate()
      } : null],
      gender: new FormArray(genderControls,
        (() => ['m', 'f', 'nb', 'm,f', 'm,nb', 'f,nb', 'm,f,nb'].filter(x => x !== this.campaign.creatorsGender).length === 0 ?
          { required: true } : null)),
      genderMale: [this.campaign.creatorsGender && (this.campaign.creatorsGender.toLowerCase().split(',').includes('m')
        || this.campaign.creatorsGender === 'Male' || this.campaign.creatorsGender === 'Both')],
      genderFemale: [this.campaign.creatorsGender && (this.campaign.creatorsGender.toLowerCase().split(',').includes('f')
        || this.campaign.creatorsGender === 'Female' || this.campaign.creatorsGender === 'Both')],
      genderNonBinary: [this.campaign.creatorsGender && this.campaign.creatorsGender.toLowerCase().split(',').includes('nb')],
      icon: [this.campaign.icon, Validators.required],
      brand: [this.campaign.brand ?? (this.isCompanyAdmin || this.isAdmin ? '' : this.companyBrandList[0]?.name), Validators.required],
      name: [this.campaign.name, Validators.required],
      cover: [this.campaign.cover, Validators.required],
      platforms: new FormArray(controls, this.minSelectedCheckboxes(1)),
      campaignIdea: [this.campaign.campaignIdea,
        Validators.compose([Validators.required, Validators.maxLength(2000)])],
      campaignTarget: [this.campaign.campaignTarget,
        Validators.compose([Validators.required, Validators.maxLength(2000)])],
      postRequirements: [this.campaign.postRequirements, Validators.maxLength(2000)],
      dos: [this.campaign.dos, Validators.required],
      donts: [this.campaign.donts, Validators.required],
      currency: this.campaign.currency,
      desglose: [this.campaign.desglose, 
        Validators.compose([Validators.required, Validators.maxLength(2000)])],
      proposalDetails: [this.campaign.proposalDetails, 
        Validators.compose([Validators.required, Validators.maxLength(2000)])],
      cpm: [this.campaign.goal > 0 ? (this.campaign.budgetGross / this.campaign.goal) * 1000 : 0, Validators.min(0)],
      goal: [this.campaign.goal, Validators.min(0)],
      budgetGross: [this.campaign.budgetGross, Validators.min(0)],
      commission: [this.campaign.commission, Validators.compose([Validators.min(0), Validators.max(100)])],
      tax: [this.campaign.tax, Validators.compose([Validators.min(0), Validators.max(100)])],
      profit: [this.campaign.profit, Validators.compose([Validators.min(0), Validators.max(100)])],
      shippingCost: [this.campaign.shippingCost, Validators.min(0)],
      agencyShippingCostFee: [this.campaign.agencyShippingCostFee, Validators.compose([Validators.min(0), Validators.max(100)])],
      influencerPlanner: new FormArray(this.campaign.planner.length > 0 ?
        this.campaign.planner.map(influencer => this.fb.group({
          influencerType: influencer.influencerType.charAt(0).toUpperCase() + influencer.influencerType.slice(1),
          followers: influencer.followers,
          quantity: influencer.quantity,
          feed: influencer.feed,
          stories: influencer.stories,
          reels: influencer.reels,
          videos: influencer.videos,
          menciones: influencer.menciones,
          videosTiktok: influencer.videosTiktok,
          budgetTable: influencer.budgetTable,
          profitNet: influencer.profitNet,
          shippingCost: [influencer.shippingCost, Validators.min(0)],
          agencyShippingCostFee: [influencer.agencyShippingCostFee, Validators.compose([Validators.min(0), Validators.max(100)])],
          targetSelect: influencer.targetSelect,
          quantityCost: influencer.quantityCost,
          cost: influencer.cost,
          impresionsGoalTable: influencer.impresionsGoalTable,
          targetCostTable: influencer.targetCostTable,
          name: influencer.name,
          active: influencer.active,
          table: influencer.table,
          estimatedImpressions: influencer.quantity * influencer.followers *
(influencer.feed * 0.2 + influencer.stories * 0.15 + influencer.reels * 0.25 + influencer.videos * 0.2 + influencer.menciones * 0.2 +
  influencer.videosTiktok * 0.2),
          estimatedReach: influencer.quantity * influencer.followers *
          (influencer.feed * 0.2 + influencer.stories * 0.15 + influencer.reels * 0.25 + influencer.videos * 0.2 + influencer.menciones * 0.2 +
            influencer.videosTiktok * 0.2),
          estimatedCost: 0,
        })) : Array.from(this.campaign.creatorType).map(c => this.fb.group({
            influencerType: c.charAt(0).toUpperCase() + c.slice(1),
            followers: 0,
            quantity: 0,
            feed: 0,
            stories: 0,
            reels: 0,
            videos: 0,
            menciones: 0,
            videosTiktok: 0,
            estimatedImpressions: 0,
            estimatedReach: 0,
            estimatedCost: 0,
            budgetTable: 0,
            comission: 0,
            tax: 0,
            profit: 0,
            shippingCost: this.campaign.shippingCost,
            agencyShippingCostFee: this.campaign.agencyShippingCostFee,
            profitNet: 0,
            targetSelect: '',
            name: '',
            quantityCost: 0,
            cost: 0,
            impresionsGoalTable: 0,
            targetCostTable: 0,
            active: 0,
            table: 0
        })),
      ),
      scenarios: new FormArray(this.campaign.scenarios?.map(scenario => this.fb.group({
        type: scenario.type,
        investment: [scenario.investment, Validators.pattern(/^[0-9]*([.,][0-9]*)?$/)],
        pricingModel: scenario.pricingModel,
        impressions: [scenario.impressions, Validators.pattern(/^[0-9]*$/)],
        influencers: scenario.influencers,
        contents: [scenario.contents, Validators.pattern(/^[0-9]*$/)],
        platforms: [scenario.platforms]
       })) ?? [])
    });
  }

  public onDeselectCountry(){
    this.currencySymbol = '-';
    this.campaign.countryList[0] = null;
    this.getEstimatedCost();
  }

  private getCurrencyFiltered( countrySelected: string ): Currency[] {
    this.currencyList = Currencies.get();
    switch(countrySelected){
      case 'Argentina':
        this.currencySymbol = '$';
        this.campaign.currency = 'ARS';
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'ARS');
      case 'Brazil':
        this.currencySymbol = 'R$';
        this.campaign.currency = 'BRL';
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'BRL');
      case 'United States':
        this.currencySymbol = 'USD';
        this.campaign.currency = this.currencySymbol;
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'USD');
      case 'United Kingdom (UK)':
        this.currencySymbol = '£';
        this.campaign.currency = 'GBP';
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'GBP');
      case 'Chile':
        this.campaign.currency = 'CLP';
        this.currencySymbol = '$';
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'CLP');
      case 'Mexico':
        this.currencySymbol = '$';
        this.campaign.currency = 'MXN';
        this.campaign.form.controls.currency.setValue(this.campaign.currency);
        return this.currencyList.filter(currency => currency.code == 'MXN');
      default:
        this.currencyList = Currencies.get();
        return this.currencyList;
    }
  }

  private getFollowersByCountry(countrySelected: string){

    if(this.suggestedPricesData){
      const suggestedPricesFollowersNano = this.suggestedPricesData?.filter(row => row.creator_level == 'Nano' && row.country == countrySelected);
      const suggestedPricesFollowersMicro = this.suggestedPricesData?.filter(row => row.creator_level == 'Micro' && row.country == countrySelected);
      const suggestedPricesFollowersMid = this.suggestedPricesData?.filter(row => row.creator_level == 'Mid' && row.country == countrySelected);
      const suggestedPricesFollowersMacro = this.suggestedPricesData?.filter(row => row.creator_level == 'Pro/Macro' && row.country == countrySelected);
      const suggestedPricesFollowersCelebrity = this.suggestedPricesData?.filter(row => row.creator_level == 'Celebrity' && row.country == countrySelected);
      this.followersByType = {
        NanoMin : suggestedPricesFollowersNano[0]?.followers_min,
        NanoMax : suggestedPricesFollowersNano[0]?.followers_max,
        MicroMin : suggestedPricesFollowersMicro[0]?.followers_min,
        MicroMax : suggestedPricesFollowersMicro[0]?.followers_max,
        MidMin : suggestedPricesFollowersMid[0]?.followers_min,
        MidMax : suggestedPricesFollowersMid[0]?.followers_max,
        MacroMin : suggestedPricesFollowersMacro[0]?.followers_min,
        MacroMax : suggestedPricesFollowersMacro[0]?.followers_max,
        CelebrityMin : suggestedPricesFollowersCelebrity[0]?.followers_min,
        CelebrityMax : suggestedPricesFollowersCelebrity[0]?.followers_max,
      };
      this.updatePlannerValuesTable();
    }

  }

  public getFollowersByType(type){
    this.getFollowersByCountry(this.campaign.countryList[0]? this.campaign.countryList[0].country : null);
    switch(type){
      case 'nano':
        return this.followersByType.NanoMax;
      case 'micro':
        return (this.followersByType.MicroMin + this.followersByType.MicroMax) / 2;
      case 'mid':
        return (this.followersByType.MidMin + this.followersByType.MidMax) / 2;
      case 'macro':
        return (this.followersByType.MacroMin + this.followersByType.MacroMax) / 2;
      case 'celebrity':
        return this.followersByType.CelebrityMin;
    }
  }

  public getEstimatedCost(){
    if (!this.campaign) {
      return;
    }
    const self = this;
    const countrySelected = this.campaign.countryList[0]? this.campaign.countryList[0].country : null;
    this.currencyList = this.getCurrencyFiltered(countrySelected);
    this.getFollowersByCountry(countrySelected);
    const suggestedPrices = this.suggestedPricesData;
    this.influencerPlannerArray.forEach(function(element){
      for(let i = 0; i< element.controls.length; i++){
        if(countrySelected && suggestedPrices){
          const influencerType = element.controls[i].controls.influencerType.value;
          const followers = self.getFollowersByType(influencerType.toLowerCase());
          element.controls[i].controls.followers.setValue(followers);
          switch(influencerType){
            case 'Nano':
              const objectPricesNano = suggestedPrices.filter(prices => prices.country === countrySelected && prices.creator_level === 'Nano');
              if(objectPricesNano.length < 1){
                element.controls[i].controls.estimatedCost.setValue('Cargar precios sugeridos');
              } else {
                const avgInstagramFeedPriceNano = (objectPricesNano[0].instagram_feed_price_min + objectPricesNano[0].instagram_feed_price_max)/2;
                const avgInstagramStoryPriceNano = (objectPricesNano[0].instagram_story_price_min + objectPricesNano[0].instagram_story_price_max)/2;
                const avgInstagramReelPriceNano = (objectPricesNano[0].instagram_reel_price_min + objectPricesNano[0].instagram_reel_price_max)/2;
                const avgYoutubeVideoPriceNano = (objectPricesNano[0].youtube_video_exclusive_price_min + objectPricesNano[0].youtube_video_exclusive_price_max)/2;
                const avgYoutubeMencionPriceNano = (objectPricesNano[0].youtube_video_mention_price_min + objectPricesNano[0].youtube_video_mention_price_max)/2;
                const avgTiktokVideoPriceNano = (objectPricesNano[0].tiktok_video_price_min + objectPricesNano[0].tiktok_video_price_max)/2;
                element.controls[i].controls.estimatedCost.setValue((element.controls[i].controls.feed.value * avgInstagramFeedPriceNano + element.controls[i].controls.stories.value * avgInstagramStoryPriceNano +
                  element.controls[i].controls.reels.value * avgInstagramReelPriceNano + element.controls[i].controls.videos.value * avgYoutubeVideoPriceNano
                  + element.controls[i].controls.menciones.value * avgYoutubeMencionPriceNano + element.controls[i].controls.videosTiktok.value * avgTiktokVideoPriceNano)*element.controls[i].controls.quantity.value );
              }
              break;
            case 'Micro':
              const objectPricesMicro = suggestedPrices.filter(prices => prices.country === countrySelected && prices.creator_level === 'Micro');
              if(objectPricesMicro.length < 1){
                element.controls[i].controls.estimatedCost.setValue('Cargar precios sugeridos');
              } else {
                const avgInstagramFeedPriceMicro = (objectPricesMicro[0].instagram_feed_price_min + objectPricesMicro[0].instagram_feed_price_max)/2;
                const avgInstagramStoryPriceMicro = (objectPricesMicro[0].instagram_story_price_min + objectPricesMicro[0].instagram_story_price_max)/2;
                const avgInstagramReelPriceMicro = (objectPricesMicro[0].instagram_reel_price_min + objectPricesMicro[0].instagram_reel_price_max)/2;
                const avgYoutubeVideoPriceMicro = (objectPricesMicro[0].youtube_video_exclusive_price_min + objectPricesMicro[0].youtube_video_exclusive_price_max)/2;
                const avgYoutubeMencionPriceMicro = (objectPricesMicro[0].youtube_video_mention_price_min + objectPricesMicro[0].youtube_video_mention_price_max)/2;
                const avgTiktokVideoPriceMicro = (objectPricesMicro[0].tiktok_video_price_min + objectPricesMicro[0].tiktok_video_price_max)/2;
                element.controls[i].controls.estimatedCost.setValue((element.controls[i].controls.feed.value * avgInstagramFeedPriceMicro + element.controls[i].controls.stories.value * avgInstagramStoryPriceMicro +
                  element.controls[i].controls.reels.value * avgInstagramReelPriceMicro + element.controls[i].controls.videos.value * avgYoutubeVideoPriceMicro
                  + element.controls[i].controls.menciones.value * avgYoutubeMencionPriceMicro + element.controls[i].controls.videosTiktok.value * avgTiktokVideoPriceMicro)*element.controls[i].controls.quantity.value );
              }
              break;
            case 'Mid':
              const objectPricesMid = suggestedPrices.filter(prices => prices.country === countrySelected && prices.creator_level === 'Mid');
              if(objectPricesMid.length < 1){
                element.controls[i].controls.estimatedCost.setValue('Cargar precios sugeridos');
              } else {
                const avgInstagramFeedPriceMid = (objectPricesMid[0].instagram_feed_price_min + objectPricesMid[0].instagram_feed_price_max)/2;
                const avgInstagramStoryPriceMid = (objectPricesMid[0].instagram_story_price_min + objectPricesMid[0].instagram_story_price_max)/2;
                const avgInstagramReelPriceMid = (objectPricesMid[0].instagram_reel_price_min + objectPricesMid[0].instagram_reel_price_max)/2;
                const avgYoutubeVideoPriceMid = (objectPricesMid[0].youtube_video_exclusive_price_min + objectPricesMid[0].youtube_video_exclusive_price_max)/2;
                const avgYoutubeMencionPriceMid = (objectPricesMid[0].youtube_video_mention_price_min + objectPricesMid[0].youtube_video_mention_price_max)/2;
                const avgTiktokVideoPriceMid = (objectPricesMid[0].tiktok_video_price_min + objectPricesMid[0].tiktok_video_price_max)/2;
                element.controls[i].controls.estimatedCost.setValue((element.controls[i].controls.feed.value * avgInstagramFeedPriceMid + element.controls[i].controls.stories.value * avgInstagramStoryPriceMid +
                  element.controls[i].controls.reels.value * avgInstagramReelPriceMid + element.controls[i].controls.videos.value * avgYoutubeVideoPriceMid
                  + element.controls[i].controls.menciones.value * avgYoutubeMencionPriceMid + element.controls[i].controls.videosTiktok.value * avgTiktokVideoPriceMid)*element.controls[i].controls.quantity.value );
              }
              break;
            case 'Macro':
              const objectPricesMacro = suggestedPrices.filter(prices => prices.country === countrySelected && prices.creator_level === 'Pro/Macro');
              if(objectPricesMacro.length < 1){
                element.controls[i].controls.estimatedCost.setValue('Cargar precios sugeridos');
              } else {
                const avgInstagramFeedPriceMacro = (objectPricesMacro[0].instagram_feed_price_min + objectPricesMacro[0].instagram_feed_price_max)/2;
                const avgInstagramStoryPriceMacro = (objectPricesMacro[0].instagram_story_price_min + objectPricesMacro[0].instagram_story_price_max)/2;
                const avgInstagramReelPriceMacro = (objectPricesMacro[0].instagram_reel_price_min + objectPricesMacro[0].instagram_reel_price_max)/2;
                const avgYoutubeVideoPriceMacro = (objectPricesMacro[0].youtube_video_exclusive_price_min + objectPricesMacro[0].youtube_video_exclusive_price_max)/2;
                const avgYoutubeMencionPriceMacro = (objectPricesMacro[0].youtube_video_mention_price_min + objectPricesMacro[0].youtube_video_mention_price_max)/2;
                const avgTiktokVideoPriceMacro = (objectPricesMacro[0].tiktok_video_price_min + objectPricesMacro[0].tiktok_video_price_max)/2;
                element.controls[i].controls.estimatedCost.setValue((element.controls[i].controls.feed.value * avgInstagramFeedPriceMacro + element.controls[i].controls.stories.value * avgInstagramStoryPriceMacro +
                  element.controls[i].controls.reels.value * avgInstagramReelPriceMacro + element.controls[i].controls.videos.value * avgYoutubeVideoPriceMacro
                  + element.controls[i].controls.menciones.value * avgYoutubeMencionPriceMacro + element.controls[i].controls.videosTiktok.value * avgTiktokVideoPriceMacro)*element.controls[i].controls.quantity.value );
              }
              break;
            case 'Celebrity':
              const objectPricesCeleb = suggestedPrices.filter(prices => prices.country === countrySelected && prices.creator_level === 'Celebrity');
              if(objectPricesCeleb.length < 1){
                element.controls[i].controls.estimatedCost.setValue('Cargar precios sugeridos');
              } else {
                const avgInstagramFeedPriceCeleb = (objectPricesCeleb[0].instagram_feed_price_min + objectPricesCeleb[0].instagram_feed_price_max)/2;
                const avgInstagramStoryPriceCeleb = (objectPricesCeleb[0].instagram_story_price_min + objectPricesCeleb[0].instagram_story_price_max)/2;
                const avgInstagramReelPriceCeleb = (objectPricesCeleb[0].instagram_reel_price_min + objectPricesCeleb[0].instagram_reel_price_max)/2;
                const avgYoutubeVideoPriceCeleb = (objectPricesCeleb[0].youtube_video_exclusive_price_min + objectPricesCeleb[0].youtube_video_exclusive_price_max)/2;
                const avgYoutubeMencionPriceCeleb = (objectPricesCeleb[0].youtube_video_mention_price_min + objectPricesCeleb[0].youtube_video_mention_price_max)/2;
                const avgTiktokVideoPriceCeleb = (objectPricesCeleb[0].tiktok_video_price_min + objectPricesCeleb[0].tiktok_video_price_max)/2;
                element.controls[i].controls.estimatedCost.setValue((element.controls[i].controls.feed.value * avgInstagramFeedPriceCeleb + element.controls[i].controls.stories.value * avgInstagramStoryPriceCeleb +
                  element.controls[i].controls.reels.value * avgInstagramReelPriceCeleb + element.controls[i].controls.videos.value * avgYoutubeVideoPriceCeleb
                  + element.controls[i].controls.menciones.value * avgYoutubeMencionPriceCeleb + element.controls[i].controls.videosTiktok.value * avgTiktokVideoPriceCeleb)*element.controls[i].controls.quantity.value );
              }
              break;
            }
        }else{
            element.controls[i].controls.estimatedCost.setValue('Debe seleccionar Pais');
        }
      }
    });
  }

  async fetchDataSuggestedPrices() {
    try {
      const all = await this.suggestedPriceservice.httpAll().toPromise();
      this.suggestedPricesData = all;
      this.getEstimatedCost();
    } catch(error: unknown){
      console.log(error);
    }
  }

  statusChange(event: string): void {
    switch (event) {
      case 'Approved':
      case 'Aprobado':
      case 'Aprovado':
        this.campaign.status = CampaignStatus.Approved;
        this.status = 'Approved';
        break;
      case 'Rejected':
      case 'Rechazado':
      case 'Concluído':
        this.campaign.status = CampaignStatus.Rejected;
        this.status = 'Rejected';
        break;
      case 'Finished':
      case 'Finalizado':
      case 'Rejeitado':
        this.campaign.status = CampaignStatus.Finished;
        this.status = 'Finished';
        break;
      case 'Pending/Update Requested':
        this.campaign.status = CampaignStatus.Pending;
        this.status = 'Pending';
        break;
      case 'Prospect':
      case 'Prospecto':
      case 'Folheto':
        this.campaign.status = CampaignStatus.Prospect;
        this.status = 'Prospect';
        break;
      case 'Draft':
      case 'Borrador':
      case 'Rascunho':
          this.campaign.status = CampaignStatus.Draft;
          this.status = 'Draft';
          break;
    }
  }

  getCategories(): void {
    this.categories = [];
    this.appArtwork = null;
    this.appTitle = null;
    this.campaign.appCategories = [];
    return;
    // if (this.campaign.previewUrl.includes('apps.apple.com')) {
    //   const id = this.campaign.previewUrl.split('/').pop().substr(2);
    //   if (id) {
    //     this.cs.getAppDetails(id, 'IOS').subscribe(x => this.handleAppInfo(x, 'IOS'));
    //     return;
    //   }
    // } else if (this.campaign.previewUrl.includes('play.google.com')) {
    //   const href = this.campaign.previewUrl;
    //   const reg = new RegExp('[?&]' + 'id' + '=([^&#]*)', 'i');
    //   const queryString = reg.exec(href);
    //   const id = queryString ? queryString[1] : null;
    //   if (id) {
    //     this.cs.getAppDetails(id, 'android').subscribe(x => this.handleAppInfo(x, 'android'));
    //     return;
    //   }
    // }
    // this.campaign.categories.forEach(x => this.categories.push(x));
  }

  private handleAppInfo(info: any, os: string) {
    if (os === 'IOS') {
      const IOScats = this.categoryList.filter(cat => cat.deviceTypeID === 'IOS');
      Array.from(info.genres).forEach((x: string) => {
        const connectCat = IOScats.find(z => z.description.toLowerCase() === x.toLowerCase());
        if (connectCat) {
          this.categories.push(x);
          this.campaign.appCategories.push(connectCat.categoryID);
        }
      });
      this.appArtwork = info.artworkUrl512;
      this.appTitle = info.trackCensoredName;
    }
    if (os === 'android') {
      const ANDcats = this.categoryList.filter(cat => cat.deviceTypeID === 'AND');
      Array.from([info.category]).forEach((x: string) => {
        const connectCat = ANDcats.map(z => z).find(z => z.description.toLowerCase() === x.toLowerCase());
        if (connectCat) {
          this.categories.push(x);
          this.campaign.appCategories.push(connectCat.categoryID);
        }
      });
      this.appArtwork = info.icon;
      this.appTitle = info.title;
    }
  }

  ownerChanged(): void {
        // Is it lemmonet and brand not lemmonet or unassinged? Then only draft is allowed
    this.ownerIsLemOrUnassigned = this.companyList && this.campaign
        && ((this.campaign.companyId === this.companyList.find(x => x.legalName === 'Lemmonet')?.id
          && this.campaign.form.value.brand.toLowerCase() !== 'lemmonet')
          || this.campaign.companyId === this.companyList.find(x => x.legalName === 'Unassigned')?.id);

    if (this.us.isAdmin() && this.campaign) {
      this.companyBrandList = [];
      this.newBrand = true;
      if (this.f['brand'].value) {
        this.f['brand'].setValue(null);
      }

      this.bs.getCompanyBrands(this.campaign.companyId).subscribe(brands => {
        this.companyBrandList = brands;
      });
    }

  }

  async changeGender(): Promise<void> {
    const male = await this.campaign.form.get('genderMale').value;
    const female = await this.campaign.form.get('genderFemale').value;
    const nonbinary = await this.campaign.form.get('genderNonBinary').value;
    const genders = [];
    if (male) {
      genders.push('m');
    }
    if (female) {
      genders.push('f');
    }
    if (nonbinary) {
      genders.push('nb');
    }
    this.campaign.creatorsGender = genders.length > 0 ? genders.join(',') : null;
  }

  public updateCostValues(ind: number){
    this.updatePlannerValues();
    const targetCost = this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.targetCostTable.value;
    const quantityCost = this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.quantityCost.value;
    this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.cost.setValue(targetCost/(quantityCost/1000));
  }

  public updateQuantityValues(ind: number){
    this.updatePlannerValues();
    const targetCost = this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.targetCostTable.value;
    const cost = this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.cost.value;
    this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.quantityCost.setValue((targetCost/cost)*1000);
  }

  public updateReachValues(){
    this.updatePlannerValues();
    const cost = this.campaign.form.get('cost').value;
    const quantity = this.campaign.form.get('quantity').value;
    this.campaign.form.get('cost').setValue(this.targetCost/(quantity/1000));
    this.campaign.form.get('quantity').setValue((this.targetCost/cost)*1000);
  }

  private updatePlannerValues(): void {
    const cpm = this.campaign.form.get('cpm').value;
    const budgetGross = this.campaign.form.get('budgetGross').value;
    const commission = this.campaign.form.get('commission').value;
    const tax = this.campaign.form.get('tax').value;
    const profit = this.campaign.form.get('profit').value;
    this.shippingCost = this.campaign.form.get('shippingCost').value;
    this.agencyShippingCostFee = this.campaign.form.get('agencyShippingCostFee').value;
    this.campaign.form.get('goal').setValue(cpm > 0 ? (budgetGross / cpm) * 1000 : 0);
    this.profitNet = budgetGross * (profit / 100);
    this.targetCost = ((budgetGross - (budgetGross * (profit / 100)) - budgetGross * (commission / 100))-budgetGross * (tax / 100));
    this.updatePlannerValuesTable();
  }

  private updatePlannerValuesTable(ind?: number): void{
    const cpm = this.campaign.form.get('cpm').value ?? 0;
    const commission = this.campaign.form.get('commission').value;
    const tax = this.campaign.form.get('tax').value;
    const profit = this.campaign.form.get('profit').value;
    this.shippingCost = this.campaign.form.get('shippingCost').value;
    this.agencyShippingCostFee = this.campaign.form.get('agencyShippingCostFee').value;
    if(ind !== undefined){
      const budgetTable = isNaN(this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.budgetTable.value) ? 0 :
      this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.budgetTable.value;
      this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.budgetTable.setValue(budgetTable);
      const impresionsGoalTable = cpm > 0 ? (budgetTable / cpm) * 1000 : 0;
      this.profitNet = budgetTable * (profit / 100);
      const targetCostTable = ((budgetTable - (budgetTable * (profit / 100)) - budgetTable * (commission / 100))-budgetTable * (tax / 100));
      this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.profitNet.setValue(this.profitNet);
      this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.impresionsGoalTable.setValue((isNaN(impresionsGoalTable) ? 0 : impresionsGoalTable));
      this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.targetCostTable.setValue(targetCostTable ? targetCostTable : 0);
    } else {
      for(let i = 0; i < this.influencerPlannerArray.length; i++){
        const budgetTable = isNaN(this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.budgetTable.value) ? 0 :
        this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.budgetTable.value;
        this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.budgetTable.setValue(budgetTable);
        this.profitNet = budgetTable * (profit / 100);
        const targetCostTable = ((budgetTable - (budgetTable * (profit / 100)) - budgetTable * (commission / 100))-budgetTable * (tax / 100));
        const impresionsGoalTable = cpm > 0 ? (budgetTable / cpm) * 1000 : 0;
        this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.profitNet.setValue(this.profitNet);
        this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.targetCostTable.setValue(targetCostTable ? targetCostTable : 0);
        this.influencerPlannerArray[i].controls['tablePlannerInputs'].controls.impresionsGoalTable.setValue((isNaN(impresionsGoalTable) ? 0 : impresionsGoalTable));
      }
    }
  }

  public checkTarget(ind: number){
    return this.influencerPlannerArray[ind].controls['tablePlannerInputs'].controls.targetSelect.value;
  }

  private updatePlannerTable(ind: number, i: number): void {
      const followers = this.influencerPlannerArray[ind].controls[i].controls.followers.value;
      const quantity = this.influencerPlannerArray[ind].controls[i].controls.quantity.value;
      const feed = this.influencerPlannerArray[ind].controls[i].controls.feed.value;
      const stories = this.influencerPlannerArray[ind].controls[i].controls.stories.value;
      const reels = this.influencerPlannerArray[ind].controls[i].controls.reels.value;
      const videosYoutube = this.influencerPlannerArray[ind].controls[i].controls.videos.value;
      const menciones = this.influencerPlannerArray[ind].controls[i].controls.menciones.value;
      const videosTiktok = this.influencerPlannerArray[ind].controls[i].controls.videosTiktok.value;
      this.influencerPlannerArray[ind].controls[i].controls.estimatedImpressions.setValue(Math.round(quantity * followers * (feed * 0.2 + stories * 0.15 + reels * 0.25
        + videosYoutube * 0.2 +  menciones * 0.2 +  videosTiktok * 0.2)));
        this.influencerPlannerArray[ind].controls[i].controls.estimatedReach.setValue(Math.round(quantity * followers * (feed * 0.2 + stories * 0.15 + reels * 0.25
          + videosYoutube * 0.2 +  menciones * 0.2 +  videosTiktok * 0.2)));
        this.getEstimatedCost();
      this.plannerTotalQuantity[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('quantity').value, 0);
      this.plannerTotalFollowers[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + (isNaN(v.get('followers').value)? 0 : v.get('followers').value) , 0);;
      this.plannerTotalFeed[ind]= this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('feed').value, 0);
      this.plannerTotalStories[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('stories').value, 0);
      this.plannerTotalReels[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('reels').value, 0);
      this.plannerTotalVideosYoutube[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('videos').value, 0);
      this.plannerTotalMenciones[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('menciones').value, 0);
      this.plannerTotalVideosTiktok[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + v.get('videosTiktok').value, 0);
      this.plannerTotalEstimatedImpressions[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + (isNaN(v.get('estimatedImpressions').value)? 0 : v.get('estimatedImpressions').value) , 0);
      this.plannerTotalEstimatedReach[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + (isNaN(v.get('estimatedReach').value)? 0 : v.get('estimatedReach').value) , 0);
      this.plannerTotalEstimatedCost[ind] = this.influencerPlannerArray[ind].controls.reduce((sum, v) => sum + (isNaN(v.get('estimatedCost').value)? 0 : v.get('estimatedCost').value) , 0);
  }

  addCampaignScenario() {
    const scenarios = this.campaign.form.get('scenarios') as FormArray;
    scenarios.push(this.fb.group({
      type: '',
      investment:  [null, Validators.pattern(/^[0-9]*([.,][0-9]*)?$/)],
      pricingModel: '',
      impressions: [null, Validators.pattern(/^[0-9]*$/)],
      influencers: '',
      contents: [null, Validators.pattern(/^[0-9]*$/)],
      platforms: [[]]
    }));
  }
  removeCampaignScenario() {
    const scenarios = this.campaign.form.get('scenarios') as FormArray;
    scenarios.removeAt(scenarios.length - 1);
  }
  addScenarioPlatform(scenarioIndex: number, platform: string) {
    const scenarios = this.campaign.form.get('scenarios') as FormArray;
    const scenario = scenarios.at(scenarioIndex).value as CampaignScenario;
    if (!scenario.platforms.find(p => p === platform)) {
      scenario.platforms.push(platform);
    } else {
      scenario.platforms = scenario.platforms.filter(p => p !== platform);
    }
  }

  private addPlanner(): void{
    const line = new FormArray ([]);
    this.activeTypes = this.activeTypes.filter((element, index) => this.activeTypes.indexOf(element) === index);

    this.activeTypes.forEach((key, value)=>{
      line.push(this.fb.group({
        influencerType: [key.charAt(0).toUpperCase() + key.slice(1)],
        followers: [this.getFollowersByType(key), Validators.min(0)],
        quantity: [0, Validators.min(0)],
        feed: [0, Validators.min(0)],
        stories: [0, Validators.min(0)],
        reels: [0, Validators.min(0)],
        videos: [0, Validators.min(0)],
        menciones: [0, Validators.min(0)],
        videosTiktok: [0, Validators.min(0)],
        estimatedImpressions: [{ value: 0, disabled: true }],
        estimatedReach: [{ value: 0, disabled: true }],
        estimatedCost: [{ value: 0, disabled: true }],
      }));
    });

    this.planner.push('New table');

    const index = this.planner.length-1;

    this.influencerPlannerArray[this.influencerPlannerArray.length] = line;
    this.influencerPlannerArray[this.influencerPlannerArray.length-1].controls.tablePlannerInputs = (this.fb.group({
      budgetTable: [0, Validators.min(0)],
      comission: [0, Validators.min(0)],
      tax: [0, Validators.min(0)],
      profit: [0, Validators.min(0)],
      shippingCost: [this.campaign.shippingCost, Validators.min(0)],
      agencyShippingCostFee: [this.campaign.agencyShippingCostFee, Validators.min(0)],
      profitNet: [0, Validators.min(0)],
      targetSelect: [''],
      quantityCost: [0, Validators.min(0)],
      cost: [0, Validators.min(0)],
      impresionsGoalTable: [0, Validators.min(0)],
      targetCostTable: [0, Validators.min(0)],
      isActive: [1, Validators.min(0)],
      table: [this.influencerPlannerArray.length, Validators.min(0)],
      tableName: ['',Validators.pattern('^[a-zA-Z]+$')]
    }));
    this.influencerPlannerArray[this.influencerPlannerArray.length-1].controls.tablePlannerInputs.controls.targetSelect.setValue(ReachTarget.reach);

    for(let i = 0; i < this.influencerPlannerArray.length-1; i++){
      this.influencerPlannerArray[i].controls.tablePlannerInputs.controls.isActive.setValue(0);
    }

    this.plannerTotalQuantity[index] = 0;
    this.plannerTotalFollowers[index] = 0;
    this.plannerTotalFeed[index]= 0;
    this.plannerTotalStories[index] = 0;
    this.plannerTotalReels[index] = 0;
    this.plannerTotalVideosYoutube[index] = 0;
    this.plannerTotalMenciones[index] = 0;
    this.plannerTotalVideosTiktok[index] = 0;
    this.plannerTotalEstimatedImpressions[index] = 0;
    this.plannerTotalEstimatedReach[index] = 0;
    this.plannerTotalEstimatedCost[index] = 0;

    for(let i=0; i<this.influencerPlannerArray.length; i++){
      this.influencerPlannerArray[i].controls.forEach((element, index) => {
        this.updatePlannerTable(i, index);
      });
    }
  }

  private removePlanner(): void{
    this.planner.pop();
    this.influencerPlannerArray.pop();
      this.influencerPlannerArray[this.influencerPlannerArray.length-1].controls.tablePlannerInputs.controls.isActive.setValue(1);
    }

  private activateTable(index: number): void{
    for(let i = 0; i < this.influencerPlannerArray.length; i++){
      if(i == index){
        this.influencerPlannerArray[index].controls.tablePlannerInputs.controls.isActive.setValue(1);
      } else {
        this.influencerPlannerArray[i].controls.tablePlannerInputs.controls.isActive.setValue(0);
      }
    }
  }

  public onTargetChange(ind: number, targetSelect: string){
    this.influencerPlannerArray[ind].controls.tablePlannerInputs.controls.targetSelect.setValue(targetSelect);
    for(let i=0; i<this.influencerPlannerArray.length; i++){
      this.influencerPlannerArray[i].controls.forEach((element, index) => {
        this.updatePlannerTable(i, index);
      });
    }
  }

  private initializePlanner(): any{
    let index = 0;
    let indexTable = 0;
    let lastTable = 1;
    this.currencySymbol = '-';
    let tablePlannerInputs = new FormArray ([]);
    const initialize = 1;
    this.campaign.form.controls.influencerPlanner['controls'].forEach(rowTable => {
      const line = new FormArray ([]);
      const tablePlannerInputsForm = new FormArray ([]);
      const influencerType = rowTable.controls.influencerType.value;
      const followers = rowTable.controls.followers.value;
      const quantity = rowTable.controls.quantity.value;
      const feed = rowTable.controls.feed.value;
      const stories = rowTable.controls.stories.value;
      const reels = rowTable.controls.reels.value;
      const videosYoutube = rowTable.controls.videos.value;
      const menciones = rowTable.controls.menciones.value;
      const estimatedImpressions = rowTable.controls.estimatedImpressions.value;
      const estimatedReach = rowTable.controls.estimatedReach.value;
      const estimatedCost = rowTable.controls.estimatedCost.value;
      const videosTiktok = rowTable.controls.videosTiktok.value;
      const targetCostTable = rowTable.controls.targetCostTable.value;
      const shippingCost = rowTable.controls.shippingCost.value;
      const agencyShippingCostFee = rowTable.controls.agencyShippingCostFee.value;
      const budgetTable = rowTable.controls.budgetTable.value;
      const profitNet = rowTable.controls.profitNet.value;
      const targetSelect = rowTable.controls.targetSelect.value;
      const cost = rowTable.controls.cost.value;
      const quantityCost = rowTable.controls.quantityCost.value;
      const impresionsGoalTable = rowTable.controls.impresionsGoalTable.value;
      const active = rowTable.controls.active.value;
      const table = rowTable.controls.table.value;
      const name = rowTable.controls.name.value;

      const tableValueIndex = rowTable.controls.table.value;

      this.activeTypes.push(influencerType);

      tablePlannerInputsForm.push(this.fb.group({
        budgetTable: [budgetTable, Validators.min(0)],
        impresionsGoalTable: [impresionsGoalTable, Validators.min(0)],
        targetCostTable: [targetCostTable, Validators.min(0)],
        shippingCost: [shippingCost, Validators.min(0)],
        agencyShippingCostFee: [agencyShippingCostFee, Validators.min(0)],
        profitNet: [profitNet, Validators.min(0)],
        targetSelect: [targetSelect],
        cost: [cost, Validators.min(0)],
        quantityCost: [quantityCost, Validators.min(0)],
        isActive: [active, Validators.min(0)],
        table: [table, Validators.min(0)],
        tableName: [name,Validators.pattern('^[a-zA-Z]+$')]
      }));

      tablePlannerInputs = tablePlannerInputsForm;

      line.push(this.fb.group({
        influencerType: [influencerType],
        followers: [followers, Validators.min(0)],
        quantity: [quantity, Validators.min(0)],
        feed: [feed, Validators.min(0)],
        stories: [stories, Validators.min(0)],
        reels: [reels, Validators.min(0)],
        videos: [videosYoutube, Validators.min(0)],
        menciones: [menciones, Validators.min(0)],
        videosTiktok: [videosTiktok, Validators.min(0)],
        estimatedImpressions: [{ value: estimatedImpressions, disabled: true }],
        estimatedReach:[{ value: estimatedReach, disabled: true }],
        estimatedCost: [{ value: estimatedCost, disabled: true }],
      }));
      if(index === 0){
        this.influencerPlannerArray[tableValueIndex-1] = line;
        this.influencerPlannerArray[tableValueIndex-1].controls.tablePlannerInputs = tablePlannerInputs.controls[0];
        if(indexTable <= 1){
          this.toggleCreatorType(influencerType.charAt(0).toLowerCase() + influencerType.slice(1), initialize);
        }
        lastTable = tableValueIndex;
        index++;
        indexTable++;
      }else if(tableValueIndex == lastTable){
        this.influencerPlannerArray[lastTable-1].controls[this.influencerPlannerArray[lastTable-1].controls.length] = line.controls[0];
        if(indexTable <= 1){
          this.toggleCreatorType(influencerType.charAt(0).toLowerCase() + influencerType.slice(1), initialize);
        }
        lastTable = tableValueIndex;
        index++;
      }else{
        this.influencerPlannerArray[tableValueIndex-1] = line;
        this.influencerPlannerArray[tableValueIndex-1].controls.tablePlannerInputs = tablePlannerInputs.controls[0];
        lastTable = tableValueIndex;
        indexTable++;
      }
    });

    for(let i=0; i<this.influencerPlannerArray.length; i++){
      this.planner.push('new table');
      this.onTargetChange(i, this.influencerPlannerArray[i].controls.tablePlannerInputs.controls.targetSelect.value);
      this.influencerPlannerArray[i].controls.forEach((element, index) => {
        this.updatePlannerTable(i, index);
      });
    }

    this.getEstimatedCost();

    return this.influencerPlannerArray;
  }
}
