import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Company } from '../../../models/company.model';
import { BrandService } from '../../../services/brand.service';

@Component({
  selector: 'x-edit-company-dialog',
  templateUrl: './edit-company-dialog.component.html',
  styleUrls: ['./edit-company-dialog.component.css']
})
export class EditCompanyDialogComponent implements OnInit {

  public companyForm: FormGroup;
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public company: Company,
    public dialogRef: MatDialogRef<EditCompanyDialogComponent>,
    private brandService: BrandService,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      legalName: new FormControl(this.company.legalName, Validators.required),
      brandName: new FormControl(this.company.brandName, Validators.required),
      companyLogo: new FormControl(this.company.logo, Validators.required),
      connectId: new FormControl(this.company.connectId) 
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public editCompany(): void {
    this.loading.next(true);
    const companyFormControls = this.companyForm.controls;
    this.company.brandName = companyFormControls.brandName.value;
    this.company.legalName = companyFormControls.legalName.value;
    if (this.company.logo !== companyFormControls.companyLogo.value) {
      this.company.logo = companyFormControls.companyLogo.value;
    }
    this.company.connectId = companyFormControls.connectId.value; 
    this.brandService.editCompany(this.company)
      .subscribe({
        next: () => {
          this.loading.next(false);
        },
        error: (error: HttpErrorResponse) => {
          this.loading.next(false);
          console.log(error);
        }
      });
  }

}

