import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationDTO } from '../../../models/notificationDTO.model';
import { Notification } from '../../../models/notification.model';
import { NotificationService } from '../../../services/notification.service';
import { NotificationSubject } from '../../../models/notificationSubject.model';
import { BrandService } from '../../../services/brand.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  userNotifications: NotificationDTO[];

  constructor(
    private notificationService: NotificationService,
    private readonly cdref: ChangeDetectorRef,
    private brandService: BrandService,
    ) { }

  ngOnInit(): void {
    this.brandService.getUser().subscribe(userData => {
      this.setNotifications(userData.id);
    });
    this.notificationService.listenNotifications().subscribe((notificationReceive: NotificationSubject) => {
      const newNotification = notificationReceive.newNotification;
      if (notificationReceive.newNotificationArr) {
        this.userNotifications = notificationReceive.newNotificationArr;
        this.cdref.detectChanges();
        return;
      }
      if (newNotification.id && !this.userNotifications.includes(newNotification)) {
        this.userNotifications.push(newNotification);
        this.notificationService.setNotReadedNotifications(this.userNotifications);
      }
    });
  }

  private setNotifications(userId: number): void {
    
    if ( environment.notificationsOff ) { return; } // stop loop on local

    const notReadedNotifications: Array<NotificationDTO> = [];
    const getDbNotificationsData$: Observable<Notification[]> = this.notificationService.getUserUnreadedNotifications(userId);
    getDbNotificationsData$
      .toPromise().then(x => {
        x.forEach((dbNotificationData: Notification) => {
          notReadedNotifications.push({
            id: dbNotificationData.id,
            title: dbNotificationData.title,
            body: dbNotificationData.message,
            readed: !dbNotificationData.unread,
            data: JSON.parse(dbNotificationData.payload),
            time: dbNotificationData.createdAt,
          });
      });
      this.userNotifications = notReadedNotifications;
      this.notificationService.setNotReadedNotifications(notReadedNotifications);
    });
  }
}
