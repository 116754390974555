import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Campaign } from '../../../models/campaign.model';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CampaignService } from '../../../services/campaign.service';

@Component({
  selector: 'app-cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss']
})
export class CardsTableComponent implements OnInit, OnDestroy {

  eventsSubscription: Subscription;
  @Input() refreshProntou: Observable<Array<Campaign>>;
  @Input() initialCampaigns: Array<Campaign>;
  campaignList: Array<Campaign>;
  filteredCampaigns: Array<Campaign>;
  lastFilterTerm = '';
  tiktok = faTiktok;

  constructor(
    public router: Router,
    private readonly campaingService: CampaignService
    ) { }

  ngOnInit(): void {
    this.campaignList = this.initialCampaigns;
    this.applyFilter();
    this.eventsSubscription = this.refreshProntou.subscribe((data) => {
      this.campaignList = data;
      this.cardFilter(this.lastFilterTerm);
    });
  }

  private applyFilter(): void {
    this.campaingService.getCardCampaignName().subscribe({
      next: (filterTerm: string) => {
        this.cardFilter(filterTerm);
      }, error: (error: Error) => {
        console.log(error);
      }
    });
  }

  private cardFilter(filterTerm: string): void {
    if (filterTerm !== '') {
      this.filteredCampaigns = this.campaignList.filter(  x =>
        x.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
        x.brand.toLowerCase().includes(filterTerm.toLowerCase()));
    } else {
      this.filteredCampaigns = this.campaignList;
    }
    this.lastFilterTerm = filterTerm;
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }
}
