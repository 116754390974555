import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarViewType } from '../../../../../enum/calendar-view-type.enum';

@Component({
  selector: 'app-campaign-calendar-header',
  templateUrl: './campaing-calendar-header.component.html',
  styleUrls: ['./campaing-calendar-header.component.scss'],
})
export class CampaignCalendarHeaderComponent implements OnInit {
  @Input() public view: BehaviorSubject<CalendarViewType> = new BehaviorSubject<CalendarViewType>(undefined);

  @Input() viewDate: Date = new Date();

  @Input() public firstDate: Date = new Date();

  @Input() public lastDate: Date = new Date();

  @Input() locale = navigator.language;

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {}

  public firstContent(): void {
    this.viewDate = this.firstDate;
    this.viewDateChange.next(this.viewDate)
  }

  public lastContent(): void {
    this.viewDate = this.lastDate;
    this.viewDateChange.next(this.viewDate)
  }

}
