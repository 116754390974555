import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Creator } from 'src/app/models/creator.model';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { SocialNetworks } from 'src/app/models/SocialNetworks';
import { Audience } from 'src/app/models/Interfaces/Audience';
import { UserService } from 'src/app/services/user.service';
import { CountriesMap } from 'src/app/models/countriesMap.model';
import { CountryList } from 'src/app/models/country';
import { InstagramAudience } from '../../../models/instagram.model';
import { AudienceDetailsDialogComponent } from '../../campaign/campaign/audience/audience-details-dialog/audience-details-dialog.component';
import { Submission } from '../../../models/submission.model';
import { MatDialog } from '@angular/material/dialog';
import { CreatorService } from '../../../services/creator.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-creator-details-card',
  templateUrl: './creator-details-card.component.html',
  styleUrls: ['./creator-details-card.component.css']
})
export class CreatorDetailsCardComponent implements OnInit {

  platforms = SocialNetworks;
  tiktokIcon = faTiktok;

  @Input() creator: Creator;
  @Input() canSelect: boolean;
  @Input() creatorSubmissions: Submission[];
  @Input() invited: boolean;

  selectedPlatform: string;
  selectedAudience: Audience;
  instagramAud: InstagramAudience;
  profilePicture: string;
  username: string;
  newPlatformUsername: string;

  @Output() edit = new EventEmitter<string>();
  @Output() precampaign = new EventEmitter<string>();
  @Output() history = new EventEmitter<Creator>();
  @Output() delete = new EventEmitter<Creator>();
  @Output() verify = new EventEmitter<Creator>();
  @Output() unverify = new EventEmitter<Creator>();
  @Output() reject = new EventEmitter<Creator>();
  @Output() newPlatform = new EventEmitter<{ username: string; platform: string }>();
  @Output() selected = new EventEmitter<boolean>();

  constructor(private readonly creatorService : CreatorService,public userService: UserService, private dialog: MatDialog) { }

  ngOnInit(): void {
    if(this.creator.hasInstagramAudience()) {
      this.selectedPlatform = SocialNetworks.Instagram;
    } else if(this.creator.hasTikTokAudience()) {
      this.selectedPlatform = SocialNetworks.TikTok;
    } else if(this.creator.hasYoutubeAudience()) {
      this.selectedPlatform = SocialNetworks.YouTube;
    }
    this.updateCardMetrics();
  }

  selectPlatform(platform: string) {
    this.selectedPlatform = platform;
    this.updateCardMetrics();
  }

  updateCardMetrics() {
    switch(this.selectedPlatform) {
      case SocialNetworks.Instagram:
        if(this.creator.hasInstagramAudience()) {
          this.profilePicture = this.creator.instagram.profilePicture;
          this.username = this.creator.instagram.username;
          this.selectedAudience = this.creator.instagram.audience;
        } else {
          this.selectedAudience = null;
        }
        break;
      case SocialNetworks.YouTube:
        if(this.creator.hasYoutubeAudience()) {
          this.profilePicture = this.creator.youtube.profilePicture;
          this.username = this.creator.youtube.username;
          this.selectedAudience = this.creator.youtube.audience;
        } else {
          this.selectedAudience = null;
        }
        break;
      case SocialNetworks.TikTok:
        if(this.creator.hasTikTokAudience()) {
          this.profilePicture = this.creator.tikTok.profilePicture;
          this.username = this.creator.tikTok.username;
          this.selectedAudience = this.creator.tikTok.audience;
        } else {
          this.selectedAudience = null;
        }
        break;
      default:
        this.profilePicture = '../../../../assets/img/person.png';
        this.username = this.creator.fullName;
        this.selectedAudience = null;
        break;
    }
  }

  editCreator(socialNetwork: string) {
    this.edit.emit(socialNetwork);
  }

  public open(): void {
    if (this.creator.audienceCount() === 0) {
      return;
    }
    this.dialog.open(AudienceDetailsDialogComponent, {
      width: '1305px',
      height: '782px',
      panelClass: 'audience-dialog-container',
      data: {
        creatorSubmissions : this.creatorSubmissions,
        updateProfile: true,
        creator : this.creator
      }});
  }

  editPrecampaign(socialNetwork: string) {
    this.precampaign.emit(socialNetwork);
  }

  verifyTiktok() {
    this.verify.emit(this.creator);
  }

  rejectTiktok() {
    this.reject.emit(this.creator);
  }

  unverifyTiktok() {
    this.unverify.emit(this.creator);
  }

  addPlatform() {
    this.newPlatform.emit({ username: this.newPlatformUsername, platform: this.selectedPlatform });
  }

  cardSelected(checked: boolean) {
    this.selected.emit(checked);
  }

  getCountryCode(country: string) {
    return CountryList.countryList.find(c => 
      c.country.toLowerCase() === country.toLowerCase()
      || c.code2.toLowerCase() === country.toLowerCase()
      )?.code2 ?? country.toLowerCase().substring(0,2);
  }
}
