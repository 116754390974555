import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.css']
})
export class ErrorDisplayComponent implements OnInit {

  @Input() errors: any;

  constructor() { }

  ngOnInit(): void { }

  isEmpty(val: any): boolean {
    return val === null || val === undefined || val === '' || Array.isArray(val) && val.length === 0 || Object.keys(val).length === 0;
  }
}
