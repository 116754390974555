import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { NegotiationOffer } from 'src/app/models/negotiationOffer.model';
import { NegotiationService } from 'src/app/services/negotiation.service';

@Component({
  selector: 'app-negotiation-make-offer',
  templateUrl: './negotiation-make-offer.component.html',
  styleUrls: ['./negotiation-make-offer.component.scss']
})
export class NegotiationMakeOfferComponent implements OnChanges {

  @Input()
  creatorApproval: CreatorApproval;

  @Input()
  counterOffer?: NegotiationOffer;

  offerForm: FormGroup = this.fb.group({});
  loading = false;

  @Output()
  offerSent = new Subject<NegotiationOffer>();

  constructor(
    private fb: FormBuilder,
    private ts: TranslateService,
    private negotiationService: NegotiationService
  ) { }

  ngOnChanges(): void {
    this.buildOfferForm();
  }

  buildOfferForm() {
    const formControls: { [controlName: string]: any} = {
      suggestedPrice: [!this.counterOffer, Validators.required],
      customPrice: [!!this.counterOffer, Validators.required],
    };
    if(this.creatorApproval.creator.hasInstagram()) {
      const instagramPlanner = this.creatorApproval.planner.find(p => p.platform === 'instagram' && p.approved);
      formControls['instagramContent'] = [this.counterOffer?.instagramPrice || instagramPlanner, Validators.required];
      formControls['instagramFeeds'] = [this.counterOffer?.instagramFeeds ?? instagramPlanner?.content.feeds ?? 0, Validators.pattern(/^\d+$/)];
      formControls['instagramReels'] = [this.counterOffer?.instagramReels ?? instagramPlanner?.content.reels ?? 0, Validators.pattern(/^\d+$/)];
      formControls['instagramStories'] = [this.counterOffer?.instagramStories ?? instagramPlanner?.content.stories ?? 0, Validators.pattern(/^\d+$/)];
      formControls['instagramPrice'] = [this.counterOffer?.instagramPrice ?? 0, Validators.pattern(/^\d+$/)];
    }
    if(this.creatorApproval.creator.hasTikTok()) {
      const tiktokPlanner = this.creatorApproval.planner.find(p => p.platform === 'tiktok' && p.approved);
      formControls['tiktokContent'] = [this.counterOffer?.tiktokPrice || tiktokPlanner, Validators.required];
      formControls['tiktokVideos'] = [this.counterOffer?.tiktokVideos ?? tiktokPlanner?.content.tiktokVideos ?? 0, Validators.pattern(/^\d+$/)];
      formControls['tiktokPrice'] = [this.counterOffer?.tiktokPrice ?? 0, Validators.pattern(/^\d+$/)];
    }
    if(this.creatorApproval.creator.hasYoutube()) {
      const youtubePlanner = this.creatorApproval.planner.find(p => p.platform === 'youtube' && p.approved);
      formControls['youtubeContent'] = [this.counterOffer?.youtubePrice || youtubePlanner, Validators.required];
      formControls['youtubeVideoMentions'] = [
        this.counterOffer?.youtubeVideoMentions ?? youtubePlanner?.content.mentions ?? 0,
        Validators.pattern(/^\d+$/)
      ];
      formControls['youtubeVideoExclusives'] = [
        this.counterOffer?.youtubeVideoExclusives ?? youtubePlanner?.content.exclusives ?? 0,
        Validators.pattern(/^\d+$/)
      ];
      formControls['youtubePrice'] = [this.counterOffer?.youtubePrice ?? 0, Validators.pattern(/^\d+$/)];
    }
    this.offerForm = this.fb.group(formControls);
  }

  getRequestingString(platform: string, offer?: NegotiationOffer): string {
    switch(platform) {
      case 'instagram':
        return `${offer?.instagramFeeds ?? this.offerForm.get('instagramFeeds')?.value ?? 0} ${this.ts.instant('CREATORS.INSIGHTS.FEED')}, 
          ${offer?.instagramStories ?? this.offerForm.get('instagramStories')?.value ?? 0} ${this.ts.instant('CREATORS.INSIGHTS.STORIES')}, 
          ${offer?.instagramReels ?? this.offerForm.get('instagramReels')?.value ?? 0} ${this.ts.instant('CREATORS.INSIGHTS.REELS')}`.toLowerCase();
      case 'youtube':
        return `
          ${offer?.youtubeVideoMentions ??
            this.offerForm.get('youtubeVideoMentions')?.value ?? 0} ${this.ts.instant('CREATORS.VIDEO.MENTIONS')}, 
          ${offer?.youtubeVideoExclusives ??
            this.offerForm.get('youtubeVideoExclusives')?.value ?? 0} ${this.ts.instant('CREATORS.VIDEO.EXCLUSIVES')}`
          .toLowerCase();
      case 'tiktok':
        return `${offer?.tiktokVideos ?? this.offerForm.get('tiktokVideos')?.value ?? 0} ${this.ts.instant('CREATORS.VIDEOS')}`.toLowerCase();
    }
  }

  getSuggestedPrice(platform: string) {
    switch(platform) {
      case 'instagram':
        return (this.offerForm.get('instagramFeeds')?.value ?? 0) * (this.creatorApproval.creator.instagram?.feedPrice ?? 0) +
          (this.offerForm.get('instagramStories')?.value ?? 0) * (this.creatorApproval.creator.instagram?.storyPrice ?? 0) +
          (this.offerForm.get('instagramReels')?.value ?? 0) * (this.creatorApproval.creator.instagram?.reelPrice ?? 0);
      case 'youtube':
        return (this.offerForm.get('youtubeVideoMentions')?.value ?? 0) * (this.creatorApproval.creator.youtube?.videoMentionPrice ?? 0) +
          (this.offerForm.get('youtubeVideoExclusives')?.value ?? 0) * (this.creatorApproval.creator.youtube?.videoExclusivePrice ?? 0);
      case 'tiktok':
        return (this.offerForm.get('tiktokVideos')?.value ?? 0) * (this.creatorApproval.creator.tikTok?.videoPrice ?? 0);
    }
  }

  makeOffer() {
    this.loading = true;
    const instagramContents = this.offerForm.get('instagramContent')?.value ? {
      feeds: this.offerForm.get('instagramFeeds')?.value ?? 0,
      stories: this.offerForm.get('instagramStories')?.value ?? 0,
      reels: this.offerForm.get('instagramReels')?.value ?? 0,
    } : null;
    const tiktokContents = this.offerForm.get('tiktokContent')?.value ? {
      videos: this.offerForm.get('tiktokVideos')?.value ?? 0,
    } : null;
    const youtubeContents = this.offerForm.get('youtubeContent')?.value ? {
      video_mentions: this.offerForm.get('youtubeVideoMentions')?.value ?? 0,
      video_exclusives: this.offerForm.get('youtubeVideoExclusives')?.value ?? 0
    } : null;
    let request: Observable<NegotiationOffer>;
    if(this.offerForm.get('suggestedPrice').value) {
      request = this.negotiationService.makeNegotiationOffer(this.creatorApproval.campaign.companyId,
        this.creatorApproval.campaign.brandId, this.creatorApproval.campaignId, this.creatorApproval.creator.id, null,
        this.creatorApproval.creator.instagram ? this.getSuggestedPrice('instagram') : null,
        this.creatorApproval.creator.tikTok ? this.getSuggestedPrice('tiktok') : null,
        this.creatorApproval.creator.youtube ? this.getSuggestedPrice('youtube') : null,
        instagramContents, tiktokContents, youtubeContents
      );
    } else if(this.offerForm.get('customPrice').value) {
      request = this.negotiationService.makeNegotiationOffer(this.creatorApproval.campaign.companyId,
        this.creatorApproval.campaign.brandId, this.creatorApproval.campaignId, this.creatorApproval.creator.id, null,
        this.offerForm.get('instagramContent')?.value ? +this.offerForm.get('instagramPrice').value : null,
        this.offerForm.get('tiktokContent')?.value ? +this.offerForm.get('tiktokPrice').value : null,
        this.offerForm.get('youtubeContent')?.value ? +this.offerForm.get('youtubePrice').value : null,
        instagramContents, tiktokContents, youtubeContents
      );
    } else {
      return;
    }
    request.subscribe(offer =>  {
      this.loading = false;
      this.offerSent.next(offer);
    }, err => this.loading = false);
  }

  getSuggestedTotalPrice() {
    return (this.offerForm.get('instagramContent')?.value ? this.getSuggestedPrice('instagram') : 0) +
      (this.offerForm.get('youtubeContent')?.value ? this.getSuggestedPrice('youtube') : 0) +
      (this.offerForm.get('tiktokContent')?.value ? this.getSuggestedPrice('tiktok') : 0);
  }

  getTotalPrice() {
    return (this.offerForm.get('instagramPrice')?.value ?? 0) +
      (this.offerForm.get('tiktokPrice')?.value ?? 0) +
      (this.offerForm.get('youtubePrice')?.value ?? 0);
  }

}
