import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Creator } from '../../../../../models/creator.model';
import { Submission } from '../../../../../models/submission.model';
import { TikTokAudience } from '../../../../../models/tiktok.model';
import { TranslateService } from '@ngx-translate/core';
import { SuggestedPrice } from '../../../../../models/suggestedPrice.model';
import { CreatorApproval, CreatorApprovalPlanner } from '../../../../../models/CreatorApproval.model';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Component({
  selector: 'app-social-tiktok-audience',
  templateUrl: './audience-tiktok.component.html',
  styleUrls: ['./audience-tiktok.component.css'],
})
export class AudienceTiktokComponent implements OnInit {
  @Input() public creator: Creator;
  @Input() public creatorSubmissions: Submission[];
  @Input() public suggestedPrices: SuggestedPrice[];
  @Input() public updateProfile: boolean;
  @Input() public creatorApproval?: CreatorApproval;

  public tikTokAud: TikTokAudience;
  public link: string;
  public creatorPrice: SuggestedPrice;
  public labels: string[];
  public maleData: number[];
  public femaleData: number[];
  public cityData: any;
  public countryData: any;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.creatorPrice =
      this.suggestedPrices.find((price) => {
        const followers = this.creator.getFollowers('instagram');
        if (price.creator_level === 'Celebrity') {
          return (
            (followers >= price.followers_min &&
              followers <= price.followers_max) ||
            followers > price.followers_max
          );
        } else if (price.creator_level === 'Micro') {
          return (
            (followers >= price.followers_min &&
              followers <= price.followers_max) ||
            followers < price.followers_min
          );
        }
        return (
          followers >= price.followers_min && followers <= price.followers_max
        );
      }) || new SuggestedPrice();
    if (this.creator.hasTikTokAudience()) {
      this.tikTokAud = this.creator.tikTok.audience;
    }
    this.labels = this.creator.tikTok.audience.gendersChartSeries.map(
      (gender) => gender.name
    );
    this.femaleData = this.creator.tikTok.audience.gendersChartSeries
      .map((gender) => gender.series[1])
      .map((gender) => gender.value);
    this.maleData = this.creator.tikTok.audience.gendersChartSeries
      .map((gender) => gender.series[0])
      .map((gender) => gender.value);
    this.cityData = this.creator.tikTok.audience.audienceCity
      ? Object.entries(this.creator.tikTok.audience.audienceCity)
      : [];
    this.countryData = [];
    const audienceCountry = this.creator.tikTok.audience.audienceCountry;
    if (audienceCountry) {
      this.countryData = Object.entries(audienceCountry).map(([name, value]) => [name, value]);
    }
  }

  public setVideoUrl(id) {
    this.link = 'https://www.tiktok.com/embed/v2/';
    return this.link + id;
  }
}
