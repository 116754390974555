import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CountriesMap } from '../../../models/countriesMap.model';
import { Creator } from '../../../models/creator.model';
import { SocialNetworks } from '../../../models/SocialNetworks';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { InsightImages } from 'src/app/models/instagram.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { inject } from '@angular/core/testing';
import { map, startWith } from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { creatorLevelsAll } from 'src/app/enum/creator-levels.enum';
import { AppService } from 'src/app/services/app.service';
import { Category } from 'src/app/models/categories.model';

@Component({
  selector: 'app-creator-edit',
  templateUrl: './creator-edit.component.html',
  styleUrls: ['./creator-edit.component.css']
})
export class CreatorEditComponent implements OnInit {
  @Input() creator: Creator;
  @Input() socialNetwork;
  @Input() getAudienceEvent: EventEmitter<any>;
  @Output() audienceObject = new EventEmitter<any>();
  countries = CountriesMap.countries;
  gender: string;
  country: string;
  email: FormControl;
  phone: string;
  password: string;
  aboutMe: string;
  creatorLevel: string;
  SocialNetworks = SocialNetworks;
  followersInsightImage: string;

  creatorLevels = creatorLevelsAll();
  categoryCtrl = new FormControl();
  allCategories = [];

  constructor(private as: AlertService, private ts: TranslateService, private AppService: AppService) {
  }
  
  async ngOnInit(): Promise<void> {
    this.gender = this.creator.gender.toLowerCase();
    this.country = this.creator.country;
    this.email = new FormControl(this.creator.email, [Validators.required, Validators.email]);
    this.aboutMe = this.creator.aboutMe;
    this.creatorLevel = this.creator.creatorLevel;
    this.phone = this.creator.phone;
    this.allCategories = this.AppService.configBackend.categories;
    const creatorCategories =  this.creator.categories;
    this.categoryCtrl.setValue(creatorCategories);
  }

  findCategory(value: number | string): Category {
    return Category.find(value, this.allCategories);
  }

  emitAudience(audienceObject: any): void {
    if (this.email.invalid) {
      this.as.emitError(this.ts.instant('LOGIN.FORM.EMAIL.REQUIRED'));
      return;
    } else if (!this.gender) {
      this.as.emitError(this.ts.instant('CAMPAIGN.CREATORS.GENDER.INPUT'));
      return;
    } else if (!this.country) {
      this.as.emitError(this.ts.instant('CAMPAIGN.CREATORS.COUNTRY.INPUT'));
      return;
    }
    audienceObject.categories = this.categoryCtrl.value.join();
    audienceObject.id = this.creator.id;
    audienceObject.country = this.country;
    audienceObject.gender = this.gender;
    audienceObject.email = this.email.value;
    audienceObject.phone = this.phone;
    audienceObject.password = this.password;
    audienceObject.aboutMe = this.aboutMe;
    audienceObject.creatorLevel = this.creatorLevel;
    if (this.followersInsightImage) {
      if (!audienceObject.insightImages) {
        audienceObject.insightImages = new InsightImages();
      }
      audienceObject.insightImages.growth = this.followersInsightImage;
    }
    this.audienceObject.emit(audienceObject);
  }


  onNewImage(croppedImage: string): void {
    this.followersInsightImage = croppedImage;
  }
}
