import { Helper } from "../support/helper.support";
import {
  CreatorApprovalPlanner,
  PlannerContent,
} from "./CreatorApproval.model";

class Metric {
  quantity = 0;
  views = 0;
  reach = 0;
  engagementsAbs = 0;
  totalEngagments = 0;

  getEngagementsPercent(): number {
    return CreatorApprovalPlanner.engagementAbsToPercent(
      this.engagementsAbs,
      this.views
    );
  }

  getTotalEngagementsPercent(
    avgView: PlannerContent,
    avgEngagement: PlannerContent
  ): number {
    let totalEngagements = 0;
    let totalViews = 0;
    Object.keys(avgView).map((key) => {
      if (avgView[key] && avgEngagement[key]) {
        totalEngagements += avgEngagement[key];
        totalViews += avgView[key];
      }
    });
    return (totalEngagements / totalViews) * 100;
  }
}

class Price {
  negotiatedPrice = 0;
  netNegotiatedPrice = 0;
  netNegotiatedPriceIfContent = 0;
}

class Total {
  negotiatedPrice = 0;
  netNegotiatedPrice = 0;
  netNegotiatedPriceIfContent = 0;
  totalEngagments = 0;
  costPerClick = 0;
  estimatedClicks = 0;
  quantity = 0;
  views = 0;
  reach = 0;
  engagementsAbs = 0;

  getEngagementsPercent(): number {
    return CreatorApprovalPlanner.engagementAbsToPercent(
      this.engagementsAbs,
      this.views
    );
  }

  getTotalEngagementsPercent(planners: CreatorApprovalPlanner[]): number {
    let totalEngagements = 0;
    let totalViews = 0;
    planners.map((planner) =>
      Object.keys(planner.avgViews).map((key) => {
        if (planner.avgViews[key] && planner.avgEngagementsAbs[key]) {
          totalEngagements += planner.avgEngagementsAbs[key];
          totalViews += planner.avgViews[key];
        }
      })
    );
    return (totalEngagements / totalViews) * 100;
  }

  costPerView(): number {
    return this.views ? this.netNegotiatedPrice / this.views : null;
  }

  costPerEngagement(): number {
    return this.engagementsAbs
      ? this.netNegotiatedPrice / this.engagementsAbs
      : null;
  }

  costPerReach(): number {
    return this.reach ? this.netNegotiatedPrice / this.reach : null;
  }
}

export class CalculatorPlanner {
  name: string;
  children: CalculatorPlanner[] = [];
  list: CreatorApprovalPlanner[] = [];
  content = new PlannerContent();
  metrics: { [platform: string]: Metric } = {
    instagram: new Metric(),
    youtube: new Metric(),
    tiktok: new Metric(),
  };
  price: { [platform: string]: Price } = {
    instagram: new Price(),
    youtube: new Price(),
    tiktok: new Price(),
  };
  estimatedClicks = 0;
  total = new Total();

  constructor(name: string, list: CreatorApprovalPlanner[]) {
    this.name = name;
    if (!list || !list.length) {
      return;
    }
    this.list = list;
    list.forEach((planner) => {
      this.estimatedClicks += planner.estimatedClicks;
      this.content.feeds += planner.content.feeds;
      this.content.reels += planner.content.reels;
      this.content.stories += planner.content.stories;
      this.content.exclusives += planner.content.exclusives;
      this.content.mentions += planner.content.mentions;
      this.content.tiktokVideos += planner.content.tiktokVideos;
      this.metrics[planner.platform].quantity += planner.totalContent();
      this.metrics[planner.platform].views += planner.totalViews();
      this.metrics[planner.platform].reach += planner.totalReach();
      this.metrics[planner.platform].engagementsAbs +=
        planner.totalEngagements();
      this.metrics[planner.platform].totalEngagments += this.metrics[
        planner.platform
      ].getTotalEngagementsPercent(planner.avgViews, planner.avgEngagementsAbs);
      this.price[planner.platform].negotiatedPrice += planner.negotiatedPrice;
      this.price[planner.platform].netNegotiatedPrice +=
        planner.netNegotiatedPrice();
      this.price[planner.platform].netNegotiatedPriceIfContent +=
        planner.netNegotiatedPriceIfContent();
      this.total.negotiatedPrice += planner.negotiatedPrice;
      this.total.netNegotiatedPrice += planner.netNegotiatedPrice();
      this.total.netNegotiatedPriceIfContent +=
        planner.netNegotiatedPriceIfContent();
      this.total.quantity += planner.totalContent();
      this.total.views += planner.totalViews();
      this.total.reach += planner.totalReach();
      this.total.engagementsAbs += planner.totalEngagements();
      this.total.estimatedClicks += planner.estimatedClicks;
      this.total.costPerClick += planner.costPerClick();
    });
    this.total.totalEngagments = this.total.getTotalEngagementsPercent(list);
  }

  static join(
    name: string,
    calcPlanners: CalculatorPlanner[]
  ): CalculatorPlanner {
    const list = calcPlanners.reduce(
      (prev, item) => prev.concat(item.list),
      []
    );
    const group = new CalculatorPlanner(name, list);
    group.children = calcPlanners;
    return group;
  }

  get(name: string): CalculatorPlanner {
    return this.children.find((item) => item.name === name);
  }

  costPerView(platform: string): number {
    return this.metrics[platform]?.views
      ? this.price[platform].netNegotiatedPrice / this.metrics[platform].views
      : null;
  }

  costPerEngagement(platform: string): number {
    return this.metrics[platform]?.engagementsAbs
      ? this.price[platform].netNegotiatedPrice /
          this.metrics[platform].engagementsAbs
      : null;
  }

  costPerReach(platform: string): number {
    return this.metrics[platform]?.reach
      ? this.price[platform].netNegotiatedPrice / this.metrics[platform].reach
      : null;
  }
}
