import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Campaign } from '../../../../../../models/campaign.model';
import { Creator } from '../../../../../../models/creator.model';
import { CreatorApproval } from '../../../../../../models/CreatorApproval.model';
import { Privilege } from '../../../../../../models/role.model';
import { Submission } from '../../../../../../models/submission.model';
import { CampaignService } from '../../../../../../services/campaign.service';
import { SubmissionService } from '../../../../../../services/submission.service';
import { UserService } from '../../../../../../services/user.service';
import { SubmissionDialogComponent } from '../../submission-dialog.component';

@Component({
  selector: 'app-social-instagram-content',
  templateUrl: './instagram-content.component.html',
  styleUrls: ['./instagram-content.component.css']
})
export class InstagramContentComponent implements OnInit {

  @Input() public submission: Submission;
  @Input() public creator: Creator;
  @Input() public campaign: Campaign;
  @Input() public creatorApproval: CreatorApproval;
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  @Output() editedSubmission: EventEmitter<Submission> = new EventEmitter<Submission>();
  @Output() editMode = new EventEmitter<Submission>();
  public networkFormat: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public errorToUploadImage: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public isImageFormat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public safeVideo: SafeResourceUrl;
  public canEdit: boolean;
  public isEditing: boolean;
  public submitted = false;
  public subscription: Subscription;
  public posting = false;
  public loadingText: string;
  public validVideoUrl = true;
  public usernames: Array<string> = [];
  public approvals: Array<CreatorApproval>;
  public imgSrc: string;
  public imgSrcSanitized: SafeResourceUrl;
  public caption: string;
  public previewNetwork: string;
  public processingContent: string;
  public isImage: boolean;
  public previousSubmission: Submission;
  public tempSub: Submission;
  public previewUrl: BehaviorSubject<string | ArrayBuffer> = new BehaviorSubject(undefined);
  public status: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public submissions: Submission[];
  public previewStory: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public previewFormat: string;
  public videoUrl: string | ArrayBuffer;
  public editing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);


  @ViewChild('uploadContentModal') callEditContentDialog: TemplateRef<any>;
  @ViewChild('uploadCaptionsModal') callEditCaptionsDialog: TemplateRef<any>;
  private editContentDialogRef: MatDialogRef<TemplateRef<any>>;
  private editCaptionsDialogRef: MatDialogRef<TemplateRef<any>>;


  constructor(private readonly sanitizer: DomSanitizer,
    public userService: UserService,
    private submissionService: SubmissionService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<SubmissionDialogComponent>,
    ) {
    this.isEditing = false;
  }

  public ngOnInit(): void {
    this.status.next(this.submission.status);
    this.networkFormat.next(this.submission.format);
    this.isImageFormat.next(this.submission.image ? true : false);
    this.setVideoUrl();
    this.caption = this.submission.captions;
    this.previousSubmission = this.submission;
    this.previewFormat = this.submission.format;

    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CONTENT], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
        this.canEdit = true;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public isEditingMode(editingMode: boolean, submission?: Submission){
    this.tempSub = this.submission;
    this.isEditing = editingMode;
    this.editMode.emit(submission);
  }

  public openContentModal(){
    this.editContentDialogRef = this.dialog.open(this.callEditContentDialog,
    {
      width: '400px',
      height : '450px'
    });
  }

  public OpenUploadCaptionsModal(){
    this.editCaptionsDialogRef = this.dialog.open(this.callEditCaptionsDialog, {
      width: '500px',
      // height : auto
    });
  }


  public fileChangeEvent(file: FileList): void {
    this.submission.mediaFile = file[0];
    const allowedImageTypes = ['image/jpeg', 'image/png'];
    const allowedVideoTypes = ['video/mp4'];
    this.previewStory.next(this.submission.format == 'Story' ? true : false);
    if(!allowedImageTypes.includes(file[0].type) && !allowedVideoTypes.includes(file[0].type)){
      this.errorToUploadImage.next('"Allowed file types are: [ .jpg .png .mp4 .mpg ]"');
    } else {
      if(allowedImageTypes.includes(file[0].type)){
        const url = URL.createObjectURL(file[0]);
        const img = new Image();
        img.src = url;
        this.imgSrc = img.src;
        this.isImage = true;
      } else {
          const reader = new FileReader();
          reader.readAsDataURL(this.submission.mediaFile);
          reader.onload = (event) => {
          this.previewUrl.next((<FileReader>event.target).result);
          this.videoUrl = (<FileReader>event.target).result;
          this.isImageFormat.next(!Boolean(String(this.previewUrl.value).includes('video')));
        };
        this.submission.video = '';
      }
      this.closeEditContentDialog();
      this.edit();
    }
  }

  public cancelEditing(){
    this.previewStory.next(undefined);
    this.imgSrc = undefined;
    this.imgSrcSanitized = undefined;
    this.isEditingMode(false);
    this.previewUrl.next(undefined);
    this.isImageFormat.next(this.submission.image ? true : false);
    this.networkFormat.next(this.submission.format);
  }

  public uploadCaptions(){
    this.caption = this.submission.captions;
    this.closeEditCaptionsDialog();
  }

  public closeEditContentDialog(){
    this.editContentDialogRef.close();
  }

  public closeEditCaptionsDialog(){
    this.editCaptionsDialogRef.close();
  }

  public uploadContent(){
    if(this.isImage){
      this.imgSrcSanitized = this.sanitizer.bypassSecurityTrustUrl(this.imgSrc);
      this.previewStory.next(true);
    } else {
      this.processingContent = 'The uploaded content is still processing. Wait a couple of minutes to see the uploaded content';
    }
    this.networkFormat.next(this.previewFormat);
    this.closeEditContentDialog();
  }

  getBackground(image) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
}

  public setContent(contentFile?){
    return contentFile ?? this.submission.image;
  }

  edit(): void {
    this.editing.next(true);
    this.submission.format = this.previewFormat;
    this.submission.image = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.imgSrc));
    this.loadingText = 'Saving...';
    this.posting = true;
    this.subscription = this.submissionService.editSubmission(this.submission).subscribe( value => {
      this.submissions = value.submissions.map(value => new Submission().deserialize(value));
      if (this.submission.mediaFile && this.submission.mediaFile.type.includes('video')) {
        this.submissionService.uploadVideo(value.upload, this.submission.mediaFile)
          .subscribe({
            next: () => {}, error: (error: HttpErrorResponse) => {
              console.log(error);
            }
          });
      }
      this.editing.next(false);
      this.dialogRef.close(this.submissions);
      this.posting = false;
    }, x => {
      this.error.emit(x.error);
      this.posting = false;
    });
    this.isEditingMode(false);
  }

  delete(): void {
    this.loadingText = 'Deleting...';
    this.posting = true;
    this.subscription = this.submissionService.deleteSubmission(this.submission).subscribe( (x) => {
      this.submissions = x.submissions.map(x => new Submission().deserialize(x));
      this.dialogRef.close(this.submissions);
      this.posting = false;
    }, x => {
      this.error.emit(x.error);
      this.posting = false;
    });
  }


  public setVideoUrl() {
    if (this.isYoutube()) {
      const videoId = this.submission.video.split('/').pop();
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoId);
    } else {
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.submission.video);
    }
  }

  validateUrl(): void {
    this.validVideoUrl = this.submission.video.length !== 0 &&
      ( this.submission.video.includes('https://www.youtube.com/embed/', 0)
      || this.submission.video.includes('https://youtu.be/', 0));
  }

  private isYoutube(): boolean {
    return !!this.submission.video && this.submission.video.includes('youtube');
  }

}
