export class Band {
  readonly name: string;
  readonly value: string;
  disabled: boolean;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
    this.disabled = false;
  }
}

export class BandList {
  public static readonly bandList: Array<Band> = [
    new Band ( 'Any time band', '00:00-23:59' ),
    new Band ( '7:00 am to 12:00pm', '07:00-12:00' ),
    new Band ( '12:00 pm to 18:00pm', '12:00-18:00' ),
    new Band ( '18:00 pm to 00:00am', '18:00-00:00' )
  ];
}
