import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Campaign } from '../../../../models/campaign.model';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../../services/campaign.service';
import { Subscription } from 'rxjs';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';

@Component({
  selector: 'app-creators',
  templateUrl: './creators.component.html',
  styleUrls: ['./creators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatorsComponent implements OnInit, OnDestroy {

  invalidToken = false;
  sub: Subscription;
  creators: Array<CreatorApproval>;
  campaign: Campaign;

  screenHeight: number;
  screenWidth: number;

  constructor(private route: ActivatedRoute, private campaignService: CampaignService,
              private ref: ChangeDetectorRef) {
    this.ref.detach(); // Detaching to avoid cpu consumption due to continuous refresh
  }

  ngOnInit(): void {
    this.ref.detectChanges();
    const token = this.route.snapshot.params.token;
    const queryFilter = this.route.snapshot.queryParams.filter;
    const filter = queryFilter ? queryFilter.toLowerCase().split(',') : [];
    if (token === null || token === '') {
      this.invalidToken = true;
    }
    this.sub = this.campaignService.getCampaignPublicCreators(token).subscribe(response => {
      const creators = Object.values(response.creators);
      this.creators = creators
        .map(creator => new CreatorApproval().deserialize(creator));
      if (filter.length > 0) {
        this.creators = this.creators.filter(c => filter.includes(c.status.toLowerCase()));
      }
      this.campaign = new Campaign().deserialize(response.campaign);
      this.onResize();
      this.onResize(); // Necessary for gradient drawing
      this.onResize();
      this.onResize(); // Necessary for gradient drawing
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    //this.view = [window.innerWidth * .55, window.innerHeight * .222];
    this.ref.detectChanges();
  }
}
