import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of} from 'rxjs';
import { User, UserRole } from '../models/user.model';
import { RestService } from './rest.service';
import { map, tap} from 'rxjs/operators';
import { UpdateUserModel } from '../modules/brand/updateUserModel';
import { Company } from '../models/company.model';
import { RequestHelper } from '../models/requestHelper';
import { Brand } from '../models/brand.model';
import { Role } from '../models/role.model';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  private updateProfileUrl = 'updateProfile';
  private brandUrl = 'me';
  private userObservable: Observable<User>;
  private companyEmitter = new EventEmitter<Company>();
  private cacheTime: number;
  private cacheLife = 300000;
  user: User;
  company: Company;
  joinableCompanies: Array<Company>;
  updatedProfile = new EventEmitter<void>();

  constructor(private rs: RestService, private environmentService: EnvironmentService) {
    this.rs.getLogoutEvent().subscribe( () => this.clearCaches());
  }

  getUser(): Observable<User> {
    if (this.user && (this.cacheLife + this.cacheTime - Date.now() ) > 0) {
      // if `data` is available just return it as `Observable`
      return of(this.user.clone());
    } else if (this.userObservable) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.userObservable;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      this.userObservable = this.rs.get(this.brandUrl)
        .pipe(
          tap ( response => {
            // when the cached data is available we don't need the `Observable` reference anymore
            this.joinableCompanies = new Array<Company>();
            this.userObservable = null;
            this.company = Company.deserialize(response.company);
            this.user = User.deserialize(response.user);
            this.user.company = this.company;
            this.companyEmitter.emit(this.company);
            if (response.companyList) {
              response.companyList.forEach(x => this.joinableCompanies.push(Company.deserialize(x)));
            }
            this.cacheTime = Date.now();
          }, () => {
              this.user = null;
              this.company = null;
              this.userObservable = null;
          }),
          map(() => this.user)
        );
      return this.userObservable;
    }
  }

  getAllCompanies(): Observable<Company[]> {
    return this.rs.get('companies/all').pipe(
      map ( (response: any) => response.map(Company.deserialize)) );
  }

  joinCompany(brand: UpdateUserModel): Observable<any> {
    const payload = brand.getFormData();
    return this.rs.postWithFile(`companies/${brand.company.id}/join`, payload)
      .pipe( tap ( () => {
        this.clearCaches();
        this.updatedProfile.emit();
      }));
  }

  updateProfile(brand: UpdateUserModel): Observable<any> {
    const payload = brand.getFormData();
    return this.rs.postWithFile(this.updateProfileUrl, payload)
      .pipe( tap ( () => {
          this.clearCaches();
          this.updatedProfile.emit();
      }));
  }

  private clearCaches(): void {
    this.user = null;
    this.cacheTime = 0;
    this.userObservable = null;
  }

  getProfileChangesListener(): EventEmitter<void> {
    return this.updatedProfile;
  }

  createCompany(company: Company, userId: number): Observable<any> {
    company.ownerId = userId;
    const formData: FormData = new FormData();
    formData.append('ownerId', company.ownerId + '');
    formData.append('brandName', company.brandName);
    formData.append('legalName', company.legalName);
    formData.append('logo', RequestHelper.newFileFromString(company.logo, 'logoImage.jpg'), 'logoImage');
    formData.append('connectId', (company.connectId || '') + '');
    return this.rs.postWithFile('companies', formData);
  }

  createCompanyUser(email: string, company: Company): Observable<{company: Company; user: User } | null> {
    let request: Observable<{ result: { company: any; user: any } }>;
    const url = `companies/${company.id}/users`;
    if(company.id) {
      request = this.rs.post(url, { email, company_id: company.id });
    } else {
      const formData: FormData = new FormData();
      formData.append('email', email);
      formData.append('brand_name', company.brandName);
      formData.append('legal_name', company.legalName);
      formData.append('logo', RequestHelper.newFileFromString(company.logo, 'logoImage.jpg'), 'logoImage');
      formData.append('connect_id', (company.connectId || '') + '');
      request = this.rs.postWithFile(url, formData);
    }
    return request.pipe(
      map(response => response.result ?
        ({ company: Company.deserialize(response.result.company), user: User.deserialize(response.result.user) }) : null
      )
    );
  }

  moveUserToCompany(id: number, targetCompany: number): Observable<void> {
    return this.rs.post(`companies/${targetCompany}/move`, {user_id: id, company_id: targetCompany});
  }

  public editCompany(company: Company): Observable<void>{
    const formData = new FormData();
    for (const key of Object.keys(company)) {
      const value = company[key];
      if(key === 'logo') {
        if(!value.includes(this.environmentService.getAzureStoragePath())){
          formData.append(key, RequestHelper.newFileFromString(value, 'logoImage.jpg'), 'logoImage');
        } else {
          continue;
        }
      } else if(value) {
formData.append(key, value);
}
    }
    return this.rs.postWithFile(`companies/${company.id}/edit`, formData);
  }

  delete(companyId: number): Observable<void> {
    return this.rs.post(`companies/${companyId}/delete`, {company_id: companyId});
  }

  getCompany(): Observable<Company> {
    if (this.company) {
      // if `data` is available just return it as `Observable`
      return of(this.company.clone());
    } else {
      return this.companyEmitter.asObservable();
    }
  }

  createBrand(name: string, description: string, logo: string, companyId: number): Observable<Brand> {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('company_id', companyId.toString());
    formData.append('logo', RequestHelper.newFileFromString(logo, 'logoImage.jpg'), 'logoImage');
    return this.rs.postWithFile(`companies/${companyId}/brands`, formData).pipe(
      map(response => Brand.deserialize(response.result))
    );
  }

  deleteBrand(companyId: number, brandId: number) {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/delete`, { brand_id: brandId });
  }

  getCompanyUsers(companyId: number): Observable<User[]> {
    return this.rs.get(`companies/${companyId}/users`).pipe(
      map((response: any[]) => response.map(User.deserialize))
    );
  }


  getCompanyBrands(companyId: number): Observable<Brand[]> {
    return this.rs.get(`companies/${companyId}/brands`).pipe(
      map((response: any[]) => response.map(Brand.deserialize))
    );
  }

  getRoles(companyId: number): Observable<Role[]> {
    return this.rs.get(`companies/${companyId}/roles`).pipe(
      map((response: any[]) => response.map(Role.deserialize))
    );
  }

  addUserRole(companyId: number, userId: number, roleId: number, brandId: number | null, campaignId: number | null): Observable<UserRole> {
    return this.rs.post(`companies/${companyId}/users/${userId}/roles`,
    { role_id: roleId, brand_id: brandId, campaign_id: campaignId }).pipe(
      map(response => UserRole.deserialize(response))
    );
  }

  addUserRoleByName(roleName: string, companyId: number, userId: number, brandId: number | null = null,
    campaignId: number | null = null): Observable<UserRole> {
    return this.rs.post(`companies/${companyId}/users/${userId}/roles`,
    { role_name: roleName, brand_id: brandId, campaign_id: campaignId }).pipe(
      map(response => UserRole.deserialize(response))
    );
  }

  editUserRole(companyId: number, userId: number, userRoleId: number, newRoleId: number): Observable<UserRole> {
    return this.rs.post(`companies/${companyId}/users/${userId}/roles/${userRoleId}`, { role_id: newRoleId }).pipe(
      map(response => UserRole.deserialize(response))
    );
  }

  deleteUserRole(companyId: number, userId: number, userRoleId: number): Observable<void> {
    return this.rs.post(`companies/${companyId}/users/${userId}/roles/${userRoleId}/delete`, null);
  }
}
