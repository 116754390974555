import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { Creator } from '../../../../models/creator.model';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionDialogComponent } from '../submission-dialog/submission-dialog.component';
import { TabAnimations } from './animations/tab';
import { BehaviorSubject } from 'rxjs';
import { HeaderAnimations } from './animations/header';
import { ArrowAnimation } from './animations/arrow';
import { HeaderCaptionAnimation } from './animations/caption';
import { ContentAnimations } from './animations/content';
import { ContentWrapperAnimation } from './animations/contentWrapper';
import { HeaderAnimationPhases } from './animations/header-animation-phases.enum';
import { TabAnimationsPhases } from './animations/tab-animation-phases.enum';
import { Campaign } from '../../../../models/campaign.model';
import { SubmissionState } from '../../../../enum/submission-state.enum';
import { delay } from 'rxjs/operators';

const maxTabOpen = 3;

@Component({
  selector: 'app-content-category',
  templateUrl: './content-category.component.html',
  styleUrls: ['./content-category.component.scss'],
  animations: [
    TabAnimations.tabAnimation, 
    HeaderAnimations.headerAnimation,
    ArrowAnimation.arrowAnimation,
    HeaderCaptionAnimation.headerCaption,
    ContentAnimations.contentAnimation,
    ContentWrapperAnimation.contentWrapperAnimation
  ]
})

export class ContentCategoryComponent implements OnInit {

  @Input() public categoryName: string;
  @Input() public creatorApprovals: Array<CreatorApproval>;
  @Output() public statusChanged = new EventEmitter<Submission | Submission[]>();
  @Output() public tabOpen: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteSubmission = new EventEmitter<Submission | Submission[]>();
  @Input() public allOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  @Input() public stateContent: string;
  @Input() public allSubmissions: Array<Submission>;
  @Input() public submissions: Array<Submission>;
  @Input() public staticTab: boolean;
  @Input() public creator: Creator;
  @Input() public arrowDirection: string;
  @Input() campaign: Campaign;
  @Output() editMode = new EventEmitter<Submission>();
  public tabPhase: TabAnimationsPhases = TabAnimationsPhases.Closed;
  public headerPhase: HeaderAnimationPhases = HeaderAnimationPhases.Closed;
  public contentPhase: TabAnimationsPhases = TabAnimationsPhases.Closed;
  public openTab: BehaviorSubject<TabAnimationsPhases> = new BehaviorSubject<TabAnimationsPhases>(TabAnimationsPhases.Closed);

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    if(this.staticTab) {
      this.openTab.next(TabAnimationsPhases.Open);
      this.tabPhase = TabAnimationsPhases.Open;
      this.contentPhase = TabAnimationsPhases.Open
      this.headerPhase = HeaderAnimationPhases.Open;
    }
    this.allOpen
      .subscribe({
        next: (data: boolean) => {
          if(data) {
            this.tabPhase = TabAnimationsPhases.OpenReduced;
            this.contentPhase = TabAnimationsPhases.OpenReduced;
            if(!this.staticTab) this.headerPhase = HeaderAnimationPhases.preOpenChange;
          } else if(this.staticTab) {
            this.tabPhase = TabAnimationsPhases.Open;
            this.contentPhase = TabAnimationsPhases.Open;
          } else {
            this.tabPhase = TabAnimationsPhases.Closed;
            this.contentPhase = TabAnimationsPhases.Closed;
            this.headerPhase = HeaderAnimationPhases.PreClosedChange;
          }
        }, error: (error: Error) => {
          console.log(error);
        }
      })
}

  public animationTrigger(): void {
    if(!this.staticTab){
      this.tabPhase =  this.tabPhase === TabAnimationsPhases.Closed ? TabAnimationsPhases.Open : TabAnimationsPhases.Closed
      this.headerPhase = this.tabPhase === TabAnimationsPhases.Closed ? HeaderAnimationPhases.PreClosedChange : HeaderAnimationPhases.preOpenChange
      this.contentPhase = this.tabPhase;
      this.tabOpen.emit(this.tabPhase);
    }
  }

  public getCreatorSocialName(sub: Submission): string {
    const found = this.creatorApprovals.find((value: CreatorApproval) => value.creator.id === sub.userId);
    const creator = found.creator;
    return creator.fullName || creator.getSocialName() || creator.email;
  }

  public openSubmission(sub: Submission): void {
    const dialogRef = this.dialog.open(SubmissionDialogComponent, {
      width: '982px',
      data: {
        allSubmissions: this.allSubmissions,
        campaign: this.campaign,
        submission: sub,
        creators: this.creatorApprovals.map(value => value.creator),
        creatorApproval: this.creatorApprovals
      },
      panelClass: 'submission-dialog'
    });

    dialogRef.afterClosed()
    .subscribe({
      next : (submission: Submission | Submission[]) => {
        if(submission){
          this.statusChanged.emit(submission);
          if(typeof submission != typeof Submission){
            this.deleteSubmission.emit(submission);
          }
        }
      },
      error: (error: Error) => console.log(error)});
  }

  public animationDone(): void {
    this.openTab.next(this.tabPhase);
  }

  public getPostTime(subTime: Date): string {
    const date = new Date(subTime);
    date.setDate(date.getDate());
    const getYear = date.toLocaleString("default", {year: "numeric"});
    const getMonth = date.toLocaleString("default", {month: "short"});
    const getDay = date.toLocaleString("default", {day: "2-digit"});
    return getMonth + ", " + getDay + " " + getYear
  }

  public changeAnimation() : void {
    this.headerPhase = HeaderAnimations.headerChangePhases(this.headerPhase);
  }

  public get tabAnimationsPhases(): typeof TabAnimationsPhases {
    return TabAnimationsPhases;
  }

  public get submissionState(): typeof SubmissionState {
    return SubmissionState;
  }

  public strToSetenceCase(str: string): string {
    let string = str.split(" ");
    for(let i = 0; i < string.length; i++){
      if(i > 0) string[i] = string[i].charAt(0).toLowerCase() + string[i].slice(1);
      else string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(" ");
  }

}