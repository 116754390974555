// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseUrl: 'https://lemmonetinfluencersbackendmaster.azurewebsites.net/api/auth/',
  // baseUrl: 'http://localhost:8000/api/auth/',
  baseUrl: 'https://lemmonetinfluencersbackendmaster.azurewebsites.net/api/auth/',
  azureStoragePath: 'https://wormblob.blob.core.windows.net/wormimages/',
  firebase: {
    apiKey: 'AIzaSyDkkvJBo6xh6HyBtcrfxuV79pTO0cFdn9Y',
    authDomain: 'lemmonet-influencers-app.firebaseapp.com',
    databaseURL: 'https://lemmonet-influencers-app.firebaseio.com',
    projectId: 'lemmonet-influencers-app',
    storageBucket: 'lemmonet-influencers-app.appspot.com',
    messagingSenderId: '242452079945',
    appId: '1:242452079945:web:7483ae63ec19be0b7ce698',
    measurementId: 'G-H0DP6GR9XY',
    vapidKey: 'BGKZokrW20x222k_fBTfGvOz-1orPPZ7L_UhEqdGS9BsRZtNEuzWQY1lQwtVVemg3zp35D1GP80mwkhYy9pnO9c'
  },
  notificationsOff: false,
  configBackendExpirationMs: 1000 * 60 * 60 * 1, // 1 hours
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
