import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../services/reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  generating = false;

  constructor(private reportService: ReportsService) { }

  ngOnInit(): void {
    this.reportService.getGeneratingEvent().subscribe( x => this.generating = x);
  }

  generateInfluencer(): void {
    this.reportService.generateInfluencerReport();
  }

  generateInfluencerByCampaign(): void {
    this.reportService.generateInfluencerByCampaignReport();
  }

  generateCampaignReports(): void {
    this.reportService.generateCampaignReports();
  }
}
