import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable, of, throwError } from 'rxjs';
import { CreatorApproval } from '../models/CreatorApproval.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Creator } from '../models/creator.model';
import { InsightImages } from '../models/instagram.model';
import { RequestHelper } from '../models/requestHelper';
import { Submission } from '../models/submission.model';
import { Filter } from '../models/filters';
import { Campaign } from '../models/campaign.model';
// import { CreatorTableItem } from '../modules/campaign/campaign/campaign-creators/creators-table/creators-table.component'

export interface CreatorPrices {
  instagram?: { reelPrice?: number; feedPrice?: number; storyPrice?: number };
  tiktok?: { videoPrice?: number };
  youtube?: { videoMentionPrice?: number; videoExclusivePrice?: number };
}

@Injectable({
  providedIn: 'root'
})
export class CreatorService {
  private creators: Array<CreatorApproval>;
  private creators$: Observable<Array<CreatorApproval>>;
  readonly creatorsSub;
  private cachedCampaignCreators: number = null;
  private cacheTime: number;
  private cacheLife = 300000;
  private cacheGetCreator: Map<number, Observable<Creator>> = new Map();

  constructor(private rs: RestService) {
    this.creatorsSub = this.rs.getLogoutEvent().subscribe(() => this.clearCaches());
  }

  getCreator(creatorId: number, useCache: boolean = false): Observable<Creator> {
    if (useCache && this.cacheGetCreator.has(creatorId)) {
      return this.cacheGetCreator.get(creatorId);
    }
    const response = this.rs.get(`creators/${creatorId}`).pipe(
      map(response => new Creator().deserialize(response))
    );
    if (useCache) {
      this.cacheGetCreator.set(creatorId, response);
    }
    return response;
  }

  getCreatorByCampaign(companyId: number, brandId: number, campaignId: number, creatorId: number): Observable<Creator> {
    const response = this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}`).pipe(
      map(response => new Creator().deserialize(response))
    );
    return response;
  }

  getCampaignCreators(companyId: number, brandId: number, campaignId: number): Observable<Array<CreatorApproval>> {
    if (this.creators && this.cachedCampaignCreators === campaignId &&
      (this.cacheLife + this.cacheTime - Date.now()) > 0) {
      // if `data` is available just return it as `Observable`
      return of(this.creators);
    } else if (this.creators$ && this.cachedCampaignCreators) {
      // if observable is set then the request is in progress
      return this.creators$;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      this.creators$ = this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators`)
        .pipe(catchError((err, obs) => {
          this.clearCaches();
          return obs;
        }),
          tap((response) => {
            this.creators$ = null;
            this.creators = new Array<CreatorApproval>();
            response.forEach((rawCreatorApproval => {
              this.creators.push(new CreatorApproval().deserialize(rawCreatorApproval));
            }));
            this.cacheTime = Date.now();
          },
            () => {
              this.clearCaches();
            }),
          map(() => this.creators)
        );
      this.cachedCampaignCreators = campaignId;
      return this.creators$;
    }
  }

  clearCaches(): void {
    this.cachedCampaignCreators = -1;
    if (this.creatorsSub) {
      this.creatorsSub.unsubscribe();
    }
    this.creators$ = null;
    this.creators = null;
  }

  changeStatus(companyId: number, brandId: number, campaignId: number, id: number, status: string, reason = 'No Reason'): Observable<void> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${id}/status`,
      { creatorId: id, campaignId, newStatus: status, motive: reason });
  }

  removeCampaign(companyId: number, brandId: number, campaignId: number, creatorId: number, approvalId: number): Observable<void> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/delete`,
      { userApprovalId: approvalId });
  }

  inviteCreator(username: string, companyId: number, brandId: number, campaignId: number, socialNetwork: string): Observable<any> {
    this.clearCaches();
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/invite`,
      { username, socialNetwork, campaignId });
  }

  inviteCreators(creatorIds: number[], companyId: number, brandId: number, campaignId: number) {
    this.clearCaches();
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/inviteMany`, { creatorIds, campaignId });
  }

  cancelInvitation(username: string, email: string, companyId: number, brandId: number, campaignId: number, socialNetwork: string): Observable<void> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/invite/cancel`,
      { username, email, socialNetwork, campaignId });
  }

  saveAudience(creatorId: number, audienceDetails: any): Observable<any> {
    this.clearCaches();
    return this.rs.
      post(`creators/${creatorId}/edit`, {
        audience: audienceDetails.audience, id: audienceDetails.id, categories: audienceDetails.categories || '',
        social_id: audienceDetails.socialId, country: audienceDetails.country, gender: audienceDetails.gender, email: audienceDetails.email,
        social_network: audienceDetails.socialNetwork, mobile_number: audienceDetails.phone, password: audienceDetails.password, about_me: audienceDetails.aboutMe, creator_level: audienceDetails.creatorLevel
      });
  }

  updateCreatorImages(creatorIds: Array<number>): Observable<Creator[]> {
    return this.rs.post('creators/images/update', { creatorIds }).pipe(
      map((response: any[]) => response?.map(c => new Creator().deserialize(c)))
    );
  }

  searchCreators(filters: Filter[], search?: string, order?: string, asc = true, page = 1): Observable<{ creators: Creator[]; count: number }> {
    let queryStr = `page=${page}&order=${asc ? 'asc' : 'desc'}`;
    queryStr = filters.length > 0 ? `${queryStr}&${filters.map(f => `filters[]=${JSON.stringify(f)}`).join('&')}` : queryStr;
    queryStr = search ? `${queryStr}&search=${search}` : queryStr;
    queryStr = order ? `${queryStr}&order_field=${order}` : queryStr;
    return this.rs.get('creators/search', queryStr)
      .pipe(map((creatorList: { creators: any[]; count: number }) => {
        const creatorArray = new Array<Creator>();
        creatorList.creators.forEach(x => creatorArray.push(new Creator().deserialize(x)));
        return {
          creators: creatorArray,
          count: creatorList.count
        };
      }));
  }


  create(username: string, socialNetwork: string, managerPhoneNumber?: string, managerEmail?: string,
    managerName?: string, creatorId?: number): Observable<any> {
    return this.rs.post('creators',
      { username, socialNetwork, creatorId, managerPhoneNumber, managerEmail, managerName }
    ).pipe(map(x => {
      if (x.creator) {
        x.creator = new Creator().deserialize(x.creator);
      }
      return x;
    }));
  }

  delete(id: number): Observable<void> {
    return this.rs.post(`creators/${id}/delete`, { id });
  }

  verifyCreator(creator: Creator): Observable<void> {
    return this.rs.post(`creators/${creator.id}/tiktok/verify`, { username: creator.tikTok.username });
  }

  unverifyCreator(creator: Creator): Observable<void> {
    return this.rs.post(`creators/${creator.id}/tiktok/unverify`, { username: creator.tikTok.username });
  }

  rejectCreator(creator: Creator): Observable<void> {
    return this.rs.post(`creators/${creator.id}/tiktok/reject`, { username: creator.tikTok.username });
  }

  uploadInsightImages(creatorId: number, insightsImages: InsightImages): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('creatorId', creatorId.toString());
    formData.append('save', 'true');
    Object.keys(insightsImages)
      .forEach((key) => formData.append(key, RequestHelper.newFileFromString(insightsImages[key], `${key}.jpg`), key));
    return this.rs.postWithFile(`creators/${creatorId}/audience-images`, formData);
  }

  generateTemporaryPasswords(creatorIds: number[], loginStart: Date | null, loginEnd: Date | null): Observable<void> {
    return this.rs.post('creators/password-generate',
      { creatorIds, loginStart: loginStart ? loginStart.toISOString() : null, loginEnd: loginEnd ? loginEnd.toISOString() : null });
  }

  generateCreatorPassword(creatorId: number): Observable<{ password: string }> {
    return this.rs.post(`creators/${creatorId}/password-generate`, { creatorId });
  }

  uploadPrecampaignStatsImage(creatorId: number, format: string, image: string): Observable<Submission[]> {
    const formData: FormData = new FormData();
    formData.append('creatorId', creatorId.toString());
    formData.append('format', format);
    formData.append('image', RequestHelper.newFileFromString(image, 'image'));
    return this.rs.postWithFile(`creators/${creatorId}/precampaign`, formData).pipe(
      map((response: Array<any>) => response.map(s => new Submission().deserialize(s)))
    );
  }

  getCreatorHistory(creatorId: number): Observable<{ approvals: CreatorApproval[]; submissions: Submission[] }> {
    return this.rs.get(`creators/${creatorId}/history`).pipe(
      map(({ approvals, submissions }: { approvals: any[]; submissions: any[] }) => ({
        approvals: approvals.map(a => new CreatorApproval().deserialize(a)),
        submissions: submissions.map(s => new Submission().deserialize(s))
      }))
    );
  }

  updateCreatorPrices(creatorId: number, prices: CreatorPrices): Observable<void> {
    const request: any = {};
    if (prices.instagram) {
      request.instagram = {};
      if (prices.instagram.reelPrice) {
        request.instagram.reel_price = prices.instagram.reelPrice;
      }
      if (prices.instagram.storyPrice) {
        request.instagram.story_price = prices.instagram.storyPrice;
      }
      if (prices.instagram.feedPrice) {
        request.instagram.feed_price = prices.instagram.feedPrice;
      }
    }
    if (prices.youtube) {
      request.youtube = {};
      if (prices.youtube.videoMentionPrice) {
        request.youtube.video_mention_price = prices.youtube.videoMentionPrice;
      }
      if (prices.youtube.videoExclusivePrice) {
        request.youtube.video_exclusive_price = prices.youtube.videoExclusivePrice;
      }
    }
    if (prices.tiktok) {
      request.tiktok = { video_price: prices.tiktok.videoPrice };
    }
    return this.rs.post(`creators/${creatorId}/prices`, request);
  }

  updateAllSocialNetworks(id: number): Observable<void> {
    return this.rs.post(`creators/${id}/social-networks/profile-update`, { id });
  }

  updateAllSocialNetworksIfExpired(id: number): Observable<void> {
    return this.rs.post(`creators/${id}/social-networks/profile-update-if-expired`, { id });
  }

  generateShortUrl(data: {url: string, service: string | null}) : Observable<Object> {
    return this.rs.postPublic('generateShortUrl', data);
  }
}
