import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Submission } from 'src/app/models/submission.model';

@Component({
  selector: 'app-statistics-image-modal',
  templateUrl: './statistics-image-modal.component.html',
  styleUrls: ['./statistics-image-modal.component.scss']
})
export class StatisticsImageModalComponent {

  constructor(private modalService: NgbModal) {
  }
  @Input() submission: Submission;
  @Output() newImages = new EventEmitter<{submission: Submission; images: File[]}>();
  files: File[] = [];
  images: string[] = [];
  modalRef: NgbModalRef;

  fileChangeEvent(e: File[]): void {
    this.files.push(e[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e[0]);
    reader.onload = (event) => {
      this.images = this.images.concat(event.target.result.toString());
    };
  }

  open(content: TemplateRef<StatisticsImageModalComponent>): void {
    this.images = [];
    this.files = [];
    this.modalRef = this.modalService.open(content);
  }

  close(): void {
    this.newImages.emit({submission: this.submission, images: this.files});
    this.modalRef.close();
  }
}
