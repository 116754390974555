import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileImageModalComponent } from './profile-image-modal/profile-image-modal.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ProfileComponent, ProfileImageModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgbAlertModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [
    ProfileImageModalComponent
  ],
  entryComponents: [
    ProfileImageModalComponent
  ]
})
export class BrandModule {
}
