import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { NegotiationOffer } from 'src/app/models/negotiationOffer.model';
import { NegotiationService } from 'src/app/services/negotiation.service';

@Component({
  selector: 'app-negotiation-offer',
  templateUrl: './negotiation-offer.component.html',
  styleUrls: ['./negotiation-offer.component.scss']
})
export class NegotiationOfferComponent {

  @Input()
  offer: NegotiationOffer;

  @Input()
  approval: CreatorApproval;

  @Input()
  showActions = false;

  @Output()
  newOffer = new Subject<NegotiationOffer>();
  @Output()
  acceptedOffer = new Subject<NegotiationOffer>();
  @Output()
  declinedOffer = new Subject<NegotiationOffer>();

  loading = false;

  constructor(private negotiationService: NegotiationService) { }

  accept() {
    this.loading = true;
    this.negotiationService.acceptNegotiationOffer(this.approval.campaign.companyId, this.approval.campaign.brandId,
      this.approval.campaign.id, this.approval.creator.id, this.offer.id).subscribe(() => {
      this.loading = false;
      this.offer.accept();
      this.acceptedOffer.next(this.offer);
    });
  }

  decline() {
    this.loading = true;
    this.negotiationService.declineNegotiationOffer(this.approval.campaign.companyId, this.approval.campaign.brandId,
      this.approval.campaign.id, this.approval.creator.id, this.offer.id).subscribe(() => {
      this.loading = false;
      this.offer.decline();
      this.acceptedOffer.next(this.offer);
    });
  }
}
