import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Company } from '../../../models/company.model';
import { BrandService } from '../../../services/brand.service';
import { SuggestedPrice } from 'src/app/models/suggestedPrice.model';
import { SuggestedPricesService } from 'src/app/services/suggested-price.service';
import { Country, CountryList } from 'src/app/models/country';


@Component({
  selector: 'x-delete-suggested-price',
  templateUrl: './suggested-prices-delete.component.html',
  styleUrls: ['./suggested-prices-delete.component.css'],
})
export class SuggestedPricesDeleteComponent {

  public loading= false

  constructor(
    @Inject(MAT_DIALOG_DATA) public price: SuggestedPrice,
    public dialogRef: MatDialogRef<SuggestedPrice>,
    private suggestedPricesService: SuggestedPricesService,
  ) { }


  public close(): void {
    this.dialogRef.close();
  }

  async delete() 
  {
    this.loading = true;
    try {
      await this.suggestedPricesService.httpDelete(this.price.id).toPromise()
      this.loading = false;
      this.close()
    } catch (err) {
      this.loading = false;
      console.error('--errors', err)
    }
  }

}
