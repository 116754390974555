import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { SuggestedPricesComponent } from './suggested-prices/suggested-prices.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import {  SuffixMinMaxPipe } from './suggested-prices/suffix-min-max.pipe';
import {  emptyToDashPipe } from './suggested-prices/empty-to-dash.pipe';
import { SuggestedPricesEditComponent } from './suggested-prices-edit/suggested-prices-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { SuggestedPricesDeleteComponent } from './suggested-prices-delete/suggested-prices-delete.component';



@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ],
  declarations: [
    SuggestedPricesComponent, 
    SuggestedPricesEditComponent,
    SuggestedPricesDeleteComponent,
    SuffixMinMaxPipe,
    emptyToDashPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatSortModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ]
})
export class SuggestedPricesModule { }
