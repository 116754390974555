import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private us: UserService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Promise<boolean> {

      const isLoggedIn = this.us.isLoggedIn();
      if ((next.url.toString().startsWith('login') || next.url.toString() === 'register') && isLoggedIn) {
        await this.router.navigate(['/dashboard']);
        return true;
      }
      if (isLoggedIn) {
        return true;
      }
      if (!isLoggedIn && (next.url.toString().startsWith('login') || next.url.toString() === 'register')) {
        return true;
      }
    await this.router.navigate(['/login']);
    return false;
  }
}
