export class Audience {
  followers: number;

  public average(value: number, total: number): number {
    return Math.round(value / total);
  }

  public ratio(value: number, total: number): number {
    return (Math.round(100 * 100 * value / total)) / 100;
  }
}
