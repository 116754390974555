import { AppModule } from "../app.module";
import { AppService } from "../services/app.service";

export class Category {
  id: number;
  title: string;

  constructor(data: {id: number, title: string}) {
    this.id = data.id;
    this.title = data.title;
  }

  // static all(): Category[] {
  //   return AppModule.injector.get(AppService).configBackend.categories;
  // }

  static find(value: number | string, all: Category[]): Category {
    return all.find(cat => cat.id === value || cat.title === value);
  }
}