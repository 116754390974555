import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Campaign } from '../../../../../models/campaign.model';
import { Creator } from '../../../../../models/creator.model';
import { CreatorApproval, CreatorApprovalPlanner } from '../../../../../models/CreatorApproval.model';
import { InstagramAudience } from '../../../../../models/instagram.model';
import { Submission } from '../../../../../models/submission.model';
import { SuggestedPrice } from '../../../../../models/suggestedPrice.model';


@Component({
  selector: 'app-social-instagram-audience',
  templateUrl: './audience-instagram.component.html',
  styleUrls: ['./audience-instagram.component.css'],
})
export class AudienceInstagramComponent implements OnInit {
  @Input() public creator: Creator;
  @Input() public creatorSubmissions: Submission[];
  @Input() public suggestedPrices: SuggestedPrice[];
  @Input() public updateProfile: boolean;
  @Input() public creatorApproval?: CreatorApproval;

  public instagramAud: InstagramAudience;
  public avgImpressions = { feeds: 0, stories: 0, reels: 0 };
  public math = Math;
  public labels: string[];
  public maleData: number[];
  public femaleData: number[];
  public creatorPrice: SuggestedPrice;
  public audienceCountry = [];
  public audienceCity = [];
  public safeVideo: SafeResourceUrl;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.creatorPrice = this.suggestedPrices.find(
      (price) => {
        const followers = this.creator.getFollowers('instagram');
        if (price.creator_level === 'Celebrity') {
          return (followers >= price.followers_min && followers <= price.followers_max) || followers > price.followers_max;
        } else if (price.creator_level === 'Micro') {
          return (followers >= price.followers_min && followers <= price.followers_max) || followers < price.followers_min;
        }
        return (followers >= price.followers_min && followers <= price.followers_max);
      }
    ) || new SuggestedPrice();
    if (this.creator.hasInstagramAudience() && this.creatorSubmissions) {
      this.instagramAud = this.creator.instagram.audience;
      if (this.creator.instagram.audience.gendersChartSeries) {
        this.creator.instagram.audience.gendersChartSeries =
          this.creator.instagram.audience.gendersChartSeries.map((s) => ({
            name: s.name,
            series: s.series.map((g) => ({
              name: this.translate.instant(g.name.toUpperCase()),
              value: g.value,
            })),
          }));
      }
      this.avgImpressions = {
        stories: this.getAverageImpressions(
          this.creatorSubmissions.filter((s) => s.format === 'Story'),
          this.instagramAud.impressionsPerStory
        ),
        feeds: this.getAverageImpressions(
          this.creatorSubmissions.filter((s) => s.format === 'Feed'),
          this.instagramAud.impressionsPerFeed
        ),
        reels: this.getAverageImpressions(
          this.creatorSubmissions.filter((s) => s.format === 'Reel'),
          0
        ),
      };
    }
    this.labels = this.creator.instagram.audience.gendersChartSeries.map((gender) => gender.name);
    this.femaleData = this.creator.instagram.audience.gendersChartSeries.map((gender) => gender.series[1]).map((gender) => gender.value);
    this.maleData = this.creator.instagram.audience.gendersChartSeries.map((gender) => gender.series[0]).map((gender) => gender.value);
  }

  public setVideoUrl(video) {
    this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(video + 'embed');
    return this.safeVideo;
  }

  private getAverageImpressions(
    submissions: Submission[],
    defaultValue: number
  ): number {
    return submissions.length > 0
      ? Math.round(
        submissions.reduce((sum, s) => sum + (s.randomImpressions ?? 0), 0) /
        submissions.length
      )
      : Math.round(defaultValue);
  }
}
