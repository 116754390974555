import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCampaignComponent } from './create/create-campaign/create-campaign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbCarouselModule,
  NgbDatepickerModule, NgbDropdownModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { CreateCampaignImagePickerComponent } from './create/create-campaign-image-picker/create-campaign-image-picker.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng5SliderModule } from 'ng5-slider';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignBriefingComponent } from './campaign/campaign-briefing/campaign-briefing.component';
import { CampaignCreatorsComponent } from './campaign/campaign-creators/campaign-creators.component';
import { CampaignSubmissionsComponent } from './campaign/campaign-submissions/campaign-submissions.component';
import { CampaignStatisticsComponent } from './campaign/campaign-statistics/campaign-statistics.component';
import { RouterModule } from '@angular/router';
import { CampaignCreatorCardComponent } from './campaign/campaign-creator-card/campaign-creator-card.component';
import {
  CreatorDetailsModalComponent,
  SafePipe
} from './campaign/creator-details-modal/creator-details-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ContentCategoryComponent } from './campaign/content-category/content-category.component';
import { SubmissionModalComponent } from './campaign/submission-modal/submission-modal.component';
import { CampaignSubmissionsTableComponent } from './campaign/campaign-submissions-table/campaign-submissions-table.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CampaignCalendarComponent } from './campaign/campaign-calendar/campaign-calendar.component';
import { CampaignCalendarHeaderComponent } from './campaign/campaign-calendar/campaing-calendar-header/campaign-calendar-header.component';
import { StatisticsComponent } from './public/statistics/statistics.component';
import { CreatorsComponent } from './public/creators/creators.component';
import { ShareComponent } from './campaign/share/share.component';
import { EditSubmissionComponent } from './campaign/edit-submission/edit-submission.component';
import { MatSelectModule } from '@angular/material/select';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { EditStatisticsComponent } from './campaign/edit-statistics/edit-statistics.component';
import { ThousandsPipe } from '../../pipes/thousands.pipe';
import { CloneCampaignComponent } from './campaign/clone-campaign/clone-campaign.component';
import { CommentsComponent } from './campaign/comments/comments.component';
import { TwoDecimalPipe } from '../../pipes/twoDecimal.pipe';
import { CreatorStatusModalComponent } from './campaign/creator-status-modal/creator-status-modal.component';
import { CreateSubmissionComponent } from './campaign/create-submission/create-submission.component';
import { MapToArrayPipe } from '../../pipes/mapToArray.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TiktokAudienceComponent } from './audience/tiktok-audience/tiktok-audience.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StatisticsImageModalComponent } from './campaign/edit-statistics/statistics-image-modal/statistics-image-modal.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../shared/shared.module';
import { CampaignStatisticsCardsComponent } from './campaign/campaign-statistics-cards/campaign-statistics-cards.component';
import { CampaignCommercialProposalComponent } from './campaign/campaign-commercial-proposal/campaign-commercial-proposal.component';
import {AutosizeModule} from 'ngx-autosize';
import { PlatformModule } from '@angular/cdk/platform';
import { CreatorNegotiationModalComponent } from './campaign/creator-negotiation-modal/creator-negotiation-modal.component';
import { NegotiationMakeOfferComponent } from './campaign/creator-negotiation-modal/negotiation-make-offer/negotiation-make-offer.component';
import { NegotiationOfferComponent } from './campaign/creator-negotiation-modal/negotiation-offer/negotiation-offer.component';
import { NegotiationChatComponent } from './campaign/creator-negotiation-modal/negotiation-chat/negotiation-chat.component';
import { ComponentModule } from '../component/component.module';
import { SubmissionDialogComponent } from './campaign/submission-dialog/submission-dialog.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {BrowserModule} from '@angular/platform-browser';
import { InstagramContentComponent } from './campaign/submission-dialog/social-media-content/instagram-content/instagram-content.component';
import { TiktokContentComponent } from './campaign/submission-dialog/social-media-content/tiktok-content/tiktok-content.component';
import { YoutubeContentComponent } from './campaign/submission-dialog/social-media-content/youtube-content/youtube-content.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import { AudienceDetailsDialogComponent } from './campaign/audience/audience-details-dialog/audience-details-dialog.component';
import { AudienceInstagramComponent } from './campaign/audience/audience-instagram/audience-instagram.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AudienceTiktokComponent } from './campaign/audience/audience-tiktok/audience-tiktok.component';
import { AudienceYoutubeComponent } from './campaign/audience/audience-youtube/audience-youtube.component';
import { LogPipe } from '../../pipes/log.pipe';
import {MatDividerModule} from '@angular/material/divider';
import { CampaignWeekCalendarComponent } from './campaign/campaign-calendar/campaign-week-calendar/campaign-week-calendar.component';
import { DateToWeek } from '../../pipes/dateToWeek.pipe';
import { PreviewImagesDialogComponent } from './campaign/preview-images-dialog/preview-images-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreatorsPlannerComponent } from './campaign/campaign-creators/planner/table/creators-planner-table.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { SuffixMinMaxPipe } from 'src/app/pipes/suffix-min-max.pipe';
import { emptyToDashPipe } from 'src/app/pipes/empty-to-dash.pipe';
import { CreatorsPlannerEditComponent } from './campaign/campaign-creators/planner/edit/creators-planner-edit.component';
import { AudienceNegociatedTabComponent } from './campaign/audience/audience-negociated-tab/audience-negociated-tab.component';
import { ReplacePipe } from 'src/app/pipes/replace.pipe';
import { CampaingPlannerTableComponent } from './campaign/campaign-creators/campaign-planner-table/campaign-planner-table.component';
import {MatCardModule} from '@angular/material/card';
import { CreatorPlannerModalComponent } from './campaign/campaign-creator-card/creator-planner-modal/creator-planner-modal.component';
import { emptyOrZeroToDashPipe } from 'src/app/pipes/empty-or-zero-to-dash.pipe';
import { SubmissionInfoboxComponent } from './campaign/submission-dialog/social-media-content/submission-infobox/submission-infobox.component';
import { propNameToText } from 'src/app/pipes/propNameToText.pipe';
import { CreatorPlannerContentsComponent } from './campaign/campaign-creators/planner/contents/contents.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ContentPlannerDialogComponent } from './campaign/content-planner-dialog/content-planner-dialog.component';
import { ErrorDisplayComponent } from 'src/app/shared/error-display/error-display.component';
import { DataToListComponent } from 'src/app/shared/data-to-list/data-to-list.component';
import { CampaignCommercialProposal2Component } from './campaign/campaign-commercial-proposal2/campaign-commercial-proposal2.component';
import { CampaignCommercialProposal3Component } from './campaign/campaign-commercial-proposal3/campaign-commercial-proposal3.component';

@NgModule({
  declarations: [CreateCampaignComponent, CreateCampaignImagePickerComponent, DateToWeek,
    CampaignComponent, CampaignBriefingComponent, CampaignCreatorsComponent,
    CampaignSubmissionsComponent, CampaignStatisticsComponent, CampaignCreatorCardComponent,
    CreatorDetailsModalComponent, ContentCategoryComponent, SubmissionModalComponent, CampaignSubmissionsTableComponent,
    CampaignCalendarComponent, CampaignCalendarHeaderComponent, StatisticsComponent,
    CreatorsComponent, ShareComponent, EditSubmissionComponent, ImageModalComponent, EditStatisticsComponent,
    CommentsComponent, CloneCampaignComponent, SafePipe, CreatorStatusModalComponent,
    CreateSubmissionComponent, TiktokAudienceComponent, StatisticsImageModalComponent, CreatorsPlannerComponent,
    CampaingPlannerTableComponent, CreatorsPlannerEditComponent,
    CampaignStatisticsCardsComponent, CampaignCommercialProposalComponent,
    CreatorNegotiationModalComponent, NegotiationMakeOfferComponent, NegotiationOfferComponent, NegotiationChatComponent,
    SubmissionDialogComponent, InstagramContentComponent, TiktokContentComponent, YoutubeContentComponent, LogPipe,
    AudienceDetailsDialogComponent, AudienceInstagramComponent, AudienceTiktokComponent, AudienceYoutubeComponent, CreatorPlannerContentsComponent,
    CampaignWeekCalendarComponent, PreviewImagesDialogComponent, CreatorPlannerModalComponent, CampaignCommercialProposal2Component,
    CampaignCommercialProposal3Component,
    ContentPlannerDialogComponent ,SuffixMinMaxPipe,
    emptyToDashPipe, emptyOrZeroToDashPipe, AudienceNegociatedTabComponent, ReplacePipe, SubmissionInfoboxComponent, propNameToText, ErrorDisplayComponent, DataToListComponent],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        ImageCropperModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatDividerModule,
        NgbAlertModule,
        MatButtonModule,
        MatProgressBarModule,
        NgbDatepickerModule,
        TagInputModule,
        BrowserAnimationsModule,
        SharedModule,
        Ng5SliderModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatTooltipModule,
        MatSlideToggleModule,
        NgbAccordionModule,
        MatIconModule,
        NgbCarouselModule,
        NgxChartsModule,
        NgbTooltipModule,
        ComponentModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgbDropdownModule,
        MatSelectModule,
        TranslateModule,
        AngularMultiSelectModule,
        MatButtonToggleModule,
        FontAwesomeModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatTableModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSortModule,
        SharedModule,
        AutosizeModule,
        MatSidenavModule,
        MatCheckboxModule,
        PlatformModule,
        BrowserModule,
        MatChipsModule,
        MatTabsModule,
        MatSortModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatButtonModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        MatCardModule,
    ],
  entryComponents: [
    CreatorDetailsModalComponent,
    SubmissionModalComponent
  ],
  providers: [ThousandsPipe, TwoDecimalPipe, MapToArrayPipe, DateToWeek, {
              provide: MatDialogRef,
              useValue: {}
            }]
})
export class CampaignModule {}
