import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { socialNetworksArray } from 'src/app/models/SocialNetworksArray';
import { AlertService } from 'src/app/services/alert.service';
import { CreatorService } from 'src/app/services/creator.service';

@Component({
  selector: 'app-new-creator-modal',
  templateUrl: './new-creator-modal.component.html',
  styleUrls: ['./new-creator-modal.component.css']
})
export class NewCreatorModalComponent {

  socialNetworksArray = socialNetworksArray;
  modalRef: NgbModalRef;

  loading = false;

  form = this.fb.group({
    socialNetwork: ['instagram', [Validators.required, Validators.pattern('^(' + socialNetworksArray.map(sn => sn.value).join('|') + ')$')]],
    username: [null, [Validators.required]],
    managerName: [null],
    managerPhone: [null],
    managerEmail: [null],
  });

  constructor(private modalService: NgbModal, private fb: FormBuilder, private alert: AlertService,
    private creatorService: CreatorService) { }

  public open(content: TemplateRef<NewCreatorModalComponent>): void {
    this.modalRef = this.modalService.open(content, { windowClass: 'app-new-creator-modal' });
  }

  get socialNetwork() {
    return this.form.get('socialNetwork');
  }
  get username() {
    return this.form.get('username');
  }
  get managerName() {
    return this.form.get('managerName');
  }
  get managerPhone() {
    return this.form.get('managerPhone');
  }
  get managerEmail() {
    return this.form.get('managerEmail');
  }

  create(): void {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.creatorService.create(this.username.value, this.socialNetwork.value, this.managerPhone.value,
      this.managerEmail.value, this.managerName.value).subscribe(() => {
        this.alert.emitSuccess('User created successfully');
        this.loading = false;
        this.modalRef.close();
      }, (err) => {
        this.loading = false;
      });
  }
}
