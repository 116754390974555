import { Component, Input, OnInit } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { Campaign } from '../../../../models/campaign.model';
import { SubmissionService } from '../../../../services/submission.service';
import { Alert } from '../../../../models/interfaces';

@Component({
  selector: 'app-edit-statistics',
  templateUrl: './edit-statistics.component.html',
  styleUrls: ['./edit-statistics.component.scss']
})
export class EditStatisticsComponent implements OnInit {
  @Input() submissions: Array<Submission>;
  @Input() creators: Array<CreatorApproval>;
  @Input() campaign: Campaign;
  draftSubmissions = new Array<Submission>();
  activeCreators = new Array<CreatorApproval>();
  posting = false;
  alert: Alert;
  pickedImages: string[] = [];
  constructor(private submissionService: SubmissionService) { }

  ngOnInit(): void {
    this.submissions.forEach( x => {
      if (x.status === 'Approved') {
        this.draftSubmissions.push(x.clone());
      }
    });
    this.activeCreators = this.creators.filter(
      (creator) => creator.status === 'Confirmed' &&
      this.submissions.some( (sub) => sub.userId === creator.userId));
  }

  setTimeFromInput($event: string, id: number): void {
    const date = new Date($event);
    date.setHours(24, 0, 0);
    this.draftSubmissions.find(x => x.id === id).timePosted = date;
  }

  getCreator(id: number): CreatorApproval {
    return this.creators.find(x => x.userId === id);
  }

  save(): void {
    this.posting = true;
    this.alert = null;
    this.submissionService.batchUpdate(this.campaign.companyId, this.campaign.brandId, this.campaign.id, this.draftSubmissions)
      .subscribe( () => {
      this.posting = false;
      this.alert = { message: 'Submission update successful.', type: 'success' };
    }, (err) => {
      this.posting = false;
      this.alert = { message: err, type: 'danger' };
    });
  }

  submissionEdited($event: Submission): void {
    const editedSubmission = $event;
    this.submissions.forEach( (x, index) => {
      if ( x.id === editedSubmission.id ) {
        this.submissions[index] = editedSubmission;
      }
    });
    this.draftSubmissions.forEach( (x, index) => {
      if ( x.id === editedSubmission.id ) {
        this.draftSubmissions[index] = editedSubmission.clone();
      }
    });
  }

  onNewImages({submission, images}: {submission: Submission; images: File[]}) {
    this.posting = true;
    this.submissionService.readStatsImages(submission.format, images).subscribe(data => {
      submission.likes = data.likes ?? submission.likes;
      submission.comments = data.comments ?? submission.comments;
      submission.randomReach = data.reach;
      submission.randomImpressions = data.impressions;
      submission.clicks = data.clicks;
      this.submissions = this.submissions.map(s => s.id === submission.id ? submission : s);
      this.posting = false;
    });
  }
}
