import { Campaign } from './campaign.model';
import { Creator } from './creator.model';
import { CreatorApproval } from './CreatorApproval.model';
import { Submission } from './submission.model';

export class SubmissionDataDialog {
    allSubmissions: Submission[];
    campaign: Campaign;
    submission:  Submission;
    creators: Creator[];
    creator: Creator;
    creatorApproval: CreatorApproval[];
    isStats: boolean;
}
