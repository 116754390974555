import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Alert } from '../models/interfaces';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Helper } from '../support/helper.support';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertEmitter = new EventEmitter<Alert>();
  constructor(private snackBar: MatSnackBar) {
 return;
}

  subscribeToAlerts(): Observable<Alert> {
    return this.alertEmitter;
  }

  emitSuccess(message: any): void {
    if (message && typeof(message) !== 'string') {
      message = Helper.anyToStringRecursive(message);
    }
    this.snackBar.open(message || 'Success', 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['alert-success'],
      duration: 4000
    });
    // this.alertEmitter.emit({ message, type: 'success' });
  }

  emitError(message: any): void {
    if (message && typeof(message) !== 'string') {
      message = Helper.anyToStringRecursive(message);
    }
    this.snackBar.open(message || 'Error', 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['alert-danger'],
      duration: 6000
    });
    // this.alertEmitter.emit({ message, type: 'danger' });
  }
}
