import { MessagingService } from './../../services/messaging.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { BrandService } from '../../services/brand.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../models/user.model';
import { Alert } from '../../models/interfaces';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CampaignService } from '../../services/campaign.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userInfo$: Observable<User>;
  userInfoSub: Subscription = new Subscription();
  user: User;
  alert: Alert;
  isCompanyAdmin = false;

  public showSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public us: UserService,
    private router: Router,
    private bs: BrandService,
    private as: AlertService,
    private ts: TranslateService,
    private localStorageService: LocalStorageService,
    private messagingService: MessagingService,
    private readonly campaingService: CampaignService
  ) {
    return;
  }

  public ngOnInit(): void {
    this.chechIfRouteIsDashboard();
    this.router.events.subscribe({
      next:() => {
        this.chechIfRouteIsDashboard();
      }, error: (error: Error) => {
        console.log(error);
      }
    });
    const userRoles = this.us.getCurrentUserRoles();
    this.isCompanyAdmin = !!userRoles.find(r => r.role?.name === 'admin' && r.companyId && !r.brandId && !r.campaignId);
    this.messagingService.requestPermission();
    this.as.subscribeToAlerts().subscribe(x => {
      const translateTag = x.message.replace('.', '').toUpperCase().split(' ').join('.');
      const translation = this.ts.instant(translateTag);
      this.alert = translation !== translateTag ? { type: 'danger', message: translation } : x;
    });
    this.alert = null;
    this.userInfo$ = this.bs.getUser();
    this.userInfoSub = this.userInfo$.subscribe(async x => {
      this.user = x;
      if (!this.user.isProfileComplete() || !this.user.hasCompany()) {
        await this.router.navigate(['profile']);
        this.alert = {
          message: this.ts.instant('COMPLETE.PROFILE.INFO'),
          type: 'danger'
        };
      }
      if (this.user.hasUnnasignedCompany()) {
        await this.router.navigate(['profile']);
        this.alert = {
          message: this.ts.instant('UNABLE.TO.VALIDATE.COMPANY'),
          type: 'danger'
        };
      }

      this.localStorageService.store('userId', this.user.id.toString());
    });
    this.bs.getProfileChangesListener().subscribe(() => this.ngOnInit());
  }

  logout(): Promise<Observable<void>> {
    return this.us.logout();
  }

  goProfile(): Promise<boolean> {
    return this.router.navigate(['profile']);
  }

  goDashboard(): Promise<boolean> {
    if (!this.user.isProfileComplete() || !this.user.hasCompany()) {
      this.alert = {
        message: 'Welcome to Duh Creators! Please complete your personal and company information before starting!',
        type: 'danger'
      };
      return;
    }
    return this.router.navigate(['dashboard']);
  }

  ngOnDestroy(): void {
    if (this.userInfoSub) {
      this.userInfoSub.unsubscribe();
    }
  }

  goCreator(): Promise<boolean> {
    return this.router.navigate(['creator']);
  }

  goCompanyApproval(): Promise<boolean> {
    return this.router.navigate(['companyApproval']);
  }

  goCompany(): Promise<boolean> {
    return this.router.navigate([`myCompany`]);
  }

  toBlog(): void {
    window.open('https://info.lemmonet.com/blog', '_blank');
  }

  goReports(): Promise<boolean> {
    return this.router.navigate(['reports']);
  }

  goSuggestedPrices(): Promise<boolean> {
    return this.router.navigate(['suggestedPrices']);
  }

  goNotification(): Promise<boolean> {
    return this.router.navigate(['notifications']);
  }

  public setNameFilter(filterTerm: string): void {
    this.campaingService.setCardCampaignName(filterTerm);
  }

  private chechIfRouteIsDashboard(): void {
    if(this.router.url === '/dashboard'){
      this.showSearch.next(true);
    } else {
      this.showSearch.next(false);
    }
  }
}
