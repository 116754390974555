import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../../../models/campaign.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SubmissionDialogComponent } from '../submission-dialog/submission-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatChip } from '@angular/material/chips';

class SummaryPostDetails extends Submission {
  engagement: number;
  plannerReach: number;
  plannerViews: number;
  plannerEngagement: number;
  performanceReach: number;
  performanceViews: number;
  performanceEngagement: number;
}

@Component({
  selector: 'app-campaign-submissions-table',
  templateUrl: './campaign-submissions-table.component.html',
  styleUrls: ['./campaign-submissions-table.component.scss'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate(800),
      ]),
      transition('* => void', [
        animate(800, style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CampaignSubmissionsTableComponent implements OnInit {
  modalRef: NgbModalRef;
  @Input() campaign: Campaign;
  @Input() creatorApprovals: Array<CreatorApproval>;
  creator: CreatorApproval;
  @Input() columnsToShow = new Map();
  @Input() isPerformance = false;
  currentSortedCol = '';
  columns: Array<string>;
  arrowDirection = '';
  displayApprovals: Array<CreatorApproval> = [];
  submissionsCount: number;
  visibleSubs: Array<boolean> = [];
  summarySubs: Array<SummaryPostDetails> = [];
  tiktok = faTiktok;
  isAdmin = this.userService.isAdmin();

  constructor(
    private readonly dialog: MatDialog,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.submissionsCount = 0;
    this.creatorApprovals.forEach((CA) => {
      if (
        CA.status === 'Confirmed' &&
        CA.getSubmissions(this.isPerformance).filter((S) => !!S.timePosted)
          .length > 0
      ) {
        const creatorApproval = CA.clone();
        const submissionsPosted = (creatorApproval.submissions = creatorApproval
          .getSubmissions(this.isPerformance)
          .filter((S) => !!S.timePosted));
        this.displayApprovals.push(creatorApproval);
        this.visibleSubs[CA.id] = false;
        this.submissionsCount += submissionsPosted.length;
        const summarySub = new SummaryPostDetails();
        summarySub.format = 'All';
        summarySub.timePosted = submissionsPosted.reduce(
          (x, y) => (x < y.timePosted ? x : y.timePosted),
          new Date()
        );
        summarySub.randomImpressions = submissionsPosted.reduce(
          (x, y) => x + y.randomImpressions,
          0
        );
        summarySub.randomReach = submissionsPosted.reduce(
          (x, y) => x + y.randomReach,
          0
        );
        summarySub.likes = submissionsPosted.reduce((x, y) => x + y.likes, 0);
        summarySub.comments = submissionsPosted.reduce(
          (x, y) => x + y.comments,
          0
        );
        summarySub.clicks = submissionsPosted.reduce((x, y) => x + y.clicks, 0);
        summarySub.savedShared = submissionsPosted.reduce(
          (x, y) => x + y.savedShared,
          0
        );
        summarySub.ctr =
          submissionsPosted.reduce((x, y) => x + y.ctr, 0) /
          submissionsPosted.length;
        summarySub.installsAndActions = submissionsPosted.reduce(
          (x, y) => x + y.installsAndActions,
          0
        );
        summarySub.conversions = submissionsPosted.reduce(
          (x, y) => x + y.conversions,
          0
        );
        summarySub.plannerReach = creatorApproval.planner.reduce(
          (x, y) => x + y.totalReach(),
          0
        );
        summarySub.plannerViews = creatorApproval.planner.reduce(
          (x, y) => x + y.totalViews(),
          0
        );
        const randomImpressionsForEngagement = submissionsPosted.reduce(
          (x, y) => {
            if (this.isFormatForEngagment(y.format)) {
              return x + y.randomImpressions;
            }
            return x;
          },
          0
        );
        const interaccionsForEngagement = submissionsPosted.reduce((x, y) => {
          if (this.isFormatForEngagment(y.format)) {
            return x + y.comments + y.likes + y.savedShared;
          }
          return x;
        }, 0);
        let totalEngagements = 0;
        let totalViews = 0;
        creatorApproval.planner.map((planner) =>
          Object.keys(planner.avgViews).map((key) => {
            if (planner.avgViews[key] && planner.avgEngagementsAbs[key]) {
              totalEngagements += planner.avgEngagementsAbs[key];
              totalViews += planner.avgViews[key];
            }
          })
        );
        summarySub.plannerEngagement = totalViews
          ? (totalEngagements / totalViews) * 100
          : 0;
        summarySub.engagement = randomImpressionsForEngagement
          ? (interaccionsForEngagement / randomImpressionsForEngagement) * 100
          : 0;
        summarySub.performanceReach = summarySub.plannerReach
          ? (summarySub.randomReach * 100) / summarySub.plannerReach - 100
          : 0;
        summarySub.performanceViews = summarySub.plannerViews
          ? (summarySub.randomImpressions * 100) / summarySub.plannerViews - 100
          : 0;
        summarySub.performanceEngagement = summarySub.plannerEngagement
          ? ((summarySub.engagement - summarySub.plannerEngagement) /
              summarySub.plannerEngagement) *
            100
          : 0;
        this.summarySubs[CA.id] = summarySub;
      }
    });
    this.columns = Array.from(this.columnsToShow.keys());
  }

  abs(value: number): number {
    return Math.abs(value);
  }

  sign(value: number): string {
    return value > 0 ? '+' : '-';
  }

  setCreator(id: number): boolean {
    this.creator = this.creatorApprovals.find((x) => x.userId === id);
    return true;
  }

  getCreator(id: number): CreatorApproval {
    return (this.creator = this.creatorApprovals.find((x) => x.userId === id));
  }

  goToCreatorProfile(id: number) {
    this.router.navigateByUrl(`/creator/${id}`);
  }

  open(content: TemplateRef<CampaignSubmissionsTableComponent>): void {
    this.modalRef = this.modalService.open(content, {
      windowClass: 'spinner-modal',
    });
  }

  isFormatForEngagment(format: string): boolean {
    return format === 'Reel' ||
    format === 'Feed' ||
    format === 'Video';
  }

  close(): void {
    this.modalRef.close();
  }

  sort(columnName: string): void {
    const sortDirection =
      columnName !== this.currentSortedCol
        ? 'down'
        : this.arrowDirection === 'down'
        ? 'up'
        : 'down';
    const sortOperator = sortDirection === 'up' ? -1 : 1;

    switch (columnName) {
      case 'creator':
        if (
          this.currentSortedCol === 'creator' &&
          this.arrowDirection === 'down'
        ) {
          this.displayApprovals = this.displayApprovals.reverse();
          this.arrowDirection = 'up';
          this.currentSortedCol = columnName;
        } else {
          this.firstSort(columnName);
          this.displayApprovals = this.displayApprovals.sort((x, y) =>
            x.creator.getSocialName() > y.creator.getSocialName() ? -1 : 1
          );
        }
        break;

      case 'format':
        if (
          this.currentSortedCol === 'format' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.format > y.format ? -1 : 1
            )
          );
        }
        break;

      case 'posted date':
        if (
          this.currentSortedCol === 'posted date' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.timePosted > y.timePosted ? -1 : 1
            )
          );
        }
        break;

      // reach
      case 'plannerReach':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].plannerReach >
            this.summarySubs[y.id].plannerReach
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'reach':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].randomReach >
            this.summarySubs[y.id].randomReach
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'performanceReach':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].performanceReach >
            this.summarySubs[y.id].performanceReach
              ? -1
              : 1) * sortOperator
        );
        break;

      // views
      case 'plannerViews':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].plannerViews >
            this.summarySubs[y.id].plannerViews
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'views':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].randomImpressions >
            this.summarySubs[y.id].randomImpressions
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'performanceViews':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].performanceViews >
            this.summarySubs[y.id].performanceViews
              ? -1
              : 1) * sortOperator
        );
        break;

      // engagement
      case 'plannerEngagement':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].plannerEngagement >
            this.summarySubs[y.id].plannerEngagement
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'engagement':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].engagement >
            this.summarySubs[y.id].engagement
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'performanceEngagement':
        this.displayApprovals.sort(
          (x, y) =>
            (this.summarySubs[x.id].performanceEngagement >
            this.summarySubs[y.id].performanceEngagement
              ? -1
              : 1) * sortOperator
        );
        break;

      case 'likes':
        if (
          this.currentSortedCol === 'likes' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.likes > y.likes ? -1 : 1
            )
          );
        }
        break;

      case 'comments':
        if (
          this.currentSortedCol === 'comments' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.comments > y.comments ? -1 : 1
            )
          );
        }
        break;

      // case 'post engagements': if (this.currentSortedCol === 'post engagements' && this.arrowDirection === 'down') {
      //   this.invertSort(columnName);
      // } else {
      //   this.firstSort(columnName);
      //   this.displayApprovals.forEach(
      //     A => this.getPostedSubmissions(A).sort( (x, y) => x.comments + x.likes > y.comments + y.likes ? -1 : 1)
      //   );
      // }
      //   break;

      case 'clicks':
        if (
          this.currentSortedCol === 'clicks' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.clicks > y.clicks ? -1 : 1
            )
          );
        }
        break;

      case 'savedShared':
        if (
          this.currentSortedCol === 'savedShared' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.savedShared > y.savedShared ? -1 : 1
            )
          );
        }
        break;

      case 'conversions':
        if (
          this.currentSortedCol === 'conversions' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.installsAndActions > y.installsAndActions ? -1 : 1
            )
          );
        }
        break;

      case 'conversion rate':
        if (
          this.currentSortedCol === 'conversion rate' &&
          this.arrowDirection === 'down'
        ) {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              (x.clicks !== 0 ? x.installsAndActions / x.clicks : 0) >
              (y.clicks !== 0 ? y.installsAndActions / y.clicks : 0)
                ? -1
                : 1
            )
          );
        }
        break;

      case 'ctr':
        if (this.currentSortedCol === 'ctr' && this.arrowDirection === 'down') {
          this.invertSort(columnName);
        } else {
          this.firstSort(columnName);
          this.displayApprovals.forEach((A) =>
            this.getPostedSubmissions(A).sort((x, y) =>
              x.ctr > y.ctr ? -1 : 1
            )
          );
        }
        break;

      default:
        return;
    }
    this.currentSortedCol = columnName;
    this.arrowDirection = sortDirection;
    this.displayApprovals = this.displayApprovals.map((x) => x);
  }

  private invertSort(columnName): void {
    this.currentSortedCol = columnName;
    this.displayApprovals.forEach((A) =>
      this.getPostedSubmissions(A).reverse()
    );
    this.arrowDirection = 'up';
  }

  private firstSort(columnName: string): void {
    this.currentSortedCol = columnName;
    this.arrowDirection = 'down';
  }

  getPostedSubmissions(creator: CreatorApproval): Array<Submission> {
    return creator
      .getSubmissions(this.isPerformance)
      .filter((x) => !!x.timePosted);
  }

  public openSubmission(sub: Submission): void {
    this.dialog.open(SubmissionDialogComponent, {
      width: '656px',
      data: {
        campaign: this.campaign,
        submission: sub,
        creatorApproval: this.creator,
        creator: this.getCreator(sub.userId).creator,
        isStats: true,
      },
    });
  }

  columnsChangedEvent(key: string, chip: MatChip): void {
    chip.toggleSelected();
    this.columnsToShow.set(key, !this.columnsToShow.get(key));
  }
}
