import {Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Campaign } from '../../../../models/campaign.model';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { CampaignCommercialProposalComponent } from '../campaign-commercial-proposal/campaign-commercial-proposal.component';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaign-briefing',
  templateUrl: './campaign-briefing.component.html',
  styleUrls: ['./campaign-briefing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignBriefingComponent implements OnChanges {

  @Input() campaign: Campaign;
  @Input() creators: CreatorApproval[];
  @Input() creatorsSub: Subscription;

  constructor(public router: Router, private modalService: NgbModal, private userService: UserService,
    ) { 
  }

  @Output() delete = new EventEmitter<void>();
  modalRef: NgbModalRef;
  tiktok = faTiktok;
  isAdmin = this.userService.isAdmin();
  gender : string;
  countryList: string;

  public getGenders():string{
    switch(this.campaign.creatorsGender){
      case 'M':
        this.gender = 'Male';
        break;
      case 'F':
        this.gender = 'Female';
        break;
      case 'Nb':
        this.gender = 'Non binary';
        break;
      case 'M,f':
        this.gender = 'Male, female';
        break;
      case 'F,nb':
        this.gender = 'Female, non binary';
        break;
      case 'M,f,nb':
        this.gender = 'Male, female, non binary';
        break;
    }
    return this.gender;
  }

  public getCreatorType():string{
    let creatorType = '';
    let i = 0;
    this.campaign.creatorType.forEach(function(element){
      if(i < 1){
        creatorType = element.charAt(0).toUpperCase() + element.slice(1);
        i++;
      } else {
        creatorType += ', ' + element; 
        i++;
      }
    })
    return creatorType;
  }

  public getCountryList() :string{
    let countryList = '';
    this.campaign.countryList.forEach(function(element, index){
      if(index < 1){
        countryList = element.country;
      } else {
        countryList += ', ' + element.country; 
      }
    })
    return countryList;
  }

  ngOnChanges() {
    const proposalModal = this.modalRef?.componentInstance as CampaignCommercialProposalComponent;
    if(proposalModal) {
      proposalModal.creators = this.creators;
    }
  }

}
