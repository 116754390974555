
import { Helper } from '../support/helper.support';
import { CreatorApproval, CreatorApprovalPlanner } from './CreatorApproval.model';
import { Submission } from './submission.model';


const FORMAT_PLANNER_TO_SUBMISSION = {
  'feeds': 'Feed',
  'reels': 'Reel',
  'stories': 'Story',
  'youtube_videos': 'Video',
  'youtube_mentions': 'Video',
  'tiktok_videos': 'Video',
}

export class CreatorPlannerContent {
  id: number;
  campaignId: number;
  plannerId: number;
  planner?: CreatorApprovalPlanner;
	userApprovalId: number;
  userApproval?: CreatorApproval;
	platform: string;
	format: string;
	postAt: string;
	description: string;
  script: string;
  approved: boolean = false;
  createdAt: string;
  updatedAt: string;
  submissionId: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  clone() : CreatorPlannerContent {
    return new CreatorPlannerContent(this);
  }

  getPostAtDate(): Date {
    return new Date(this.postAt)
  }

  getPostAtFormatted(): string {
    return this.getPostAtDate().toLocaleDateString('es', { year: 'numeric', month: 'numeric', day: 'numeric' })
  }

  getPostAtWithoutTime() : string {
    return this.postAt.split(' ')[0];
  }

  getFormatInSubmissionStyle() : string {
    return FORMAT_PLANNER_TO_SUBMISSION[this.format];
  }

  public serialize() {
    return {
      id: this.id,
      campaign_id: this.campaignId,
      planner_id: this.plannerId,
      user_approval_id: this.userApprovalId,
      platform: this.platform,
      format: this.format,
      post_at: this.postAt,
      description: this.description,
      script: this.script,
      approved: this.approved,
      submission_id: this.submissionId,
    }
  }

  public deserialize(input: any): CreatorPlannerContent {
    this.id = Helper.numberOrNull(input.id);
    this.campaignId = Helper.numberOrNull(input.campaign_id);
    this.userApprovalId = Helper.numberOrNull(input.user_approval_id);
    this.plannerId = Helper.numberOrNull(input.planner_id);
    if (input.planner) {
      this.planner = (new CreatorApprovalPlanner()).deserialize(input.planner);
    }
    this.platform = input.platform;
    this.format = input.format;
    this.postAt = input.post_at;
    this.description = input.description;
    this.script = input.script;
    this.approved = input.approved;
    this.createdAt = input.created_at;
    this.updatedAt = input.updated_at;
    this.submissionId = Helper.numberOrNull(input.submission_id);
    return this;
  }
}