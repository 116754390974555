import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Brand } from 'src/app/models/brand.model';
import { AlertService } from 'src/app/services/alert.service';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.css']
})
export class CreateBrandComponent {

  @ViewChild('modalContent', { static: true }) content;
  submitted = false;
  brand: Brand;
  modalRef: NgbModalRef;
  @Output() brandCreated = new EventEmitter<Brand>();

  constructor(
    private modalService: NgbModal,
    private brandService: BrandService,
    private alertService: AlertService ) { }

  open(companyId: number): void {
    this.submitted = false;
    this.brand = new Brand(-1, '', '', '', '', companyId);
    this.modalRef = this.modalService.open(this.content, { windowClass: 'app-modal'});
  }

  close(): void {
    this.modalRef.close();
  }

  create(): void {
    if (this.brand.logo === '' || this.brand.name === '') {
      return;
    }
    this.submitted = true;
    this.brandService.createBrand(this.brand.name, this.brand.description, this.brand.logo, this.brand.companyId).subscribe((brand) => {
      this.modalRef.close();
      this.brandCreated.emit(brand);
      this.alertService.emitSuccess('Brand created successfully.');
    }, () => {
      this.modalRef.close();
    });
  }

}
