import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription} from 'rxjs';
import { Creator } from '../../../../models/creator.model';
import { Submission } from '../../../../models/submission.model';
import { SubmissionDataDialog } from '../../../../models/SubmissionDataDialog.model';
import { NotificationService } from '../../../../services/notification.service';
import { SubmissionService } from '../../../../services/submission.service';
import { NotificationTypes } from '../../../../ui/footer/notification/const';
import { Privilege } from '../../../../models/role.model';
import { Campaign } from '../../../../models/campaign.model';
import { UserService } from '../../../../services/user.service';
import { CreatorApproval, CreatorApprovalPlanner, PlannerContent } from '../../../../models/CreatorApproval.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Time, TimeOptions, TimeOptionsList } from '../../../../models/timeOptions.model';
import { Band, BandList } from '../../../../models/band.model';
import { MatChip } from '@angular/material/chips';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { take } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-content-planner-dialog',
  templateUrl: './content-planner-dialog.component.html',
  styleUrls: ['./content-planner-dialog.component.scss']
})
export class ContentPlannerDialogComponent {

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  content: CreatorPlannerContent;
  creatorApproval: CreatorApproval;
  planner: CreatorApprovalPlanner;
  platform: string;
  campaign: Campaign;
  form: FormGroup;
  loading: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<ContentPlannerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      content: CreatorPlannerContent,
      campaign: Campaign,
      creatorApproval: CreatorApproval,
    },
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    private _ngZone: NgZone
    ) {
      
      this.content = data.content;
      this.platform = data.content.platform;
      this.campaign = data.campaign;
      this.creatorApproval = data.creatorApproval;
      this.createForm();
    }
  
  createForm() {
    this.form = this.formBuilder.group({
      id: [this.content.id || null],
      userApprovalId: [this.creatorApproval.id],
      postAt: [this.content.postAt ? new Date(this.content.postAt) : null, Validators.required],
      platform: [this.platform],
      format: [this.content.format || null, Validators.required],
      description: [this.content.description || null],
      script: [this.content.script || null],
      approved: [this.content.approved ? '1' : '0', Validators.required],
      submissionId: [this.content.submissionId],
    });
  }

  async save() {
    if (!this.form.dirty) {
      return this.close();
    }
    this.loading = true;
    try {
      const data = new CreatorPlannerContent(this.form.value);
      const updated = await this.campaignService.updatePlannerContent(this.campaign, data).toPromise();
      Object.assign(this.content, updated);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
    this.close();
  }

  // updatePlanner() {
  //   this.planner
  // }


  close() {
    this.dialogRef.close(this.content);
  }


  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
