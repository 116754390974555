import {state, style, trigger } from "@angular/animations";

const closed = style({
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    whiteSpace: "nowrap",
    opacity: 1
})

const open = style({
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    opacity: 1,
})

const headerCaptionClosed = state('closed', closed);
const headerCaptionOpen = state('open', open);
const headerCaptionPreOpenChange = state('preOpenChange', closed);
const headerCaptionPreClosedChange = state('preClosedChange', open);
const headerPreClosed = state('preClosed', style({
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    whiteSpace: "nowrap",
    opacity: 0,
}));
const headerCaptionPreOpen = state('preOpen', style({
    display: "flex",
    marginLeft: "10px",
    opacity: 0
}));

  export class HeaderCaptionAnimation {
    public static headerCaption = trigger('headerCaption', [
        headerCaptionOpen,
        headerCaptionClosed,
        headerCaptionPreClosedChange,
        headerPreClosed,
        headerCaptionPreOpen,
        headerCaptionPreOpenChange,
      ])
  }
  