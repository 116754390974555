import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatorsManagerComponent } from './creators-manager/creators-manager.component';
import { YoutubeAudienceComponent } from './youtube-audience/youtube-audience.component';
import { InstagramAudienceComponent } from './instagram-audience/instagram-audience.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignModule } from '../campaign/campaign.module';
import { NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CreatorEditComponent } from './creator-edit/creator-edit.component';
import { TiktokAudienceEditorComponent } from './tiktok-audience-editor/tiktok-audience-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PrecampaignStatsComponent } from './precampaign-stats/precampaign-stats.component';
import { SharedModule } from '../shared/shared.module';
import { CreatorHistoryComponent } from './creator-history/creator-history.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewCreatorModalComponent } from './new-creator-modal/new-creator-modal.component';
import { CreatorDetailsCardComponent } from './creator-details-card/creator-details-card.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ThousandsPipe } from 'src/app/pipes/thousands.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    CreatorsManagerComponent,
    YoutubeAudienceComponent,
    InstagramAudienceComponent,
    CreatorEditComponent,
    TiktokAudienceEditorComponent,
    PrecampaignStatsComponent,
    CreatorHistoryComponent,
    NewCreatorModalComponent,
    CreatorDetailsCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CampaignModule,
    NgbAlertModule,
    TranslateModule,
    ImageCropperModule,
    NgbDropdownModule,
    SharedModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule
  ]
})
export class CreatorsModule { }
