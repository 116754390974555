import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { UserService } from './user.service';
import { share, catchError, retryWhen, mergeMap, finalize, last, endWith } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { EnvironmentService } from './environment.service';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../support/helper.support';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  private baseUrl: string = this.environmentService.getInfluencersURI();
  logout: Subject<any>;

  constructor(private http: HttpClient, private userService: UserService, private readonly environmentService: EnvironmentService,
    private errorService: ErrorService, private router: Router, private alertService: AlertService, private translateService: TranslateService) {
    this.logout = this.userService.getLogoutEvent();
  }

  public getLogoutEvent(): Subject<any> {
    return this.logout;
  }

  getLang(): string {
    return this.translateService.currentLang;
  }

  public get(url: string, parameters?: string ): Observable<any> {
    const token = this.userService.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      }),
      body: ''
    };
    return this.http.get(this.baseUrl + url + (parameters === undefined ? '' : '?' + parameters),
      httpOptions).pipe(
        share(),
        catchError((err) => this.requestError(err)));
  }

  public post(url: string, entity: any): Observable<any> {
    const token = this.userService.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + url, entity, httpOptions)
      .pipe(
        share(),
        catchError((err) => this.requestError(err))
      );
  }

  public put(url: string, entity: any): Observable<any> {
    const token = this.userService.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      })
    };
    return this.http.put(this.baseUrl + url, entity, httpOptions)
      .pipe(
        share(),
        catchError((err) => this.requestError(err))
      );
  }
  public delete(url: string, entity: any): Observable<any> {
    const token = this.userService.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
        Authorization: 'bearer ' + token
      }),
      body: entity
    };
    return this.http.delete(this.baseUrl + url, httpOptions)
      .pipe(
        share(),
        catchError((err) => this.requestError(err))
      );
  }

  public postWithFile(url: string, formData: FormData): Observable<any> {
    const token = this.userService.authToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': this.getLang(),
        Authorization: 'bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + url, formData, httpOptions)
      .pipe(
        share(),
        catchError((err) => this.requestError(err))
      );
  }

  public postFile(url: string, file: File): Observable<number> {
    return new Observable<number>((observer) => {
      let maxBlockSize = 92 * 1024 * 1024; // 92MB
      if (file.size < maxBlockSize) {
        maxBlockSize = file.size;
      }
      let currentFilePointer = 0;
      let totalBytesRemaining = file.size;
      const blockIds = [];
      const blockIdPrefix = 'block-';

      const reader = new FileReader();
      const http = this.http;

      const loadChunk = () => {
        const nextChunk = file.slice(currentFilePointer, currentFilePointer + maxBlockSize);
        const blockId = blockIdPrefix + blockIds.length.toString().padStart(6, '0');
        blockIds.push(btoa(blockId));
        currentFilePointer += maxBlockSize;
        totalBytesRemaining -= maxBlockSize;
        if (totalBytesRemaining < maxBlockSize) {
          maxBlockSize = totalBytesRemaining;
        }
        reader.readAsArrayBuffer(nextChunk);
      };
      const commitUpload = () => {
        const commitUrl = url + '&comp=blocklist';
        let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (let i = 0; i < blockIds.length; i++) {
          requestBody += '<Latest>' + blockIds[i] + '</Latest>';
        }
        requestBody += '</BlockList>';
        return http.put(commitUrl, requestBody, {
          headers: {
            'x-ms-blob-content-type': file.type
          }
        });
      };

      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) { // DONE == 2
          const chunkUrl = url + '&comp=block&blockid=' + blockIds[blockIds.length - 1];
          // Upload chunk
          http.put(chunkUrl, evt.target.result, {
            headers: {
              'x-ms-blob-type': 'BlockBlob'
            }
          }).subscribe(() => {
            if (totalBytesRemaining > 0) {
              // Load next chunk if still remaining
              observer.next(1 - (totalBytesRemaining / file.size));
              loadChunk();
            } else {
              // Commit upload if finished
              commitUpload().subscribe(() => observer.next(1), error => observer.error(error));
            }
          }, error => observer.error(error));
        }
      };
      loadChunk();
    });
  }

  public getPublic(url: string, parameters?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
      }),
      body: ''
    };
    // Remove the /auth
    const publicUrl = this.baseUrl.substr(0, this.baseUrl.length - 5);
    return this.http.get(publicUrl + url + (parameters === undefined ? '' : '?' + parameters),
      httpOptions);
  }

  public postPublic(url: string, data: {[key: string]: any}) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.getLang(),
        Accept: 'application/json',
      })
    };
    // Remove the /auth
    const publicUrl = this.baseUrl.substr(0, this.baseUrl.length - 5);
    return this.http.post(publicUrl + url, data, httpOptions);
  }

  public getExternal(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      body: ''
    };
    return this.http.get(url, httpOptions);
  }


  private async requestError(err: HttpErrorResponse) {

    

    if (err.error && err.error.message) {

      console.error(err.error);

      // const msg = [err.error.message];
      // if (err.error.errors) {
      //   msg.push(err.error.errors);
      // }
      // this.alertService.emitError(Helper.anyToStringRecursive(msg));
      this.alertService.emitError(err.error.message);
    } else {
      console.error(err);
      if (err.status == 403) {
        this.alertService.emitError('Forbidden');
      } else if (err.status == 401) {
        this.alertService.emitError('Unauthorized');
      } else {
        this.alertService.emitError('An error has occurred, please try again later.');
      }
    }

    // unauthorized
    if (err.status && (err.status === 401)) {
      this.logout.next();
      await this.userService.logout();
      return throwError(err.message);
    }

    // forbiden
    if (err.status && (err.status === 403)) {
      throw err;
    }

    // backend error
    if (err.status >= 500) {
      if (err.message) {
        this.alertService.emitError(Helper.anyToStringRecursive(err.message));
      }
      throw err;
    }

    throw err;
  }
}
