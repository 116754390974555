import { Component, Input, OnChanges} from '@angular/core';
import { Brand } from 'src/app/models/brand.model';
import { Company } from 'src/app/models/company.model';
import { AlertService } from 'src/app/services/alert.service';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-company-brands',
  templateUrl: './company-brands.component.html',
  styleUrls: ['./company-brands.component.scss']
})
export class CompanyBrandsComponent implements OnChanges {

  @Input()
  company: Company;

  @Input()
  brands: Brand[] = [];
  brandsList: Brand[] = [];

  searchString = '';
  loading = false;

  constructor(private brandService: BrandService, private alertService: AlertService) { }

  ngOnChanges(): void {
    this.brandsList = this.brands;
  }

  search(value: string) {
    this.searchString = value;
    const lowerSearch = this.searchString.toLowerCase();
    this.brandsList = this.brands.filter(u => u.name.toLowerCase().startsWith(lowerSearch));
  }

  brandCreated(brand: Brand) {
    this.brandsList.push(brand);
  }

  deleteBrand(brandId: number) {
    this.loading = true;
    this.brandService.deleteBrand(this.company.id, brandId).subscribe(() => {
      this.loading = false;
      this.brandsList = this.brandsList.filter(b => b.id !== brandId);
      this.alertService.emitSuccess( 'Brand was deleted.');
    });
  }
}
