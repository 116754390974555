import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

    constructor() { }

    public getAzureStoragePath(): string {
        return environment.azureStoragePath;
    }

    public getInfluencersURI(): string {
        return environment.baseUrl;
    }
}
