import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from 'src/app/models/campaign.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { Subscription, interval } from 'rxjs';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { CampaignService } from 'src/app/services/campaign.service';
import { ActivatedRoute } from '@angular/router';
import { CampaignScenario } from 'src/app/models/campaign.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/modules/shared/dialog-confirm/dialog-confirm.component';
import { is } from 'date-fns/locale';
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignPresentation } from 'src/app/models/campaignPresentation.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-campaign-commercial-proposal2',
  templateUrl: './campaign-commercial-proposal2.component.html',
  styleUrls: ['./campaign-commercial-proposal2.component.scss'],
})
export class CampaignCommercialProposal2Component implements OnInit {
 
  @Input() campaign: Campaign;
  successCasesNames: string[] = [];
  successCases: string[]  = [];
  isEdit = false;
  isLoading = false;
  lastRevisionId: string;
 
  constructor(
    private modalService: NgbModal,
    private campaignService: CampaignService,
    private readonly dialog: MatDialog,
    private appService: AppService,
  ) {
    this.successCasesNames = this.appService.configBackend.campaign_presentation_success_cases;
  }

  ngOnInit() {
    if (this.getPresentationId()) {
      this.resetSelectedCases();
      this.setCampaignRevisionToLastRevision();
      this.fetchLastRevisionId();
    } else {
      this.setEdit(true);
    }
  }

  close() {
    this.modalService.dismissAll(); 
  }

  setEdit(value: boolean) {
    this.isEdit = value;
    this.resetSelectedCases();
  }

  getPresentationId() {
    return this.campaign.campaignPresentation?.file_id;
  }

  getPresentationUrl() {
    const presentationId = this.getPresentationId();
    return `https://docs.google.com/presentation/d/${presentationId}/embed?start=false&loop=false&delayms=3000`;
  }

  resetSelectedCases() {
    this.successCases = (this.campaign.campaignPresentation?.success_cases || []).map(x => x);
  }

  isChecked(value: string) {
    return this.successCases.includes(value);
  }

  checkBoxChecked(checked: boolean, name: string) {
    if (checked) {
      this.successCases.push(name);
    } else {
      this.successCases = this.successCases.filter((sc) => sc !== name);
    }
  }

  fetchLastRevisionId() {
    this.campaignService
      .getPresentationLatestRevision(this.campaign)
      .subscribe((latestRevisionId) => {
        this.lastRevisionId = latestRevisionId;
      });
  }

  isRevisionOld() {
    return this.lastRevisionId ?
      this.lastRevisionId !== this.campaign.campaignPresentation.revision_id
      : false;
  }

  setCampaignRevisionToLastRevision() {
    this.lastRevisionId = this.campaign.campaignPresentation.revision_id;
  }

  create() {
    this.isLoading = true;
    const data = { 'success_cases': this.successCases };
    this.campaignService
      .createPresentation(this.campaign, data)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.setEdit(false);
          this.campaign.campaignPresentation = res;
          this.setCampaignRevisionToLastRevision();
          this.resetSelectedCases();
        }, (error) => {
          this.isLoading = false;
        });
  }

  update() {    
    this.isLoading = true;
    const data = { 'success_cases': this.successCases };
    this.campaignService
      .updatePresentation(this.campaign, this.campaign.campaignPresentation, data)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.setEdit(false);
          this.campaign.campaignPresentation = res;
          this.setCampaignRevisionToLastRevision();
          this.resetSelectedCases();
        }, (error) => {
          this.isLoading = false;
        });
  }

  updateConfig() {
    if (this.campaign.campaignPresentation) {
      const successCasesCampaign = this.campaign.campaignPresentation.success_cases.sort().join(',');
      const successCasesEdit = this.successCases.sort().join(',');
      if(successCasesCampaign == successCasesEdit){
        this.setEdit(false);
        return;
      }
      this.update();
    } else {
      this.create();
    }
  }

  new() {
    this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirm Create New Presentation',
        description: 'Are you sure you want to delete and create a new presentation?',
      },
    }).afterClosed().subscribe(ok => {
      if(!ok) {
        return;
      }
      this.create();
    });
  }

  export(downloadFile = false) {
    this.isLoading = true;
    this.campaignService
      .exportPresentation(this.campaign)
      .subscribe((res) => {
        this.campaign.campaignPresentation = res;
        this.setCampaignRevisionToLastRevision();
        this.resetSelectedCases();
        this.isLoading = false;
        if (downloadFile) {
          window.open(this.campaign.campaignPresentation.file_pdf, '_blank');
        }
      }, (error) => {
        this.isLoading = false;
      });
  }

  openPresentation() {
    const presentationId = this.getPresentationId();
    const link = `https://docs.google.com/presentation/d/${presentationId}`;
    window.open(link, '_blank');
  }

  downloadPptx() {
    const presentationId = this.getPresentationId();
    const link = `https://docs.google.com/feeds/download/presentations/Export?id=${presentationId}&exportFormat=pptx`
    window.open(link, '_blank');
  } 

}
