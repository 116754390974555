export class Country {
  readonly code2: string;
  readonly code_country: number;
  readonly country: string;

  constructor(code2: string, code_country: number, country: string) {
    this.code2 = code2;
    this.code_country = code_country;
    this.country = country;
  }

  public toString(): string {
    return this.country;
  }
}

export class CountryList {
  public static readonly countryList: Array<Country> = [
    new Country ( 'AF', 3, 'Afghanistan' ),
    new Country ( 'AX', 15, 'Åland' ),
    new Country ( 'AL', 6, 'Albania' ),
    new Country ( 'DZ', 62, 'Algeria' ),
    new Country ( 'AS', 11, 'American Samoa' ),
    new Country ( 'AD', 1, 'Andorra' ),
    new Country ( 'AO', 8, 'Angola' ),
    new Country ( 'AI', 5, 'Anguilla' ),
    new Country ( 'AQ', 9, 'Antarctica' ),
    new Country ( 'AG', 4, 'Antigua and Barbuda' ),
    new Country ( 'AR', 10, 'Argentina' ),
    new Country ( 'AM', 7, 'Armenia' ),
    new Country ( 'AW', 14, 'Aruba' ),
    new Country ( 'AU', 13, 'Australia' ),
    new Country ( 'AT', 12, 'Austria' ),
    new Country ( 'AZ', 16, 'Azerbaijan' ),
    new Country ( 'BS', 32, 'Bahamas' ),
    new Country ( 'BH', 23, 'Bahrain' ),
    new Country ( 'BD', 19, 'Bangladesh' ),
    new Country ( 'BB', 18, 'Barbados' ),
    new Country ( 'BY', 36, 'Belarus' ),
    new Country ( 'BE', 20, 'Belgium' ),
    new Country ( 'BZ', 37, 'Belize' ),
    new Country ( 'BJ', 25, 'Benin' ),
    new Country ( 'BM', 27, 'Bermuda' ),
    new Country ( 'BT', 33, 'Bhutan' ),
    new Country ( 'BO', 29, 'Bolivia' ),
    new Country ( 'BQ', 30, 'Bonaire' ),
    new Country ( 'BA', 17, 'Bosnia and Herzegovina' ),
    new Country ( 'BW', 35, 'Botswana' ),
    new Country ( 'BV', 34, 'Bouvet Island' ),
    new Country ( 'BR', 31, 'Brazil' ),
    new Country ( 'VG', 239, 'British Virgin Islands' ),
    new Country ( 'BN', 28, 'Brunei' ),
    new Country ( 'BG', 22, 'Bulgaria' ),
    new Country ( 'BF', 21, 'Burkina Faso' ),
    new Country ( 'BI', 24, 'Burundi' ),
    new Country ( 'KH', 117, 'Cambodia' ),
    new Country ( 'CM', 47, 'Cameroon' ),
    new Country ( 'CA', 38, 'Canada' ),
    new Country ( 'CV', 52, 'Cape Verde' ),
    new Country ( 'KY', 124, 'Cayman Islands' ),
    new Country ( 'CF', 41, 'Central African Republic' ),
    new Country ( 'TD', 215, 'Chad' ),
    new Country ( 'CL', 46, 'Chile' ),
    new Country ( 'CN', 48, 'China' ),
    new Country ( 'CX', 54, 'Christmas Island' ),
    new Country ( 'CC', 39, 'Cocos [Keeling] Islands' ),
    new Country ( 'CO', 49, 'Colombia' ),
    new Country ( 'KM', 119, 'Comoros' ),
    new Country ( 'CK', 45, 'Cook Islands' ),
    new Country ( 'CR', 50, 'Costa Rica' ),
    new Country ( 'HR', 98, 'Croatia' ),
    new Country ( 'CU', 51, 'Cuba' ),
    new Country ( 'CW', 53, 'Curacao' ),
    new Country ( 'CY', 55, 'Cyprus' ),
    new Country ( 'CZ', 56, 'Czech Republic' ),
    new Country ( 'CD', 40, 'Democratic Republic of the Congo' ),
    new Country ( 'DK', 59, 'Denmark' ),
    new Country ( 'DJ', 58, 'Djibouti' ),
    new Country ( 'DM', 60, 'Dominica' ),
    new Country ( 'DO', 61, 'Dominican Republic' ),
    new Country ( 'TL', 221, 'East Timor' ),
    new Country ( 'EC', 63, 'Ecuador' ),
    new Country ( 'EG', 65, 'Egypt' ),
    new Country ( 'SV', 210, 'El Salvador' ),
    new Country ( 'GQ', 88, 'Equatorial Guinea' ),
    new Country ( 'ER', 67, 'Eritrea' ),
    new Country ( 'EE', 64, 'Estonia' ),
    new Country ( 'ET', 69, 'Ethiopia' ),
    new Country ( 'FK', 72, 'Falkland Islands' ),
    new Country ( 'FO', 74, 'Faroe Islands' ),
    new Country ( 'FJ', 71, 'Fiji' ),
    new Country ( 'FI', 70, 'Finland' ),
    new Country ( 'FR', 75, 'France' ),
    new Country ( 'GF', 80, 'French Guiana' ),
    new Country ( 'FG', 261, 'French Guiana' ),
    new Country ( 'PF', 175, 'French Polynesia' ),
    new Country ( 'TF', 216, 'French Southern Territories' ),
    new Country ( 'GA', 76, 'Gabon' ),
    new Country ( 'GM', 85, 'Gambia' ),
    new Country ( 'GE', 79, 'Georgia' ),
    new Country ( 'DE', 57, 'Germany' ),
    new Country ( 'GH', 82, 'Ghana' ),
    new Country ( 'GI', 83, 'Gibraltar' ),
    new Country ( 'GR', 89, 'Greece' ),
    new Country ( 'GL', 84, 'Greenland' ),
    new Country ( 'GD', 78, 'Grenada' ),
    new Country ( 'GP', 87, 'Guadeloupe' ),
    new Country ( 'GU', 92, 'Guam' ),
    new Country ( 'GT', 91, 'Guatemala' ),
    new Country ( 'GG', 81, 'Guernsey' ),
    new Country ( 'GN', 86, 'Guinea' ),
    new Country ( 'GW', 93, 'Guinea-Bissau' ),
    new Country ( 'GY', 94, 'Guyana' ),
    new Country ( 'HT', 99, 'Haiti' ),
    new Country ( 'HM', 96, 'Heard Island and McDonald Islands' ),
    new Country ( 'HN', 97, 'Honduras' ),
    new Country ( 'HK', 95, 'Hong Kong' ),
    new Country ( 'HU', 100, 'Hungary' ),
    new Country ( 'IS', 109, 'Iceland' ),
    new Country ( 'IN', 105, 'India' ),
    new Country ( 'ID', 101, 'Indonesia' ),
    new Country ( 'IR', 108, 'Iran' ),
    new Country ( 'IQ', 107, 'Iraq' ),
    new Country ( 'IE', 102, 'Ireland' ),
    new Country ( 'IM', 104, 'Isle of Man' ),
    new Country ( 'IL', 103, 'Israel' ),
    new Country ( 'IT', 110, 'Italy' ),
    new Country ( 'CI', 44, 'Ivory Coast' ),
    new Country ( 'JM', 112, 'Jamaica' ),
    new Country ( 'JP', 114, 'Japan' ),
    new Country ( 'JE', 111, 'Jersey' ),
    new Country ( 'JO', 113, 'Jordan' ),
    new Country ( 'KZ', 125, 'Kazakhstan' ),
    new Country ( 'KE', 115, 'Kenya' ),
    new Country ( 'KI', 118, 'Kiribati' ),
    new Country ( 'XK', 245, 'Kosovo' ),
    new Country ( 'KW', 123, 'Kuwait' ),
    new Country ( 'KG', 116, 'Kyrgyzstan' ),
    new Country ( 'LA', 126, 'Laos' ),
    new Country ( 'LV', 135, 'Latvia' ),
    new Country ( 'LB', 127, 'Lebanon' ),
    new Country ( 'LS', 132, 'Lesotho' ),
    new Country ( 'LR', 131, 'Liberia' ),
    new Country ( 'LY', 136, 'Libya' ),
    new Country ( 'LI', 129, 'Liechtenstein' ),
    new Country ( 'LT', 133, 'Lithuania' ),
    new Country ( 'LU', 134, 'Luxembourg' ),
    new Country ( 'MO', 148, 'Macao' ),
    new Country ( 'MK', 144, 'Macedonia' ),
    new Country ( 'MG', 142, 'Madagascar' ),
    new Country ( 'MW', 156, 'Malawi' ),
    new Country ( 'MY', 158, 'Malaysia' ),
    new Country ( 'MV', 155, 'Maldives' ),
    new Country ( 'ML', 145, 'Mali' ),
    new Country ( 'MT', 153, 'Malta' ),
    new Country ( 'MH', 143, 'Marshall Islands' ),
    new Country ( 'MQ', 150, 'Martinique' ),
    new Country ( 'MR', 151, 'Mauritania' ),
    new Country ( 'MU', 154, 'Mauritius' ),
    new Country ( 'YT', 247, 'Mayotte' ),
    new Country ( 'MX', 157, 'Mexico' ),
    new Country ( 'FM', 73, 'Micronesia' ),
    new Country ( 'MD', 139, 'Moldova' ),
    new Country ( 'MC', 138, 'Monaco' ),
    new Country ( 'MN', 147, 'Mongolia' ),
    new Country ( 'ME', 140, 'Montenegro' ),
    new Country ( 'MS', 152, 'Montserrat' ),
    new Country ( 'MA', 137, 'Morocco' ),
    new Country ( 'MZ', 159, 'Mozambique' ),
    new Country ( 'MM', 146, 'Myanmar [Burma]' ),
    new Country ( 'NA', 160, 'Namibia' ),
    new Country ( 'NR', 169, 'Nauru' ),
    new Country ( 'NP', 168, 'Nepal' ),
    new Country ( 'NL', 166, 'Netherlands' ),
    new Country ( 'AN', 259, 'Netherlands Antilles' ),
    new Country ( 'NC', 161, 'New Caledonia' ),
    new Country ( 'NZ', 171, 'New Zealand' ),
    new Country ( 'NI', 165, 'Nicaragua' ),
    new Country ( 'NE', 162, 'Niger' ),
    new Country ( 'NG', 164, 'Nigeria' ),
    new Country ( 'NU', 170, 'Niue' ),
    new Country ( 'NF', 163, 'Norfolk Island' ),
    new Country ( 'KP', 121, 'North Korea' ),
    new Country ( 'MP', 149, 'Northern Mariana Islands' ),
    new Country ( 'NO', 167, 'Norway' ),
    new Country ( 'OM', 172, 'Oman' ),
    new Country ( 'PK', 178, 'Pakistan' ),
    new Country ( 'PW', 185, 'Palau' ),
    new Country ( 'PS', 183, 'Palestine' ),
    new Country ( 'PA', 173, 'Panama' ),
    new Country ( 'PG', 176, 'Papua New Guinea' ),
    new Country ( 'PY', 186, 'Paraguay' ),
    new Country ( 'PE', 174, 'Peru' ),
    new Country ( 'PH', 177, 'Philippines' ),
    new Country ( 'PN', 181, 'Pitcairn Islands' ),
    new Country ( 'PL', 179, 'Poland' ),
    new Country ( 'PT', 184, 'Portugal' ),
    new Country ( 'PR', 182, 'Puerto Rico' ),
    new Country ( 'QA', 187, 'Qatar' ),
    new Country ( 'CG', 42, 'Republic of the Congo' ),
    new Country ( 'RE', 188, 'Réunion' ),
    new Country ( 'RO', 189, 'Romania' ),
    new Country ( 'RU', 191, 'Russia' ),
    new Country ( 'RW', 192, 'Rwanda' ),
    new Country ( 'BL', 26, 'Saint Barthélemy' ),
    new Country ( 'SH', 199, 'Saint Helena' ),
    new Country ( 'KN', 120, 'Saint Kitts and Nevis' ),
    new Country ( 'LC', 128, 'Saint Lucia' ),
    new Country ( 'MF', 141, 'Saint Martin' ),
    new Country ( 'PM', 180, 'Saint Pierre and Miquelon' ),
    new Country ( 'VC', 237, 'Saint Vincent and the Grenadines' ),
    new Country ( 'WS', 244, 'Samoa' ),
    new Country ( 'SM', 204, 'San Marino' ),
    new Country ( 'ST', 209, 'São Tomé and Príncipe' ),
    new Country ( 'SA', 193, 'Saudi Arabia' ),
    new Country ( 'SN', 205, 'Senegal' ),
    new Country ( 'RS', 190, 'Serbia' ),
    new Country ( 'SC', 195, 'Seychelles' ),
    new Country ( 'SL', 203, 'Sierra Leone' ),
    new Country ( 'SG', 198, 'Singapore' ),
    new Country ( 'SX', 211, 'Sint Maarten' ),
    new Country ( 'SK', 202, 'Slovakia' ),
    new Country ( 'SI', 200, 'Slovenia' ),
    new Country ( 'SB', 194, 'Solomon Islands' ),
    new Country ( 'SO', 206, 'Somalia' ),
    new Country ( 'ZA', 248, 'South Africa' ),
    new Country ( 'GS', 90, 'South Georgia and the South Sandwich Islands' ),
    new Country ( 'KR', 122, 'South Korea' ),
    new Country ( 'SS', 208, 'South Sudan' ),
    new Country ( 'ES', 68, 'Spain' ),
    new Country ( 'LK', 130, 'Sri Lanka' ),
    new Country ( 'SD', 196, 'Sudan' ),
    new Country ( 'SR', 207, 'Suriname' ),
    new Country ( 'SJ', 201, 'Svalbard and Jan Mayen' ),
    new Country ( 'SZ', 213, 'Swaziland' ),
    new Country ( 'SE', 197, 'Sweden' ),
    new Country ( 'CH', 43, 'Switzerland' ),
    new Country ( 'SY', 212, 'Syria' ),
    new Country ( 'TW', 228, 'Taiwan' ),
    new Country ( 'TJ', 219, 'Tajikistan' ),
    new Country ( 'TZ', 229, 'Tanzania' ),
    new Country ( 'TH', 218, 'Thailand' ),
    new Country ( 'TG', 217, 'Togo' ),
    new Country ( 'TK', 220, 'Tokelau' ),
    new Country ( 'TO', 224, 'Tonga' ),
    new Country ( 'TT', 226, 'Trinidad and Tobago' ),
    new Country ( 'TN', 223, 'Tunisia' ),
    new Country ( 'TR', 225, 'Turkey' ),
    new Country ( 'TM', 222, 'Turkmenistan' ),
    new Country ( 'TC', 214, 'Turks and Caicos Islands' ),
    new Country ( 'TV', 227, 'Tuvalu' ),
    new Country ( 'VI', 240, 'U.S. Virgin Islands' ),
    new Country ( 'UG', 231, 'Uganda' ),
    new Country ( 'UA', 230, 'Ukraine' ),
    new Country ( 'AE', 2, 'United Arab Emirates' ),
    new Country ( 'UK', 77, 'United Kingdom (UK)' ),
    new Country ( 'US', 233, 'United States' ),
    new Country ( 'UY', 234, 'Uruguay' ),
    new Country ( 'UZ', 235, 'Uzbekistan' ),
    new Country ( 'VU', 242, 'Vanuatu' ),
    new Country ( 'VA', 236, 'Vatican City' ),
    new Country ( 'VE', 238, 'Venezuela' ),
    new Country ( 'VN', 241, 'Vietnam' ),
    new Country ( 'WF', 243, 'Wallis and Futuna' ),
    new Country ( 'EH', 66, 'Western Sahara' ),
    new Country ( 'YE', 246, 'Yemen' ),
    new Country ( 'ZM', 249, 'Zambia' ),
    new Country ( 'ZW', 250, 'Zimbabwe')
  ];
}
