import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { RegisterComponent } from './modules/login/register/register.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { AuthGuardGuard } from './auth-guard.guard';
import { LayoutComponent } from './ui/layout/layout.component';
import { ProfileComponent } from './modules/brand/profile/profile.component';
import { CreateCampaignComponent } from './modules/campaign/create/create-campaign/create-campaign.component';
import { CampaignComponent } from './modules/campaign/campaign/campaign.component';
import { StatisticsComponent } from './modules/campaign/public/statistics/statistics.component';
import { CreatorsComponent } from './modules/campaign/public/creators/creators.component';
import { AdminGuard } from './admin.guard';
import { CompanyApprovalComponent } from './modules/company/company-approval/company-approval.component';
import { ReportsComponent } from './modules/reports/reports/reports.component';
import { CreatorsManagerComponent } from './modules/creators/creators-manager/creators-manager.component';
import { NotificationComponent } from './modules/notification/notification/notification.component';
import { CampaignCommercialProposalComponent } from './modules/campaign/campaign/campaign-commercial-proposal/campaign-commercial-proposal.component';
import { SuggestedPricesComponent } from './modules/suggested-prices/suggested-prices/suggested-prices.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardGuard] },
  { path: 'login/:confirmed', component: LoginComponent, canActivate: [AuthGuardGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuardGuard] },
  { path: 'recoverPassword', component: ForgotPasswordComponent, canActivate: [AuthGuardGuard] },
  { path: 'campaignStats/:token', component: StatisticsComponent },
  { path: 'campaignCreators/:token', component: CreatorsComponent },
  { path: 'commercialProposals/:token', component: CampaignCommercialProposalComponent },
  { path: '', component: LayoutComponent, canActivate: [AuthGuardGuard],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'campaign/edit/:id', component: CreateCampaignComponent },
      { path: 'campaign/create', component: CreateCampaignComponent },
      { path: 'campaign/:id', component: CampaignComponent },
      { path: 'campaign/:id/:tab', component: CampaignComponent },
      { path: 'creator', component: CreatorsManagerComponent, canActivate: [AdminGuard] },
      { path: 'creator/:id', component: CreatorsManagerComponent, canActivate: [AdminGuard] },
      { path: 'companyApproval', component: CompanyApprovalComponent, canActivate: [AdminGuard] },
      { path: 'companyApproval/:companyId', component: CompanyApprovalComponent, canActivate: [AdminGuard] },
      { path: 'myCompany', component: CompanyApprovalComponent },
      { path: 'reports', component: ReportsComponent, canActivate: [AdminGuard] },
      { path: 'suggestedPrices', component: SuggestedPricesComponent, canActivate: [AdminGuard] },
      { path: 'notifications', component: NotificationComponent, canActivate: [AdminGuard] },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]},
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuardGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
