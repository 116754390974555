import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, Inject, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { Campaign } from '../../../../models/campaign.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { SubmissionService } from '../../../../services/submission.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CampaignService } from '../../../../services/campaign.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';

@Component({
  selector: 'app-create-submission',
  templateUrl: './create-submission.component.html',
  styleUrls: ['./create-submission.component.scss']
})
export class CreateSubmissionComponent implements OnInit, OnDestroy {

  campaign: Campaign;
  approvals: Array<CreatorApproval>;
  selectedApproval: CreatorApproval;
  plannerContents: CreatorPlannerContent[];
  selectedPlannerContent: CreatorPlannerContent;
  submission: Submission;
  submitted = false;
  subscription: Subscription;
  posting = false;
  loadingText: string;
  validVideoUrl = true;
  usernames: Array<string> = [];
  
  constructor(
    private submissionService: SubmissionService, 
    private ts: TranslateService,
    public dialogRef: MatDialogRef<CreateSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      approvedCreators: CreatorApproval[],
      campaign: Campaign,
    },
    private readonly campaingService: CampaignService
  ) {
    this.campaign = data.campaign;
    this.approvals = data.approvedCreators;
    this.approvals.forEach(x => this.usernames.push(x.creator.getSocialName()));
    this.setPlannerContents(this.approvals);
  }
    
  public ngOnInit(): void {
    this.setSubmission();
  }
  
  setSubmission() {
    const sub = this.submission = new Submission();
    const content = this.selectedPlannerContent;
    sub.campaignId =  this.campaign.id;
    if (content) {
      sub.platform = content.platform;
      sub.format = content.getFormatInSubmissionStyle();
      sub.dateToPost = content.getPostAtWithoutTime();
      sub.userId = content.userApproval.creator.id;
      sub.plannerContentId = content.id;
    }
  }

  onChangePlannerContent(content: CreatorPlannerContent) {
    if (content) {
      this.selectedApproval = content.userApproval;
    }
    this.setSubmission();
  }

  onChangeCreator(approval: CreatorApproval) {
    this.selectedApproval = approval;
    this.selectedPlannerContent = null;
    this.setPlannerContents(approval ? [approval] : this.approvals);
    this.setSubmission();
  }

  setPlannerContents(creatorApprovals: CreatorApproval[]) {
    this.plannerContents = creatorApprovals.reduce( (prev, approval) => prev.concat(approval.getPlannerContents()), [])
    this.plannerContents.forEach(plannerContent => plannerContent.userApproval = creatorApprovals.find(approval => approval.id === plannerContent.userApprovalId))
    this.plannerContents.sort((x, y) => x.postAt < y.postAt ? -1 : 1)
  }

  fileChangeEvent(file: FileList): void {
    this.submission.mediaFile = file[0];
    this.submission.video = '';
  }

  public create(): void {
    this.loadingText = this.ts.instant('CAMPAIGN.SUBMISSIONS.CREATING.SUBMISSION');
    this.posting = true;
    this.submission.userId = this.selectedApproval.userId;
    this.subscription = this.submissionService.createSubmission(this.campaign, this.submission).subscribe( x => {
      const submission = new Submission().deserialize(x.submission);
      submission.campaign = this.campaign;
      if (this.submission.mediaFile.type.includes('video')) {
        this.loadingText = this.ts.instant('CAMPAIGN.SUBMISSIONS.UPLOADING.CONTENT');
        this.submissionService.uploadVideo(x.upload, this.submission.mediaFile).subscribe((perc) => {
          if (perc === 1) {
            if (x.submission) {
              this.dialogRef.close(submission);
            } else {
              console.log(x.error);
            }
            this.posting = false;
          }
        });
      } else {
        if (x.submission) {
          this.dialogRef.close(submission);
        } else {
          console.log(x.error);
        }
        this.posting = false;
      }
    }, x => {
      console.log(x.error);
      this.submissionService.logUploadError(this.submission.userId, this.submission.id, x.error).subscribe();
      this.posting = false;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validateUrl(): void {
    this.validVideoUrl = this.submission.video.length !== 0 &&
      ( this.submission.video.includes('https://www.youtube.com/embed/', 0)
      || this.submission.video.includes('https://youtu.be/', 0));
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
