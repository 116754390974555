import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousands'
})
export class ThousandsPipe implements PipeTransform {

  transform(num: number): string {
    if (Number.isNaN(num) || num === undefined || num === null) {
      return '';
    }
    if (num >= 1000000000) {
      return Math.trunc(Math.round(100 * num / 1000000000) / 10) / 10 + 'b';
    }
    if (num >= 1000000) {
      return Math.trunc(Math.round(100 * num / 1000000) / 10) / 10 + 'm';
    }
    if (num >= 1000) {
      return Math.trunc(Math.round(100 * num / 1000) / 10) / 10 + 'k';
    }
    return num.toString();
  }

}
