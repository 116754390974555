import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Platform } from '../../../../../enum/platforms-string.enum';
import { Creator } from '../../../../../models/creator.model';
import { InstagramAudience } from '../../../../../models/instagram.model';
import { Submission } from '../../../../../models/submission.model';
import { TikTokAudience } from '../../../../../models/tiktok.model';
import { YoutubeAudience } from '../../../../../models/youtube.model';
import { SubmissionService } from '../../../../../services/submission.service';
import { CreatorService } from '../../../../../services/creator.service';
import { CreatorDetailsModalComponent } from '../../creator-details-modal/creator-details-modal.component';
import { SuggestedPricesService } from '../../../../../services/suggested-price.service';
import { SuggestedPrice } from '../../../../../models/suggestedPrice.model';
import { AlertService } from 'src/app/services/alert.service';
import { Alert } from 'src/app/models/interfaces';
import { CreatorApprovalPlanner } from '../../../../../models/CreatorApproval.model';
import { UserService } from 'src/app/services/user.service';
import { AppService } from 'src/app/services/app.service';
import { Category } from 'src/app/models/categories.model';

@Component({
  selector: 'app-audience-details-dialog',
  templateUrl: './audience-details-dialog.component.html',
  styleUrls: ['./audience-details-dialog.component.css'],
})
export class AudienceDetailsDialogComponent implements OnInit {
  public instagramAud: InstagramAudience;
  public youtubeAud: YoutubeAudience;
  public loadingProfile: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(undefined);
  public tikTokAud: TikTokAudience;
  private suggestedPrices: SuggestedPrice[];
  public initializeSocialNetworkSelected: string;
  public socialNetworkSelected: BehaviorSubject<string> =
    new BehaviorSubject<string>(undefined);
  public loadingUpdateSocialProfile = false;
  public alert: Alert;

  public submission: Submission[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreatorDetailsModalComponent,
    private readonly suggestedPricesService: SuggestedPricesService,
    private readonly creatorService: CreatorService,
    public dialogRef: MatDialogRef<AudienceDetailsDialogComponent>,
    public userService: UserService,
    public AppService: AppService,
  ) {
    this.setInitializeSocialNetworkAudience();
  }

  public ngOnInit(): void {
    this.loadingProfile.next(true);
    let httpQuery;
    this.creatorService.updateAllSocialNetworksIfExpired(this.data.creator.id).subscribe({
      error: (error: HttpErrorResponse) => console.error(error),
    });
    if (this.data.campaign) {
      httpQuery = this.creatorService.getCreatorByCampaign(
        this.data.campaign.companyId,
        this.data.campaign.brandId,
        this.data.campaign.id,
        this.data.creator.id
      );
    } else {
      httpQuery = this.creatorService.getCreator(this.data.creator.id);
    }
    httpQuery.subscribe({
      next: (creator: Creator) => {
        this.data.creator = creator;
        this.data.creatorSubmissions = creator.userSubmissions;
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      },
    });
    this.submission = this.data.creatorSubmissions;
    this.socialNetworkSelected.next(this.initializeSocialNetworkSelected);
    this.suggestedPricesService
      .httpAll()
      .pipe(finalize(() => this.loadingProfile.next(false)))
      .subscribe({
        next: (data: SuggestedPrice[]) => {
          this.suggestedPrices = data.filter(
            (price) =>
              price.country === this.data.creator.country
          );
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
        },
      });
  }

  public updatedSocialNetwork(): void {
    this.socialNetworkSelected.next('instagram');
  }

  public updateAllSocialNetworks(): void {
    this.loadingUpdateSocialProfile = true;
    this.creatorService.updateAllSocialNetworks(this.data.creator.id).subscribe({
      next: () => {
        this.loadingUpdateSocialProfile = false;
        this.alert = { message: 'Order sent. Please wait from 1 to 15min.', type: 'success' };
        // this.alertService.emitSuccess()
      },
      error: (error: HttpErrorResponse) => {
        this.loadingUpdateSocialProfile = false;
        this.alert = { message: 'Error creating the update profile', type: 'error' };
      },
    });
  }

  private setInitializeSocialNetworkAudience(): void {
    this.initializeSocialNetworkSelected = this.data.creator.instagram
      ? Platform.Instagram
      : this.data.creator.tikTok
        ? Platform.Tiktok
        : Platform.Youtube;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public getProfileImage(): string {
    const socialNetwork = this.data['creator'].socialNetworks[0]?.socialNetwork
      ? this.data['creator'].socialNetworks[0].socialNetwork
      : '';
    return this.data['creator'].getProfilePicture(socialNetwork);
  }

  public getSocialName(): string {
    const socialNetwork = this.data['creator'].socialNetworks[0]?.socialNetwork
      ? this.data['creator'].socialNetworks[0].socialNetwork
      : '';
    return this.data['creator'].getSocialName(socialNetwork);
  }

  public isSelected(socialNetwork): boolean {
    return this.socialNetworkSelected.value === socialNetwork.socialNetwork;
  }

  public selectSocialNetwork(socialNetwork): void {
    this.socialNetworkSelected.next(socialNetwork);
  }
}
