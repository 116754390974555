import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { Campaign } from '../../../../models/campaign.model';
import { SubmissionService } from '../../../../services/submission.service';
import { Alert } from '../../../../models/interfaces';

@Component({
  selector: 'app-edit-submission',
  templateUrl: './edit-submission.component.html',
  styleUrls: ['./edit-submission.component.scss']
})
export class EditSubmissionComponent implements OnInit {
  @Input() submissions: Array<Submission>;
  @Input() creators: Array<CreatorApproval>;
  @Input() campaign: Campaign;
  @Output() editedSub = new EventEmitter<Submission>();
  activeSubmissionList = new Array<Submission>();
  activeSubmission: Submission;
  activeCreators = new Array<CreatorApproval>();
  activeCreator: CreatorApproval;
  draftSubmission: Submission;
  posting = false;
  alert: Alert;

  constructor(private ss: SubmissionService) {
  }

  ngOnInit(): void {
    this.activeCreators = this.creators.filter(
      (creator) => creator.status === 'Confirmed' &&
        this.submissions.some((sub) => sub.userId === creator.userId));
  }

  getCreatorSubmissions(creator: CreatorApproval): void {
    this.creatorChanged();
    if (creator) {
      this.activeSubmissionList = this.submissions.filter((sub) => sub.userId === creator.userId);
    }
  }

  creatorChanged(): void {
    this.activeSubmissionList = new Array<Submission>();
    this.activeSubmission = null;
  }

  newDraft(): void {
    this.draftSubmission = this.activeSubmission ? this.activeSubmission.clone() : null;
  }

  save(): void {
    this.posting = true;
    this.alert = null;
    this.ss.updateStats(this.draftSubmission).subscribe(() => {
      this.posting = false;
      window.moveTo(0, 0);
      this.alert = { message: 'Submission update successful.', type: 'success' };
      this.editedSub.emit(this.draftSubmission);
    }, () => {
      this.posting = false;
      this.alert = { message: 'An error occurred, unable to edit submission.', type: 'danger' };
    });
  }

  setTimeFromInput($event: string): void {
    const date = new Date($event);
    date.setHours(24, 0, 0);
    this.draftSubmission.timePosted = date;
  }

  onNewImages({submission, images}: {submission: Submission; images: File[]}) {
    this.posting = true;
    this.ss.readStatsImages(submission.format, images).subscribe(data => {
      submission.likes = data.likes ?? submission.likes;
      submission.comments = data.comments ?? submission.comments;
      submission.randomReach = data.reach;
      submission.randomImpressions = data.impressions;
      submission.clicks = data.clicks;
      this.draftSubmission = submission;
      this.posting = false;
    });
  }
}
