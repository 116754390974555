import { Campaign } from '../models/campaign.model';

export class Helper {

    static numberOrNull(value: number | null): number | null {
        return value != null ? Number(value) : null;
    }

    static sum = (items: number[]) => items.reduce((prev, value) => Number(value) + Number(prev), 0);

    static avg = (items: number[]) => Helper.sum(items) / items.length;

    static avgWithoutNulls = (items: number[]) => {
        items = items.filter(item => item !== null);
        if (!items.length) {
            return null;
        }
        return Helper.sum(items) / items.length;
    };

    static objectFlip(obj) {
        return Object.keys(obj).reduce((ret, key) => {
            ret[obj[key]] = key;
            return ret;
        }, {});
    }

    static ucfirst(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    static flattenObject(ob, separator = '.') {
        const toReturn = {};
        for (const i in ob) {
            if (!ob.hasOwnProperty(i)) {
              continue;
            }
            if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                const flatObject = Helper.flattenObject(ob[i]);
                for (const x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) {
                        continue;
                    }
                    toReturn[i + separator + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }

    static anyToStringRecursive(data: any, separator = ", ") : string {
        if (Array.isArray(data)) {
            return data.map(item => Helper.anyToStringRecursive(item, separator)).join(separator);
        }
        if (typeof data === 'object') {
            return Object.entries(data).map(([key, value]) => `${key}: ${Helper.anyToStringRecursive(value, separator)}`).join(separator);
        }
        return String(data);
    }

    static copyToClipboard(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
