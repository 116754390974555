import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Brand } from 'src/app/models/brand.model';
import { Campaign } from 'src/app/models/campaign.model';
import { Company } from 'src/app/models/company.model';
import { User, UserRole } from 'src/app/models/user.model';
import { Role } from 'src/app/models/role.model';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({transform: 'translateY(-100%)', opacity: 0}),
        animate(400)
      ]),
      transition('* => void', [
        animate(400, style({transform: 'translateY(-100%)', opacity: 0}))
      ])
    ])
  ]
})
export class UserRolesComponent implements OnInit, OnChanges {

  readonly ROLE_NAMES = Role.NAMES;

  @Input()
  company: Company;

  @Input()
  user: User;

  @Input()
  brands: Brand[] = [];

  rolesList: Role[] = [];
  userBrands: { brand: Brand; userRole: UserRole; newRoleId: number }[] = [];
  brandCampaigns: { [ brand: number ]: { show: boolean; campaigns: { campaign: Campaign; userRole: UserRole; newRoleId: number }[] } } = {};

  selectedBrand: number | null = null;
  selectedBrandCampaigns: Campaign[] = [];
  selectedRole: number | null = null;
  selectedCampaign: number | 'All' | null = null;

  loading = false;

  constructor(private brandService: BrandService) { }

  ngOnInit(): void {
    this.loading = true;
    this.brandService.getRoles(this.company.id).subscribe(roles => {
      this.loading = false;
      this.rolesList = roles.filter(r => r.name !== 'admin');
    }, () => this.loading = false);
  }

  ngOnChanges(): void {
    this.refreshList();
  }

  refreshList() {
    this.userBrands = this.brands
      .filter(b => this.user.getBrandRole(b.companyId, b.id))
      .map(brand => {
        const userRole = this.user.getBrandRole(brand.companyId, brand.id);
        return { brand, userRole, newRoleId: userRole?.role.id };
      });
    this.brandCampaigns = this.userBrands.reduce((campaigns, userBrand) => {
      campaigns[userBrand.brand.id] = {
        show: this.brandCampaigns[userBrand.brand.id]?.show ?? false,
        campaigns: userBrand.brand.campaigns.map(campaign => {
          const userRole = this.user.getCampaignRole(campaign.companyId, campaign.brandId, campaign.id);
          return { campaign, userRole, newRoleId: userRole?.role.id };
        })
      };
      return campaigns;
    }, {});
  }
  addRole(): void {
    if (!this.selectedBrand || !this.selectedRole || !this.selectedCampaign) {
      return;
    }
    this.loading = true;
    this.brandService.addUserRole(this.company.id, this.user.id, this.selectedRole,
      this.selectedBrand, this.selectedCampaign !== 'All' ? this.selectedCampaign : null).subscribe(userRole => {

      userRole.role = this.rolesList.find(r => r.id === this.selectedRole);
      this.user.roles = (this.user.roles ?? []).concat([userRole]);
      this.refreshList();
      this.loading = false;
    });
  }

  brandSelected() {
    this.selectedBrandCampaigns = this.brands.find(b => b.id === this.selectedBrand)?.campaigns ?? [];
  }

  editUserRole(userRole: UserRole | undefined, brandId: number, newRoleId: number, campaignId?: number) {
    this.loading = true;
    if (!userRole || (!campaignId && userRole.campaignId) || (campaignId && !userRole.campaignId)) {
      this.brandService.addUserRole(this.company.id, this.user.id, newRoleId, brandId, campaignId).subscribe(newUserRole => {

        newUserRole.role = this.rolesList.find(r => r.id === newRoleId);
        this.user.roles = (this.user.roles ?? []).concat([newUserRole]);
        this.refreshList();
        this.loading = false;
      });
    } else {
      this.brandService.editUserRole(this.company.id, this.user.id, userRole.id, newRoleId).subscribe(() => {
        userRole.roleId = newRoleId;
        userRole.role = this.rolesList.find(r => r.id === newRoleId);
        this.refreshList();
        this.loading = false;
      });
    }
  }
  deleteUserRole(userRoleId: number) {
    this.loading = true;
    this.brandService.deleteUserRole(this.company.id, this.user.id, userRoleId).subscribe(() => {
      this.user.roles = (this.user.roles ?? []).filter(r => r.id !== userRoleId);
      this.refreshList();
      this.loading = false;
    });
  }
}
