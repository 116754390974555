import { CreatorFilter } from "../enum/creator-filter.enum";

export const creatorFilter = [{
    name : CreatorFilter.Pending, 
    quantity: 0
},{
    name : CreatorFilter.Confirmed, 
    quantity: 0
},{
    name : CreatorFilter.Invited, 
    quantity: 0
},{
    name : CreatorFilter.Suggested, 
    quantity: 0
},{
    name : CreatorFilter.Rejected,
    quantity: 0
},{
    name : CreatorFilter.Negotiation, 
    quantity: 0
},{
    name : CreatorFilter.NotAvailable, 
    quantity: 0
}];

