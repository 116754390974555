import { NotificationService } from './../../../../services/notification.service';
import { SideBarDirection } from '../../../../models/sideBarDirection.model';
import { Component, Input, OnInit } from '@angular/core';
import { SlideBarService } from '../../../../services/side-bar.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-notifications-side-bar',
  templateUrl: './notifications-side-bar.component.html',
  styleUrls: ['./notifications-side-bar.component.scss']
})
export class NotificationsSideBarComponent implements OnInit {

  showSideNav: Observable<boolean>;
  public notReadedNotificationsQty: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  @Input() sidenavTemplateRef: any;
  @Input() duration = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: SideBarDirection = SideBarDirection.Right;

  constructor(
    private slideBarService: SlideBarService,
    private notificationService: NotificationService
    ) {}

  ngOnInit(): void {
    this.showSideNav = this.slideBarService.getShowNav();
    this.notReadedBadgeSubcription();
  }

  private notReadedBadgeSubcription(): void {
    this.notificationService.notReadedNotificactions().subscribe(notRedednotificationsReceive => {
      this.notReadedNotificationsQty.next(notRedednotificationsReceive ? notRedednotificationsReceive.length : 0);
    });
  }

  onSidebarClose() {
    this.slideBarService.setShowNav(false);
  }

  getSideNavBarStyle(showNav: boolean) {
    const navBarStyle: any = {};
    const widthUnit = 'px';//this.navWidth < 100 ? 'vw' : 'px';
    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + widthUnit;
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + widthUnit;

    return navBarStyle;
  }
}
