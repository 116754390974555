import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-create-company-user',
  templateUrl: './create-company-user.component.html',
  styleUrls: ['./create-company-user.component.css']
})
export class CreateCompanyUserComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) content;
  submitted = false;
  modalRef: NgbModalRef;
  @Input() companyList: Array<Company>;
  user: User;
  loading = false;
  conflict = false;
  @Output()
  companyCreated = new EventEmitter<Company>();

  form = this.fb.group({
    email: ['', Validators.compose([
      Validators.required, Validators.email
    ])],
    companyLogo: ['', Validators.required],
    companyName: ['', Validators.required],
    companyLegalName: ['', Validators.required],
    connectId: ['']
  });

  constructor(
    private brandService: BrandService,
    private modalService: NgbModal,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    return;
  }

  open(): void {
    this.loading = false;
    this.modalRef = this.modalService.open(this.content, { windowClass: 'app-share-campaign-modal'});
  }

  createCompanyUser(): void {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.conflict = false;
    this.loading = true;
    this.brandService.createCompanyUser(this.email.value, {
        id: null,
        brandName: this.companyName.value,
        legalName: this.companyLegalName.value,
        logo: this.companyLogo.value,
        connectId: this.connectId.value
    } as Company).subscribe(response => {
      this.loading = false;
      if (!response) {
        this.conflict = true;
      } else {
        this.companyCreated.emit(response.company);
        this.modalRef.close();
      }
    });
  }

  get email() {
    return this.form.get('email');
  }

  get companyLogo() {
    return this.form.get('companyLogo');
  }

  get companyName() {
    return this.form.get('companyName');
  }

  get companyLegalName() {
    return this.form.get('companyLegalName');
  }

  get connectId() {
    return this.form.get('connectId');
  }

}
