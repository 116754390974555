export class ConnectInventory {
  id: number;
  userApprovalId: number;
  trackingLink: string;
  tinyUrl: string;
  inventoryId: number;
  publisherId: number;
  platform: string;

  constructor(
    id: number,
    userApprovalId: number,
    trackingLink: string,
    tinyUrl: string,
    inventoryId: number,
    publisherId: number,
    platform: string 
  ) {
    this.userApprovalId = userApprovalId;
    this.trackingLink = trackingLink;
    this.tinyUrl = tinyUrl;
    this.id = id;
    this.inventoryId = inventoryId;
    this.publisherId = publisherId;
    this.platform = platform;
  }

  static deserialize(input: {
    id: string;
    user_approval_id: string;
    tracking_link: string;
    tiny_url: string;
    inventory_id: string;
    publisher_id: string;
    platform: string; 
  }): ConnectInventory {
    if (!input) {
      return new ConnectInventory(0, 0, '-', '-', 0, 0, '-');
    }
    return new ConnectInventory(
      Number.parseInt(input.id, 10),
      Number.parseInt(input.user_approval_id, 10),
      input.tracking_link,
      input.tiny_url,
      Number.parseInt(input.inventory_id, 10),
      Number.parseInt(input.publisher_id, 10),
      input.platform
    );
  }
}