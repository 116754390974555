import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegisterComponent } from './register/register.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmEmailModalComponent } from './confirm-email-modal/confirm-email-modal.component';
import { ConfirmedEmailModalComponent } from './confirmed-email-modal/confirmed-email-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ LoginComponent, LoginFormComponent, RegisterComponent,
    RegisterFormComponent, ForgotPasswordComponent, ConfirmEmailModalComponent, ConfirmedEmailModalComponent, ErrorModalComponent ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule
    ],
  providers: [ ],
  entryComponents: [
    ConfirmEmailModalComponent,
    ConfirmedEmailModalComponent,
    ErrorModalComponent
  ]
})
export class LoginModule { }
