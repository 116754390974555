import { Deserializable } from './deserializable';

export class NegotiationOffer implements Deserializable {

    id: number;
    userApprovalId: number;
    comment?: string;
    instagramPrice?: number;
    instagramFeeds?: number;
    instagramReels?: number;
    instagramStories?: number;
    tiktokPrice?: number;
    tiktokVideos?: number;
    youtubePrice?: number;
    youtubeVideoMentions?: number;
    youtubeVideoExclusives?: number;
    state: 'Accepted' | 'Declined' | 'Pending';
    time: Date;
    fromCreator: boolean;
    get totalPrice() {
        return (this.instagramPrice ?? 0) + (this.tiktokPrice ?? 0) + (this.youtubePrice ?? 0);
    }

    deserialize(input: any): this {
        this.id = input.id;
        this.userApprovalId = input.user_approval_id;
        this.comment = input.comment;
        this.instagramPrice = (input.instagram_price ? Number.parseInt(input.instagram_price, 10) : null) || 0;
        this.instagramFeeds = input.instagram_feeds;
        this.instagramReels = input.instagram_reels;
        this.instagramStories = input.instagram_stories;
        this.tiktokPrice = (input.tiktok_price ? Number.parseInt(input.tiktok_price, 10) : null) || 0;
        this.tiktokVideos = input.tiktok_videos;
        this.youtubePrice = (input.youtube_price ? Number.parseInt(input.youtube_price, 10) : null) || 0;
        this.youtubeVideoMentions = input.youtube_video_mentions ? Number.parseInt(input.youtube_video_mentions, 10) : null;
        this.youtubeVideoExclusives = input.youtube_video_exclusives ? Number.parseInt(input.youtube_video_exclusives, 10) : null;
        this.state = input.state;
        this.time = new Date(input.time);
        this.fromCreator = input.from_creator;
        return this;
    }

    accept() {
        this.state = 'Accepted';
    }

    decline() {
        this.state = 'Declined';
    }
}
