export const socialNetworksArray = [{
  name: 'Instagram',
  value: 'instagram'
}, {
  name: 'YouTube',
  value: 'youtube'
}, {
  name: 'TikTok',
  value: 'tiktok'
}];
