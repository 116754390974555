import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Campaign } from '../../../models/campaign.model';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CampaignService } from '../../../services/campaign.service';

@Component({
  selector: 'app-campaigns-table',
  templateUrl: './campaigns-table.component.html',
  styleUrls: ['./campaigns-table.component.scss']
})
export class CampaignsTableComponent implements OnDestroy, OnInit {
  eventsSubscription: Subscription;
  displayedColumns: string[] = ['brand', 'pricingModel', 'socialNetwork', 'totalPosts', 'status'];
  dataSource: MatTableDataSource<Campaign>;
  @Input() campaignList: Array<Campaign>;
  @Input() refreshProntou: Observable<Array<Campaign>>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  tiktok = faTiktok;

  constructor(
    public router: Router,
    private readonly campaingService: CampaignService) {
  }

  ngOnInit(): void {
    this.initDataSource(this.campaignList);
    this.applyFilter();
    this.eventsSubscription = this.refreshProntou.subscribe((data) => this.initDataSource(data));
  }

  initDataSource(data: Array<Campaign>): void {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  private applyFilter(): void {
    this.campaingService.getCardCampaignName().subscribe({
      next: (filterTerm: string) => {
        this.dataSource.filter = filterTerm.trim().toLowerCase();
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      }, error: (error: Error) => {
        console.log(error);
      }
    });
  }
}
