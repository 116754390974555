import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmedEmailModalComponent } from '../confirmed-email-modal/confirmed-email-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements AfterViewInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal
              ) {}

  signInVisible = true;
  showResetMessage = false;
  display = false;

  ngAfterViewInit(): void {
    if (this.route.snapshot.paramMap.get('confirmed') !== null) {
      setTimeout(() => this.modalService.open(ConfirmedEmailModalComponent));
    }
  }

  imageLoaded(): void {
    this.delay(2000).finally(() => {
      this.display = true;
    });
  }

  delay(ms: number): Promise<void> {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
