import { Campaign } from './campaign.model';
import { Country } from './country';

export class SuggestedPrice {

    id: number
    country: string
    creator_level: string
    followers_min: number
    followers_max: number
    instagram_feed_price_min: number
    instagram_feed_price_max: number
    instagram_reel_price_min: number
    instagram_reel_price_max: number
    instagram_story_price_min: number
    instagram_story_price_max: number
    youtube_video_exclusive_price_min: number
    youtube_video_exclusive_price_max: number
    youtube_video_mention_price_min: number
    youtube_video_mention_price_max: number
    tiktok_video_price_min: number
    tiktok_video_price_max: number
    created_at: string
    updated_at: string

    constructor(data: {[key:string]: string} = {}) {
        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }
    }

    emptyStringToNull() {
        for (const [key, value] of Object.entries(this)) {
            if (value === '') {
                this[key] = null
            }
        }
    }

    null() {
        this.id = null
        this.country = null
        this.creator_level = null
        this.followers_min = null
        this.followers_max = null
        this.instagram_feed_price_min = null
        this.instagram_feed_price_max = null
        this.instagram_reel_price_min = null
        this.instagram_reel_price_max = null
        this.instagram_story_price_min = null
        this.instagram_story_price_max = null
        this.youtube_video_exclusive_price_min = null
        this.youtube_video_exclusive_price_max = null
        this.youtube_video_mention_price_min = null
        this.youtube_video_mention_price_max = null
        this.tiktok_video_price_min = null
        this.tiktok_video_price_max = null
        this.created_at = null
        this.updated_at = null
        return this
    }

    
}

