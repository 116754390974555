import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Campaign } from '../../../../../../models/campaign.model';
import { Creator } from '../../../../../../models/creator.model';
import { Privilege } from '../../../../../../models/role.model';
import { Submission } from '../../../../../../models/submission.model';
import { SubmissionService } from '../../../../../../services/submission.service';
import { UserService } from '../../../../../../services/user.service';
import { SubmissionDialogComponent } from '../../submission-dialog.component';
import { InputFiles } from 'typescript';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';

@Component({
  selector: 'app-tiktok-content',
  templateUrl: './tiktok-content.component.html',
  styleUrls: ['./tiktok-content.component.css']
})
export class TiktokContentComponent implements OnInit {

  @Input() public submission: Submission;
  @Input() public creator: Creator;
  @Input() public campaign: Campaign;
  @Input() public creatorApproval: CreatorApproval;
  @Output() public editMode: EventEmitter<Submission> = new EventEmitter<Submission>();
  @Output() public error: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('uploadContentModal') callEditContentDialog: TemplateRef<any>;
  @ViewChild('uploadCaptionsModal') callEditCaptionsDialog: TemplateRef<any>;
  @ViewChild('file') file: ElementRef;
  private editContentDialogRef: MatDialogRef<TemplateRef<any>>;
  private editCaptionsDialogRef: MatDialogRef<TemplateRef<any>>;
  public canEdit: boolean;
  public safeVideo: SafeResourceUrl;
  public status: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public posting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public previewUrl: BehaviorSubject<string | ArrayBuffer> = new BehaviorSubject(undefined);
  public userHasPrivileges: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public editing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public caption: string;
  public subscription: Subscription;
  public submissions: Submission[];



  constructor(
    private readonly submissionService: SubmissionService,
    private readonly sanitizer: DomSanitizer,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<SubmissionDialogComponent>,
    ){
   }

  public ngOnInit(): void {
    this.status.next(this.submission.status);
    this.caption = this.submission.captions;
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CONTENT], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
        this.canEdit = true;
    }
    this.userHasPrivileges.next(this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CONTENT], this.campaign.companyId, this.campaign.brandId, this.campaign.id));
    this.setVideoUrl();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public setVideoUrl() {
    if (this.isYoutube()) {
      const videoId = this.submission.video.split('/').pop();
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoId);
    } else {
      this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.submission.video);
    }
  }

  private isYoutube(): boolean {
    return !!this.submission.video && this.submission.video.includes('youtube');
  }

  public enableEditMode(): void {
    this.isEditing.next(true);
    this.editMode.emit(this.submission);
  }

  onUploadClick() {
    document.getElementById('uploadFile').click();
    // this.file.click();
  }

  public handleFileInput(files: FileList): void {
    const file = this.submission.mediaFile = files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.previewUrl.next((<FileReader>event.target).result);
        this.saveEdit();
      };
    }
  }

  public saveEdit(): void {
    this.posting.next(true);
    this.submission.captions = this.caption;
    this.submissionService.editSubmission(this.submission)
    .pipe(
      finalize(() => {
        this.posting.next(false);
        // this.dialogRef.close();
      }))
        .subscribe({
            next: (value: any) => {
              this.submissions = value.submissions.map(value => new Submission().deserialize(value));
                if (this.submission.mediaFile && this.submission.mediaFile.type.includes('video')) {
                  this.submissionService.uploadVideo(value.upload, this.submission.mediaFile)
                    .subscribe({
                      error: (error: HttpErrorResponse) => {
                        console.log(error);
                      }
                    });
                }
            }, error: (error: HttpErrorResponse) => {
              console.log(error);
          }});
  }

  public cancelEdit(): void {
    this.editMode.emit(this.submission);
    this.previewUrl.next(undefined);
    this.isEditing.next(false);
  }

  public uploadCaptions(){
    this.caption = this.submission.captions;
    this.closeEditCaptionsDialog();
  }

  public delete(): void {
    this.posting.next(true);
    this.submissionService.deleteSubmission(this.submission)
    .pipe(
      finalize(() => {
        this.posting.next(false);
        this.dialogRef.close(this.submissions);
      }))
        .subscribe({
            next: (value: any) => {
              this.submissions = value.submissions.map(value => new Submission().deserialize(value));
            }, error:  (error: HttpErrorResponse) => {
              this.error.emit(error.message);
            }
        });
  }


  openContentModal(){
    this.editContentDialogRef = this.dialog.open(this.callEditContentDialog,
    {
      width: '400px',
      height : '450px'
    });
  }


  public closeEditContentDialog(){
    this.editContentDialogRef.close();
  }


  public OpenUploadCaptionsModal(){
    this.editCaptionsDialogRef = this.dialog.open(this.callEditCaptionsDialog, {
      width: '500px',
      // height : auto
    });
  }


  public closeEditCaptionsDialog(){
    this.editCaptionsDialogRef.close();
  }

}
