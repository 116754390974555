export class NegotiationMessage {

    id: number;
    userApprovalId: number;
    message: string;
    time: Date;
    fromCreator: boolean;

    constructor(userApprovalId: number, message: string, time: string, fromCreator: boolean, id?: number) {
        this.id = id;
        this.userApprovalId = userApprovalId;
        this.message = message;
        this.time = new Date(time);
        this.fromCreator = fromCreator;
    }

    static deserialize(input: any): NegotiationMessage {
        return new NegotiationMessage(input.user_approval_id, input.message, input.time, input.from_creator, input.id);
    }

}
