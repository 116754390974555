import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UiModule } from '../../ui/ui.module';
import { CampaignsTableComponent } from './campaigns-table/campaigns-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardsTableComponent } from './cards-table/cards-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { ComponentModule } from '../component/component.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
  declarations: [ DashboardComponent, CampaignsTableComponent, CardsTableComponent],
  imports: [
    CommonModule,
    UiModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ComponentModule,
    TranslateModule,
    FontAwesomeModule,
    SharedModule,
    MatSlideToggleModule
  ]
})
export class DashboardModule { }
