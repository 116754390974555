import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface DialoConfirmgData {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
}

@Component({
  selector: 'app-diaplog-confirm',
  templateUrl: './dialog-confirm.component.html',
  // standalone: true,
  styleUrls: ['./dialog-confirm.component.css'],
  // imports: [
  //   MatFormFieldModule,
  //   MatInputModule,
  //   FormsModule,
  //   MatButtonModule,
  //   MatDialogTitle,
  //   MatDialogContent,
  //   MatDialogActions,
  //   MatDialogClose,
  // ],
})
export class DialogConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialoConfirmgData,
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
