import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { NegotiationMessage } from 'src/app/models/negotiationMessage.model';
import { NegotiationOffer } from 'src/app/models/negotiationOffer.model';
import { NegotiationService } from 'src/app/services/negotiation.service';
import { NotificationService } from '../../../../../services/notification.service';
import { NotificationSubject } from '../../../../../models/notificationSubject.model';
import { NotificationTypes } from '../../../../../ui/footer/notification/const';

@Component({
  selector: 'app-negotiation-chat',
  templateUrl: './negotiation-chat.component.html',
  styleUrls: ['./negotiation-chat.component.scss']
})
export class NegotiationChatComponent implements OnInit, OnChanges, AfterViewChecked {

  @ViewChild('chatContainer') private chatContainer: ElementRef;

  @Input()
  approval: CreatorApproval;
  @Input()
  offers: NegotiationOffer[] = [];
  messages: NegotiationMessage[] = [];
  chat: (NegotiationMessage | NegotiationOffer)[] = [];
  messageText = '';
  loading = false;

  constructor(
    private negotiationService: NegotiationService,
    private notificationService: NotificationService
  ) { }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnInit(): void {
    this.loading = true;
    this.listenChatNotifications();
    this.negotiationService
      .getNegotiationMessages(this.approval.campaign.companyId, this.approval.campaign.brandId, this.approval.campaign.id, this.approval.userId)
      .subscribe((dbMessages) => {
        this.messages = dbMessages;
        this.updateChat();
        this.updateNotificationMessagesReaded();
        this.loading = false;
      });
  }

  ngOnChanges(): void {
    this.updateChat();
  }

  updateNotificationMessagesReaded() {
    const markAsReaded = this.notificationService.getNotReadedNotifications()
      .filter(not => not.data.creatorId == this.approval.userId && NotificationTypes.NEGOTIATION_CHAT_ARR.includes(not.data.name))
      .map(not => not.id);
    markAsReaded.length && this.notificationService.markAsRead(markAsReaded).subscribe();
  }

  updateChat() {
    this.chat = this.offers ?? [];
    this.chat = this.chat.concat(this.messages).sort((a, b) => a.time.getTime() - b.time.getTime());
  }

  sendMessage() {
    if(this.messageText.length === 0 || this.loading) {
      return;
    }
    const message = new NegotiationMessage(this.approval.id, this.messageText, new Date().toISOString(), false);
    this.chat.push(message);
    this.messages.push(message);
    this.messageText = '';
    this.negotiationService.sendNegotiationMessage(
      this.approval.campaign.companyId,
      this.approval.campaign.brandId,
      this.approval.campaign.id,
      this.approval.userId,
      message,
    ).subscribe(res => message.id = res.id, () => this.chat.pop());
  }

  scrollToBottom(): void {
    try {
      if(this.chatContainer) {
        this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      }
    } catch(err) { };
  }

  listenChatNotifications(): void {
    this.notificationService
      .listenNotifications()
      .subscribe((notification: NotificationSubject) => {
        const newNotification = notification.newNotification;
        if (this.chatContainer.nativeElement.isConnected && this.approval.userId == newNotification?.data?.creatorId && newNotification?.id){
          switch (newNotification.data.name) {
            case NotificationTypes.CHAT_FROM_CREATOR:
              this.messages.push({
                id: newNotification.id,
                fromCreator: true,
                message: newNotification.body,
                time: newNotification.time,
                userApprovalId: this.approval.id
              });
              break;
            case NotificationTypes.CREATOR_OFFERT_ACCEPTED:
              this.offers.map(offer => {
                if(offer.id == newNotification.data.offerId){
                  offer.accept();
                }
              });
              break;
            case NotificationTypes.CREATOR_OFFERT_COUNTER_OFFERT:
              this.offers.map(offer => {
                if(offer.id == newNotification.data.counteredOfferId){
                  offer.decline();
                }
              });
              const newOfferNotificationData = JSON.parse(newNotification.data.newOfferData);
              const newOffer = new NegotiationOffer().deserialize(newOfferNotificationData);
              this.offers.unshift(newOffer);
              break;
            case NotificationTypes.CREATOR_OFFERT_REJECTED:
              this.offers.map(offer => {
                if(offer.id == newNotification.data.offerId){
                  offer.decline();
                }
              });
              break;
            default:
              break;
          }
          this.updateChat();
          this.updateNotificationMessagesReaded();
        }
    });
  }
}
