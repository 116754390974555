import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreatorService } from '../../../../services/creator.service';
import { Subscription } from 'rxjs';
import { Campaign } from '../../../../models/campaign.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Privilege } from 'src/app/models/role.model';
import { PrivilegeBlockModalComponent } from 'src/app/modules/shared/privilege-block-modal/privilege-block-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-creator-status-modal',
  templateUrl: './creator-status-modal.component.html',
  styleUrls: ['./creator-status-modal.component.scss']
})
export class CreatorStatusModalComponent implements OnInit {

  @Input() creator: CreatorApproval;
  @Input() campaign: Campaign;
  public statusForm: FormGroup;
  posting = false;
  submitted = false;
  invalidCredentials = false;
  sub: Subscription;
  modalRef: NgbModalRef;
  confirm = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreatorStatusModalComponent, public dialogRef: MatDialogRef<CreatorStatusModalComponent>,
  private modalService: NgbModal, private fb: FormBuilder, private cs: CreatorService, private userService: UserService) {
    this.creator = this.data.creator;
    this.campaign = this.data.campaign;
  }

  ngOnInit(): void {
    this.statusForm = this.fb.group({
      status: ['', Validators.compose([
        Validators.required])],
    });
  }

  open(modal: TemplateRef<CreatorStatusModalComponent>): void {
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_PROFILES], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
      this.confirm = false;
      this.modalRef = this.modalService.open(modal);
    } else {
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }

  getStatusColor(): string {
    switch (this.creator.status) {
      case 'Confirmed': {
        return '#5DBB47';
      }
      case 'Invited': {
        return '#BB4747';
      }
      case 'Canceled': {
        return '#BB4747';
      }
      case 'Not available': {
        return '#B9A35C';
      }
      case 'Pending': {
        return '#FFCD33';
      }
      case 'Negotiation': {
        return '#4287f5';
      }
      case 'Suggested': {
        return '#FFCD33';
      }
      default: {
        return 'grey';
      }
    }
  }

  changeStatus(): void {
    this.posting = true;
    this.submitted = true;
    this.invalidCredentials = false;
    if (this.statusForm.invalid) {
      return;
    }
    const values = this.statusForm.value;
    const oldStatus = this.creator.status;
    let newStatus = values.status;
    this.creator.status = 'Transition';
    this.sub = this.cs.changeStatus(this.campaign.companyId, this.campaign.brandId, this.campaign.id, this.creator.userId, newStatus)
      .pipe(finalize(() => this.dialogRef.close({categorizeApprovals: true, removeCreator: undefined}))).subscribe(
        {
          next: () => {
            this.posting = false;
            if (values.status === 'Rejected') {
              newStatus = 'Canceled';
            }
            this.creator.status = newStatus;
            this.modalRef.close();
          }, error: (error: HttpErrorResponse) => {
            this.posting = false;
            console.log(error);
            this.creator.status = oldStatus;
          }
        }
       );
  }

  public confirmDelete(){
    this.statusForm = this.fb.group({
      status: ['', Validators.compose([
        Validators.required])],
    });
    this.confirm = !this.confirm;
  }

  removeUserApproval(): void {
    this.sub = this.cs.removeCampaign(this.campaign.companyId, this.campaign.brandId, this.campaign.id,
      this.creator.userId, this.creator.id).subscribe(() => {
        this.dialogRef.close({categorizeApprovals: undefined, removeCreator: this.creator});
    });
  }

}
