import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PerformanceSubmission, Submission } from '../models/submission.model';
import { RestService } from './rest.service';
import { map, switchMap } from 'rxjs/operators';
import { Campaign } from '../models/campaign.model';
import { HttpClient } from '@angular/common/http';
import { Platform } from '../enum/platforms.enum';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {

  private logErrorUrl = 'logError';

  constructor(private rs: RestService, private http: HttpClient) { }

  public getSubmissions(campaign: Campaign): Observable<{submissions: Submission[], performanceStats: PerformanceSubmission[]}> {
    return this.rs.get(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/submissions`)
      .pipe( map(function(response) {
        response.submissions = response.submissions.map( x => {
          return new Submission().deserialize(x);
        });
        response.performanceStats = response.performance_stats.map( x => {
          return new PerformanceSubmission().deserialize(x);
        });
        return response;
      }));
  }

  public changeSubmissionStatus(status: string, submission: Submission): Observable<boolean> {
    let requestObj = {};
    if ( status === 'Approved') {
      submission.captionErrors = '';
      requestObj = {
        status,
        dateToPost : submission.dateToPost,
        timeToPost: submission.timeToPostStart,
        timeToPostStart: submission.timeToPostStart,
        timeToPostEnd: submission.timeToPostEnd ?? null,
        id: submission.id
      };
    } else if ( status === 'Update Required' || status === 'Rejected') {
      submission.dateToPost = '';
      submission.timeToPostStart = '';
      requestObj = {
        status, id: submission.id, error: submission.captionErrors
      };
    }
    return this.rs.post(`companies/${submission.campaign.companyId}/brands/${submission.campaign.brandId}/campaigns/${submission.campaign.id}
      /submissions/${submission.id}/status/edit`, requestObj);
  }

  public updateStats(submission: Submission): Observable<any> {
    return this.rs.post(`companies/${submission.campaign.companyId}/brands/${submission.campaign.brandId}/campaigns/${submission.campaign.id}
      /submissions/${submission.id}/stats/edit`, {
      id:                 submission.id,
      reach:              submission.randomReach,
      impressions:        submission.randomImpressions,
      likes:              submission.likes,
      comments:           submission.comments,
      clicks:             submission.clicks,
      installsAndActions: submission.installsAndActions,
      savedShared:        submission.savedShared,
      date:               submission.timePosted.getTime() / 1000
    });
  }

  public batchUpdate(companyId: number, brandId: number, campaignId: number, list: Array<Submission>): Observable<any> {
    const entity = new Array<any>();
    list.forEach( submission => {
      if (submission.timePosted) {
        entity.push(
          {
            id:                 submission.id,
            reach:              submission.randomReach,
            impressions:        submission.randomImpressions,
            likes:              submission.likes,
            comments:           submission.comments,
            clicks:             submission.clicks,
            installsAndActions: submission.installsAndActions,
            savedShared:        submission.savedShared,
            date:               submission.timePosted.getTime() / 1000
          }
        );
      }
    });
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/submissions/stats`, {submissions: entity});
  }

  createSubmission(campaign: Campaign, sub: Submission): Observable<any> {
    const form = new FormData();
    form.append('campaign_id', sub.campaignId + '');
    form.append('user_id', sub.userId + '');
    form.append('creator_captions', sub.captions);
    form.append('creator_note', sub.note);
    form.append('format', sub.format);
    form.append('platform', sub.platform);
    form.append('planner_content_id', sub.plannerContentId ? sub.plannerContentId.toString() : '');
    if (!!sub.video && sub.video !== '') {
      form.append('url', sub.video);
    }
    if (sub.mediaFile && !sub.mediaFile.type.includes('video')) {
      form.append('media', sub.mediaFile, sub.mediaFile.name);
    }
    return this.rs.postWithFile(`companies/${campaign.companyId}/brands/${campaign.brandId}/campaigns/${campaign.id}/submissions`, form);
  }

  editSubmission(sub: Submission): Observable<any> {
    const form = new FormData();
    form.append('id', sub.id + '');
    form.append('campaign_id', sub.campaignId + '');
    form.append('user_id', sub.userId + '');
    form.append('delete_thumbnail', (sub.mediaFile && sub.mediaFile.type.includes('video')) ? String(true) : String(false));
    form.append('creator_captions', sub.captions);
    form.append('creator_note', sub.note);
    form.append('captions_error', sub.captionErrors);
    form.append('format', sub.format);
    form.append('platform', sub.platform);
    form.append('planner_content_id', sub.plannerContentId ? sub.plannerContentId.toString() : '');
    if (sub.mediaFile && !sub.mediaFile.type.includes('video')) {
      form.append('media', sub.mediaFile, sub.mediaFile.name);
    }
    if (!!sub.video && sub.video !== '') {
      form.append('url', sub.video);
    }
    return this.rs.postWithFile(`companies/${sub.campaign.companyId}/brands/${sub.campaign.brandId}/campaigns/${sub.campaign.id}
      /submissions/${sub.id}`, form);
  }

  deleteSubmission(sub: Submission): Observable<any> {
    return this.rs.post(`companies/${sub.campaign.companyId}/brands/${sub.campaign.brandId}/campaigns/${sub.campaign.id}
      /submissions/${sub.id}/delete`, {id: sub.id});
  }

  downloadSubmissionVideo(companyId: number, brandId: number, campaignId: number, submissionId: number): Observable<{type: string; video: Blob}> {
    return this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/submissions/${submissionId}/download`)
      .pipe(
        switchMap(res => this.http.get(res.download, { responseType: 'blob' })
          .pipe(map(blob => ({video: blob, type: res.type})))));
  }

  readStatsImages(submissionFormat: string, images: File[]): Observable<{likes?: number; comments?: number; reach: number;
    impressions: number; clicks: number;}> {
    const form = new FormData();
    form.append('format', submissionFormat);
    images.forEach((i, index) => form.append(`images[${index}]`, i));
    return this.rs.postWithFile('submissions/images', form);
  }

  uploadVideo(url: string, file: File): Observable<number> {
    return this.rs.postFile(url, file);
  }

  logUploadError(creatorId: number, submissionId: number, error: string) {
    return this.rs.post(this.logErrorUrl, { error: `Creator: ${creatorId}\nSubmission: ${submissionId}\nException: ${error}`});
  }

  createPrecampaignStats(companyId: number, brandId: number, campaignId: number,
                               newStats: Array<{ creatorId: number; platform: string; avgFeed: number; avgStories: number; avgReels: number;
                                                 avgVideosTiktok: number; avgMentions: number; avgVideosYoutube: number; }>) {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/precampaign`, newStats).pipe(
      map((response: Array<any>) => console.log(response))
    );
  }

  getPrecampaignSubmissions(creatorId: number): Observable<Submission[]> {
    return this.rs.get(`creators/${creatorId}/precampaign`).pipe(
      map((response: Array<any>) => response.map(s => new Submission().deserialize(s)))
    );
  }

  clearPrecampaignSubmissions(creatorId: number, format: string, platform: string): Observable<void> {
    return this.rs.post(`creators/${creatorId}/precampaign/delete`, { creatorId, format, platform });
  }

}
