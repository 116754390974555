import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimal'
})
export class TwoDecimalPipe implements PipeTransform {

  transform(num: number): string {
    return (Math.round(100 * num) / 100).toString();
  }
}
