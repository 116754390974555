import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValuesFilter } from 'src/app/models/filters';

@Component({
  selector: 'app-checkbox-search-filter',
  templateUrl: './checkbox-search-filter.component.html',
  styleUrls: ['./checkbox-search-filter.component.scss']
})
export class CheckboxSearchFilterComponent implements OnInit {

  @Input() filterName: string;
  @Input() filterTitle: string;
  @Input() filterDescription: string;
  @Input() options: { key: string; label: string }[];
  @Input() clearAction: Observable<void>;

  checkboxes: FormGroup;

  @Output() filterChanged = new EventEmitter<ValuesFilter | null>();

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.clearAction.subscribe(() => {
      this.initForm();
      this.formChanged();
    });
  }

  initForm() {
    this.checkboxes = this.fb.group(this.options.reduce((form, option) => {
      form[option.key] = [false];
      return form;
    }, {}));
    this.checkboxes.valueChanges.subscribe(() => this.formChanged());
  }

  formChanged() {
    const selected = this.options.filter(o => this.checkboxes.controls[o.key].value).map(o => o.key);
    this.filterChanged.next(selected.length > 0 ? new ValuesFilter(this.filterName, selected, undefined) : null);
  }
}
