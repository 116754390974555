import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmed-email-modal',
  templateUrl: './confirmed-email-modal.component.html',
  styleUrls: ['../register/register.component.scss']
})
export class ConfirmedEmailModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    return;
  }

}
