import { Pipe, PipeTransform } from '@angular/core';
/**
 * @description
 *  Se utliza para formatear dentro del HTML, {{parametro del date | dateToWeek}}
 */
@Pipe({ name: 'dateToWeek', pure: true })
export class DateToWeek implements PipeTransform {
    /**
     * @description
     *  Se utliza formatear la semana
     *
     * @param value
     *  Lo que quieras formatear
     *
     * @returns
     *  Retorna el date formateado a semana
     */
    transform(value: Date): any {
        const month = value.toLocaleString('default', {month: 'long'}) + ","
        return month + this.getFirstDayOfWeekOrLast(value, true).toLocaleDateString('default', {
            month: 'short',
            day: '2-digit'
        }) + " - " + this.getFirstDayOfWeekOrLast(value, false).toLocaleDateString('default', {
            month: 'short',
            day: '2-digit'
        });
    }

    private getFirstDayOfWeekOrLast(d: Date, first: boolean) {
        d = new Date(d);
        const day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6: (first ? 0 : 6));
        return new Date(d.setDate(diff));
    }

}
