import { Deserializable } from './deserializable';
import { Instagram } from './instagram.model';
import { Youtube } from './youtube.model';
import { SocialUser } from './Interfaces/SocialUser';
import { TikTok } from './tiktok.model';
import { CountriesMap } from './countriesMap.model';
import { Submission } from './submission.model';
import { Injectable, inject } from '@angular/core';
import { AppService } from '../services/app.service';
import { Category } from './categories.model';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class Creator implements Deserializable {
  socialNetworks: Array<SocialUser> = [];
  fullName: string;
  dateOfBirth: string;
  constructor() {
    return;
  }
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  tikTok: TikTok;
  instagram: Instagram;
  youtube: Youtube;
  gender: string;
  country: string;
  countryAb: string;
  categories: number[] = [];
  // interestCategories: string[] = [];
  phone: string;
  age: number;
  state: string;
  city: string;
  registerType: string;
  userSubmissions: Submission[];
  managerPhoneNumber: string;
  managerEmail: string;
  managerName: string;
  deletedAt: string;
  registerTs: string;
  lastLoginTs: string;
  instagramUserId: number;
  youtubeUserId: number;
  tiktokUserId: number;
  aboutMe: string;
  creatorLevel: string; 

  get username() {
    return this.instagram?.username ?? this.tikTok?.username ?? this.youtube?.username ?? '';
  };

  get tiktok(): TikTok {
    return this.tikTok;
  }

  interestCategories(allCategories: Category[]): string[] {
    return this.categories
      .map(cat => Category.find(cat, allCategories)?.title )
      .filter(x => x);
  }

  deserialize(input: any): this {
    if (input && input.instagram) {
      this.instagram = new Instagram(input.instagram);
      this.socialNetworks.push(this.instagram);
    }
    if (input && input.youtube) {
      this.youtube = new Youtube(input.youtube);
      this.socialNetworks.push(this.youtube);
    }
    if (input && input.tiktok) {
      this.tikTok = new TikTok(input.tiktok);
      this.socialNetworks.push(this.tikTok);
    }
    this.id = input.id && parseInt(input.id);
    this.firstName = input.firstname ? input.firstname : '';
    this.lastName = input.lastname ? input.lastname : '';
    this.email = input.email ? input.email : '';
    this.gender = input.gender ? input.gender : '';
    this.country = input.country ? input.country : '';
    this.categories = input.categories && input.categories.user_selected_category ? 
      input.categories.user_selected_category
        .split(',')
        .filter(cat => cat)
        .map(cat => parseInt(cat))
        // .filter(cat => Category.find(cat))
         : [];
    // this.interestCategories = this.categories
    //   .map(cat => Category.find(cat)?.title )
    //   .filter(x => x);
    this.phone = input.mobile_number ? input.mobile_number : null;
    this.countryAb = CountriesMap.getCountryAb(this.country.toUpperCase()).toLocaleLowerCase();
    this.fullName = (this.firstName.length > 0 ? this.firstName + ' ' : '') + (this.lastName);
    this.dateOfBirth = input.dob ? input.dob : null;
    this.city = input.city ? input.city : null;
    this.state = input.state ? input.state : null;
    this.setAge();
    this.registerType = input.register_type;
    this.managerPhoneNumber = input.manager_phone_number;
    this.managerEmail = input.manager_email;
    this.managerName = input.manager_name;
    this.deletedAt = input.deleted_at;
    this.registerTs = input.register_ts;
    this.lastLoginTs = input.last_login_ts;
    this.instagramUserId = input.instagram_user_id && parseInt(input.instagram_user_id);
    this.youtubeUserId = input.youtube_user_id && parseInt(input.youtube_user_id);
    this.tiktokUserId = input.tiktok_user_id && parseInt(input.tiktok_user_id);
    this.aboutMe = input.about_me;
    this.creatorLevel = input.creator_level;
    this.userSubmissions = input.user_submissions ? input.user_submissions.map(s => new Submission().deserialize(s)) : [];
    return this;
  }

  private setAge() {
    if (this.dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(this.dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.age = age;
    }
  }

  public hasInstagram(): boolean {
    return !!this.instagram;
  }

  public hasYoutube(): boolean {
    return !!this.youtube;
  }

  public hasTikTok(): boolean {
    return !!this.tikTok;
  }

  public hasInstagramAudience(): boolean {
    return this.hasInstagram() && this.instagram.audience.hasAudience;
  }

  public hasYoutubeAudience(): boolean {
    return this.hasYoutube() && this.youtube.audience.hasAudience;
  }

  public hasTikTokAudience(): boolean {
    return this.hasTikTok() && this.tikTok.audience.hasAudience;
  }

  public getProfilePicture(socialNetwork = ''): string {
    if (this.socialNetworks.length === 0) {
      return '';
    }
    if (socialNetwork && socialNetwork.length !== 0) {
      return this.socialNetworks.find( x => x.socialNetwork.toLowerCase() === socialNetwork.toLowerCase())?.profilePicture ?? '';
    } else {
      const instagram = this.socialNetworks.find(sn => sn.socialNetwork.toLowerCase() === 'instagram');
      return instagram?.profilePicture ?? this.socialNetworks.map(x => x).pop().profilePicture;
    }
  }

  public getFollowers(socialNetwork = ''): number {
    if (this.socialNetworks.length === 0) {
      return 0;
    }
    if (socialNetwork && socialNetwork.length !== 0) {
      return this.socialNetworks.find( x => x.socialNetwork.toLowerCase() === socialNetwork.toLowerCase())?.audience?.followers ?? 0;
    } else {
      const instagram = this.socialNetworks.find(sn => sn.socialNetwork.toLowerCase() === 'instagram');
      return instagram?.audience?.followers ?? this.socialNetworks.map(x => x).pop().audience?.followers ?? 0;
    }
  }

  public getSocialName(socialNetwork = ''): string {
    if (this.socialNetworks.length === 0) {
      return '';
    }
    if (socialNetwork && socialNetwork.length !== 0) {
      return this.socialNetworks.find( x => x.socialNetwork.toLowerCase() === socialNetwork.toLowerCase())?.username ?? '';
    } else {
      const instagram = this.socialNetworks.find(sn => sn.socialNetwork.toLowerCase() === 'instagram');
      return instagram?.username ?? this.socialNetworks.map(x => x).pop().username;
    }
  }

  public audienceCount(): number {
    let count = 0;
    this.socialNetworks.forEach(x => {
      if (x.hasAudience) {
        count++;
      }
    });
    return count;
  }

  public navigateToSocialProfile(socialNetwork: string): void {
    this.socialNetworks.find( x => x.socialNetwork.toLowerCase() === socialNetwork.toLowerCase())?.goToProfile();
  }

  public checkTiktokCardSpace(creator: Creator): boolean {
    return (!creator.hasTikTok() && !creator.hasYoutube()) || (creator.hasTikTok() && !creator.hasYoutube() && !creator.hasInstagram()) || creator.hasTikTok();
  }

  public checkYoutubeCardSpace(creator: Creator): boolean {
    return (!creator.hasTikTok() && !creator.hasInstagram() && !creator.hasYoutube()) || (creator.hasTikTok() && creator.hasInstagram() && !creator.hasYoutube()) || (creator.hasTikTok() && !creator.hasYoutube() && !creator.hasInstagram()) ||  creator.hasYoutube();
  }

  public checkExtraCardSpace(creator: Creator): boolean{
    return (!creator.hasTikTok() && !creator.hasInstagram() && creator.hasYoutube());
  }
}
