import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-email-modal',
  templateUrl: './confirm-email-modal.component.html',
  styleUrls: ['../register/register.component.scss']
})
export class ConfirmEmailModalComponent {

  @Input()
  validated = false;
  constructor(public activeModal: NgbActiveModal) {}

}
