import { Deserializable } from './deserializable';
import { Company } from './company.model';
import { Role } from './role.model';
import { Brand } from './brand.model';

export class User implements Deserializable {
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string;
  phoneNumber: string;
  id: number;
  company: Company;
  active: boolean;
  verificationToken?: string;
  roles?: UserRole[];

  constructor(firstName: string, lastName: string, email: string, profileImage: string,
             phoneNumber: string, company: Company, id: number, active: boolean, verificationToken?: string, roles?: UserRole[]) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.profileImage = profileImage || '';
    this.phoneNumber = phoneNumber;
    this.company = company;
    this.id = id;
    this.active = active;
    this.verificationToken = verificationToken;
    this.roles = roles;
  }

  static deserialize(input: any): User {
    return new User(input.firstname, input.lastname, input.email,
      input.profile_image, input.mobile_number, input.company_id, input.id, input.active_status === '1',
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      input.email_verification_code, input.roles?.map(UserRole.deserialize) ?? []);
  }

  deserialize(input: any): this {
    this.firstName =  input.firstname;
    this.lastName = input.lastname;
    this.email = input.email;
    this.profileImage = input.profileImage || '';
    this.phoneNumber = input.mobile_number;
    this.company = Company.deserialize(input.company);
    this.id = input.id;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    this.roles = (input.roles as any[])?.map(UserRole.deserialize);
    return this;
  }

   clone(): User {
    return new User(this.firstName, this.lastName, this.email, this.profileImage,
      this.phoneNumber, this.company, this.id, this.active, this.verificationToken, this.roles);
  }

  public isProfileComplete(): boolean {
    return !!this.firstName && !!this.lastName && !!this.email
      && !!this.phoneNumber;
  }

  public hasCompany(): boolean {
    return !!this.company && !!this.company.id;
  }

  public hasUnnasignedCompany(): boolean {
    return !!this.company && this.company.id === -1;
  }

  public isCompanyOwner(): boolean {
    return !!this.company && this.id === this.company.ownerId;
  }

  public isCompanyAdmin(companyId: number): boolean {
    return !!this.roles?.find(r => r.role?.name === 'admin' && r.companyId === companyId && !r.brandId && !r.campaignId);
  }

  public getCompanyRole(companyId: number): UserRole {
    return this.roles?.find(r => r.companyId === companyId && !r.brandId && !r.campaignId);
  }

  public getBrandRole(companyId: number, brandId: number): UserRole {
    const brandRole = this.roles?.find(r => r.companyId === companyId && r.brandId === brandId && !r.campaignId);
    return brandRole ?? this.getCompanyRole(companyId);
  }

  public getCampaignRole(companyId: number, brandId: number, campaignId: number): UserRole {
    const campaignRole = this.roles?.find(r => r.companyId === companyId && r.brandId === brandId && r.campaignId === campaignId);
    return campaignRole ?? this.getBrandRole(companyId, brandId);
  }

  getName(): string {
    if (!this.firstName && !this.lastName) {
      return '';
    } else {
      return (this.firstName ? this.firstName : '') + ' ' + (this.lastName ? this.lastName : '');
    }
  }
}

export class UserRole {

  id: number;
  userId: number;
  companyId: number;
  brandId: number;
  campaignId: number;
  roleId: number;
  role?: Role;
  user?: User;
  brand?: Brand;

  constructor(id: number, userId: number, companyId: number, brandId: number, campaignId: number, roleId: number,
              role?: Role, user?: User, brand?: Brand) {
      this.id = id;
      this.userId = userId;
      this.companyId = companyId;
      this.brandId = brandId;
      this.campaignId = campaignId;
      this.roleId = roleId;
      this.role = role;
      this.user = user;
      this.brand = brand;
  }

  static deserialize(data: any) {
      return new UserRole(
          Number.parseInt(data.id, 10),
          data.user_id ? Number.parseInt(data.user_id, 10) : null,
          data.company_id ? Number.parseInt(data.company_id, 10) : null,
          data.brand_id ? Number.parseInt(data.brand_id, 10) : null,
          data.campaign_id ? Number.parseInt(data.campaign_id, 10) : null,
          data.role_id ? Number.parseInt(data.role_id, 10) : null,
          data.role ? Role.deserialize(data.role) : undefined,
          data.user ? User.deserialize(data.user) : undefined,
          data.brand ? Brand.deserialize(data.brand) : undefined
      );
  }
}
