import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription} from 'rxjs';
import { Creator } from '../../../../models/creator.model';
import { Submission } from '../../../../models/submission.model';
import { SubmissionDataDialog } from '../../../../models/SubmissionDataDialog.model';
import { NotificationService } from '../../../../services/notification.service';
import { SubmissionService } from '../../../../services/submission.service';
import { NotificationTypes } from '../../../../ui/footer/notification/const';
import { Privilege } from '../../../../models/role.model';
import { Campaign } from '../../../../models/campaign.model';
import { UserService } from '../../../../services/user.service';
import { CreatorApproval } from '../../../../models/CreatorApproval.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Time, TimeOptions, TimeOptionsList } from '../../../../models/timeOptions.model';
import { Band, BandList } from '../../../../models/band.model';
import { MatChip } from '@angular/material/chips';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';

@Component({
  selector: 'app-submission-dialog',
  templateUrl: './submission-dialog.component.html',
  styleUrls: ['./submission-dialog.component.css']
})
export class SubmissionDialogComponent implements OnInit {

  public socialNetwork: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public isRequestingEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public isRejecting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public changingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public isApproving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  @Output() deleteSubmission = new EventEmitter<Submission[]>();
  @Input() submissionInput: Submission;
  public status: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public creator: Creator;
  private tempSub: Submission;
  public campaign: Campaign;
  public sideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public submissionToEdit: Submission;
  public creatorApproval: Array<CreatorApproval>;
  public creatorApprovalOwner: CreatorApproval;
  public isEditing: boolean;
  public editFormGroup: FormGroup;
  public rejectFormGroup: FormGroup;
  public posting = false;
  public selectedDate: Date | null;
  public selectedHour: string;
  public selectedMinutes: string;
  public selectedOption = 'timeBand';
  private submissionDate: string;
  public date: string;
  public statusConfirm = false;
  public changingDate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public timeOptions: TimeOptions[] = TimeOptionsList.timeOptionsList;
  public hours: Time[] = [];
  public minutes: Time[] = [];
  public bands: Band[] = BandList.bandList;
  public dataFormGroup: FormGroup;
  public selectedBands: Band;
  public subscription: Subscription = new Subscription();
  private tempSubmission = new Submission();
  public isAdmin = false;
  public isStats = false;
  canEdit = false;


  constructor(
    private readonly dialog: MatDialog,
    private readonly submissionService: SubmissionService,
    private readonly notificationService: NotificationService,
    public dialogRef: MatDialogRef<SubmissionDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: SubmissionDataDialog
    ) {
      this.isEditing = false;
      this.tempSub = this.data.submission;
      this.creator = this.data.creators? this.data.creators.find(creator => creator.id === this.data.submission.userId) : this.data.creator;
      this.campaign = this.data.campaign;
      this.isStats = this.data.isStats?? false;
      this.creatorApproval = this.data.creatorApproval;
      // this.creatorApprovalOwner = this.data.creatorApproval.find(approval => approval.userId === this.data.submission.userId);
      this.submissionInput = this.data.submission;
      this.dataFormGroup = new FormGroup({
        date : new FormControl(undefined ,Validators.required),
        fullTime: new FormControl(undefined, Validators.required ),
        hour : new FormControl(undefined),
        minutes: new FormControl(undefined)
      });
      this.canEdit = this.userService.hasPrivileges([Privilege.PREDEFINED.EDIT_CONTENT],
        this.campaign.companyId, this.campaign.brandId, this.campaign.id
      );
    }


  public ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
    this.socialNetwork.next(this.data.submission.platform);
    this.status.next(this.data.submission.status);
    this.isRequestingEdit.next(false);
    this.isRejecting.next(false);
    this.changingStatus.next(false);
    this.isApproving.next(false);
    this.updateNotificationMessagesReaded();
    this.getHours();
    this.getMinutes();
  }

  private updateNotificationMessagesReaded(): void {
    const markAsReaded = this.notificationService
      .getNotReadedNotifications()
        .filter(not => not.data?.creatorId == this.creator.id && NotificationTypes.CREATOR_SUBMISSION_VALIDATION_REQUEST === not.data?.name && not.data?.submissionId == this.data.submission.id)
          .map(not => not.id);
    markAsReaded.length && this.notificationService.markAsRead(markAsReaded).subscribe();
  }

  public closeDialog(submission: Submission = null): void {
    this.dialogRef.close(submission);
  }

  public openRejectDialog(): void {
    this.dialogRef.updateSize('992px','550px');
    this.changingStatus.next(true);
    this.isRejecting.next(true);
    this.rejectFormGroup = new FormGroup({
      reason : new FormControl(undefined, Validators.required),
      comment : new FormControl(undefined, Validators.required)
    });
  }

  public openEditDialog(): void {
    this.dialogRef.updateSize('992px','550px');
    this.changingStatus.next(true);
    this.isRequestingEdit.next(true);
    this.editFormGroup = new FormGroup({
      comment : new FormControl(undefined ,Validators.required)
    });
  }

  openEditSubmissionModal(sub: Submission): void {
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CONTENT], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
        this.submissionToEdit = sub;
        this.isEditing = (this.isEditing == true) ? false : true;
      }
  }

  public reject(): void{
    this.changeStatus(this.rejectFormGroup);
  }

  public edit(): void{
    this.changeStatus(this.editFormGroup);
  }

  public cancelRequestingEdit(){
    this.isRejecting.next(false);
    this.isRequestingEdit.next(false);
    this.changingStatus.next(false);
    this.isApproving.next(false);
    this.dialogRef.updateSize('992px','720px');
  }

  private changeStatus(formGroup: FormGroup): void {
      if(formGroup) {
        const reason = formGroup.controls.reason ? formGroup.controls.reason.value : '';
        const comment = formGroup.controls.comment ? formGroup.controls.comment.value : '';
        this.tempSub.captionErrors = reason + (comment ? ' - ' + comment : '');
        if(this.isRequestingEdit.value){
          this.tempSub.status = 'Update Required';
        } else {
          this.tempSub.status = 'Rejected';
        }
        this.submissionService.changeSubmissionStatus(this.tempSub.status, this.tempSub).subscribe({
          next: () => {
            // this.closeDialog(this.tempSub);
          }, error: (error: HttpErrorResponse) => {
            console.log(error);
          }
        });
      }
      this.closeDialog(this.tempSub);
  }

  public openConfirmModal(): void{
    this.changingStatus.next(true);
    this.isApproving.next(true);
    this.dialogRef.updateSize('760px', '900px');
  }

  selectDate() {
    this.openConfirmModal();
  }

  private getHours(): void{
    for(let i = 0; i< 24; i++){
      this.hours.push({value: this.convert(i)});
    }
  }


  private getMinutes(): void{
    for(let i = 0; i< 60 ; i++){
      this.minutes.push({value: this.convert(i)});
    }
  }

  public convert(n: any): string {
    n = String(n);
    if (n.length == 1) {
n = '0' + n;
};
    return n;
  }

  public timeSelector(value: string): string{
    if(value === 'timeBand'){
      this.dataFormGroup.get('fullTime').setValidators([Validators.required]);
      this.dataFormGroup.get('hour').clearValidators();
      this.dataFormGroup.get('minutes').clearValidators();
    } else {
      this.dataFormGroup.get('hour').setValidators([Validators.required]);
      this.dataFormGroup.get('minutes').setValidators([Validators.required]);
      this.dataFormGroup.get('fullTime').clearValidators();
    }
    this.dataFormGroup.get('hour').updateValueAndValidity();
    this.dataFormGroup.get('minutes').updateValueAndValidity();
    this.dataFormGroup.get('fullTime').updateValueAndValidity();
    this.dataFormGroup.controls.hour.setValue('');
    this.dataFormGroup.controls.minutes.setValue('');
    this.dataFormGroup.controls.fullTime.setValue(undefined);
    this.selectedBands = Band[''];
    this.selectedOption = value;
    return value;
  }

  public setFormControlFullTime(band: any, chip: MatChip): void{
    chip.toggleSelected();
    this.dataFormGroup.get('fullTime').setValue(band);
    this.selectedBands = band;
  }

  public getChangedValue(newDate: any): void {
    this.dataFormGroup.get('date').setValue(newDate);
    this.changingDate.next(true);
    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    };

    this.date = newDate.toLocaleDateString('en', options);
    this.submissionDate = this.selectedDate.toISOString().split('T')[0];
    this.selectedDate = newDate;
  }

  public changeStatusConfirm(status: string): void{

    if(this.selectedOption === 'timeBand'){
      this.tempSubmission.timeToPostStart = this.selectedBands['value']?.substring(0,5);
      this.tempSubmission.timeToPostEnd = this.selectedBands['value']?.substring(6,11);
    } else {
      this.tempSubmission.timeToPostStart = this.selectedHour + ':' + this.selectedMinutes;
    }

    this.tempSubmission.status = status;
    this.submissionInput.dateToPost = this.submissionDate;
    this.submissionInput.timeToPostStart = this.tempSubmission.timeToPostStart;
    this.submissionInput.timeToPostEnd = this.tempSubmission.timeToPostEnd ?? '';
    this.submissionInput.status = this.tempSubmission.status;
    this.submissionInput.captionErrors = this.tempSubmission.captionErrors;

    if(!this.dataFormGroup.invalid){
      this.subscription = this.submissionService.changeSubmissionStatus(status, this.submissionInput).subscribe(
        () => {
          this.statusConfirm = true;
          // setTimeout(() => {
            // this.closeConfirmModal();
          // }, 5000);
        }
      );
    }
    this.closeConfirmModal();
  }

  public closeConfirmModal(): void {
    this.dialogRef.close(this.submissionInput);
  }
}
