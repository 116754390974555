import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile-image-modal',
  templateUrl: './profile-image-modal.component.html',
  styleUrls: ['./profile-image-modal.component.scss']
})
export class ProfileImageModalComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Input() image: string;
  @Output() imageChange = new EventEmitter<string>();
  @Input() strings: Array<string>;
  imageChangedEvent: Event;
  croppedImage = '';
  modalRef: NgbModalRef;

  ngOnInit(): void {
    return;
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }
  imageLoaded(): void {
    // show cropper
  }
  loadImageFailed(): void {
    // show message
  }

  open(content: TemplateRef<ProfileImageModalComponent>): void {
    this.modalRef = this.modalService.open(content,  {windowClass: 'modal-dialog-image-picker'});
  }

  close(): void {
    this.imageChange.emit(this.croppedImage);
    this.modalRef.close();
  }
}
