import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { CreatorService } from 'src/app/services/creator.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild(SpinnerComponent) spinner: SpinnerComponent;
  public recipientIds: string;
  public temporaryPasswordRecipientIds = '';
  public message: string;
  private regexp = /^\d+$|^(\d+,\d+)+$/;
  public inputsAreValid = true;
  public temporaryPasswordsRecipientsAreValid = true;
  public posting = false;
  public postingTemporaryPasswords = false;
  private subscription: Subscription;
  public confirmed = false;
  public generateConfirmed = false;
  public specifyCreators = false;
  public specifyDates = false;
  public loginStart = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
  public loginEnd = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
  public title: string;

  constructor(private ns: NotificationService, private as: AlertService, private cs: CreatorService) { }

  ngOnInit(): void {
    return;
  }

  recipientIdsChanged(): void {
    this.inputsAreValid = this.regexp.test(this.recipientIds);
  }
  temporaryPasswordRecipientsChanged(): void {
    this.temporaryPasswordsRecipientsAreValid = this.regexp.test(this.temporaryPasswordRecipientIds);
  }
  generateTemporaryPasswords(): void {
    this.postingTemporaryPasswords = true;
    if (!this.generateConfirmed) {
      return;
    }
    this.spinner.spin();
    const ids = this.specifyCreators && this.temporaryPasswordRecipientIds.length > 0 ?
      this.temporaryPasswordRecipientIds.split(',').map(parseInt) : [];
    this.subscription =
      this.cs.generateTemporaryPasswords(ids,
        this.specifyDates ? new Date(this.loginStart.year, this.loginStart.month - 1, this.loginStart.day) : null,
        this.specifyDates ? new Date(this.loginEnd.year, this.loginEnd.month - 1, this.loginEnd.day) : null)
      .subscribe(() => {
        this.spinner.stop();
        this.postingTemporaryPasswords = false;
        this.generateConfirmed = false;
        this.as.emitSuccess('Passwords generated successfully');
      });
  }
  sendNotification(): void {
    this.posting = true;
    if (!this.confirmed) {
      return;
    }
    this.spinner.spin();
    this.subscription =
      this.ns.sendNotifications(this.recipientIds, this.message, this.title).
      subscribe( () => {
        this.spinner.stop();
        this.posting = false;
        this.confirmed = false;
        this.as.emitSuccess('Notification sent successfully');
      });
  }
}
