import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { CampaignService } from './campaign.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private generating = new EventEmitter<boolean>();

  constructor(private restService: RestService, private campaignService: CampaignService, private translateService: TranslateService) { }

  public getGeneratingEvent(): EventEmitter<boolean> {
    return this.generating;
  }

  public generateInfluencerReport(): void {
    let readyToExport = [];
    this.generating.emit(true);
    this.restService.get('reportInfluencer').subscribe(x => {
      this.generating.emit(false);
      readyToExport = x.report;
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(readyToExport);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      XLSX.writeFile(workBook, 'inflencer.xlsx'); // initiate a file download in browser
    });
  }

  public generateInfluencerByCampaignReport(): void {
    let readyToExport = [];
    this.generating.emit(true);
    this.restService.get('reportInfluencerByCampaign').subscribe(x => {
      this.generating.emit(false);
      readyToExport = x.report;
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(readyToExport);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      XLSX.writeFile(workBook, 'inflencerByCampaign.xlsx'); // initiate a file download in browser
    });
  }


  generateCampaignReports(): void {
    this.generating.emit(true);
    this.restService.get('reportCampaigns').subscribe(x => {
      this.generating.emit(false);
      const report = x.report.map(campaign => {
        const row: { [column: string]: string } = {};
        row[this.translateService.instant('CAMPAIGN.CAMPAIGN')] = campaign.campaign_name;
        row[this.translateService.instant('REPORTS.APPROVED.CONTENTS')] = campaign.approved_contents;
        row[this.translateService.instant('REPORTS.CURRENT.IMPRESSIONS')] = campaign.impressions;
        row[this.translateService.instant('CAMPAIGN.SUBMISSIONS.LIKES')] = campaign.likes;
        row[this.translateService.instant('CAMPAIGN.SUBMISSIONS.COMMENTS')] = campaign.comments;
        row[this.translateService.instant('CAMPAIGN.BUDGET.NET')] = campaign.budget_net ?? 0;
        row[this.translateService.instant('DASHBOARD.STATUS')] = campaign.campaign_status;
        return row;
      });

      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(report);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      XLSX.writeFile(workBook, 'campaignsReport.xlsx'); // initiate a file download in browser
    });
  }
}
