import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NegotiationMessage } from '../models/negotiationMessage.model';
import { NegotiationOffer } from '../models/negotiationOffer.model';
import { RestService } from './rest.service';

interface InstagramContents {
  feeds: number;
  stories: number;
  reels: number;
}
interface YoutubeContents {
  video_mentions: number;
  video_exclusives: number;
}
interface TiktokContents {
  videos: number;
}

@Injectable({
    providedIn: 'root'
})
export class NegotiationService {

  constructor(private rs: RestService) { }

  getNegotiationOffers(companyId: number, brandId: number, campaignId: number, creatorId: number): Observable<NegotiationOffer[]> {
    return this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/offers`).pipe(
      map((res: any[]) => res.map(o => new NegotiationOffer().deserialize(o)))
    );
  }

  makeNegotiationOffer(companyId: number, brandId: number, campaignId: number, creatorId: number,
    comment?: string, instagramPrice?: number, tiktokPrice?: number, youtubePrice?: number, instagramContents?: InstagramContents,
    tiktokContents?: TiktokContents, youtubeContents?: YoutubeContents): Observable<NegotiationOffer> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/offers`,
      { comment, instagram_price: instagramPrice, tiktok_price: tiktokPrice, instagram: instagramContents,
        tiktok: tiktokContents, youtube: youtubeContents, youtube_price: youtubePrice
      }).pipe(
      map(res => new NegotiationOffer().deserialize(res))
    );
  }

  acceptNegotiationOffer(companyId: number, brandId: number, campaignId: number, creatorId: number,
    offerId: number): Observable<NegotiationOffer> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/offers/${offerId}/accept`, null).pipe(
      map(res => new NegotiationOffer().deserialize(res))
    );
  }

  declineNegotiationOffer(companyId: number, brandId: number, campaignId: number, creatorId: number,
    offerId: number): Observable<NegotiationOffer> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/offers/${offerId}/decline`, null)
      .pipe(
        map(res => new NegotiationOffer().deserialize(res))
      );
  }

  sendNegotiationMessage(companyId: number, brandId: number, campaignId: number, creatorId: number,
    messageData: NegotiationMessage): Observable<NegotiationMessage> {
    return this.rs.post(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/messages`,
        { message: messageData.message }
    ).pipe(
        map(NegotiationMessage.deserialize)
    );
  }

  getNegotiationMessages(companyId: number, brandId: number, campaignId: number, creatorId: number): Observable<NegotiationMessage[]> {
    return this.rs.get(`companies/${companyId}/brands/${brandId}/campaigns/${campaignId}/creators/${creatorId}/messages`).pipe(
        map((res: any[]) => res.map(NegotiationMessage.deserialize))
    );
  }
}
