import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { options } from './gender-chart-doughnut.config';

@Component({
  selector: 'app-gender-chart-doughnut',
  templateUrl: './gender-chart-doughnut.component.html',
  styleUrls: ['./gender-chart-doughnut.component.css']
})
export class GenderChartDoughnutComponent implements OnInit {
  public chart: Chart<'doughnut', number[], string>;
  @Input() public labels: string[];
  @Input() public femaleData: string[];
  @Input() public maleData: string[];
  private femaleQuantity: number;
  private maleQuantity: number;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.femaleQuantity = this.femaleData.reduce((acumulador, numero) => acumulador + Number(numero), 0);
    this.maleQuantity = this.maleData.reduce((acumulador, numero) => acumulador + Number(numero), 0);
    this.createChart();
  }

  createChart(): void {
    const htmlRef = this.elementRef.nativeElement.querySelector(`#genderChart`);
    this.chart = new Chart(htmlRef, {
      type: 'doughnut',
      data: {
        labels: [' ' + Math.round((100 * this.maleQuantity) / (this.maleQuantity + this.femaleQuantity)) + '%' + ' Male',
        ' ' + Math.round((100 * this.femaleQuantity) / (this.maleQuantity + this.femaleQuantity)) + '%' +' Female'],
        datasets: [
          {
            data: [this.maleQuantity, this.femaleQuantity],
            borderWidth: 0.1,
            backgroundColor: [
              '#7400E7',
              '#D8C2ED'
            ]
          },
        ]
      },
      options
    });
  }

}
