import { Component, OnInit, ViewChild } from '@angular/core';
import { BrandService } from '../../../services/brand.service';
import { Company } from '../../../models/company.model';
import { AlertService } from '../../../services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditCompanyDialogComponent } from '../edit-company-dialog/edit-company-dialog.component';

@Component({
  selector: 'app-company-approval',
  templateUrl: './company-approval.component.html',
  styleUrls: ['./company-approval.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition('void => *', [
        style({transform: 'translateY(-100%)', opacity: 0}),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({transform: 'translateY(-100%)', opacity: 0}))
      ])
    ])
  ]
})
export class CompanyApprovalComponent implements OnInit {

  public dataSource: MatTableDataSource<Company>;
  public displayedColumns: string[] = ['id', 'brandName', 'legalName', 'approved', 'registerDate', 'options'];
  public allCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>(undefined);
  public filteredCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>(undefined);
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  approvedCompanies: Array<Company>;
  selectedCompany: Company | null = null;
  approvalFilter = true;
  filterString = '';
  loading = false;
  reverseSort = false;

  constructor(private brandService: BrandService,
              private alertService: AlertService,
              public userService: UserService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router,
              private location: Location) { }

  ngOnInit(): void {
    this.allCompanies.subscribe({
      next: (companies: Company[]) => {
        this.initDataSource(companies);
      }, error: (error: Error) => {
        console.log(error);
      }
    });
    this.filteredCompanies.subscribe({
      next: (companies: Company[]) => {
        this.initDataSource(companies);
      }, error: (error: Error) => {
        console.log(error);
      }
    });
    this.loading = true;
    combineLatest([this.route.paramMap, this.route.queryParamMap]).subscribe(([routeParams, queryParams]) => {
      if (this.userService.isAdmin()) {
        this.brandService.getAllCompanies().subscribe(companies => {
          this.approvedCompanies = new Array<Company>();
          this.allCompanies.next(companies);
          this.filteredCompanies.next(companies);
          this.approvedCompanies = companies.filter(c => c.isAdminApproved);
          this.reverseSort = true;
          this.loading = false;
          this.sortData('approved');
          this.selectedCompany = routeParams.has('companyId') ?
            companies.find(c => c.id.toString() === routeParams.get('companyId')) : null;
          if (queryParams.has('company')) {
            this.filterString = queryParams.get('company');
            this.filter();
          }
        });
      } else {
        this.brandService.getCompany().subscribe(c => {
          this.selectedCompany = c;
        });
      }
    });
  }

  public sortData(field: string) {
    const reverse = this.reverseSort ? -1 : 1;
    switch (field) {
      case 'id':
        this.filteredCompanies.next(this.filteredCompanies.value.sort((c1, c2) => (c1.id - c2.id) * reverse));
        break;
      case 'brandName':
        this.filteredCompanies.next(this.filteredCompanies.value.sort((c1, c2) => c1.brandName.localeCompare(c2.brandName) * reverse));
        break;
      case 'legalName':
        this.filteredCompanies.next(this.filteredCompanies.value.sort((c1, c2) => c1.legalName.localeCompare(c2.legalName) * reverse));
        break;
      case 'approved':
        this.approvalFilter = !this.approvalFilter;
        this.filter();
        break;
      case 'registerDate':
        this.filteredCompanies.next(this.filteredCompanies.value.sort((c1, c2) => (c1.registerDate.getTime() - c2.registerDate.getTime()) * reverse));
    }
    this.reverseSort = !this.reverseSort;
  }

  private initDataSource(companies: Company[]): void {
    this.dataSource = new MatTableDataSource(companies);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public filter(): void {
    this.filteredCompanies.next(this.allCompanies.value.filter(x =>
      (!this.approvalFilter || !x.isAdminApproved) &&
      (x.legalName.toLowerCase().includes(this.filterString.toLowerCase()) ||
      x.brandName.toLowerCase().includes(this.filterString.toLowerCase()))
    ));
  }

  companyCreated(company?: Company): void {
    if (company) {
      this.allCompanies.next(this.allCompanies.value.concat(company));
      this.filteredCompanies.next(this.filteredCompanies.value.concat(company));
      this.approvedCompanies = this.approvedCompanies.concat(company);
    } else {
      window.location.reload();
    }
  }

  approve(company: Company): void {
    this.loading = true;
    this.userService.validateOnboarding(company.id).subscribe(() => {
      company.isAdminApproved = true;
      this.alertService.emitSuccess( 'Company was approved.');
      this.loading = false;
    });
  }

  delete(company: Company): void {
    this.loading = true;
    this.allCompanies.next(this.allCompanies.value.filter(c => c.id !== company.id));
    this.filteredCompanies.next(this.filteredCompanies.value.filter(c => c.id !== company.id));
    this.approvedCompanies = this.approvedCompanies.filter(c => c.id !== company.id);
    this.brandService.delete(company.id).subscribe(() => {
      this.alertService.emitSuccess( 'Company was deleted.');
      this.loading = false;
    }, () => {
      this.allCompanies.next(this.allCompanies.value.concat(company));
      this.filteredCompanies.next(this.filteredCompanies.value.concat(company));
      this.loading = false;
    });
  }

  selectCompany(company: Company) {
    const url = this.router.createUrlTree([`companyApproval/${company.id}`]).toString();
    this.location.go(url);
    this.selectedCompany = company;
  }

  goBack() {
    this.location.go('companyApproval');
    this.selectedCompany = null;
  }

  public openEditCompanyDialog(company: Company): void {
    this.dialog.open(EditCompanyDialogComponent, {
      width: '1000px',
      data: company
    });
  }

  // public editCompany(company: Company) {
  //   this.brandService.editCompany(company.id, company.legalName, company.brandName, company.logo);
  // }
}
