import { Component, Input, OnChanges } from '@angular/core';
import { Campaign } from '../../../models/campaign.model';
import {
  CreatorApproval,
  CreatorApprovalPlanner,
} from '../../../models/CreatorApproval.model';
import { Helper } from '../../../support/helper.support';

@Component({
  selector: 'app-suggested-price-tabs',
  templateUrl: './suggested-price-tabs.component.html',
  styleUrls: ['./suggested-price-tabs.component.css'],
})
export class SuggestedPriceTabsComponent implements OnChanges {
  @Input() creatorType: string;
  @Input() campaign: Campaign;
  @Input() creators: CreatorApproval[];
  @Input() remainingBudget: number;
  public budgetGross: number;
  public suggestedPricesPlanner: CreatorApprovalPlanner;
  constructor() {
    this.budgetGross = 0;
  }

  ngOnChanges(): void {
    this.suggestedPricesPlanner = new CreatorApprovalPlanner();
    this.suggestedPricesPlanner.content.feeds = 0;
    this.suggestedPricesPlanner.content.reels = 0;
    this.suggestedPricesPlanner.content.stories = 0;
    this.suggestedPricesPlanner.negotiatedPrice = 0;
    this.suggestedPricesPlanner.content.tiktokVideos = 0;
    this.suggestedPricesPlanner.content.exclusives = 0;
    this.suggestedPricesPlanner.content.mentions = 0;
    this.creators.forEach((creator) => {
      creator.planner.forEach((item) => {
        this.suggestedPricesPlanner.content.feeds += item.content.feeds || 0;
        this.suggestedPricesPlanner.content.reels += item.content.reels || 0;
          this.suggestedPricesPlanner.content.stories += item.content.stories || 0;
          this.suggestedPricesPlanner.negotiatedPrice += item.negotiatedPrice || 0;
          //TODO CAMBIAR LA LINEA 40 CUANDO SE SOLUCIONE ESTO
          this.suggestedPricesPlanner.content.tiktokVideos += item.platform === 'tiktok' ? item.content.reels || 0 : 0;
          this.suggestedPricesPlanner.content.exclusives +=
            item.content.exclusives || 0;
          this.suggestedPricesPlanner.content.mentions +=
            item.content.mentions || 0;
        }
      );
    });
    this.budgetGross = this.campaign.getGrossPrice(this.campaign.budgetGross);
    this.suggestedPricesPlanner.negotiatedPrice = this.campaign.getGrossPrice(this.suggestedPricesPlanner.negotiatedPrice);
  }
}
